import React, { useEffect, useState } from 'react';
import { getDatabase, ref as dbRef, onValue, query, orderByChild, startAt } from 'firebase/database';
import './ContactStats.css';

const ContactStats = () => {
    const [last24HoursCount, setLast24HoursCount] = useState(0);
    const [last7DaysCount, setLast7DaysCount] = useState(0);
    const [last30DaysMeetingsCount, setLast30DaysMeetingsCount] = useState(0);
    const [last30DaysSalesCount, setLast30DaysSalesCount] = useState(0);
    const db = getDatabase();

    useEffect(() => {
        const now = new Date();
        const last24Hours = new Date(now.getTime() - (24 * 60 * 60 * 1000)).toISOString();
        const last7Days = new Date(now.getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString();
        const last30Days = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000)).toISOString();

        const marketingRef = dbRef(db, 'marketingDatabase');

        // Query for last 24 hours
        const last24HoursQuery = query(marketingRef, orderByChild('contactedDate'), startAt(last24Hours));
        onValue(last24HoursQuery, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setLast24HoursCount(Object.keys(data).length);
            } else {
                setLast24HoursCount(0);
            }
        });

        // Query for last 7 days
        const last7DaysQuery = query(marketingRef, orderByChild('contactedDate'), startAt(last7Days));
        onValue(last7DaysQuery, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setLast7DaysCount(Object.keys(data).length);
            } else {
                setLast7DaysCount(0);
            }
        });

        // Query for last 30 days meetings
        const last30DaysMeetingsQuery = query(marketingRef, orderByChild('meetingDate'), startAt(last30Days));
        onValue(last30DaysMeetingsQuery, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setLast30DaysMeetingsCount(Object.keys(data).length);
            } else {
                setLast30DaysMeetingsCount(0);
            }
        });

        // Query for last 30 days sales
        const last30DaysSalesQuery = query(marketingRef, orderByChild('saleDate'), startAt(last30Days));
        onValue(last30DaysSalesQuery, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setLast30DaysSalesCount(Object.keys(data).length);
            } else {
                setLast30DaysSalesCount(0);
            }
        });
    }, [db]);

    return (
        <div className="contact-stats">
            <p style={{marginLeft: '10px'}}>Contacted/24h: <span>{last24HoursCount}</span></p>
            <p>Contacted/Week: <span>{last7DaysCount}</span></p>
            <p>Meetings/Month: <span>{last30DaysMeetingsCount}</span></p>
            <p>Sales/Month: <span>{last30DaysSalesCount}</span></p>
        </div>
    );
};

export default ContactStats;
