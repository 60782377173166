import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref as dbRef, remove, get } from "firebase/database";
import { getAuth } from 'firebase/auth';
import './AddPageComponent.css';

const PagesDisplay = ({ pages, setPages }) => {
    const [focusedCardId, setFocusedCardId] = useState(null);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [pageToDelete, setPageToDelete] = useState(null);
    const navigate = useNavigate();
    const db = getDatabase();
    const auth = getAuth();
    const currentUser = auth.currentUser;

    const openPage = (pageId) => {
        navigate(`/settings/page/${pageId}`);
    };

    const promptDeletePage = (pageId) => {
        setPageToDelete(pageId);
        setDeleteModalShow(true);
    };

    const confirmDeletePage = () => {
        if (!currentUser || !pageToDelete) {
            console.error('Fehler: Kein Benutzer angemeldet oder keine Seite ausgewählt.');
            return;
        }
        // Referenz zur Seite, die gelöscht werden soll
        const pageRef = dbRef(db, `/pages/${pageToDelete}`);
        // Zuerst die Seite abrufen, um die benutzerdefinierte Route zu erhalten
        get(pageRef).then(snapshot => {
            if (snapshot.exists()) {
                const pageData = snapshot.val();
                // Referenzen zu den entsprechenden Einträgen in `publicPages`
                const customRouteRef = dbRef(db, `/publicPages/${pageData.customDomain}`);
                const pageIdRef = dbRef(db, `/publicPages/${pageToDelete}`);
                // Lösche den Eintrag basierend auf der customDomain
                return remove(customRouteRef).then(() => {
                    // Lösche den Eintrag basierend auf der pageId
                    return remove(pageIdRef);
                }).then(() => {
                    // Nach erfolgreichem Löschen der Einträge in `publicPages`, die Seite selbst löschen
                    return remove(pageRef);
                });
            } else {
                console.error('Seite nicht gefunden.');
                return Promise.reject('Seite nicht gefunden.');
            }
        })
        .then(() => {
            console.log("Seite und zugehörige Einträge in 'publicPages' erfolgreich gelöscht");
            // Aktualisiere den Seitenzustand, um die gelöschte Seite aus der Liste zu entfernen
            setPages(pages.filter(page => page.id !== pageToDelete));
            // Schließe das Modal zum Löschen
            setDeleteModalShow(false);
        })
        .catch(error => {
            console.error("Fehler beim Löschen der Seite und der zugehörigen Einträge in 'publicPages':", error);
        });
        // Setze `pageToDelete` zurück
        setPageToDelete(null);
    };

    return (
        <>
            {deleteModalShow && (
                <div className="modal-background">
                    <div className="modal-content" style={{ height: 350 }}>
                        <h2>Really want to delete this masterpiece?</h2>
                        <div className="qr-buttons">
                            <button className="qr-modal-open" onClick={confirmDeletePage}>Yes, delete</button>
                            <button className="qr-modal-close" onClick={() => setDeleteModalShow(false)}>Close</button>
                        </div>
                    </div>
                </div>
            )}
            <div className="pages-display">
                <div className="pages-grid">
                    {pages.map((page) => (
                        <div
                            key={page.id}
                            className="page-card"
                            onMouseEnter={() => setFocusedCardId(page.id)}
                            onMouseLeave={() => setFocusedCardId(null)}
                            onClick={() => openPage(page.id)}
                        >
                            <div>
                                <div className="page-card-header-left">
                                    {page.viewCount > 0 && <div><img className="page-card-delete-icon" src="./img/view.svg" alt="" /><p>{page.viewCount}</p></div>}
                                </div>
                                <div className="page-card-header-right">
                                    <div className="page-card-header-right-open">
                                        <a href={page.customDomain ? `https://bonusclub.link/to/${page.customDomain}` : `https://bonusclub.link/${page.id}`} target="_blank" rel="noopener noreferrer" className="live-page-link">
                                            <img className="page-card-upright-icon" src="./img/upright.svg" alt="" />
                                        </a>
                                    </div>
                                    <div className={`page-card-header-right-close ${focusedCardId === page.id ? 'visible' : ''}`}>
                                        <button className="page-card-icon-button" onClick={(e) => { e.stopPropagation(); promptDeletePage(page.id); }}><img className="page-card-delete-icon" src="./img/trasher.svg" alt="" /></button>
                                    </div>
                                </div>
                            </div>

                            <div className="page-qr-code">
                                {page.isActive ? (
                                    <>
                                        <div className="circle-overlay">
                                            <img src="./img/bonusclub_icon.png" alt="Image im Kreis" />
                                        </div>
                                        <QRCode
                                            className="modal-qr-code-2"
                                            value={page.customDomain ? `https://bonusclub.link/to/${page.customDomain}` : `bonusclub.link/${page.id}`}
                                        />
                                    </>
                                ) : (
                                    <div className="inactive-qr-code">
                                        <div className="inactive-qr-background">
                                        </div>
                                        <p style={{ fontWeight: '500', fontSize: '14px' }}>PUBLISH TO <br />GET QR CODE</p>
                                    </div>
                                )}
                            </div>
                            <h3>{page.name}</h3>
                            <p className="page-card-description">{page.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default PagesDisplay;
