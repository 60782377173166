import React, { useState } from 'react';
import "./IconPicker.css"

// Dummy-Icons für das Beispiel
const iconData = [

  { id: 'icon19', src: '/img/gif/clean/activity.gif', alt: 'Activity', style: 'Animations' },
  { id: 'icon20', src: '/img/gif/clean/bar1.gif', alt: 'Bar1', style: 'Animations' },
  { id: 'icon21', src: '/img/gif/clean/bird.gif', alt: 'Bird', style: 'Animations' },
  { id: 'icon22', src: '/img/gif/clean/brush.gif', alt: 'Brush', style: 'Animations' },
  { id: 'icon23', src: '/img/gif/clean/camel.gif', alt: 'Camel', style: 'Animations' },
  { id: 'icon24', src: '/img/gif/clean/camera.gif', alt: 'Camera', style: 'Animations' },
  { id: 'icon25', src: '/img/gif/clean/car.gif', alt: 'Car', style: 'Animations' },
  { id: 'icon26', src: '/img/gif/clean/champagne.gif', alt: 'Champagne', style: 'Animations' },
  { id: 'icon27', src: '/img/gif/clean/chat.gif', alt: 'Chat', style: 'Animations' },
  { id: 'icon28', src: '/img/gif/clean/check.gif', alt: 'Check', style: 'Animations' },
  { id: 'icon29', src: '/img/gif/clean/chicken.gif', alt: 'Chicken', style: 'Animations' },
  { id: 'icon30', src: '/img/gif/clean/coke.gif', alt: 'Coke', style: 'Animations' },
  { id: 'icon31', src: '/img/gif/clean/diamond.gif', alt: 'Diamond', style: 'Animations' },
  { id: 'icon32', src: '/img/gif/clean/dolphin.gif', alt: 'Dolphin', style: 'Animations' },
  { id: 'icon33', src: '/img/gif/clean/drink.gif', alt: 'Drink', style: 'Animations' },
  { id: 'icon34', src: '/img/gif/clean/drinks.gif', alt: 'Drinks', style: 'Animations' },
  { id: 'icon35', src: '/img/gif/clean/film.gif', alt: 'Film', style: 'Animations' },
  { id: 'icon36', src: '/img/gif/clean/heart.gif', alt: 'Heart', style: 'Animations' },
  { id: 'icon37', src: '/img/gif/clean/heart2.gif', alt: 'Heart2', style: 'Animations' },
  { id: 'icon38', src: '/img/gif/clean/heartballon.gif', alt: 'Heartballon', style: 'Animations' },
  { id: 'icon39', src: '/img/gif/clean/location.gif', alt: 'Location', style: 'Animations' },
  { id: 'icon40', src: '/img/gif/clean/mail.gif', alt: 'Mail', style: 'Animations' },
  { id: 'icon41', src: '/img/gif/clean/music.gif', alt: 'Music', style: 'Animations' },
  { id: 'icon42', src: '/img/gif/clean/nav.gif', alt: 'Nav', style: 'Animations' },
  { id: 'icon43', src: '/img/gif/clean/palm.gif', alt: 'Palm', style: 'Animations' },
  { id: 'icon44', src: '/img/gif/clean/pen.gif', alt: 'Pen', style: 'Animations' },
  { id: 'icon45', src: '/img/gif/clean/percentage.gif', alt: 'Percentage', style: 'Animations' },
  { id: 'icon46', src: '/img/gif/clean/pig.gif', alt: 'Pig', style: 'Animations' },
  { id: 'icon47', src: '/img/gif/clean/plate.gif', alt: 'Plate', style: 'Animations' },
  { id: 'icon48', src: '/img/gif/clean/present.gif', alt: 'Present', style: 'Animations' },
  { id: 'icon49', src: '/img/gif/clean/servant.gif', alt: 'Servant', style: 'Animations' },
  { id: 'icon50', src: '/img/gif/clean/speaker.gif', alt: 'Speaker', style: 'Animations' },
  { id: 'icon51', src: '/img/gif/clean/sportscar.gif', alt: 'Sportscar', style: 'Animations' },
  { id: 'icon52', src: '/img/gif/clean/sun.gif', alt: 'Sun', style: 'Animations' },
  { id: 'icon53', src: '/img/gif/clean/tea.gif', alt: 'Tea', style: 'Animations' },
  { id: 'icon54', src: '/img/gif/clean/vip.gif', alt: 'Vip', style: 'Animations' },
  { id: 'icon55', src: '/img/gif/clean/vip2.gif', alt: 'Vip2', style: 'Animations' },
  { id: 'icon56', src: '/img/gif/clean/20percent.gif', alt: '20 Percent', style: 'Animations' },
  { id: 'icon57', src: '/img/gif/clean/30percent.gif', alt: '30 Percent', style: 'Animations' },
  { id: 'icon58', src: '/img/gif/clean/40percent.gif', alt: '40 Percent', style: 'Animations' },
  { id: 'icon59', src: '/img/gif/clean/50percent.gif', alt: '50 Percent', style: 'Animations' },
  { id: 'icon60', src: '/img/gif/clean/70percent.gif', alt: '70 Percent', style: 'Animations' },
  { id: 'icon61', src: '/img/gif/clean/80percent.gif', alt: '80 Percent', style: 'Animations' },
  { id: 'icon62', src: '/img/gif/clean/persons.gif', alt: 'Persons', style: 'Animations' },
  { id: 'icon63', src: '/img/gif/clean/flipflops.gif', alt: 'Flipflops', style: 'Animations' },
  { id: 'icon65', src: '/img/gif/clean/pool.gif', alt: 'Pool', style: 'Animations' },
  { id: 'icon66', src: '/img/gif/clean/gas.gif', alt: 'Gas', style: 'Animations' },
  { id: 'icon67', src: '/img/gif/clean/scooter.gif', alt: 'Scooter', style: 'Animations' },
  { id: 'icon68', src: '/img/gif/clean/bike.gif', alt: 'Bike', style: 'Animations' },
  { id: 'icon69', src: '/img/gif/clean/toiletpaper.gif', alt: 'Toiletpaper', style: 'Animations' },
  { id: 'icon70', src: '/img/gif/clean/hairdryer.gif', alt: 'Hairdryer', style: 'Animations' },
  { id: 'icon71', src: '/img/gif/clean/bath.gif', alt: 'Bath', style: 'Animations' },
  { id: 'icon72', src: '/img/gif/clean/furniture.gif', alt: 'Furniture', style: 'Animations' },
  { id: 'icon73', src: '/img/gif/clean/bed.gif', alt: 'Bed', style: 'Animations' },
  { id: 'icon74', src: '/img/gif/clean/sofa.gif', alt: 'Sofa', style: 'Animations' },
  { id: 'icon75', src: '/img/gif/clean/suhsi.gif', alt: 'Suhsi', style: 'Animations' },
  { id: 'icon76', src: '/img/gif/clean/kitchen.gif', alt: 'Kitchen', style: 'Animations' },
  { id: 'icon77', src: '/img/gif/clean/pasta.gif', alt: 'Pasta', style: 'Animations' },
  { id: 'icon78', src: '/img/gif/clean/bread.gif', alt: 'Bread', style: 'Animations' },
  { id: 'icon79', src: '/img/gif/clean/boiled.gif', alt: 'Boiled', style: 'Animations' },
  { id: 'icon80', src: '/img/gif/clean/pizza.gif', alt: 'Pizza', style: 'Animations' },
  { id: 'icon81', src: '/img/gif/clean/tea2.gif', alt: 'Tea2', style: 'Animations' },
  { id: 'icon82', src: '/img/gif/clean/vegansteak.gif', alt: 'Vegansteak', style: 'Animations' },
  { id: 'icon83', src: '/img/gif/clean/olive.gif', alt: 'Olive', style: 'Animations' },
  { id: 'icon84', src: '/img/gif/clean/bowl.gif', alt: 'Bowl', style: 'Animations' },
  { id: 'icon85', src: '/img/gif/clean/eggs.gif', alt: 'Eggs', style: 'Animations' },
  { id: 'icon86', src: '/img/gif/clean/foodie.gif', alt: 'Foodie', style: 'Animations' },
  { id: 'icon87', src: '/img/gif/clean/coffemachine.gif', alt: 'Coffemachine', style: 'Animations' },
  { id: 'icon88', src: '/img/gif/clean/chef.gif', alt: 'Chef', style: 'Animations' },
  { id: 'icon89', src: '/img/gif/clean/coffe.gif', alt: 'Coffe', style: 'Animations' },
  { id: 'icon90', src: '/img/gif/clean/potion.gif', alt: 'Potion', style: 'Animations' },
  { id: 'icon91', src: '/img/gif/clean/yoga.gif', alt: 'Yoga', style: 'Animations' },
  { id: 'icon92', src: '/img/gif/clean/lotus.gif', alt: 'Lotus', style: 'Animations' },
  { id: 'icon93', src: '/img/gif/clean/wrth.gif', alt: 'Wrth', style: 'Animations' },
  { id: 'icon94', src: '/img/gif/clean/prize.gif', alt: 'Prize', style: 'Animations' },
  { id: 'icon95', src: '/img/gif/clean/lamp.gif', alt: 'Lamp', style: 'Animations' },
  { id: 'icon96', src: '/img/gif/clean/desk.gif', alt: 'Desk', style: 'Animations' },
  { id: 'icon97', src: '/img/gif/clean/bath2.gif', alt: 'Bath2', style: 'Animations' },
  { id: 'icon98', src: '/img/gif/clean/bowling.gif', alt: 'Bowling', style: 'Animations' },
  { id: 'icon99', src: '/img/gif/clean/skiing.gif', alt: 'Skiing', style: 'Animations' },
  { id: 'icon100', src: '/img/gif/clean/beer.gif', alt: 'Beer', style: 'Animations' },
  { id: 'icon101', src: '/img/gif/clean/italianhand.gif', alt: 'Italianhand', style: 'Animations' },
  { id: 'icon102', src: '/img/gif/clean/tabletennis.gif', alt: 'Tabletennis', style: 'Animations' },
  { id: 'icon103', src: '/img/gif/clean/pin.gif', alt: 'Pin', style: 'Animations' },
  { id: 'icon104', src: '/img/gif/clean/chatmessage.gif', alt: 'Chatmessage', style: 'Animations' },
  { id: 'icon105', src: '/img/gif/clean/thumbsup.gif', alt: 'Thumbsup', style: 'Animations' },
  { id: 'icon106', src: '/img/gif/clean/piano.gif', alt: 'Piano', style: 'Animations' },
  { id: 'icon107', src: '/img/gif/clean/door.gif', alt: 'Door', style: 'Animations' },
  { id: 'icon108', src: '/img/gif/clean/keyholder.gif', alt: 'Keyholder', style: 'Animations' },
  { id: 'icon109', src: '/img/gif/clean/singlekey.gif', alt: 'Singlekey', style: 'Animations' },
  { id: 'icon110', src: '/img/gif/clean/luggage.gif', alt: 'Luggage', style: 'Animations' },
  { id: 'icon111', src: '/img/gif/clean/qr.gif', alt: 'Qr', style: 'Animations' },
  { id: 'icon112', src: '/img/gif/clean/stop.gif', alt: 'Stop', style: 'Animations' },
  { id: 'icon113', src: '/img/gif/clean/stop2.gif', alt: 'Stop2', style: 'Animations' },
  { id: 'icon114', src: '/img/gif/clean/party.gif', alt: 'Party', style: 'Animations' },
  { id: 'icon115', src: '/img/gif/clean/football.gif', alt: 'Football', style: 'Animations' },
  { id: 'icon116', src: '/img/gif/clean/hookah.gif', alt: 'Hookah', style: 'Animations' },
  { id: 'icon117', src: '/img/gif/clean/vip3.gif', alt: 'Vip3', style: 'Animations' },
  { id: 'icon118', src: '/img/gif/clean/partycrasher.gif', alt: 'Partycrasher', style: 'Animations' },
  { id: 'icon119', src: '/img/gif/clean/award.gif', alt: 'Award', style: 'Animations' },
  { id: 'icon120', src: '/img/gif/clean/pyramids.gif', alt: 'Pyramids', style: 'Animations' },
  { id: 'icon121', src: '/img/gif/clean/boat.gif', alt: 'Boat', style: 'Animations' },
  { id: 'icon122', src: '/img/gif/clean/ocean.gif', alt: 'Ocean', style: 'Animations' },
  { id: 'icon123', src: '/img/gif/clean/fish.gif', alt: 'Fish', style: 'Animations' },
  { id: 'icon124', src: '/img/gif/clean/lobster.gif', alt: 'Lobster', style: 'Animations' },
  { id: 'icon125', src: '/img/icons/handdrawn/accept.svg', alt: 'Accept', style: 'Handdrawn' },
  { id: 'icon126', src: '/img/icons/handdrawn/animals.svg', alt: 'Animals', style: 'Handdrawn' },
  { id: 'icon127', src: '/img/icons/handdrawn/basketball.svg', alt: 'Basketball', style: 'Handdrawn' },
  { id: 'icon128', src: '/img/icons/handdrawn/beach-ball.svg', alt: 'Beach-Ball', style: 'Handdrawn' },
  { id: 'icon129', src: '/img/icons/handdrawn/beach-chair.svg', alt: 'Beach-Chair', style: 'Handdrawn' },
  { id: 'icon130', src: '/img/icons/handdrawn/beach.svg', alt: 'Beach', style: 'Handdrawn' },
  { id: 'icon131', src: '/img/icons/handdrawn/bed1.svg', alt: 'Bed1', style: 'Handdrawn' },
  { id: 'icon132', src: '/img/icons/handdrawn/bunk-bed.svg', alt: 'Bunk-Bed', style: 'Handdrawn' },
  { id: 'icon133', src: '/img/icons/handdrawn/cactus.svg', alt: 'Cactus', style: 'Handdrawn' },
  { id: 'icon134', src: '/img/icons/handdrawn/calendar1.svg', alt: 'Calendar1', style: 'Handdrawn' },
  { id: 'icon135', src: '/img/icons/handdrawn/camera.svg', alt: 'Camera', style: 'Handdrawn' },
  { id: 'icon136', src: '/img/icons/handdrawn/camping.svg', alt: 'Camping', style: 'Handdrawn' },
  { id: 'icon137', src: '/img/icons/handdrawn/cat.svg', alt: 'Cat', style: 'Handdrawn' },
  { id: 'icon138', src: '/img/icons/handdrawn/coconut-drink.svg', alt: 'Coconut-Drink', style: 'Handdrawn' },
  { id: 'icon139', src: '/img/icons/handdrawn/colosseum.svg', alt: 'Colosseum', style: 'Handdrawn' },
  { id: 'icon140', src: '/img/icons/handdrawn/couple.svg', alt: 'Couple', style: 'Handdrawn' },
  { id: 'icon141', src: '/img/icons/handdrawn/cover.svg', alt: 'Cover', style: 'Handdrawn' },
  { id: 'icon142', src: '/img/icons/handdrawn/cycling.svg', alt: 'Cycling', style: 'Handdrawn' },
  { id: 'icon143', src: '/img/icons/handdrawn/diamond.svg', alt: 'Diamond', style: 'Handdrawn' },
  { id: 'icon144', src: '/img/icons/handdrawn/diving.svg', alt: 'Diving', style: 'Handdrawn' },
  { id: 'icon145', src: '/img/icons/handdrawn/dog.svg', alt: 'Dog', style: 'Handdrawn' },
  { id: 'icon146', src: '/img/icons/handdrawn/double-click.svg', alt: 'Double-Click', style: 'Handdrawn' },
  { id: 'icon147', src: '/img/icons/handdrawn/drink.svg', alt: 'Drink', style: 'Handdrawn' },
  { id: 'icon148', src: '/img/icons/handdrawn/fan.svg', alt: 'Fan', style: 'Handdrawn' },
  { id: 'icon149', src: '/img/icons/handdrawn/feather.svg', alt: 'Feather', style: 'Handdrawn' },
  { id: 'icon150', src: '/img/icons/handdrawn/field.svg', alt: 'Field', style: 'Handdrawn' },
  { id: 'icon151', src: '/img/icons/handdrawn/flip-flops.svg', alt: 'Flip-Flops', style: 'Handdrawn' },
  { id: 'icon152', src: '/img/icons/handdrawn/football.svg', alt: 'Football', style: 'Handdrawn' },
  { id: 'icon153', src: '/img/icons/handdrawn/fridge.svg', alt: 'Fridge', style: 'Handdrawn' },
  { id: 'icon154', src: '/img/icons/handdrawn/gift.svg', alt: 'Gift', style: 'Handdrawn' },
  { id: 'icon155', src: '/img/icons/handdrawn/giraffe.svg', alt: 'Giraffe', style: 'Handdrawn' },
  { id: 'icon156', src: '/img/icons/handdrawn/glasses.svg', alt: 'Glasses', style: 'Handdrawn' },
  { id: 'icon157', src: '/img/icons/handdrawn/golf-club.svg', alt: 'Golf-Club', style: 'Handdrawn' },
  { id: 'icon158', src: '/img/icons/handdrawn/internet.svg', alt: 'Internet', style: 'Handdrawn' },
  { id: 'icon159', src: '/img/icons/handdrawn/invitation.svg', alt: 'Invitation', style: 'Handdrawn' },
  { id: 'icon160', src: '/img/icons/handdrawn/jacuzzi.svg', alt: 'Jacuzzi', style: 'Handdrawn' },
  { id: 'icon161', src: '/img/icons/handdrawn/jellyfish.svg', alt: 'Jellyfish', style: 'Handdrawn' },
  { id: 'icon162', src: '/img/icons/handdrawn/key.svg', alt: 'Key', style: 'Handdrawn' },
  { id: 'icon163', src: '/img/icons/handdrawn/letter.svg', alt: 'Letter', style: 'Handdrawn' },
  { id: 'icon164', src: '/img/icons/handdrawn/moka.svg', alt: 'Moka', style: 'Handdrawn' },
  { id: 'icon165', src: '/img/icons/handdrawn/mountain.svg', alt: 'Mountain', style: 'Handdrawn' },
  { id: 'icon166', src: '/img/icons/handdrawn/mountain1.svg', alt: 'Mountain1', style: 'Handdrawn' },
  { id: 'icon167', src: '/img/icons/handdrawn/mouse-clicker.svg', alt: 'Mouse Clicker', style: 'Handdrawn' },
  { id: 'icon168', src: '/img/icons/handdrawn/night.svg', alt: 'Night', style: 'Handdrawn' },
  { id: 'icon169', src: '/img/icons/handdrawn/palm.svg', alt: 'Palm', style: 'Handdrawn' },
  { id: 'icon170', src: '/img/icons/handdrawn/paper-plane.svg', alt: 'Paper Plane', style: 'Handdrawn' },
  { id: 'icon171', src: '/img/icons/handdrawn/park.svg', alt: 'Park', style: 'Handdrawn' },
  { id: 'icon172', src: '/img/icons/handdrawn/pig.svg', alt: 'Pig', style: 'Handdrawn' },
  { id: 'icon173', src: '/img/icons/handdrawn/pin.svg', alt: 'Pin', style: 'Handdrawn' },
  { id: 'icon174', src: '/img/icons/handdrawn/placeholder.svg', alt: 'Placeholder', style: 'Handdrawn' },
  { id: 'icon175', src: '/img/icons/handdrawn/pool.svg', alt: 'Pool', style: 'Handdrawn' },
  { id: 'icon176', src: '/img/icons/handdrawn/pop-up.svg', alt: 'Pop Up', style: 'Handdrawn' },
  { id: 'icon177', src: '/img/icons/handdrawn/pot.svg', alt: 'Pot', style: 'Handdrawn' },
  { id: 'icon178', src: '/img/icons/handdrawn/pull-up.svg', alt: 'Pull Up', style: 'Handdrawn' },
  { id: 'icon179', src: '/img/icons/handdrawn/push-pin.svg', alt: 'Push Pin', style: 'Handdrawn' },
  { id: 'icon180', src: '/img/icons/handdrawn/reward.svg', alt: 'Reward', style: 'Handdrawn' },
  { id: 'icon181', src: '/img/icons/handdrawn/running.svg', alt: 'Running', style: 'Handdrawn' },
  { id: 'icon182', src: '/img/icons/handdrawn/sea.svg', alt: 'Sea', style: 'Handdrawn' },
  { id: 'icon183', src: '/img/icons/handdrawn/sea2.svg', alt: 'Sea2', style: 'Handdrawn' },
  { id: 'icon184', src: '/img/icons/handdrawn/shell.svg', alt: 'Shell', style: 'Handdrawn' },
  { id: 'icon185', src: '/img/icons/handdrawn/sketchbook.svg', alt: 'Sketchbook', style: 'Handdrawn' },
  { id: 'icon186', src: '/img/icons/handdrawn/squash.svg', alt: 'Squash', style: 'Handdrawn' },
  { id: 'icon187', src: '/img/icons/handdrawn/star.svg', alt: 'Star', style: 'Handdrawn' },
  { id: 'icon188', src: '/img/icons/handdrawn/teapot.svg', alt: 'Teapot', style: 'Handdrawn' },
  { id: 'icon189', src: '/img/icons/handdrawn/telephone.svg', alt: 'Telephone', style: 'Handdrawn' },
  { id: 'icon190', src: '/img/icons/handdrawn/toilet-paper.svg', alt: 'Toilet Paper', style: 'Handdrawn' },
  { id: 'icon191', src: '/img/icons/handdrawn/towel.svg', alt: 'Towel', style: 'Handdrawn' },
  { id: 'icon192', src: '/img/icons/handdrawn/trophy.svg', alt: 'Trophy', style: 'Handdrawn' },
  { id: 'icon193', src: '/img/icons/handdrawn/video.svg', alt: 'Video', style: 'Handdrawn' },
  { id: 'icon194', src: '/img/icons/handdrawn/yoga.svg', alt: 'Yoga', style: 'Handdrawn' },

  // ... (continue with any additional icons)
];

const IconPicker = ({ onSelect }) => {
    const [selectedIconId, setSelectedIconId] = useState('');
    const [filter, setFilter] = useState('');
  
    // Filterlogik
    const filteredIcons = filter
      ? iconData.filter(icon => icon.style === filter)
      : iconData.filter(icon => icon.style !== 'Animations Style');
  
    // Extrahieren Sie einzigartige Stile für die Filterbuttons
    const styles = [...new Set(iconData.map(icon => icon.style))];
  
    // Funktion zum Toggle der Auswahl eines Icons
    const toggleSelectIcon = (icon) => {
      if (selectedIconId === icon.id) {
        // Wenn das ausgewählte Icon erneut angeklickt wird, heben Sie die Auswahl auf
        setSelectedIconId('');
        onSelect(null); // Rufen Sie onSelect mit null auf, um die Auswahl aufzuheben
      } else {
        setSelectedIconId(icon.id);
        onSelect(icon);
      }
    };
  
    return (
      <div className="iconPicker">
        {/* Stil-Filterleiste */}
        <div className="style-filter-scrollbox">
          {/* Button für "Alle" zuerst */}
          <button onClick={() => setFilter('')} className="style-filter-button">All</button>
  
          {/* Dynamisch generierte Stil-Buttons */}
          {styles.map(style => (
            <button key={style} onClick={() => setFilter(style)} className="style-filter-button">
              {style}
            </button>
          ))}
        </div>
  
        {/* Icon-Auswahlcontainer wie zuvor */}
        <div className="icon-picker-container">
          {filteredIcons.map((icon) => (
            <img
              key={icon.id}
              src={icon.src}
              alt={icon.alt}
              onClick={() => toggleSelectIcon(icon)} // Verwenden Sie die Toggle-Funktion
              className={`icon-style ${selectedIconId === icon.id ? 'selected' : ''} ${icon.style === 'Animations' ? 'animation-icon' : ''}`}
            />
          ))}
        </div>
      </div>
    );
  };
  
  export default IconPicker;