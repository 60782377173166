import React, { useState } from 'react';
import './TopWidget.css'; 
import './WidgetStyle.css'; 
import './DeleteModal.css'; 
import { useFont } from '../../Design/FontContext';
import DeleteModal from './DeleteModal';

function appendPx(value) {
    if (typeof value === 'string' && isNaN(value)) {
        return value;
    }
    return `${value}px`;
}

function appendPercent(value) {
    if (typeof value === 'string' && isNaN(value)) {
        return value;
    }
    const percentValue = Math.min(Number(value), 100);
    return `${percentValue}%`;
}

function HeaderWidget({ data, onRemove, isSelected, isEditMode }) {
    const defaultBannerSrc = '/img/bakaya_background.webp';
    const defaultLogoSrc = '/img/bakaya_logo.png';
    const deleteLogo = '/img/delete.svg';

    const fonts = useFont();

    function isDark(color) {
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;
        const luma = 0.299 * r + 0.587 * g + 0.114 * b;
        return luma < 128;
    }

    function getBorderStyle() {
        if (isEditMode) {
            if (isSelected) {
                const borderColor = isDark(data.backgroundColor) ? '#FFFFFF' : '#000000';
                return `2px dashed ${borderColor}`;
            } else {
                return data.border ? `1.5px solid ${data.borderColor}` : '2px dashed transparent';
            }
        } else {
            return data.border ? `1.5px solid ${data.borderColor}` : 'none';
        }
    }

    const widgetStyle = {
        backgroundColor: data.backgroundColor,
        marginBottom: appendPx(data.marginBottom),
        marginTop: appendPx(data.marginTop),
        border: getBorderStyle(),
        width: '100%',
        minHeight: appendPx(data.height),
        zIndex: 100,
        paddingBottom: '40px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    const backgroundStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${data.imgBanner || defaultBannerSrc})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: data.backgroundOpacity || 0.8,
        zIndex: -1,
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (event) => {
        onRemove(event, data.id);
        setShowDeleteModal(false);
    };

    return (
        <div className="top_widget" style={{...widgetStyle, position: 'relative'}}>
            <div className={`background_shape_style ${data.headerStyle}`} style={backgroundStyle}></div>
            <div>
                {isEditMode && (
                    <button className="delete_button" onClick={() => setShowDeleteModal(true)}>
                        <img className="delete_logo" src={deleteLogo} alt="Löschen" />
                    </button>
                )}
                {isEditMode && showDeleteModal && (
                    <DeleteModal
                        isOpen={showDeleteModal}
                        onClose={() => setShowDeleteModal(false)}
                        onConfirm={(event) => handleDelete(event, data.id)}
                    />
                )}
            </div>
            <img src={data.imgLogo || defaultLogoSrc} className="top_widget_logo_img" alt="Logo" style={{ width: appendPercent(data.logoWidth) }} />
            <h2 style={{
                color: data.textColor1,
                fontSize: appendPx(data.text1Size),
                fontWeight: data.text1Weight,
                fontFamily: fonts.globalBigFont,
            }}>
                {data.text1}
            </h2>
            <p style={{
                color: data.textColor2,
                fontSize: appendPx(data.text2Size),
                fontWeight: data.text2Weight,
                fontFamily: fonts.globalSmallFont,
                marginLeft: '7%',
                marginRight: '7%',
                maxWidth: '550px',
            }}>
                {data.text2}
            </p>
        </div>
    );
}

export default HeaderWidget;
