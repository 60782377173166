import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, onValue, set, push, remove } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import './DigitalLogos.css';

const DigitalLogos = ({ userId }) => {
    const [logos, setLogos] = useState([]);
    const [newLogo, setNewLogo] = useState({ file: null, text: '', filetype: 'png' });
    const [preview, setPreview] = useState(null);

    const database = getDatabase();
    const storage = getStorage();

    useEffect(() => {
        if (!userId) return;

        const logosRef = dbRef(database, `Brandbooks/${userId}/logos`);
        onValue(logosRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setLogos(Object.entries(data).map(([key, value]) => ({ id: key, ...value })));
            } else {
                setLogos([]);
            }
        });
    }, [userId, database]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setNewLogo({ ...newLogo, file });
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewLogo({ ...newLogo, [name]: value });
    };

    const addLogo = () => {
        if (!newLogo.file) return;

        const fileRef = storageRef(storage, `logos/${userId}/${newLogo.file.name}`);
        uploadBytes(fileRef, newLogo.file).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                const logosRef = dbRef(database, `Brandbooks/${userId}/logos`);
                const newLogoRef = push(logosRef);
                set(newLogoRef, { file: downloadURL, text: newLogo.text, filetype: newLogo.filetype });
                setNewLogo({ file: null, text: '', filetype: 'png' });
                setPreview(null);
            });
        });
    };

    const deleteLogo = (logoId) => {
        const logoRef = dbRef(database, `Brandbooks/${userId}/logos/${logoId}`);
        remove(logoRef);
    };

    const downloadLogo = (url, filename) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
    };

    
    return (
        <div className="digital-logos">
            <h2>Logos</h2>
            <div className="logos-container">
                {logos.map((logo) => (
                    <div key={logo.id} className="logo-item">
                        <img src={logo.file} alt={logo.text} width="300px" />
                        <div onClick={() => deleteLogo(logo.id)}>
                            <img src='./img/trasher.svg' alt="Delete" style={{top: '10px', right: '5px'}} className="branding-delete-button"/>
                        </div>
                        <div className="logo-actions">
                            <p>{logo.filetype}</p>
                            <button onClick={() => downloadLogo(logo.file, logo.text)}>
                                Download
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="add-logo">
                <label htmlFor="file-upload" className="file-input-label">
                    {preview ? (
                        <img src={preview} alt="Preview" className="digitals-logo-preview" />
                    ) : (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img className="digitals-plus-icon" src="./img/plus.svg" alt="Plus Icon" />
                            <span>Click here to Add Logo</span>
                        </div>
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        id="file-upload"
                    />
                </label>
                {preview && (
                    <select
                        name="filetype"
                        value={newLogo.filetype}
                        onChange={handleInputChange}
                    >
                        <option value="png">PNG</option>
                        <option value="jpeg">JPEG</option>
                        <option value="svg">SVG</option>
                    </select>
                )}
                {preview && <button onClick={addLogo}>Add Logo</button>}
            </div>
        </div>
    );
};

export default DigitalLogos;