import React, { useState } from 'react';
import Papa from 'papaparse';
import { getDatabase, ref as dbRef, query, orderByChild, equalTo, get } from 'firebase/database';
import './AddProspects.css';

function AddProspects({ onAddProspects }) {
    const [showModal, setShowModal] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [entriesCount, setEntriesCount] = useState(0);
    const [importName, setImportName] = useState("");
    const [skippedCount, setSkippedCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const db = getDatabase();

    const handleFileUpload = async (event) => {
        setLoading(true);
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                header: true,
                complete: async (results) => {
                    const data = results.data;
                    const headers = results.meta.fields;
                    const requiredColumns = ["Number", "Name"];
                    const allowedColumns = ["Number", "Name", "Address", "Reviews", "Ratings", "Country", "Website", "State", "Type", "Img"];

                    const allRequiredColumnsPresent = requiredColumns.every(col => headers.includes(col));
                    if (!allRequiredColumnsPresent) {
                        alert("CSV file must contain 'Number' and 'Name' columns.");
                        setLoading(false);
                        return;
                    }

                    const filteredData = data.map(row => {
                        const filteredRow = {};
                        Object.keys(row).forEach(key => {
                            if (allowedColumns.includes(key)) {
                                filteredRow[key] = row[key];
                            }
                        });
                        return filteredRow;
                    }).filter(row => row.Number && row.Name);

                    const existingNumbers = await getExistingNumbers();
                    const newEntries = filteredData.filter(row => !existingNumbers.includes(row.Number));
                    setSkippedCount(filteredData.length - newEntries.length);

                    setCsvData(newEntries);
                    setEntriesCount(newEntries.length);
                    setLoading(false);
                }
            });
        } else {
            setLoading(false);
        }
    };

    const getExistingNumbers = async () => {
        const snapshot = await get(query(dbRef(db, 'marketingDatabase'), orderByChild('Number')));
        const data = snapshot.val();
        return data ? Object.values(data).map(entry => entry.Number) : [];
    };

    const handleSaveEntries = () => {
        if (!importName) {
            alert("Please enter an import name.");
            return;
        }
        const timestamp = new Date().toISOString();
        const entries = csvData.map(entry => ({ ...entry, importName, timestamp, interest: false }));
        onAddProspects(entries);
        setCsvData([]);
        setEntriesCount(0);
        setImportName("");
        setSkippedCount(0);
        setShowModal(false);
    };

    return (
        <>
            <button onClick={() => setShowModal(true)}>Add Prospects
            <img style={{width: '23px', filter: 'invert(1)', marginRight: '-5px'}} src="./img/plus.svg" alt="" />
            </button>
            {showModal && (
                <div className="add-prospects-modal">
                    <div className="add-prospects-modal-content">
                        <h2>Upload CSV</h2>
                        <input type="file" accept=".csv" onChange={handleFileUpload} />
                        {loading && <div className="loading-bar">Analyzing data...</div>}
                        {entriesCount > 0 && (
                            <>
                                <p>{entriesCount} entries will be added</p>
                                <p>{skippedCount} numbers already exist and will be skipped</p>
                                <input
                                    type="text"
                                    placeholder="Enter import name"
                                    value={importName}
                                    onChange={(e) => setImportName(e.target.value)}
                                />
                            </>
                        )}
                        <div className="add-prospects-modal-buttons">
                            <button onClick={handleSaveEntries}>Confirm</button>
                            <button style={{backgroundColor: 'gray'}} onClick={() => setShowModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AddProspects;
