import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref as dbRef, get } from 'firebase/database';
import './ChatList.css';
import ChatWindow from './ChatWindow';
import DashboardHeader from '../../AdminComponents/DashboardHeader';

const ChatList = () => {
    const [chatId, setChatId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchChat = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) {
                setIsLoading(false);
                return;
            }

            const database = getDatabase();
            const chatsRef = dbRef(database, 'Chats');
            const snapshot = await get(chatsRef);
            if (snapshot.exists()) {
                const chatData = snapshot.val();
                const chatKey = Object.keys(chatData).find(
                    key => chatData[key].userId === currentUser.uid || chatData[key].managerId === currentUser.uid
                );

                if (chatKey) {
                    setChatId(chatKey);
                }
            }
            setIsLoading(false);
        };

        fetchChat();
    }, []);

    if (isLoading) {
        return (
            <div className="loading-spinner-3">
            </div>
        );
    }

    return (
        <div className="chat-list-container">
            {chatId ? (
                <div>
                    <DashboardHeader />         
                    <h2>Chat with your Manager</h2>
                      <ChatWindow chatId={chatId} />

                    </div>
            ) : (
                <div className="no-chats-message">
                    <img style={{ width: '100px' }} src="../../img/big_chat.webp" alt="" />
                    <h2>Seems like your Inbox is empty.</h2>
                    <p>Your inbox is empty. Check back later for new messages.</p>
                </div>
            )}
        </div>
    );
};

export default ChatList;
