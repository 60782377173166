import './IconFeature.css'; 
import './WidgetStyle.css'; 
import { useFont } from '../../Design/FontContext';
import React, { useState } from 'react';
import DeleteModal from './DeleteModal';

function appendPx(value) {
    if (typeof value === 'string' && isNaN(value)) {
        return value;
    }
    return `${value}px`;
}

function AddyCard({ data, onRemove, isSelected, isEditMode, inverted }) {
    const defaultImg = '/img/pin.svg';
    const deleteLogo = '/img/delete.svg';
    const widgetStyle = {
        backgroundColor: data.backgroundColor,
        marginBottom: appendPx(data.marginBottom),
        marginTop: appendPx(data.marginTop),
        border: getBorderStyle(),
        paddingTop: '10px',
        paddingBottom: '40px',
        boxShadow: data.shadow ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : 'none',
    };

    const fonts = useFont();

    function isDark(color) {
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;
        const luma = 0.299 * r + 0.587 * g + 0.114 * b;
        return luma < 128;
    }

    function getBorderStyle() {
        if (isEditMode) {
            if (isSelected) {
                const borderColor = isDark(data.backgroundColor) ? '#FFFFFF' : '#000000';
                return `2px dashed ${borderColor}`;
            } else {
                return data.border ? `1.5px solid ${data.borderColor}` : '2px dashed transparent';
            }
        } else {
            return data.border ? `1.5px solid ${data.borderColor}` : 'none';
        }
    }
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (event) => {
        onRemove(event, data.id);
        setShowDeleteModal(false);
    };

    const widgetHref = isEditMode ? null : data.link;

    return (
        <div className="icon_feature-2" style={widgetStyle}>
            <div>
                {isEditMode && (
                    <button className="delete_button" onClick={() => setShowDeleteModal(true)}>
                        <img className="delete_logo" src={deleteLogo} alt="Löschen" />
                    </button>
                )}
                {isEditMode && showDeleteModal && (
                    <DeleteModal
                        isOpen={showDeleteModal}
                        onClose={() => setShowDeleteModal(false)}
                        onConfirm={(event) => handleDelete(event, data.id)}
                    />
                )}
            </div>
            <div className="icon-feature-right-20">
                <div>
                    <h4 style={{
                        color: data.textColor1,
                        fontSize: appendPx(data.text1Size),
                        fontWeight: data.text1Weight,
                        fontFamily: fonts.globalBigFont
                    }}>
                        {data.text1}
                    </h4>
                    <p style={{
                        color: data.textColor2,
                        fontSize: appendPx(data.text2Size),
                        fontWeight: data.text2Weight,
                        marginBottom: data.marginBottom,
                        fontFamily: fonts.globalSmallFont,
                    }}>
                        {data.text2}
                    </p>
                    <p style={{
                        color: data.textColor2,
                        fontSize: appendPx(data.text2Size),
                        fontWeight: data.text2Weight,
                        marginBottom: data.marginBottom,
                        fontFamily: fonts.globalSmallFont,
                    }}>
                        {data.text3}
                    </p>
                    <p style={{
                        color: data.textColor2,
                        fontSize: appendPx(data.text2Size),
                        fontWeight: data.text2Weight,
                        marginBottom: data.marginBottom,
                        fontFamily: fonts.globalSmallFont,
                    }}>
                        {data.text4}
                    </p>
                </div>
            </div>
            <div className="icon_border_4">
                <img 
                    src={data.img || defaultImg} 
                    className={`icon_feature_img_2 ${inverted ? 'inverted' : ''}`}
                    alt="Img" 
                    style={{
                        width: appendPx(data.logoWidth),
                        filter: data.inverted ? 'invert(100%)' : 'none',
                    }} 
                />
            </div>
        </div>
    );
}
export default AddyCard;
