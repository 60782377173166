import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getDatabase, ref, onValue } from "firebase/database";
import { useParams } from 'react-router-dom';
import '../Design/HeaderComponent.css'

function BackendHeaderComponent({ to }) {
  const [headerImage, setHeaderImage] = useState(null);  // Anfangswert auf null setzen
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const { userId } = useParams();

  useEffect(() => {
      const db = getDatabase();
      const imageRef = ref(db, `surveyConfigs/${userId}/headerImage`);  

      onValue(imageRef, (snapshot) => {
          const fetchedImage = snapshot.val();
          if (fetchedImage) {
              setHeaderImage(fetchedImage); 
          }
      }, {
          onlyOnce: true
      });
  }, [userId]);

  return (
      <div className="header_component">
          {headerImage && (
              <>
                  {!isImageLoaded && <div className="image-placeholder"></div>}
                  <img
                      className={`header_img ${isImageLoaded ? 'visible' : 'hidden'}`}
                      src={headerImage}
                      alt="Header"
                      onLoad={() => setIsImageLoaded(true)}
                  />
              </>
          )}
      </div>
  )
}

export default BackendHeaderComponent;
