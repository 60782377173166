import { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { getAuth } from 'firebase/auth';

export const useDashboardData = () => {
    const [surveys, setSurveys] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const stringSafeToLower = (value) => {
        return typeof value === 'string' ? value.toLowerCase() : '';
    };

    useEffect(() => {
        const db = getDatabase();
        const surveyRef = ref(db, 'surveys');
        const auth = getAuth();
        const userEmail = auth.currentUser?.email;

        onValue(surveyRef, (snapshot) => {
            const surveysData = snapshot.val();

            const surveysList = [];
            for (let id in surveysData) {
                if (surveysData[id].adminMail === userEmail) {
                    surveysList.push({ id, ...surveysData[id] });
                }
            }

            setSurveys(surveysList);
        });

        return () => {
            off(surveyRef);
        };
    }, []);

    // ... any other shared functions

    return {
        surveys,
        searchTerm, setSearchTerm,
        startDate, setStartDate,
        endDate, setEndDate,
        stringSafeToLower,
        // ... any other shared state or functions
    };
};
