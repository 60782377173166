import React, { useState, useEffect, useRef } from 'react';
import './WizardFinalDetails.css';

const isDarkColor = (color) => {
    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminance < 128;
};

const WizardFinalDetails = ({ backgroundColor, setBackgroundColor, setImageFile, fontStyle, setFontStyle, fontColor, setFontColor }) => {
    const [imagePreview, setImagePreview] = useState(null);
    const [uploading, setUploading] = useState(false);
    const fileInputRef = useRef(null);
    const dropRef = useRef(null);
    const componentRef = useRef(null);

    useEffect(() => {
        if (!backgroundColor) {
            setBackgroundColor('#FFFFFF');
        }

        const dropArea = dropRef.current;
        if (dropArea) {
            const handleDrop = (e) => {
                e.preventDefault();
                e.stopPropagation();
                const file = e.dataTransfer.files[0];
                if (file) {
                    handleFile(file);
                }
                dropArea.classList.remove('drag-over');
            };

            const handleDragOver = (e) => {
                e.preventDefault();
                e.stopPropagation();
                dropArea.classList.add('drag-over');
            };

            const handleDragLeave = (e) => {
                e.preventDefault();
                e.stopPropagation();
                dropArea.classList.remove('drag-over');
            };

            dropArea.addEventListener('dragover', handleDragOver);
            dropArea.addEventListener('dragleave', handleDragLeave);
            dropArea.addEventListener('drop', handleDrop);

            return () => {
                dropArea.removeEventListener('dragover', handleDragOver);
                dropArea.removeEventListener('dragleave', handleDragLeave);
                dropArea.removeEventListener('drop', handleDrop);
            };
        }
    }, [backgroundColor, setBackgroundColor]);

    useEffect(() => {
        if (componentRef.current) {
            componentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const colors = [
        '#ffffff', '#F8F9FA', '#F5EBE0', '#F2E9E4', '#F4F1DE',
        '#F9F4F2', '#E9ECEF', '#FDF0D5', '#C1121F', '#A5A58D',
        '#9A8C98', '#4A4E69', '#EA526F', '#E8CB6B', '#2F3E46',
        '#000000',
    ];

    const fontStyles = [
        { primary: 'Classic', secondary: 'Poppins' },
        { primary: 'Bloque', secondary: 'Inter' },
        { primary: 'Gong', secondary: 'Montserrat' },
        { primary: 'Money', secondary: 'Nunito Sans' },
        { primary: 'Urbanist', secondary: 'Poppins' },
        { primary: 'Posh', secondary: 'Nunito Sans' }
    ];

    const fontColorOptions = {
        light: { primary: '#FFFFFF', secondary: '#f7f7f7' },
        dark: { primary: '#000000', secondary: '#1a1a1a' }
    };

    const handleColorPick = (color) => {
        setBackgroundColor(color);
        const newFontColor = isDarkColor(color) ? 'light' : 'dark';
        setFontColor(newFontColor);
        console.log('Background color changed:', color);
        console.log('Updated fontColor:', newFontColor);
    };

    const handleFile = (file) => {
        setUploading(true);
        setImageFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setUploading(false);
        };
        reader.readAsDataURL(file);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            handleFile(file);
        }
    };

    const toggleFontColor = () => {
        const newFontColor = fontColor === 'light' ? 'dark' : 'light';
        setFontColor(newFontColor);
        console.log('Font color toggled:', newFontColor);
    };

    return (
        <div className="wizard-final-details" ref={componentRef}>
            <div
                ref={dropRef}
                className="wizard-final-upload-container"
                onClick={() => fileInputRef.current && fileInputRef.current.click()}
            >
                {!imagePreview && <p>Upload a logo or Drag and Drop here</p>}
                {imagePreview && <img src={imagePreview} alt="Image preview" className="wizard-final-image-preview" />}
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    accept="image/*"
                    className="wizard-final-upload-input"
                    style={{ display: 'none' }}
                />
            </div>
            <div className="wizard-final-color-picker-container">
                <div className="wizard-final-color-picker-section">
                    <p>Background color:</p>
                    <input
                        type="color"
                        style={{backgroundColor: backgroundColor}}
                        value={backgroundColor}
                        onChange={(e) => handleColorPick(e.target.value)}
                        className="wizard-final-color-picker"
                    />
                    {/* <div className="wizard-final-font-color-container">
                        <p>Choose font color:</p>
                        <div className="wizard-final-font-color-toggle">
                            <button
                                className={`wizard-toggle-button ${fontColor === 'dark' ? 'selected' : ''}`}
                                onClick={toggleFontColor}
                                style={{backgroundColor: 'white', color: 'black'}}
                            >
                                Light BG
                            </button>
                            <button
                                className={`wizard-toggle-button ${fontColor === 'light' ? 'selected' : ''}`}
                                onClick={toggleFontColor}
                                style={{backgroundColor: 'white', color: 'black'}}
                            >
                                Dark BG
                            </button>
                        </div>
                    </div> */}
                </div>
                <div className="wizard-final-color-options">
                    {colors.map((color, index) => (
                        <div
                            key={index}
                            className="wizard-final-color-circle"
                            style={{ backgroundColor: color }}
                            onClick={() => handleColorPick(color)}
                        />
                    ))}
                </div>
            </div>
            <div className="wizard-final-font-style-container">
                <p style={{marginBottom: '20px'}}>Choose font style:</p>
                <div className="wizard-final-font-style-options">
                    {fontStyles.map((style, index) => (
                        <div
                            key={index}
                            className={`wizard-final-font-style-box ${fontStyle === index ? 'selected' : ''}`}
                            onClick={() => setFontStyle(index)}
                        >
                            <div className="wizard-final-font-primary" style={{ fontFamily: style.primary }}>
                                Primary Font
                            </div>
                            <div className="wizard-final-font-secondary" style={{ fontFamily: style.secondary }}>
                                Secondary Font
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WizardFinalDetails;
