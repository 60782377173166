import React from "react";

const StepFive = () => {
  return (
    <div>
      <h2>Vielen Dank!</h2>
      <p>Wir haben Ihre Antworten erhalten.</p>
    </div>
  );
};

export default StepFive;