import React, { useState } from 'react';
import { getDatabase, ref as dbRef, set } from 'firebase/database';
import './EditNameModal.css';

const EditNameModal = ({ entry, onClose, onSave }) => {
    const [name, setName] = useState(entry.Name);
    const db = getDatabase();

    const handleSave = () => {
        set(dbRef(db, `marketingDatabase/${entry.id}/Name`), name).then(() => {
            onSave(entry.id, name);
            onClose();
        });
    };

    return (
        <div className="edit-name-modal">
            <div className="edit-name-modal-content">
                <h2>Edit Name</h2>
                <input 
                    type="text" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                />
                <div className="edit-name-modal-content-buttons">
                <button onClick={handleSave}>Save</button>
                <button style={{backgroundColor: 'grey', marginTop: '10px'}} onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default EditNameModal;
