import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, get, update } from "firebase/database";
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './AddService.css';
import AddServiceImageUploader from './AddServiceImageUploader';

const categories = [
    "Marketing and Promotion",
    "Print Materials",
    "Branding",
    "Graphic Design",
    "Photography",
    "Merchandise",
    "On Location",
    "Social Media",
    "Packaging",
];

function AddService({ serviceId, onSave }) {
    const [serviceTitle, setServiceTitle] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [features, setFeatures] = useState(['']);
    const [tags, setTags] = useState(['']);
    const [category, setCategory] = useState(categories[0]);
    const [images, setImages] = useState([]);
    const [deliveryOptions, setDeliveryOptions] = useState([{ deliveryTime: 'Standard', deliveryDays: '1', deliveryCredits: '', customOffer: false }]);
    
    const db = getDatabase();

    useEffect(() => {
        if (serviceId) {
            const serviceRef = dbRef(db, `Services/${serviceId}`);
            get(serviceRef).then(snapshot => {
                const data = snapshot.val();
                if (data) {
                    setServiceTitle(data.serviceTitle || '');
                    setServiceDescription(data.serviceDescription || '');
                    setFeatures(data.features || ['']);
                    setTags(data.tags || ['']);
                    setCategory(data.category || categories[0]);
                    setImages(data.images || []);
                    setDeliveryOptions(data.delivery || [{ deliveryTime: 'Standard', deliveryDays: '1', deliveryCredits: '', customOffer: false }]);
                }
            });
        } else {
            resetForm();
        }
    }, [serviceId, db]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const serviceData = {
            serviceTitle,
            serviceDescription,
            features,
            tags,
            category,
            images,
            delivery: deliveryOptions
        };

        const serviceRef = serviceId ? dbRef(db, `Services/${serviceId}`) : dbRef(db, `Services/${uuidv4()}`);
        update(serviceRef, serviceData).then(() => {
            alert('Service saved successfully');
            onSave();
        }).catch(error => {
            console.error("Error saving service:", error);
        });
    };

    const resetForm = () => {
        setServiceTitle('');
        setServiceDescription('');
        setFeatures(['']);
        setTags(['']);
        setCategory(categories[0]);
        setImages([]);
        setDeliveryOptions([{ deliveryTime: 'Standard', deliveryDays: '1', deliveryCredits: '', customOffer: false }]);
    };

    const handleImageUpload = (url) => {
        setImages((prevImages) => [...prevImages, { url, text: '', vibe: '' }]);
    };

    const handleImageMetaChange = (index, field, value) => {
        const newImages = [...images];
        newImages[index][field] = value;
        setImages(newImages);
    };

    const handleFeatureChange = (index, value) => {
        const newFeatures = [...features];
        newFeatures[index] = value;
        setFeatures(newFeatures);
    };

    const addFeatureField = () => {
        setFeatures([...features, '']);
    };

    const handleTagChange = (index, value) => {
        const newTags = [...tags];
        newTags[index] = value;
        setTags(newTags);
    };

    const addTagField = () => {
        setTags([...tags, '']);
    };

    const handleDeliveryChange = (index, field, value) => {
        const newOptions = [...deliveryOptions];
        newOptions[index][field] = value;
        setDeliveryOptions(newOptions);
    };

    const handleCustomOfferChange = (index) => {
        const newOptions = [...deliveryOptions];
        newOptions[index].customOffer = !newOptions[index].customOffer;
        if (newOptions[index].customOffer) {
            newOptions[index].deliveryCredits = '';
        }
        setDeliveryOptions(newOptions);
    };

    const addDeliveryOption = () => {
        setDeliveryOptions([...deliveryOptions, { deliveryTime: 'Standard', deliveryDays: '1', deliveryCredits: '', customOffer: false }]);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedImages = Array.from(images);
        const [removed] = reorderedImages.splice(result.source.index, 1);
        reorderedImages.splice(result.destination.index, 0, removed);

        setImages(reorderedImages);
    };

    const removeImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    return (
        <div className="add-service-container">
            <h2 className="add-service-title">{serviceId ? 'Edit Service' : 'Add Service'}</h2>
            <form className="add-service-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="form-label">Service Title:</label>
                    <input 
                        type="text" 
                        className="form-input" 
                        value={serviceTitle} 
                        onChange={(e) => setServiceTitle(e.target.value)} 
                    />
                </div>
                <div className="form-group">
                    <label className="form-label">Service Description:</label>
                    <textarea 
                        className="form-textarea" 
                        value={serviceDescription} 
                        onChange={(e) => setServiceDescription(e.target.value)} 
                    />
                </div>
                <div className="form-group">
                    <label className="form-label">Features:</label>
                    {features.map((feature, index) => (
                        <input 
                            key={index} 
                            type="text" 
                            className="form-input" 
                            value={feature} 
                            onChange={(e) => handleFeatureChange(index, e.target.value)} 
                        />
                    ))}
                    <button type="button" className="add-feature-button" onClick={addFeatureField}>Add Feature</button>
                </div>
                <div className="form-group">
                    <label className="form-label">Tags:</label>
                    {tags.map((tag, index) => (
                        <input 
                            key={index} 
                            type="text" 
                            className="form-input" 
                            value={tag} 
                            onChange={(e) => handleTagChange(index, e.target.value)} 
                        />
                    ))}
                    <button type="button" className="add-tag-button" onClick={addTagField}>Add Tag</button>
                </div>
                <div className="form-group">
                    <label className="form-label">Category:</label>
                    <select 
                        className="form-select" 
                        value={category} 
                        onChange={(e) => setCategory(e.target.value)}
                    >
                        {categories.map((cat) => (
                            <option key={cat} value={cat}>{cat}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label className="form-label">Upload Images:</label>
                    <AddServiceImageUploader onImageUpload={handleImageUpload} />
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="images">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {images.map((image, index) => (
                                        <Draggable key={index} draggableId={`image-${index}`} index={index}>
                                            {(provided) => (
                                                <div 
                                                    ref={provided.innerRef} 
                                                    {...provided.draggableProps} 
                                                    {...provided.dragHandleProps} 
                                                    className="image-preview-wrapper"
                                                >
                                                    {image.url && (
                                                        <img 
                                                            src={image.url} 
                                                            alt={`Preview ${index}`} 
                                                            className="image-preview" 
                                                        />
                                                    )}
                                                    <input 
                                                        type="text" 
                                                        className="form-input" 
                                                        placeholder="Image Text" 
                                                        value={image.text} 
                                                        onChange={(e) => handleImageMetaChange(index, 'text', e.target.value)} 
                                                    />
                                                    <input 
                                                        type="text" 
                                                        className="form-input" 
                                                        placeholder="Image Vibe" 
                                                        value={image.vibe} 
                                                        onChange={(e) => handleImageMetaChange(index, 'vibe', e.target.value)} 
                                                    />
                                                    <button type="button" className="remove-image-button" onClick={() => removeImage(index)}>Remove</button>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                <div className="form-group">
                    <label className="form-label">Delivery Options:</label>
                    {deliveryOptions.map((option, index) => (
                        <div key={index} className="delivery-option">
                            <select 
                                className="form-select" 
                                value={option.deliveryTime} 
                                onChange={(e) => handleDeliveryChange(index, 'deliveryTime', e.target.value)}
                            >
                                <option value="Standard">Standard</option>
                                <option value="Fast">Fast</option>
                                <option value="Superfast">Superfast</option>
                            </select>
                            <select 
                                className="form-select" 
                                value={option.deliveryDays} 
                                onChange={(e) => handleDeliveryChange(index, 'deliveryDays', e.target.value)}
                            >
                                <option value="1">1</option>
                                <option value="1-2">1-2</option>
                                <option value="3-5">3-5</option>
                                <option value="5-7">5-7</option>
                                <option value="7-14">7-14</option>
                                <option value="14-21">14-21</option>
                                <option value="21-31">21-31</option>
                            </select>
                            {!option.customOffer && (
                                <input 
                                    type="number" 
                                    className="form-input" 
                                    placeholder="Delivery Credits" 
                                    value={option.deliveryCredits} 
                                    onChange={(e) => handleDeliveryChange(index, 'deliveryCredits', e.target.value)} 
                                />
                            )}
                            <label className="form-label">
                                <input 
                                    type="checkbox" 
                                    checked={option.customOffer} 
                                    onChange={() => handleCustomOfferChange(index)} 
                                />
                                Custom Offer
                            </label>
                        </div>
                    ))}
                    <button type="button" className="add-delivery-button" onClick={addDeliveryOption}>Add Delivery Option</button>
                </div>
                <button type="submit" className="submit-button">{serviceId ? 'Save Service' : 'Add Service'}</button>
            </form>
        </div>
    );
}

export default AddService;
