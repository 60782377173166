import React, { useRef, useEffect } from 'react';
import './AddPageComponent.css'; // Erstellen Sie eine CSS-Datei für das Styling des Modals

const CreatePageModal = ({ showModal, setShowModal, pageName, setPageName, pageDescription, setPageDescription, createNewPage }) => {
    const modalRef = useRef();

    // Event-Listener, der bei Klick außerhalb des Modals dieses schließt
    useEffect(() => {
        const clickHandler = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        };

        document.addEventListener('mousedown', clickHandler);

        return () => {
            document.removeEventListener('mousedown', clickHandler);
        };
    }, [setShowModal]);

    return (
        showModal && (
            <div className="modal-background">
                <div className="modal-content" ref={modalRef}>
                    <h2>Create a new Page</h2>
                    <label className="modal-content-label">Name of your Page</label>
                    <input
                        type="text"
                        value={pageName}
                        onChange={(e) => setPageName(e.target.value)}
                        placeholder="Page Name"
                        className="text-input-field"
                    />
                    <label className="modal-content-label">Describe your Page</label>
                    <textarea
                        value={pageDescription}
                        onChange={(e) => setPageDescription(e.target.value)}
                        placeholder="e.g. This is our Club Page for Hotel Room One and Two."
                        className="description-input-field"
                    />
                    <div className="qr-buttons">
                        <button className="qr-modal-open" onClick={createNewPage}>Create Page</button>
                        <button className="qr-modal-close" onClick={() => setShowModal(false)}>Close</button>
                    </div>
                </div>
            </div>
        )
    );
};

export default CreatePageModal;
