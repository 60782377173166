import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import ImageWidget from './WidgetTypes/ImageWidget';
import TopWidget from './WidgetTypes/TopWidget';
import FancyBullet from './WidgetTypes/FancyBullet';
import IconFeature from './WidgetTypes/IconFeature';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './PagePreview.css';
import WifiCard from './WidgetTypes/WifiCard';
import FaqWidget from './WidgetTypes/FaqWidget';
import CheckInCard from './WidgetTypes/CheckInCard';
import DoubleText from './WidgetTypes/DoubleText';
import EliteButton from './WidgetTypes/EliteButton';
import PicText from './WidgetTypes/PicText';
import Bonus1 from './WidgetTypes/Bonus1';
import BigButton from './WidgetTypes/BigButton';
import MegaButton from './WidgetTypes/MegaButton';
import AddyCard from './WidgetTypes/AddyCard';
import EndWidget from './WidgetTypes/EndWidget';
import VideoText from './WidgetTypes/VideoText';
import HeaderWidget from './WidgetTypes/HeaderWidget';
import ImprintBar from './WidgetTypes/ImprintBar';
import WeatherButton from './WidgetTypes/WeatherButton';
import EmergencyWidget from './WidgetTypes/EmergencyWidget';

function PagePreview({ pageData, onRemoveWidget, onAddWidget, onReorderWidgets, onToggleActivation, selectedWidget }) {
  const handleRemoveClick = (event, widgetId) => {
    event.stopPropagation();
    onRemoveWidget(widgetId);
  };

  const [, drop] = useDrop(() => ({
    accept: 'widget',
    drop: (item, monitor) => {
      if (item && item.widgetType) {
        onAddWidget(item.widgetType);
      }
    }
  }));

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    onReorderWidgets(result.source.index, result.destination.index);
  };

  const handleWidgetClick = (widgetId) => {
    // Prüfen, ob das angeklickte Widget bereits das aktuell ausgewählte ist
    if (selectedWidget && widgetId === selectedWidget.id) {
      // Wenn ja, führen Sie keine Aktion aus (verhindern Sie das erneute Laden/Flackern)
      return;
    }
    // Andernfalls, rufen Sie die Funktion onToggleActivation auf, um das Widget zu aktivieren/deaktivieren
    onToggleActivation(widgetId);
  };

  const renderWidget = (widget) => {
    const isSelected = widget.id === selectedWidget?.id;
    const clickHandler = () => handleWidgetClick(widget.id);
    const removeHandler = (event) => handleRemoveClick(event, widget.id);

    switch (widget.type) {
      case 'Widget1':
        return <TopWidget data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget2':
        return <FancyBullet data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget3':
        return <IconFeature data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget4':
        return <WifiCard data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget5':
        return <FaqWidget data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget6':
        return <CheckInCard data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget7':
        return <DoubleText data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget8':
        return <EliteButton data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget9':
        return <PicText data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget10':
        return <Bonus1 data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget11':
        return <BigButton data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget12':
        return <MegaButton data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget13':
        return <AddyCard data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget14':
        return <EndWidget data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget15':
        return <VideoText data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget16':
        return <HeaderWidget data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      case 'Widget17':
        return <ImprintBar data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
        case 'Widget18':
          return <WeatherButton data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
          case 'Widget19':
            return <EmergencyWidget data={widget} isSelected={isSelected} onRemove={removeHandler} isEditMode={true} />;
      default:
        return <div>Unknown Widget</div>;
    }
  };

  // Hintergrundfarbe festlegen
  const backgroundColor = pageData.backgroundColor || '#FFFFFF';

  return (
    <div ref={drop} className="page_preview" style={{ backgroundColor }}>
      <div className="page_preview_header">
        <div>
          <p>Page Start</p>
          <img src="/img/down_2.svg" alt="" />
        </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="widget-list" type="widget">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="outer-preview-div">
              {pageData.widgets.length > 0 ? (
                pageData.widgets.map((widget, index) => (
                  <Draggable key={widget.id} draggableId={widget.id} index={index}>
                    {(provided) => (
                      <div
                        className='inner_preview_div'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => handleWidgetClick(widget.id)}>
                        {renderWidget(widget)}
                      </div>
                    )}
                  </Draggable>
                ))
              ) : (
                <div className="widget-placeholder">
                  <div className="widget-placeholder-top">
                    <img src="/img/down_2.svg" alt="" />
                    <h4>Add your first widget</h4>
                  </div>
                  <div className="widget-placeholder-bottom">
                    <h1>Your Page is empty.</h1>
                  </div>
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default PagePreview;
