import React, { useState, useEffect } from 'react';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import './TopWidgetEdit.css';
import ImageSizeSlider from '../ImageSizeSlider';

function DoubleTextEdit({ widget, onUpdate, colors }) {
    const [editData, setEditData] = useState({ ...widget });
    const [imagePreview, setImagePreview] = useState(widget.imgBanner || '');
    const [imagePreview2, setImagePreview2] = useState(widget.imgLogo || '');
    const [isUploadingBanner, setIsUploadingBanner] = useState(false);
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const auth = getAuth();
    const [position, setPosition] = useState(widget.position);

    useEffect(() => {
        setImagePreview(widget.imgBanner || '');
        setImagePreview2(widget.imgLogo || '');
    }, [widget.imgBanner, widget.imgLogo]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedEditData = { ...editData, [name]: value };
    
        setEditData(updatedEditData);
    
        // Speichern Sie die aktualisierten Daten sofort in der Datenbank
        onUpdate({ ...updatedEditData, position: position });
    };
    

    const handleImageUploadBanner = (event) => {
        const file = event.target.files[0];
        if (file) {
            uploadImage(file, 'imgBanner', setImagePreview, setIsUploadingBanner);
        }
    };

    const handleImageUploadLogo = (event) => {
        const file = event.target.files[0];
        if (file) {
            uploadImage(file, 'imgLogo', setImagePreview2, setIsUploadingLogo); // Korrigiert zu setImagePreview2 und setIsUploadingLogo
        }
    };


   const uploadImage = (file, fieldName, setPreview, setUploadingState) => {
    if (!file || file.size > 1024 * 1024) {
        alert('File is too big (max. 1MB).');
        return;
    }

    setUploadingState(true);
    const storage = getStorage();
    const userId = auth.currentUser.uid;
    const imageRef = storageRef(storage, `pages/${userId}/${widget.id}/${file.name}`);
    const uploadTask = uploadBytesResumable(imageRef, file);

    uploadTask.on('state_changed', 
        (snapshot) => {
            // Optional: Upload-Fortschritt anzeigen
        }, 
        (error) => {
            console.error('Fehler beim Hochladen:', error);
            setUploadingState(false);
        }, 
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                const updatedEditData = { ...editData, [fieldName]: downloadURL };
                setEditData(updatedEditData);
                setPreview(downloadURL);
                setUploadingState(false);

                // Speichern Sie die aktualisierten Daten sofort in der Datenbank
                onUpdate({ ...updatedEditData, position: position });
            });
        }
    );
};

    const handleSave = () => {
        console.log("Gespeicherte Position:", position);
        const updatedData = { ...editData, position: position };
        onUpdate(updatedData);
    };
    return (
        <div>
              <div className="widget-trenner"        style={{ marginTop: '20px'}}>  
     <h3>{editData.name}</h3>
     <hr className="hrrrr" />
    
</div>
            <div className="top-widget-edit-container">
        
                <div className="input-group">
                    <label htmlFor="text1">Text 1:</label>
                    <input 
                        id="text1"
                        type="text" 
                        name="text1" 
                        value={editData.text1}
                        onChange={handleInputChange} 
                        className="widgetInputField"
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="text2">Text 2:</label>
                    <textarea 
                        id="text2"
                        type="text" 
                        name="text2" 
                        value={editData.text2}
                        onChange={handleInputChange} 
                        className="widgetDescriptionField"
                    />
                        <div className="widget-trenner" style={{ marginTop: '30px', marginBottom: '0px', display: 'flex'}}>  
     <h3>Colors</h3>
     <div className="widget-color-previews">
     <div className="widget-color-preview" style={{ backgroundColor: colors.color1 }}></div>
     <div className="widget-color-preview" style={{ backgroundColor: colors.color2 }}></div>
     <div className="widget-color-preview" style={{ backgroundColor: colors.color3 }}></div>
     <div className="widget-color-preview" style={{ backgroundColor: colors.color4 }}></div>    
     </div>
</div>
<hr className="hrrrr" />
                </div>
                <div id="double_color_picker"> 
                <div className="input-group">
    <label htmlFor="textColor1">Text Big:</label>
    <div className="colorPicker">
        <input 
            id="textColor1"
            type="color" 
            name="textColor1" 
            value={editData.textColor1}
            onChange={handleInputChange}
            className="color-input" // Falls Sie zusätzliche Stile anwenden möchten
        />
        <div 
            className="custom-color-display-2" 
            style={{ backgroundColor: editData.textColor1 }}
        ></div>
                        <img className="color-picker-icon" src="/img/text-fat.svg" alt="" />
    </div>
</div>

<div className="input-group">
    <label htmlFor="textColor2">Text Small:</label>
    <div className="colorPicker">
        <input 
            id="textColor2"
            type="color" 
            name="textColor2" 
            value={editData.textColor2}
            onChange={handleInputChange}
            className="color-input" // Falls Sie zusätzliche Stile anwenden möchten
        />
        <div 
            className="custom-color-display-2" 
            style={{ backgroundColor: editData.textColor2 }}
        ></div>
                        <img className="color-picker-icon" src="/img/text.svg" alt="" />
    </div>
</div>
<div className="input-group">
    <label htmlFor="backgroundColor">Background:</label>
    <div className="colorPicker">
        <input 
            id="backgroundColor"
            type="color" 
            name="backgroundColor" 
            value={editData.backgroundColor}
            onChange={handleInputChange}
            className="color-input" // Falls Sie zusätzliche Stile anwenden möchten
        />
        <div 
            className="custom-color-display-2" 
            style={{ backgroundColor: editData.backgroundColor}}
        ></div>
                        <img className="color-picker-icon" src="/img/background.svg" alt="" />
    </div>
</div>

</div> 

 <div className="widget-trenner">  
     <h3>Fonts</h3>
     <hr className="hrrrr" />
    
</div>
                <div className="input-container"> 
               

                <div className="input-custom-component">
                <h3>Text Big</h3>
                <div className="input-group">
                    <label htmlFor="text1Size">Size</label>
                    <input 
                        id="text1Size"
                        type="text" 
                        name="text1Size" 
                        value={editData.text1Size}
                        onChange={handleInputChange} 
                        class="text-component-1"
                    />
                </div>
                <hr className="hrrrrr"/>
                <div className="input-group">
                    <label htmlFor="text1Weight" id="alt-label">Thick</label>
                    <input 
                        id="text1Weight"
                        type="text" 
                        name="text1Weight" 
                        value={editData.text1Weight}
                        onChange={handleInputChange} 
                        class="text-component-2"

                    />
                </div>
                </div>

                <div className="input-custom-component">
                <h3>Text Small</h3>
                <div className="input-group">
                    <label htmlFor="text2Size">Size</label>
                    <input 
                        id="text2Size"
                        type="text" 
                        name="text2Size" 
                        value={editData.text2Size}
                        onChange={handleInputChange} 
                        class="text-component-1"
                    />
                </div>
                <hr className="hrrrrr"/>
                <div className="input-group">
                    <label htmlFor="text2Weight" id="alt-label">Thick</label>
                    <input 
                        id="text2Weight"
                        type="text" 
                        name="text2Weight" 
                        value={editData.text2Weight}
                        onChange={handleInputChange} 
                        class="text-component-2"

                    />
                </div>
                </div>
</div>
<div className="widget-trenner">  
     <h3>More Settings</h3>
     <hr className="hrrrr" />
    
</div>
<div className="input-container"> 
<div className="input-group">
                    <label htmlFor="marginTop">Space Top:</label>
                    <input 
                        id="marginTop"
                        type="text" 
                        name="marginTop" 
                        value={editData.marginTop}
                        onChange={handleInputChange} 
                        className="widgetInputField"

                    />
                    <img className="label-icon" src="/img/element.svg" alt="" />
                </div>
                <div className="input-group">
                    <label htmlFor="marginBottom">Space Bottom:</label>
                    <input 
                        id="marginBottom"
                        type="text" 
                        name="marginBottom" 
                        value={editData.marginBottom}
                        onChange={handleInputChange} 
                        className="widgetInputField"

                    />
                <img className="label-icon" src="/img/element.svg" style={{rotate: '180deg'}} alt="" />
                </div>
             
                
            </div>
            
              <div className="save-button-banner">
              </div> 
              <ImageSizeSlider
                  min="100"
                  max="700"
  label=""
  width={editData.height}
  setWidth={(value) => handleInputChange({ target: { name: 'height', value } })}
/>                 
              </div>
              </div>
        );
}    

export default DoubleTextEdit;
