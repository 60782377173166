import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Importiere createRoot aus react-dom/client
import { createRoot } from 'react-dom/client';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // Erstelle eine Root-Instanz

if (rootElement.hasChildNodes()) {
  root.hydrate(<App />); // Verwende hydrate für SSR
} else {
  root.render(<App />); // Verwende render für CSR
}

reportWebVitals();
