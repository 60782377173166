import { getDatabase, ref as dbRef, set, push, get, child } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';

export const createChatAndMessages = async (userId, managerId, orderData) => {
    const db = getDatabase();
    const timestamp = new Date().toISOString();

    const chatData = {
        userId,
        managerId,
        date: timestamp,
        lastMessageAt: timestamp,
    };

    const messageData = {
        serviceTitle: orderData.serviceTitle,
        serviceDeliveryTime: orderData.selectedDelivery.deliveryTime,
        credits: orderData.selectedDelivery.deliveryCredits,
        strategyCall: orderData.strategyCall,
        text: orderData.description,
        type: 'new-order',
        fileUrl: orderData.fileUrl,
        timestamp: timestamp,
        readStatus: false
    };

    try {
        const existingChatsRef = dbRef(db, 'Chats');
        const snapshot = await get(existingChatsRef);
        let chatId = null;

        snapshot.forEach(chat => {
            const chatInfo = chat.val();
            if (chatInfo.userId === userId && chatInfo.managerId === managerId) {
                chatId = chat.key;
            }
        });

        if (chatId) {
            const messagesRef = dbRef(db, `Chats/${chatId}/messages`);
            await push(messagesRef, messageData);
        } else {
            chatId = uuidv4();
            const chatRef = dbRef(db, `Chats/${chatId}`);
            await set(chatRef, chatData);

            const messagesRef = dbRef(db, `Chats/${chatId}/messages`);
            await set(messagesRef, { [uuidv4()]: messageData });
        }

        return chatId;
    } catch (error) {
        console.error('Error creating chat and messages:', error);
        throw error;
    }
};
