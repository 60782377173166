import React, { useState } from 'react';
import GenerativeOrbAnimation from '../GenerativeOrbAnimation';
import DashboardHeader from '../../AdminComponents/DashboardHeader';
import LayoutTemplates from './LayoutTemplates';
import './Layouts.css';
import '../GlobalSettingsModal.css';

const Layout = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPageId, setSelectedPageId] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');

  return (
    <div>
      <DashboardHeader />
      <GenerativeOrbAnimation />
      <div className="layout-component">
        <h1 className="layout_top_text">Select Template</h1>
        <LayoutTemplates
          showModal={showModal}
          setShowModal={setShowModal}
          selectedPageId={selectedPageId}
          setSelectedPageId={setSelectedPageId}
          selectedUserId={selectedUserId}
          setSelectedUserId={setSelectedUserId}
        />
      </div>
    </div>
  );
};

export default Layout;

