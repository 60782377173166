import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, set, onValue, remove, query, orderByKey } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import AddProspects from './AddProspects';
import BulkDelete from './BulkDelete';
import WhatsappTextModal from './WhatsappTextModal';
import ContactStats from './ContactStats';
import EditNameModal from './EditNameModal';
import MarketingDatabaseHeader from './MarketingDatabaseHeader';
import MarketingTable from './MarketingTable';
import SortButtons from './SortButtons';
import './MarketingDatabase.css';

function MarketingDatabase() {
    const [marketingEntries, setMarketingEntries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMoreEntries, setHasMoreEntries] = useState(true);
    const [showWhatsappTextModal, setShowWhatsappTextModal] = useState(false);
    const [editEntry, setEditEntry] = useState(null);
    const [totalEntriesCount, setTotalEntriesCount] = useState(0);
    const [sortOrder, setSortOrder] = useState('notSentFirst'); // Sortierreihenfolge
    const [displayedEntries, setDisplayedEntries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(''); // Neuer Zustand für das ausgewählte Land
    const db = getDatabase();

    const loadAllEntries = () => {
        setLoading(true);
        const marketingRef = query(dbRef(db, 'marketingDatabase'), orderByKey());

        onValue(marketingRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                let entries = Object.entries(data).map(([key, value]) => ({ id: key, ...value }));
                setMarketingEntries(entries);
                sortAndSetEntries(entries, sortOrder, selectedCountry);
            } else {
                setMarketingEntries([]);
                setDisplayedEntries([]);
                setHasMoreEntries(false);
                setTotalEntriesCount(0);
            }
            setLoading(false);
        }, { onlyOnce: true });
    };

    const sortAndSetEntries = (entries, sortOrder, country) => {
        const filteredEntries = country ? entries.filter(entry => entry.Country === country) : entries;
        const sortedEntries = [...filteredEntries].sort((a, b) => {
            if (sortOrder === 'notSentFirst') {
                return (a.whatsappSent === b.whatsappSent) ? 0 : a.whatsappSent ? 1 : -1;
            } else if (sortOrder === 'sentFirst') {
                if (a.whatsappSent === b.whatsappSent) {
                    return new Date(b.contactedDate) - new Date(a.contactedDate);
                }
                return a.whatsappSent ? -1 : 1;
            }
            return 0;
        });
        setMarketingEntries(sortedEntries);
        setDisplayedEntries(sortedEntries.slice(0, 30)); // Initiale Anzeige der ersten 30 Einträge nach Sortierung
        setHasMoreEntries(sortedEntries.length > 30);
        setTotalEntriesCount(sortedEntries.length);
    };

    const loadMoreEntries = () => {
        const nextEntries = marketingEntries.slice(displayedEntries.length, displayedEntries.length + 30);
        setDisplayedEntries(prevEntries => [...prevEntries, ...nextEntries]);
        setHasMoreEntries(displayedEntries.length + nextEntries.length < marketingEntries.length);
    };

    useEffect(() => {
        loadAllEntries();
    }, []);

    useEffect(() => {
        sortAndSetEntries(marketingEntries, sortOrder, selectedCountry);
    }, [sortOrder, selectedCountry]);

    const handleAddProspects = (entries) => {
        entries.forEach(entry => {
            const id = uuidv4();
            set(dbRef(db, `marketingDatabase/${id}`), { id, ...entry });
        });
        loadAllEntries();
    };

    const handleDeleteEntry = (id) => {
        remove(dbRef(db, `marketingDatabase/${id}`)).then(() => {
            setMarketingEntries((prevEntries) => prevEntries.filter(entry => entry.id !== id));
            loadAllEntries();
        });
    };

    const toggleField = (id, field, currentValue) => {
        const dbRefField = dbRef(db, `marketingDatabase/${id}/${field}`);
        const dbRefFieldDate = dbRef(db, `marketingDatabase/${id}/${field}Date`);

        if (currentValue) {
            set(dbRefField, null);
            set(dbRefFieldDate, null).then(() => {
                setMarketingEntries((prevEntries) =>
                    prevEntries.map(entry =>
                        entry.id === id ? { ...entry, [field]: false, [`${field}Date`]: null } : entry
                    )
                );
                loadAllEntries();
            });
        } else {
            const currentDate = new Date().toISOString();
            set(dbRefField, true);
            set(dbRefFieldDate, currentDate).then(() => {
                setMarketingEntries((prevEntries) =>
                    prevEntries.map(entry =>
                        entry.id === id ? { ...entry, [field]: true, [`${field}Date`]: currentDate } : entry
                    )
                );
                loadAllEntries();
            });
        }
    };

    const toggleInterest = (id, interest) => {
        set(dbRef(db, `marketingDatabase/${id}/interest`), !interest).then(() => {
            setMarketingEntries((prevEntries) =>
                prevEntries.map(entry =>
                    entry.id === id ? { ...entry, interest: !interest } : entry
                )
            );
            loadAllEntries();
        });
    };

    const handleBulkDelete = (importName) => {
        const marketingRef = dbRef(db, 'marketingDatabase');
        onValue(marketingRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                Object.entries(data).forEach(([key, value]) => {
                    if (value.importName === importName) {
                        remove(dbRef(db, `marketingDatabase/${key}`)).then(() => {
                            setMarketingEntries((prevEntries) => prevEntries.filter(entry => entry.importName !== importName));
                            loadAllEntries();
                        });
                    }
                });
            }
        });
    };

    const handleWhatsappSent = (id) => {
        const contactedDate = new Date().toISOString();
        set(dbRef(db, `marketingDatabase/${id}/whatsappSent`), true);
        set(dbRef(db, `marketingDatabase/${id}/contactedDate`), contactedDate).then(() => {
            setMarketingEntries((prevEntries) =>
                prevEntries.map(entry =>
                    entry.id === id ? { ...entry, whatsappSent: true, contactedDate } : entry
                )
            );
            loadAllEntries();
        });
    };

    const handleNameSave = (id, newName) => {
        setMarketingEntries((prevEntries) =>
            prevEntries.map(entry =>
                entry.id === id ? { ...entry, Name: newName } : entry
            )
        );
        loadAllEntries();
    };

    // Extracting countries from the entries
    const uniqueCountries = Array.from(new Set(marketingEntries.map(entry => entry.Country)));

    return (
        <div className="marketing-database-container">
            <div className="marketing-header-row">
                <MarketingDatabaseHeader entryCount={totalEntriesCount} />
                <SortButtons sortOrder={sortOrder} setSortOrder={setSortOrder} /> {/* Verwenden Sie die SortButtons Komponente */}
            </div>
            <div className="marketing-top-row">
                <AddProspects onAddProspects={handleAddProspects} />
                <BulkDelete marketingEntries={marketingEntries} onBulkDelete={handleBulkDelete} />
                <button onClick={() => setShowWhatsappTextModal(true)}>WhatsApp Text
                    <img style={{ width: '22px', marginRight: '-10px' }} src="./img/whatsapp.svg" alt="" /> </button>
                {showWhatsappTextModal && <WhatsappTextModal onClose={() => setShowWhatsappTextModal(false)} />}
                <select className="select-country-dropdown" onChange={(e) => setSelectedCountry(e.target.value)} value={selectedCountry}>
                    <option value="">All Countries</option>
                    {uniqueCountries.map((country, index) => (
                        <option key={index} value={country}>{country}</option>
                    ))}
                </select>
                <ContactStats />
               
            </div>
            <MarketingTable
                displayedEntries={displayedEntries}
                handleDeleteEntry={handleDeleteEntry}
                toggleInterest={toggleInterest}
                toggleField={toggleField}
                handleWhatsappSent={handleWhatsappSent}
                setEditEntry={setEditEntry}
                sortOrder={sortOrder} // Passing sortOrder as a prop
            />
            {loading && <p>Loading...</p>}
            {!loading && hasMoreEntries && (
                <button onClick={loadMoreEntries}>Load More
                    <img style={{ width: '25px', marginLeft: '10px', filter: 'invert(1)' }} src="./img/plus.svg" alt="" />
                </button>
            )}
            {editEntry && <EditNameModal entry={editEntry} onClose={() => setEditEntry(null)} onSave={handleNameSave} />}
        </div>
    );
}

export default MarketingDatabase;
