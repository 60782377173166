import React from 'react';
import './StepIndicator.css';

const StepIndicator = ({ currentStep }) => {
    return (
        <div className="step-indicator">
            <div className={`step-indicator__step ${currentStep >= 1 ? 'active' : ''}`}>
                <div className="circle">1</div>
            </div>
            <div className={`step-indicator__line ${currentStep >= 2 ? 'active' : ''}`}></div>
            <div className={`step-indicator__step ${currentStep >= 2 ? 'active' : ''}`}>
                <div className="circle">2</div>
            </div>
        </div>
    );
};

export default StepIndicator;
