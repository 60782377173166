import React, { useEffect, useState, useRef } from 'react';
import { getDatabase, ref, get, push, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Layouts.css';

const LayoutTemplates = ({ showLayoutModal, setShowLayoutModal, selectedPageId, setSelectedPageId, selectedUserId, setSelectedUserId }) => {
  const [pages, setPages] = useState([]);
  const [pageName, setPageName] = useState('');
  const [pageDescription, setPageDescription] = useState('');
  const [customDomain, setCustomDomain] = useState('');
  const [isSuccess, setIsSuccess] = useState(false); // New state for success message
  const modalRef = useRef();
  const auth = getAuth();
  const db = getDatabase();
  const currentUser = auth.currentUser;
  const navigate = useNavigate();
  const [newPageId, setNewPageId] = useState(null);

  useEffect(() => {
    const pagesRef = ref(db, '/demoPages');
    // Close modal on outside click
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  useEffect(() => {
    const pagesRef = ref(db, '/demoPages');

    get(pagesRef).then((snapshot) => {
      if (snapshot.exists()) {
        const pagesData = snapshot.val();
        const loadedPages = Object.keys(pagesData).flatMap(userId =>
          Object.entries(pagesData[userId]).map(([pageId, pageInfo]) => ({
            url: `/demo/${pageInfo.customRoute}`,
            id: pageId,
            userId,
            name: pageInfo.name, // Assuming pageInfo has a 'name' field
          }))
        );

        setPages(loadedPages);
      } else {
        console.log("No pages found.");
      }
    }).catch(error => {
      console.error("Error loading pages:", error);
    });
  }, []);

  const validateCustomDomain = () => {
    if (!pageName.trim() || !pageDescription.trim() || !customDomain.trim()) {
      alert('Please fill out all fields.');
      return false;
    }
    return true;
  };

  const checkCustomDomainExists = async () => {
    const customDomainRef = ref(db, `/publicPages/${customDomain}`);
    const snapshot = await get(customDomainRef);
    return snapshot.exists();
  };

  const duplicatePageToUserAccount = async () => {
    if (!currentUser) {
      alert('Please log in to use this feature.');
      return;
    }
    if (!validateCustomDomain()) return;

    const exists = await checkCustomDomainExists();
    if (exists) {
      alert('This custom domain is already taken. Please choose another.');
      return;
    }
    const demoPageRef = ref(db, `/demoPages/${selectedUserId}/${selectedPageId}`);
    const demoPageSnapshot = await get(demoPageRef);
    if (!demoPageSnapshot.exists()) {
      alert('Page not found.');
      return;
    }

    const demoPageData = demoPageSnapshot.val();
    const newPageRef = push(ref(db, `/pages`));
    const newPageId = newPageRef.key;

    await set(newPageRef, {
      ...demoPageData,
      name: pageName,
      description: pageDescription,
      customDomain: customDomain,
      customRoute: customDomain,
      isActive: false,
      id: newPageId,
      userId: currentUser.uid, // add userId
    });

    if (customDomain) {
      await set(ref(db, `/publicPages/${customDomain}`), { userId: currentUser.uid, pageId: newPageId });
    }

    setIsSuccess(true); // Set success state after successful duplication
    setNewPageId(newPageRef.key); // After successfully creating the page
  };

  const openModal = (pageId, userId) => {
    setSelectedPageId(pageId);
    setSelectedUserId(userId);
    setShowLayoutModal(true);
  };

  const closeModal = () => {
    setShowLayoutModal(false);
    setPageName('');
    setPageDescription('');
    setCustomDomain('');
    setIsSuccess(false); // Reset success state
  };

  return (
    <div>
      <div className="layout-component">
        <div className="layouts">
          {pages.map((page, index) => (
            <div key={index} className="framey-component">
              <div className="framey-buttons">
                <div>
                  <p>{page.name}</p>
                </div>
                <div className="framey-hidden">
                  <button className="framey_button" id="left" onClick={() => openModal(page.id, page.userId)}>
                    <p>Use Template</p>
                    <img src="./img/plus.svg" alt="" />
                  </button>
                </div>
              </div>
              <iframe className="framey" src={page.url} title={`Seite ${index}`} style={{ width: '100%', height: '500px' }}></iframe>
            </div>
          ))}
        </div>
      </div>
      {showLayoutModal && (
        <div className="modal-background">
          <div ref={modalRef}>
            {isSuccess ? (
              <div className="modal-content" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <h2>Yay!</h2>
                <p>You just imported this page.</p>
                <div className="qr-buttons">
                  <button className="qr-modal-open" onClick={() => navigate(`/settings/page/${newPageId}`)}>Edit Page</button>
                  <button className="qr-modal-close" onClick={() => navigate(`/`)}>My Pages</button>
                </div>
              </div>
            ) : (
              <div className="modal-content">
                <h2>Use this Template</h2>
                <label className="modal-content-label">Page Link</label>
                <div className="custom-domain-input" style={{ width: '380px', border: '1px solid lightgrey' }}>
                  <span className="static-text">bonusclub.link/to/</span>
                  <input
                    style={{ border: 'none', fontSize: '15px', marginBottom: '10px', marginLeft: '-2px' }}
                    type="text"
                    name="customDomain"
                    className="pseudo_input"
                    value={customDomain}
                    onChange={(e) => setCustomDomain(e.target.value)}
                    placeholder="my_business"
                  />
                </div>
                <label className="modal-content-label">Name of your Page</label>
                <input
                  className="text-input-field-2"
                  type="text"
                  value={pageName}
                  onChange={(e) => setPageName(e.target.value)}
                  placeholder="Page Name"
                />
                <label className="modal-content-label">Describe your Page</label>
                <textarea
                  className="description-input-field-2"
                  value={pageDescription}
                  onChange={(e) => setPageDescription(e.target.value)}
                  placeholder="Short description of your page"
                />
                <div className="qr-buttons">
                  <button className="qr-modal-open" onClick={duplicatePageToUserAccount}>Use Template</button>
                  <button className="qr-modal-close" onClick={closeModal}>Close</button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

LayoutTemplates.propTypes = {
  showLayoutModal: PropTypes.bool.isRequired,
  setShowLayoutModal: PropTypes.func.isRequired,
  selectedPageId: PropTypes.string.isRequired,
  setSelectedPageId: PropTypes.func.isRequired,
  selectedUserId: PropTypes.string.isRequired,
  setSelectedUserId: PropTypes.func.isRequired,
};

export default LayoutTemplates;
