import './TopWidget.css'; 
import './Bonus1.css'; 
import { useFont } from '../../Design/FontContext';
import React, { useState } from 'react';
import DeleteModal from './DeleteModal';


function appendPx(value) {
    // Falls der Wert bereits eine Einheit hat, geben Sie ihn unverändert zurück.
    if (typeof value === 'string' && isNaN(value)) {
      return value;
    }
  
    // Andernfalls hängen Sie 'px' an die Zahl an.
    return `${value}px`;
  }
  
 



function Bonus1({ data, onRemove, isSelected, isEditMode, inverted}) {
    // Standardpfade für Bilder festlegen
    const defaultBannerSrc = '/img/default-banner.jpg'; // Pfad zum Standard-Bannerbild anpassen
    const defaultLogoSrc = '/img/gif/clean/diamond.gif'; // Pfad zum Standard-Logobild anpassen
    const defaultIcon = '/img/right.svg'; // Pfad zum Standard-Bild anpassen
    const deleteLogo = '/img/delete.svg'; // Pfad zum Standard-Logobild anpassen
    const widgetStyle = {
        backgroundColor: data.backgroundColor,
        marginBottom: appendPx(data.marginBottom),
        marginTop: appendPx(data.marginTop),
        border: getBorderStyle(),
        borderColor: data.borderColor,
        boxShadow: data.shadow ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : 'none',
    };
    
    const fonts = useFont();
    const widgetHref = isEditMode ? null : data.link;
    const [showDeleteModal, setShowDeleteModal] = useState(false);


    const handleDelete = (event) => {
        // Logik zum Löschen des Objekts
        onRemove(event, data.id);
        setShowDeleteModal(false);
    };



    function isDark(color) {
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;
        const luma = 0.299 * r + 0.587 * g + 0.114 * b;
        return luma < 128;
    }

    function getBorderStyle() {
        if (isEditMode) {
            // Im Editiermodus
            if (isSelected) {
                // Wenn das Widget ausgewählt ist
                const borderColor = isDark(data.backgroundColor) ? '#FFFFFF' : '#000000';
                return `2px dashed ${borderColor}`;
            } else {
                // Wenn das Widget nicht ausgewählt ist
                return data.border ? `1.5px solid ${data.borderColor}` : '2px dashed transparent';
            }
        } else {
            // Im Live-Modus
            return data.border ? `1.5px solid ${data.borderColor}` : 'none';
        }
    }
    
    function getBorderStyle2() {
        if (isEditMode) {
            // Im Editiermodus
            if (isSelected) {
                // Wenn das Widget ausgewählt ist
                return '2px dashed black';
            } else {
                // Wenn das Widget nicht ausgewählt ist
                return data.border ? `1.5px solid ${data.borderColor}` : '2px dashed transparent';
            }
        } else {
            // Im Live-Modus
            return data.border ? `1.5px solid ${data.borderColor}` : 'none';
        }
    }
    
    

    return (
        <div className="bonus_1"  style={widgetStyle}>
       <div>
    {isEditMode && (
        <button className="delete_button" onClick={() => setShowDeleteModal(true)} 

>
            <img className="delete_logo" src={deleteLogo} alt="Löschen" />
        </button>
    )}
    {isEditMode && showDeleteModal && (
        <DeleteModal
            isOpen={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onConfirm={(event) => handleDelete(event, data.id)}
        />
    )}
</div>
            <h6
            className="top_bonus_banner"
            style={{                
                color: data.textColor1,
                border: getBorderStyle(),
        }}
            >
                {data.text1}</h6>
            <img 
              src={data.imgLogo || defaultLogoSrc} 
              className={`bonus_1_logo_img ${inverted ? 'inverted' : ''}`}
              alt="Logo" 
              style={{ width: appendPx(data.logoWidth) ,
            filter: data.inverted ? 'invert(100%)' : 'none'
            
            
            }} 
            />
               <p style={{
                color: data.textColor2,
                fontSize: appendPx(data.text2Size),
                fontWeight: data.text2Weight,
                fontFamily: fonts.globalSmallFont,
            }}>
                {data.text2}
            </p>
            <h2 style={{
                color: data.textColor3,
                fontSize: appendPx(data.text1Size),
                fontWeight: data.text1Weight,
                fontFamily: fonts.globalBigFont,
            }}>
                {data.text3}
            </h2>
            
            <a  href={widgetHref} target="_blank" className="icon_feature_10" style={{
            textDecoration: 'none',
            color: 'black',
            backgroundColor: data.buttonBackgroundColor,
            marginBottom: appendPx(data.marginBottom),
            marginTop: appendPx(data.marginTop),
            boxShadow: data.shadow ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : 'none',
            borderRadius: appendPx(data.borderRadius),
            background: data.buttonBackgroundColor,
            border: getBorderStyle2(),
        }}>
                <h5 
                id="button_h5_text_2"
                style={{
                    color: data.buttonTextColor,
                    fontFamily: fonts.globalSmallFont, 
                }}>
                    {data.buttonText}
                </h5>
                         
            <div>
          
            </div>
        </a>
        </div>
    );
}

export default Bonus1;
