const StepFour = ({ inputs, handleInputChange, handleBack, handleSubmission }) => {
  return (
    <div>
      <div>
        <label>First Name:</label>
        <input 
          type="text" 
          name="firstName"
          value={inputs.firstName}
          onChange={handleInputChange}
          placeholder="First Name"
        />
      </div>
      <div>
        <label>Last Name:</label>
        <input 
          type="text" 
          name="lastName"
          value={inputs.lastName}
          onChange={handleInputChange}
          placeholder="Last Name"
        />
      </div>
      <div>
        <label>Telephone:</label>
        <input 
          type="text" 
          name="telephone"
          value={inputs.telephone}
          onChange={handleInputChange}
          placeholder="Telephone"
        />
      </div>
      <div>
        <label>Email:</label>
        <input 
          type="text" 
          name="email"
          value={inputs.email}
          onChange={handleInputChange}
          placeholder="Email"
        />
      </div>
      <div>
        <input type="checkbox" name="termsOne" checked={inputs.termsOne} onChange={handleInputChange} />
        Accept terms of use 1
      </div>
      <div>
        <input type="checkbox" name="termsTwo" checked={inputs.termsTwo} onChange={handleInputChange} />
        Accept terms of use 2
      </div>
      <button onClick={handleBack}>Back</button>
      <button onClick={handleSubmission}>Submit</button>
    </div>
  );
};

export default StepFour;