import React, { useState, useRef } from 'react';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebaseConfig'; // Stellen Sie sicher, dass der Pfad korrekt ist
import Resizer from 'react-image-file-resizer';
import { v4 as uuidv4 } from 'uuid'; // Korrekt importieren
import './AddService.css'

function AddServiceImageUploader({ onImageUpload }) {
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const fileInputRef = useRef(null);

    const handleFilesChange = async (event) => {
        const files = Array.from(event.target.files);
        setUploading(true);

        const resizedFilesPromises = files.map((file) => 
            new Promise((resolve) => {
                Resizer.imageFileResizer(
                    file,
                    800, // maximale Breite
                    800, // maximale Höhe
                    'JPEG',
                    90, // Qualität
                    0,
                    (uri) => {
                        resolve(uri);
                    },
                    'blob'
                );
            })
        );

        const resizedFiles = await Promise.all(resizedFilesPromises);
        resizedFiles.forEach(uploadImage);
    };

    const uploadImage = (blob) => {
        const uniqueFileName = `services/${uuidv4()}.jpeg`;
        const fileRef = storageRef(storage, uniqueFileName);
        const uploadTask = uploadBytesResumable(fileRef, blob);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                console.error('Upload failed:', error);
                setUploading(false);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    onImageUpload(downloadURL);
                    setUploading(false);
                }).catch((error) => {
                    console.error('Error retrieving download URL:', error);
                    setUploading(false);
                });
            }
        );
    };

    return (
        <div>
            <div className="uploader-profile-image-container" onClick={() => fileInputRef.current.click()}>
                <input
                    type="file"
                    onChange={handleFilesChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept="image/*"
                    multiple // Ermöglicht das Hochladen mehrerer Bilder
                />
                <div className="uploader-profile-placeholder-2">
                    <img src="../../img/uploading.svg" alt="" />
                    <span>Upload Project Image</span>
                </div>
            </div>
            {uploading && (
                <div className="unique-image-display" style={{ height: '100px' }}>
                    <div className="unique-image-placeholder">
                        <img style={{ width: '30px' }} className="uploading-image" src="../../img/icon.png" alt="" />
                        <div className="spinner-3"></div>
                        <div>Upload progress: {uploadProgress}%</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddServiceImageUploader;
