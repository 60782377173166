// BackgroundColorChanger.js
import React from 'react';
import './BackgroundColorChanger.css';

const BackgroundColorChanger = ({ color, onChange }) => {
    return (
        <div className="background-color-changer">
                          <h3>Background Color</h3>
          <div className="colorPicker">
                        <input
                            id="backgroundColorPicker"
                            type="color"
                            value={color}
                            onChange={(e) => onChange(e.target.value)}
                            className="color-input"
                        />
                        <div
                            className="custom-color-display-2"
                            style={{ backgroundColor: color }}
                        ></div>
                        <img className="color-picker-icon" src="/img/text-fat.svg" alt="" />
                    </div>
        </div>
    );
};

export default BackgroundColorChanger;
