import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, get } from "firebase/database";
import { getAuth } from 'firebase/auth';
import CheckoutButton from '../Checkout/CheckoutButton';
import './BottomPurchase.css';

const BottomPurchase = () => {
  const [timeLeft, setTimeLeft] = useState({});
  const [isPremium, setIsPremium] = useState(null);  // Initialwert auf null setzen
  const [trialOver, setTrialOver] = useState(false);

  const calculateTimeLeft = (startDate) => {
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 30);
    const now = new Date();
    const difference = endDate - now;

    if (difference <= 0) {
      setTrialOver(true);
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    } else {
      setTrialOver(false);
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
  };

  useEffect(() => {
    const db = getDatabase();
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const userRef = dbRef(db, `/Users/${currentUser.uid}`);
      get(userRef).then((snapshot) => {
        const userInfo = snapshot.val();
        const premiumStatus = userInfo?.isPremium ?? false;
        setIsPremium(premiumStatus);

        if (!premiumStatus) {
          const trialInfo = userInfo?.pagesFreeTrial;
          if (trialInfo && trialInfo.hasPagesTrial) {
            const startDate = trialInfo.startDate;
            setTimeLeft(calculateTimeLeft(startDate));

            const timer = setInterval(() => {
              setTimeLeft(calculateTimeLeft(startDate));
            }, 1000);

            return () => clearInterval(timer);
          } else {
            setTrialOver(true);
            setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
          }
        }
      });
    }
  }, []);

  // Prüfung auf null, um das Rendering zu verzögern
  if (isPremium === null) {
    return null; // Rendern Sie nichts, wenn isPremium noch nicht festgestellt wurde
  }

  if (isPremium) {
    return null;
  }

  return (
    <div className="bottom-purchase-container">
      <div className="bottom-purchase-left">
        <div className="time-unit">
          <div className="time-value">{trialOver ? 0 : timeLeft.days}</div>
          <div className="time-label">Days</div>
        </div>
        <div className="time-unit">
          <div className="time-value">{trialOver ? 0 : timeLeft.hours}</div>
          <div className="time-label">Hours</div>
        </div>
        <div className="time-unit">
          <div className="time-value">{trialOver ? 0 : timeLeft.minutes}</div>
          <div className="time-label">Minutes</div>
        </div>
        <div className="time-unit">
          <div className="time-value">{trialOver ? 0 : timeLeft.seconds}</div>
          <div className="time-label">Seconds</div>
        </div>
      </div>
      <div className="bottom-purchase-center">
        <span>{trialOver ? "Trial over, please purchase premium" : "Bonusclub Free Trial"}</span>
      </div>
      <div className="bottom-purchase-right">
        <CheckoutButton />
      </div>
    </div>
  );
};

export default BottomPurchase;
