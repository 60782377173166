import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import './NewsPage.css'
import { useFont } from '../Design/FontContext';


const NewsletterDisplay = () => {
    const [newsletter, setNewsletter] = useState({
        newsletterImage: "",
        newsletterTitle: "",
        newsletterText: "",
        newsletterSendgrid: "", // Fügen Sie das Feld für die newsletterSendgrid ID hinzu
        newsletterOptBanner: "", // Neues Feld
        newsletterOptTitle: "",  
        features: []
    });
    const [isLoading, setIsLoading] = useState(true);
    const selectedFont = useFont();
    const currentUser = getAuth().currentUser;
    

    const [isImageLoading, setIsImageLoading] = useState(true);

    useEffect(() => {
        if (!currentUser) {
            console.error("Benutzer ist nicht angemeldet.");
            setIsLoading(false);
            setIsImageLoading(false);
            return;
        }
    
        const loadNewsletterData = async () => {
            setIsLoading(true);
            setIsImageLoading(true);
    
            const db = getDatabase();
            const newsletterRef = dbRef(db, `news/${currentUser.uid}`);
    
            try {
                const snapshot = await get(newsletterRef);
                if (snapshot.exists()) {
                    const newsletterData = snapshot.val();
                    setNewsletter(newsletterData);
    
                    if (newsletterData.newsletterImage) {
                        const img = new Image();
                        img.src = newsletterData.newsletterImage;
                        img.onload = () => setIsImageLoading(false);
                        img.onerror = () => setIsImageLoading(false);
                    } else {
                        setIsImageLoading(false);
                    }
                } else {
                    console.log("Keine Newsletter-Daten gefunden.");
                    setIsImageLoading(false);
                }
            } catch (error) {
                console.error("Fehler beim Laden des Newsletters:", error);
                setIsImageLoading(false);
            } finally {
                setIsLoading(false);
            }
        };
    
        loadNewsletterData();
    }, [currentUser]);
    
    // Anzeigebedingung für das Laden
    if (isLoading || isImageLoading) {
        return    <div className="loading-spinner" id="fake-loading-spinner">
        <h1>BonusClub</h1>
        <div className="spinner-circle"></div>
      </div>;
    }
    


    return (
        <div className="news_page">
            <h2 style={{ fontFamily: selectedFont }}>{newsletter.newsletterTitle}</h2>
            <p>{newsletter.newsletterText}</p>
            <img src={newsletter.newsletterImage} alt="Newsletter" className="news_header_image" />
            <ul className="listlist">
                {newsletter.features.map((feature, index) => (
                    <div className="feature">
                    <li key={index} className="news-feature-list">
                        <img src={feature.img} alt={`Feature ${index}`} />
                        <div>
                        <h4>{feature.title}</h4>
                        <p>{feature.description}</p>
                        </div>
                    </li>
                    </div>
                    
                ))}
            </ul>
            <div>
                <div className="iframe_wrapper">
                    <h5>{newsletter.newsletterOptBanner || 'FREE BONUS'}</h5>
                    <p>{newsletter.newsletterOptTitle || 'Where should we send your Bonus? 📧'}</p>
            <iframe className="iframe_box" src={newsletter.newsletterSendgrid} title="Newsletter Form" />
            </div>
            </div>
        </div>
    );
};

export default NewsletterDisplay;
