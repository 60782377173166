import React, { useState, useEffect, useRef } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref as dbRef, onValue, update, query, orderByChild, limitToLast, get } from 'firebase/database';
import './TeamChatWindow.css';  // Verwenden Sie die neue CSS-Datei für TeamChatWindow
import ChatInput from './ChatInput';

const INITIAL_PAGE_SIZE = 30;

const TeamChatWindow = ({ chatId }) => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
    const messagesEndRef = useRef();
    const initialLoad = useRef(true);

    useEffect(() => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        const database = getDatabase();
        const messagesRef = query(dbRef(database, `Chats/${chatId}/messages`), orderByChild('timestamp'), limitToLast(pageSize));

        const fetchChatAndUserInfo = async () => {
            const chatRef = dbRef(database, `Chats/${chatId}`);
            const chatSnapshot = await get(chatRef);
            if (chatSnapshot.exists()) {
                // Keine zusätzlichen Daten abzurufen, da es nur die Nachrichten anzuzeigen gibt
            }
        };

        fetchChatAndUserInfo();

        const unsubscribe = onValue(messagesRef, (snapshot) => {
            if (snapshot.exists()) {
                const newMessages = [];
                snapshot.forEach(childSnapshot => {
                    newMessages.push({ id: childSnapshot.key, ...childSnapshot.val() });
                });
                setMessages(newMessages);
                markMessagesAsRead(newMessages);
                scrollToBottom();
            }
        });

        return () => {
            unsubscribe();
            initialLoad.current = true;
        };
    }, [chatId, pageSize]);

    useEffect(() => {
        if (initialLoad.current && messages.length > 0) {
            scrollToBottom();
            initialLoad.current = false;
        }
    }, [messages]);

    const markMessagesAsRead = (messages) => {
        const auth = getAuth();
        const database = getDatabase();
        messages.forEach(message => {
            if (message.userId !== auth.currentUser.uid && !message.readStatus) {
                const msgRef = dbRef(database, `Chats/${chatId}/messages/${message.id}`);
                update(msgRef, { readStatus: true });
            }
        });
    };

    const scrollToBottom = () => {
        const scrollContainer = document.querySelector('.team-messages-list');
        if (scrollContainer) {
            const start = scrollContainer.scrollTop;
            const end = scrollContainer.scrollHeight;
            const distance = end - start;
            const duration = 200;
            const startTime = performance.now();
            const easeInOutQuad = (t) => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

            const scrollStep = (timestamp) => {
                const currentTime = timestamp - startTime;
                const scrollProgress = easeInOutQuad(Math.min(1, currentTime / duration));
                scrollContainer.scrollTop = start + distance * scrollProgress;

                if (currentTime < duration) {
                    requestAnimationFrame(scrollStep);
                }
            };

            requestAnimationFrame(scrollStep);
        }
    };

    const loadMoreMessages = () => {
        if (!loading) {
            setLoading(true);
            setPageSize(prevPageSize => prevPageSize + INITIAL_PAGE_SIZE);
            setLoading(false);
        }
    };

    return (
        <div className="team-chat-window">
            <div className="team-messages-list">
                {messages.length > INITIAL_PAGE_SIZE && (
                    <button onClick={loadMoreMessages} disabled={loading} className="team-load-more-btn">Load More Messages</button>
                )}
                {messages.map((message) => {
                    const auth = getAuth();
                    const isCurrentUserMessage = message.userId === auth.currentUser.uid;
                    const lastMessage = messages[messages.length - 1];
                    const isLastMessageFromCurrentUser = lastMessage && lastMessage.userId === auth.currentUser.uid;
                    const showReadStatus = isLastMessageFromCurrentUser && lastMessage.id === message.id;

                    if (message.type === "new-order") {
                        return (
                            <div key={message.id} className={`team-message new-order ${isCurrentUserMessage ? 'right' : 'left'}`}>
                                <div className="team-new-order-bubble">
                                    <div className="team-new-order-top-bar" style={{display: 'flex'}}>
                                        <h3>{message.serviceTitle}</h3>
                                   
                                 <p className="team-delivery-truck-bar"><strong><img src="./img/delivery_1.svg" alt="" /></strong> {message.serviceDeliveryTime}</p>
                                <div style={{display: 'flex'}}>
                                <img style={{width: '15px', marginLeft: '10px', marginRight: '5px'}} src="./img/coin.svg" alt="" />
                                <p>{message.credits}</p>
                                </div>
                                        </div>
                                    <p><strong></strong> {message.strategyCall ? 'incl. 30 Minutes Strategy Call' : 'No'}</p>
                                    <p className="team-new-message-text">{message.text}</p>
                                    {message.fileUrl && <img src={message.fileUrl} alt="Order Attachment" />}
                                    {showReadStatus && (
                                        <div className="team-read-status">
                                            {message.readStatus ? 'Read' : 'Sent'}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    }

                    return (
                        <div key={message.id} className={`team-message ${isCurrentUserMessage ? 'right' : 'left'}`}>
                            <div className="team-bubble">
                                <p>{message.description}</p>
                                {message.fileUrl && <img src={message.fileUrl} alt="Message" />}
                                {showReadStatus && (
                                    <div className="team-read-status">
                                        {message.readStatus ? 'Read' : 'Sent'}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
                <div ref={messagesEndRef} />
            </div>
            <div className="team-chat-message-input-container">
                <ChatInput chatId={chatId} />
            </div>
        </div>
    );
};

export default TeamChatWindow;
