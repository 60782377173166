// TemplateSelector.js
import React from 'react';
import { getDatabase, ref, set } from 'firebase/database';

const TemplateSelector = ({ pageId }) => {
    const db = getDatabase();

    const handleTemplateSelection = (templateId) => {
        // Hier würde die Logik stehen, um das ausgewählte Template zu laden
        // und seine Widgets zur aktuellen Seite hinzuzufügen.
        // Dies ist ein Platzhalter für die tatsächliche Implementierung.
        console.log(`Template ${templateId} ausgewählt für Seite ${pageId}`);
    };

    return (
        <div>
            {/* UI für die Auswahl von Templates */}
            <button onClick={() => handleTemplateSelection('template1')}>Template 1</button>
            <button onClick={() => handleTemplateSelection('template2')}>Template 2</button>
            {/* Weitere Templates */}
        </div>
    );
};

export default TemplateSelector;
