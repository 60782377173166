import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, onValue, remove } from "firebase/database";
import { getStorage, ref as storageRef, listAll, deleteObject } from "firebase/storage";
import './DashboardServiceList.css';

function DashboardServiceList({ onEdit }) {
    const [services, setServices] = useState([]);

    useEffect(() => {
        const db = getDatabase();
        const servicesRef = dbRef(db, 'Services');
        
        onValue(servicesRef, (snapshot) => {
            const data = snapshot.val();
            const serviceList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
            setServices(serviceList);
        });
    }, []);

    const handleDelete = async (serviceId) => {
        const db = getDatabase();
        const storage = getStorage();
        
        try {
            // Remove service from database
            const serviceRef = dbRef(db, `Services/${serviceId}`);
            await remove(serviceRef);

            // Remove associated files from storage
            const storageFolderRef = storageRef(storage, `services/${serviceId}`);
            const folderContents = await listAll(storageFolderRef);
            const deletePromises = folderContents.items.map(item => deleteObject(item));
            await Promise.all(deletePromises);

            // Update the service list state
            setServices(prevServices => prevServices.filter(service => service.id !== serviceId));
            alert('Service deleted successfully');
        } catch (error) {
            console.error('Error deleting service:', error);
            alert('An error occurred while deleting the service. Please try again.');
        }
    };

    return (
        <div className="service-list-container">
            <h2 className="service-list-title">Service List</h2>
            {services.map(service => (
                <div key={service.id} className="service-item">
                    <h3>{service.serviceTitle}</h3>
                    <p>{service.serviceDescription}</p>
                    <button className="edit-button" onClick={() => onEdit(service.id)}>Edit</button>
                    <button className="delete-button" onClick={() => handleDelete(service.id)}>Delete</button>
                </div>
            ))}
        </div>
    );
}

export default DashboardServiceList;
