import React, { useState, useEffect } from 'react';
import { get, getDatabase, ref as dbRef, onValue, update } from "firebase/database";
import { getAuth } from 'firebase/auth';
import './TeamDashboard.css';

function PremiumToggle() {
    const [users, setUsers] = useState({});
    const db = getDatabase();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const [showModal, setShowModal] = useState(false);
    const [currentThumbnails, setCurrentThumbnails] = useState([]);

    useEffect(() => {
        if (!currentUser) return; // Exit if no user is logged in.

        const usersRef = dbRef(db, 'Users');
        const unsubscribe = onValue(usersRef, async (snapshot) => {
            const usersData = snapshot.val();
            if (!usersData) {
                console.log("No users found in the database.");
                return;
            }

            const usersWithCounts = {};
            for (const userId of Object.keys(usersData)) {
                const surveysRef = dbRef(db, `surveys/${userId}`);
                const pagesRef = dbRef(db, `pages/${userId}`);
                const creditsRef = dbRef(db, `Credits/${userId}`);
                const surveysSnapshot = await get(surveysRef);
                const pagesSnapshot = await get(pagesRef);
                const creditsSnapshot = await get(creditsRef);
                const surveysData = surveysSnapshot.val();
                const pagesData = pagesSnapshot.val();
                const creditsData = creditsSnapshot.val();

                let totalPhotoCount = 0;
                let totalPagesCount = pagesData ? Object.keys(pagesData).length : 0;
                let totalCredits = creditsData ? creditsData.amount : 0;

                if (surveysData) {
                    for (const surveyId of Object.keys(surveysData)) {
                        const currentSurvey = surveysData[surveyId];
                        if (currentSurvey.uploadedThumbnails) {
                            totalPhotoCount += Object.keys(currentSurvey.uploadedThumbnails).length;
                        }
                    }
                }

                usersWithCounts[userId] = {
                    ...usersData[userId],
                    photoCount: totalPhotoCount,
                    pagesCount: totalPagesCount,
                    credits: totalCredits,
                    sessionCount: usersData[userId].sessionCount || 0,
                    lastLogin: usersData[userId].lastLogin || 'Never',
                    lastAccess: usersData[userId].lastAccess || 'Never',
                };
            }

            setUsers(usersWithCounts);
        });

        return () => unsubscribe();
    }, [db, currentUser]);

    const toggleAgencyStatus = (userId) => {
        const userRef = dbRef(db, `Users/${userId}`);
        const currentAgencyStatus = users[userId].hasAgency;
        update(userRef, { hasAgency: !currentAgencyStatus }).catch(error => {
            console.error("Error updating hasAgency status:", error);
        });
    };

    const togglePremiumStatus = (userId) => {
        const userRef = dbRef(db, `Users/${userId}`);
        const currentStatus = users[userId].isPremium;
        update(userRef, { isPremium: !currentStatus }).catch(error => {
            console.error("Error updating isPremium status:", error);
        });
    };

    const handleThumbnailClick = async (userId) => {
        const thumbnails = [];
        // Reference to user surveys
        const userSurveysRef = dbRef(db, `surveys/${userId}`);
        const surveysSnapshot = await get(userSurveysRef);
        const surveysData = surveysSnapshot.val();

        console.log("Surveys data for user", userId, surveysData);

        if (surveysData) {
            for (const surveyId of Object.keys(surveysData)) {
                const uploadedThumbnails = surveysData[surveyId].uploadedThumbnails;
                if (uploadedThumbnails) {
                    for (const thumbnailData of Object.values(uploadedThumbnails)) {
                        thumbnails.push(thumbnailData.thumbnail);
                    }
                }
            }
        }

        console.log("Collected thumbnails for user", userId, thumbnails);

        setCurrentThumbnails(thumbnails);
        setShowModal(true);
    };

    return (
        <div style={{ padding: '20px' }}>
            <div className="thumbnail-modal" style={{ display: showModal ? 'block' : 'none' }}>
                <button onClick={() => setShowModal(false)}>Close</button>
                <div className="thumbnail-grid">
                    {currentThumbnails.map((thumbnail, index) => (
                        <img key={index} src={thumbnail} alt={`Thumbnail ${index}`} />
                    ))}
                </div>
            </div>
            <div className="master_dashboard_header">
                <h1>BonusClub Master Dashboard</h1>
            </div>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr style={{ borderBottom: '1px solid #000' }}>
                        <th>Email</th>
                        <th>Name</th>
                        <th>User ID</th>
                        <th>Has Settings</th>
                        <th>Premium Status</th>
                        <th>Club Status</th>
                        <th>Pages Count</th>
                        <th>Session Count</th>
                        <th>Last Login</th>
                        <th>Last Access</th>
                        <th>Credits</th> {/* Neu hinzugefügt */}
                        <th>Images Uploaded</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(users).map(([userId, userData]) => (
                        <tr key={userId}>
                            <td>{userData.email}</td>
                            <td>{userData.name || 'No Name'}</td>
                            <td>{userId}</td>
                            <td>{userData.hasSettings ? 'True' : 'False'}</td>
                            <td>
                                <button onClick={() => togglePremiumStatus(userId)} style={{ padding: '5px 10px' }}>
                                    {userData.isPremium ? "Is Premium" : "Add Premium"}
                                </button>
                            </td>
                            <td>
                                <button onClick={() => toggleAgencyStatus(userId)} style={{ padding: '5px 10px' }}>
                                    {userData.hasAgency ? "Is Club" : "Add Club"}
                                </button>
                            </td>
                            <td>{userData.pagesCount}</td>
                            <td>{userData.sessionCount}</td>
                            <td>{userData.lastLogin}</td>
                            <td>{userData.lastAccess}</td>
                            <td>{userData.credits}</td> {/* Neu hinzugefügt */}
                            <td>{userData.photoCount}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default PremiumToggle;
