import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref as databaseRef, push, set, update } from 'firebase/database';

const ChatInput = ({ chatId }) => {
    const [inputMessage, setInputMessage] = useState('');
    const [imageUploadUrl, setImageUploadUrl] = useState('');
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [resetImageUpload, setResetImageUpload] = useState(false);

    const handleUploadSuccess = (url) => {
        setImageUploadUrl(url);
    };

    const updateTypingStatus = () => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        const database = getDatabase();
        const typingRef = databaseRef(database, `Chats/${chatId}/typing/${currentUser.uid}`);
        set(typingRef, true);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const newTimeout = setTimeout(() => {
            set(typingRef, false);
        }, 3000);

        setTypingTimeout(newTimeout);
    };

    const handleTyping = (value) => {
        setInputMessage(value);
        updateTypingStatus();
    };

    const handleSendMessage = async () => {
        if (!inputMessage && !imageUploadUrl) {
            console.error('No message or image to send');
            return;
        }

        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.error('No user logged in');
            return;
        }

        const database = getDatabase();
        const messagesRef = databaseRef(database, `Chats/${chatId}/messages`);
        const newMessageRef = push(messagesRef);
        const timestamp = new Date().toISOString();

        await set(newMessageRef, {
            description: inputMessage,
            fileUrl: imageUploadUrl,
            userId: currentUser.uid,
            type: "normal",
            timestamp,
            readStatus: false
        });

        const chatRef = databaseRef(database, `Chats/${chatId}`);
        await update(chatRef, {
            lastMessageAt: timestamp
        });

        setInputMessage('');
        setImageUploadUrl('');
        setResetImageUpload(!resetImageUpload);
        set(databaseRef(database, `Chats/${chatId}/typing/${currentUser.uid}`), false);
    };

    return (
        <div className="chat-message-input">
            <textarea
                value={inputMessage}
                onChange={e => handleTyping(e.target.value)}
                placeholder="Type here"
            />
            <button className="chat-image-send-button" onClick={handleSendMessage}>
                <img src="../../img/sendo.svg" alt="Send" />
            </button>
        </div>
    );
};

export default ChatInput;
