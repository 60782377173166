import React, { useState, useEffect } from 'react';
import { get, getDatabase, ref as dbRef, onValue, update } from "firebase/database";
import { getAuth } from 'firebase/auth';
import './TeamDashboard.css';

function AddCredits() {
    const [users, setUsers] = useState({});
    const [selectedUser, setSelectedUser] = useState(null);
    const [creditAmount, setCreditAmount] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const db = getDatabase();
    const auth = getAuth();
    const currentUser = auth.currentUser;

    useEffect(() => {
        if (!currentUser) return;

        const usersRef = dbRef(db, 'Users');
        const unsubscribe = onValue(usersRef, async (snapshot) => {
            const usersData = snapshot.val();
            if (!usersData) {
                console.log("No users found in the database.");
                return;
            }

            setUsers(usersData);
        });

        return () => unsubscribe();
    }, [db, currentUser]);

    const handleAddCredits = async () => {
        if (!selectedUser || !creditAmount) {
            setErrorMessage('Please select a user and enter a credit amount.');
            return;
        }

        const userCreditsRef = dbRef(db, `Credits/${selectedUser}`);
        const userRef = dbRef(db, `Users/${selectedUser}`);
        const currentTimestamp = new Date().toISOString();
        const email = users[selectedUser].email;

        try {
            await update(userCreditsRef, {
                amount: Number(creditAmount),
                email: email,
                createdAt: currentTimestamp,
                updatedAt: currentTimestamp
            });

            await update(userRef, {
                hasAgency: true
            });

            setErrorMessage('');
            setCreditAmount('');
            setSelectedUser(null);
            alert('Credits and agency status updated successfully!');
        } catch (error) {
            console.error('Error adding credits and updating agency status:', error);
            setErrorMessage('Error adding credits and updating agency status. Please try again.');
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2>Add Credits</h2>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <div>
                <label>
                    Select User:
                    <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                        <option value="">-- Select User --</option>
                        {Object.keys(users).map(userId => (
                            <option key={userId} value={userId}>{users[userId].email}</option>
                        ))}
                    </select>
                </label>
            </div>
            <div>
                <label>
                    Credit Amount:
                    <input
                        type="number"
                        value={creditAmount}
                        onChange={(e) => setCreditAmount(e.target.value)}
                    />
                </label>
            </div>
            <button onClick={handleAddCredits}>Add Credits</button>
        </div>
    );
}

export default AddCredits;
