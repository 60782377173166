import * as actionTypes from './actionTypes';

export const saveFileUrls = (url) => ({
    type: actionTypes.SAVE_FILE_URLS,
    payload: url
});


export const submitStart = () => ({
    type: actionTypes.SUBMIT_START
});



export const submitSuccess = () => ({
    type: actionTypes.SUBMIT_SUCCESS
});

export const submitFailure = (error) => ({
    type: actionTypes.SUBMIT_FAILURE,
    payload: error
});

export const setInputs = (inputs) => ({
  type: actionTypes.SET_INPUTS,
  payload: inputs,
});

export const setUploadedFiles = (files) => ({
  type: actionTypes.SET_UPLOADED_FILES,
  payload: files,
});


export const setCurrentStep = (step) => ({
  type: actionTypes.SET_CURRENT_STEP,
  payload: step,
});

// Set User Data in UserDatafields.js
export const setUserData = (data) => {
    return {
        type: actionTypes.SET_USER_DATA,
        payload: data
    };
};

// Set Quick Questions Data in QuickQuestions.js
export const setQuickQuestions = (data) => {
    return {
        type: actionTypes.SET_QUICK_QUESTIONS,
        payload: data
    };
};

export const setThumbnails = (thumbnails) => {
    return {
      type: actionTypes.SET_THUMBNAILS,
      payload: thumbnails,
    };
  };

  export const setHasManyItems = (value) => ({
    type: actionTypes.SET_HAS_MANY_ITEMS,
    payload: value
});

export const setSelectedBonus = (bonus) => {
    return {
        type: actionTypes.SET_SELECTED_BONUS,
        payload: bonus
    };
};

