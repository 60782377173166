import React, { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, get } from 'firebase/database';
import QRCode from 'react-qr-code';

function QRCodeComponent() {
  const [link, setLink] = useState("");
  const auth = getAuth();
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    if (userId) {
      setLink(`bonusclub.link/survey/${userId}`);
    }
  }, [userId]);

  if (!link) {
    return <div>Loading...</div>; // Oder irgendeine andere Platzhalteranzeige
  }

  return (
    <div className="modal-content-2">
      <QRCode className="modal-qr-code-2" value={link} />
      <div className="modal-link-container-2">
        <button onClick={() => navigator.clipboard.writeText(link)}>
          <img className="copy-link-img-2" src="./img/link.svg" alt="copy"/>
        </button>
      </div>
    </div>
  );
}

export default QRCodeComponent;
