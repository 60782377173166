import React, { useState, useEffect, useRef } from 'react';
import { getDatabase, ref as dbRef, push,get, set, onValue } from "firebase/database";
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import DashboardHeader from '../../AdminComponents/DashboardHeader';
import { remove } from "firebase/database";
import QRCode from 'react-qr-code';
import GenerativeOrbAnimation from '../GenerativeOrbAnimation';
import Lottie from 'react-lottie';
import animationData from '/Users/julianpropst/Documents/bonusclub/src/img/locked.json'
import '../AddPageComponent.css';

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const AddDemoComponent = () => {
    const [showModal, setShowModal] = useState(false);
    const [pageName, setPageName] = useState('');
    const [pageDescription, setPageDescription] = useState('');
    const [customRoute, setCustomRoute] = useState('');
    const [pages, setPages] = useState([]);
    const db = getDatabase();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const modalRef = useRef();
    const navigate = useNavigate();
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [pageToDelete, setPageToDelete] = useState(null);
    const [editModalShow, setEditModalShow] = useState(false);
    const [pageToEdit, setPageToEdit] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [editedDescription, setEditedDescription] = useState('');
    const [focusedCardId, setFocusedCardId] = useState(null);
    const [newClubPageName, setNewClubPageName] = useState('');
    const [modalMode, setModalMode] = useState(null); // 'edit' oder 'duplicate'
    const [isRouteValid, setIsRouteValid] = useState(true);
    const [isPremium, setIsPremium] = useState(null);
    const [loading, setLoading] = useState(true); // Neuer Zustand für den Ladestatus


    const openEditModal = (page) => {
        setPageToEdit(page);
        setEditedName(page.name);
        setEditedDescription(page.description);
        setEditModalShow(true);
    };

    const openDuplicateModal = (page) => {
        setPageToEdit(page);
        setEditedName(''); // Leer lassen oder den Namen der Seite vorbelegen
        setCustomRoute(''); // Leer, da eine neue Route definiert werden muss
        setEditModalShow(true);
        setModalMode('duplicate'); // Setze den Modalmodus auf 'duplicate'
      };

      
    const validateCustomRoute = (route) => {
        const isValid = /^[a-zA-Z0-9-]+$/.test(route); // Erlaubt nur Buchstaben, Zahlen und Bindestriche
        if (!isValid) {
            alert("Invalid custom route. Only letters, numbers, and hyphens are allowed.");
        }
        return isValid;
    };

    const checkCustomRouteExists = async (route) => {
        const publicPageRef = dbRef(db, `/demoPublicPages/${route}`);
        const snapshot = await get(publicPageRef);
        return snapshot.exists();
    };
    
    const handleCreateOrUpdatePage = async () => {
        // Beispiel für das Erstellen einer neuen Seite
        if (!await checkCustomRouteExists(customRoute)) {
            createNewPage(); // Oder saveDuplicatedPage() für Duplizierung
        } else {
            alert('Diese benutzerdefinierte Domain ist bereits vergeben. Bitte wählen Sie eine andere.');
        }
    };
    
    const saveChanges = () => {
        if (!currentUser || !pageToEdit) {
            console.error('Fehler: Kein Benutzer angemeldet oder keine Seite ausgewählt.');
            return;
        }
    
        if (!validateCustomRoute(customRoute)) {
            return; // Validierung fehlgeschlagen
        }
    
        const updatedPage = { ...pageToEdit, name: editedName, description: editedDescription, customRoute: customRoute };
        const pageRef = dbRef(db, `/demoPages/${currentUser.uid}/${pageToEdit.id}`);
    
        // Prüfen, ob eine alte Route existiert und diese entfernen
        const oldRouteRef = dbRef(db, `/demoPublicPages/${pageToEdit.customRoute}`);
        get(oldRouteRef).then(snapshot => {
            if (snapshot.exists() && pageToEdit.customRoute !== customRoute) {
                return remove(oldRouteRef); // Entfernen der alten Route
            }
        })
        .then(() => {
            // Aktualisieren der Hauptseite in der `pages`-Datenbank
            return set(pageRef, updatedPage);
        })
        .then(() => {
            console.log("Seite erfolgreich in 'pages' aktualisiert");
    
            // Erstellen oder Aktualisieren der Route in der `publicPages`-Datenbank
            const publicPageRef = dbRef(db, `/demoPublicPages/${customRoute}`);
            return set(publicPageRef, { pageId: pageToEdit.id, userId: currentUser.uid });
        })
        .then(() => {
            console.log("Route und Benutzer-ID erfolgreich in 'publicPages' gespeichert");
            setPages(pages.map(page => page.id === pageToEdit.id ? updatedPage : page));
            setEditModalShow(false);
        })
        .catch(error => {
            console.error("Fehler beim Speichern der Änderungen:", error);
        });
    };
    
    

    const goToLivePage = (pageId) => {
        navigate(`/page/${pageId}`);
    };

    const promptDeletePage = (pageId) => {
        setPageToDelete(pageId);
        setDeleteModalShow(true);
    };

    const confirmDeletePage = () => {
        if (!currentUser || !pageToDelete) {
            console.error('Fehler: Kein Benutzer angemeldet oder keine Seite ausgewählt.');
            return;
        }
        // Referenz zur Seite, die gelöscht werden soll
        const pageRef = dbRef(db, `/demoPages/${currentUser.uid}/${pageToDelete}`);
        // Zuerst die Seite abrufen, um die benutzerdefinierte Route zu erhalten
        get(pageRef).then(snapshot => {
            if (snapshot.exists()) {
                const pageData = snapshot.val();
                // Referenzen zu den entsprechenden Einträgen in `publicPages`
                const customRouteRef = dbRef(db, `/demoPublicPages/${pageData.customRoute}`);
                const pageIdRef = dbRef(db, `/demoPublicPages/${pageToDelete}`);
                // Lösche den Eintrag basierend auf der customRoute
                return remove(customRouteRef).then(() => {
                    // Lösche den Eintrag basierend auf der pageId
                    return remove(pageIdRef);
                }).then(() => {
                    // Nach erfolgreichem Löschen der Einträge in `publicPages`, die Seite selbst löschen
                    return remove(pageRef);
                });
            } else {
                console.error('Seite nicht gefunden.');
                return Promise.reject('Seite nicht gefunden.');
            }
        })
        .then(() => {
            console.log("Seite und zugehörige Einträge in 'publicPages' erfolgreich gelöscht");
            // Aktualisiere den Seitenzustand, um die gelöschte Seite aus der Liste zu entfernen
            setPages(pages.filter(page => page.id !== pageToDelete));
            // Schließe das Modal zum Löschen
            setDeleteModalShow(false);
        })
        .catch(error => {
            console.error("Fehler beim Löschen der Seite und der zugehörigen Einträge in 'publicPages':", error);
        });
        // Setze `pageToDelete` zurück
        setPageToDelete(null);
    };
    
    

    const openPage = (pageId) => {
        navigate(`/settings/demoPage/${pageId}`);
    };
    
    
   
    useEffect(() => {
        if (currentUser) {
            setLoading(true); // Beginn des Ladevorgangs
            const premiumStatusRef = dbRef(db, `/Users/${currentUser.uid}/isPremium`);
            
            get(premiumStatusRef).then((snapshot) => {
                const isPremiumUser = snapshot.val();
                setIsPremium(!!isPremiumUser); // Setzt isPremium basierend auf dem Snapshot-Wert
                
                if (!isPremiumUser) {
                    setLoading(false); // Beendet den Ladevorgang, wenn der Benutzer kein Premium ist
                    return;
                }
    
                // Laden der Seiten, wenn der Benutzer ein Premium-Benutzer ist
                const pagesRef = dbRef(db, `/demoPages/${currentUser.uid}`);
                onValue(pagesRef, (snapshot) => {
                    const pagesData = snapshot.val() || {};
                    const loadedPages = Object.keys(pagesData).map((key) => ({
                        id: key,
                        ...pagesData[key],
                    }));
    
                    setPages(loadedPages);
                    setLoading(false); // Beendet den Ladevorgang, nachdem die Seiten geladen sind
                });
            }).catch(error => {
                console.error("Error fetching premium status:", error);
                setLoading(false); // Beendet den Ladevorgang im Fehlerfall
            });
        } else {
            setLoading(false); // Beendet den Ladevorgang, wenn kein Benutzer angemeldet ist
        }
    }, [currentUser, db]);
    


    useEffect(() => {
        // Event-Listener, der bei Klick außerhalb des Modals dieses schließt
        const clickHandler = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        };

        document.addEventListener('mousedown', clickHandler);

        return () => {
            document.removeEventListener('mousedown', clickHandler);
        };
    }, []);

    const handleCreateClubPage = async () => {
        // Stellen Sie sicher, dass ein Benutzer angemeldet ist
        if (!currentUser) {
            console.error('Es ist kein Benutzer angemeldet.');
            return;
        }
    
        // Stellen Sie sicher, dass die benutzerdefinierte Route eingegeben wurde
        if (!customRoute.trim()) {
            setIsRouteValid(false); // Setze den Validierungsstatus auf false
            setTimeout(() => setIsRouteValid(true), 2000); // Setze den Status nach 2 Sekunden zurück
            return;
        }
    
        // Überprüfung, ob die benutzerdefinierte Route bereits existiert
        const routeExists = await checkCustomRouteExists(customRoute);
        if (routeExists) {
            alert('This domain is already in use. Please try with a different name.');
        } else {
            // Wenn die Route nicht existiert, öffnen Sie das Modal
            setShowModal(true);
        }
    };
    
    

    const createNewPage = async () => {
        if (!currentUser) {
            console.error('Es ist kein Benutzer angemeldet.');
            return;
        }
    
        if (!customRoute.trim()) {
            alert("Please type in a custom route for your page.");
            return;
        }
    
        // Überprüfung, ob die benutzerdefinierte Route bereits existiert
        const routeExists = await checkCustomRouteExists(customRoute);
        if (routeExists) {
            alert('This domain is already in use. Please try with a different name.');
            return;
        }
    
        // Ein neues Page-Objekt erstellen
        const newPage = {
            name: pageName || "Neue Seite",
            description: pageDescription,
            customRoute: customRoute,
            customDomain: customRoute,
            globalSettings: {
                font: "DefaultFont",
                colorScheme: "DefaultColorScheme"
            },
            widgets: []
        };
    
        // Neues Page-Objekt zu Firebase hinzufügen
        const pagesRef = dbRef(db, `/demoPages/${currentUser.uid}`);
        const newPageRef = push(pagesRef);
        set(newPageRef, newPage)
            .then(() => {
                console.log("Neue Seite erfolgreich erstellt");
                // Speichere die customRoute in `publicPages`
                const publicPageRef = dbRef(db, `/demoPublicPages/${customRoute}`);
                return set(publicPageRef, { pageId: newPageRef.key, userId: currentUser.uid });
            })
            .then(() => {
                console.log("Custom Route erfolgreich in 'publicPages' gespeichert");
                setPageName('');
                setPageDescription('');
                setCustomRoute('');
                setShowModal(false); // Schließt das Modal
    
                // Weiterleitung zur Bearbeitungsseite der neuen Seite
                navigate(`/settings/demoPage/${newPageRef.key}`);
            })
            .catch(error => {
                console.error("Fehler beim Erstellen einer neuen Seite:", error);
            });
    };
    
    const deletePage = (pageId) => {
        if (!currentUser) {
            console.error('Es ist kein Benutzer angemeldet.');
            return;
        }
        const pageRef = dbRef(db, `/demoPages/${currentUser.uid}/${pageId}`);
        remove(pageRef)
            .then(() => {
                console.log("Seite erfolgreich gelöscht");
                // Aktualisieren der Seiten-Liste
                setPages(pages.filter(page => page.id !== pageId));
            })
            .catch(error => {
                console.error("Fehler beim Löschen der Seite:", error);
            });
    };

    const openCopyModal = (page) => {
        setPageToEdit(page); // Setze die Seite, die kopiert werden soll, als `pageToEdit`
        setEditedName(page.name); // Optional: Setze den Namen der Seite als Ausgangspunkt
        setCustomRoute(''); // Setze die customRoute zurück, damit der Benutzer eine neue eingeben kann
        setEditModalShow(true); // Verwende dasselbe Modal für Bearbeitung und Duplizierung
      };


      const saveDuplicatedPage = async () => {
        if (!currentUser) {
            console.error('Fehler: Kein Benutzer angemeldet.');
            return;
        }
    
        if (!validateCustomRoute(customRoute)) {
            console.error('Ungültige benutzerdefinierte Route.');
            return; // Überprüfen, ob die Route gültig ist
        }
    
        // Überprüfung, ob die benutzerdefinierte Route bereits existiert
        const routeExists = await checkCustomRouteExists(customRoute);
        if (routeExists) {
            alert('Diese benutzerdefinierte Domain ist bereits vergeben. Bitte wählen Sie eine andere.');
            return;
        }
    
        // Fortfahren mit der Duplizierung, wenn die Route nicht existiert
        const newPage = {
            ...pageToEdit,
            name: editedName,
            description: editedDescription,
            customRoute: customRoute,
            customDomain: customRoute
        };
        delete newPage.id; // Entferne die alte ID
    
        const pagesRef = dbRef(db, `/demoPages/${currentUser.uid}`);
        push(pagesRef, newPage).then((ref) => {
            const newPageId = ref.key;
            console.log("Seite erfolgreich dupliziert mit ID: ", newPageId);
    
            const publicPageRef = dbRef(db, `/demoPublicPages/${customRoute}`);
            set(publicPageRef, { pageId: newPageId, userId: currentUser.uid }).then(() => {
                console.log("Neue Route und Benutzer-ID erfolgreich in 'publicPages' gespeichert");
                setPages(prevPages => [...prevPages, {...newPage, id: newPageId}]);
                setEditModalShow(false);
                navigate(`/settings/demoPage/${newPageId}`);
            });
        }).catch(error => {
            console.error("Fehler beim Duplizieren der Seite:", error);
        });
    };

    if (loading) {
        return  <div className="loading-spinner" id="fake-loading-spinner">
        <h1 style={{marginTop: '-100px'}} >BonusClub</h1>
        <div className="spinner-circle"></div>
      </div>;; // Zeigt den Spinner an, während der Ladestatus `true` ist
    }

    return (
        <div>
          <DashboardHeader />
          {/* <BackgroundAnimation /> */}
          <GenerativeOrbAnimation />
      
          <div className="add-page-container">
            {isPremium ? (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                  <div className="add_page_top_header">
                    <h2>Special Pages for your Guests</h2>
                    {pages.length === 0 && (
                      <div className="no-pages-message">
                        <p>Create a Guest Page👋 <br /> <span>Start by creating a new page!</span></p>
                      </div>
                    )}
                    <p>Build your own Club Page and share it with your Guests. The Page can contain information like Wifi-Passwords, Houserules, Q&A and even welcome videos.</p>
                  </div>
                  <div className="add_page_big_cta">
                    <div>
                      <p>bonusclub.link/</p>
                      <input
                        type="text"
                        value={customRoute}
                        onChange={(e) => setCustomRoute(e.target.value)}
                        placeholder="Name-of-Your-Link"
                        className={!isRouteValid ? "input-invalid" : ""}
                      />
                    </div>
                    <button className="add-page-highlight-button" onClick={handleCreateClubPage}>
                      <p>Create new club page</p>
                      <img className="add-page-button-icon" src="./img/down.svg" alt="" />
                    </button>
                  </div>
                </div>
                {pages.length === 0 && (
                  <div className="no-pages-message-2">
                    <img className="val-pic" src="/img/val.webp" alt="" />
                    <a style={{ textDecoration: 'none', color: 'black' }} href="https://bonusclub.io" target="_blank">
                      <p>Need help? 🤗 <br /> <span>Click here to get support</span></p>
                    </a>
                  </div>
                )}
              </>
            ) : (
              <div className="premium-restriction-div">
              <div className="premium-restriction-message" style={{ textAlign: 'center', position: 'relative', marginLeft: '15px', marginBottom: '-5px' }}>
                   <div className="question-right-pro" style={{backgroundColor: 'transparent', border: '1px solid #f2f2f2'}}>
                 <img className="crown" src="./img/crow.svg" alt="lock"/>
                 <p>PRO</p>
                 </div>
                <h2>This is a <span>Pro</span>Feature</h2>
                <p>Upgrade your account to get access.</p>
                <a className="pro-feature-button" style={{textDecoration: 'none', color: 'white'}}href="https://bonusclub.com">Try Bonusclub Pro</a>
              </div>
              </div>
            )}
      
            {isPremium && (
              <>
                {showModal && (
                  <div className="modal-background">
                    <div className="modal-content" ref={modalRef}>
                      <h2>Create a new Page</h2>
                      <label className="modal-content-label">Name of your Page</label>
                      <input
                        type="text"
                        value={pageName}
                        onChange={(e) => setPageName(e.target.value)}
                        placeholder="Page Name"
                        className="text-input-field"
                      />
                      <label className="modal-content-label">Describe your Page</label>
                      <textarea
                        value={pageDescription}
                        onChange={(e) => setPageDescription(e.target.value)}
                        placeholder="e.g. This is our Club Page for Hotel Room One and Two."
                        className="description-input-field"
                      />
                      <div className="qr-buttons">
                        <button className="qr-modal-open" onClick={createNewPage}>Create Page</button>
                        <button className="qr-modal-close" onClick={() => setShowModal(false)}>Close</button>
                      </div>
                    </div>
                  </div>
                )}
      
                {editModalShow && (
                  <div className="modal-background">
                    <div className="modal-content">
                      <h2>{modalMode === 'duplicate' ? 'Duplicate your Page' : 'Edit your Page'}</h2>
                      <label className="modal-content-label">Page Name</label>
                      <input
                        type="text"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                        placeholder="New Page Name"
                        className="text-input-field"
                      />
                      {modalMode === 'duplicate' && (
                        <>
                          <label className="modal-content-label">Custom Route</label>
                          <input
                            type="text"
                            value={customRoute}
                            onChange={(e) => setCustomRoute(e.target.value)}
                            placeholder="Custom Route for the duplicated page"
                            className="text-input-field"
                          />
                        </>
                      )}
                      <label className="modal-content-label">Description</label>
                      <textarea
                        value={editedDescription}
                        onChange={(e) => setEditedDescription(e.target.value)}
                        placeholder="Page Description"
                        className="description-input-field"
                      />
                      <div className="qr-buttons">
                        <button className="qr-modal-open" onClick={modalMode === 'duplicate' ? saveDuplicatedPage : saveChanges}>Save</button>
                        <button className="qr-modal-close" onClick={() => setEditModalShow(false)}>Close</button>
                      </div>
                    </div>
                  </div>
                )}
      
                {deleteModalShow && (
                  <div className="modal-background">
                    <div className="modal-content" style={{ height: 350 }}>
                      <h2>Really want to delete this masterpiece?</h2>
                      <div className="qr-buttons">
                        <button className="qr-modal-open" onClick={confirmDeletePage}>Yes, delete</button>
                        <button className="qr-modal-close" onClick={() => setDeleteModalShow(false)}>Close</button>
                      </div>
                    </div>
                  </div>
                )}
      
                <div className="pages-display">
                  <div className="pages-grid">
                    {/* Seitenanzeige */}
                    {pages.map((page) => (
                      <div
                        key={page.id}
                        className="page-card"
                        onMouseEnter={() => setFocusedCardId(page.id)}
                        onMouseLeave={() => setFocusedCardId(null)}
                        onClick={() => openPage(page.id)}
                      >
                        <div>
                          <div className="page-card-header-left">
                            {page.viewCount > 0 && <div><img className="page-card-delete-icon" src="./img/view.svg" alt="" /><p>{page.viewCount}</p></div>}
                          </div>
                          <div className="page-card-header-right">
                            <div className="page-card-header-right-open">
                              <a href={page.customDomain ? `https://bonusclub.link/demo/${page.customDomain}` : `https://bonusclub.link/${page.id}`} target="_blank" rel="noopener noreferrer" className="live-page-link">
                                <img className="page-card-upright-icon" src="./img/upright.svg" alt="" />
                              </a>
                            </div>
                            <div className={`page-card-header-right-close ${focusedCardId === page.id ? 'visible' : ''}`}>
                              <button className="page-card-icon-button" onClick={(e) => { e.stopPropagation(); promptDeletePage(page.id); }}><img className="page-card-delete-icon" src="./img/trasher.svg" alt="" /></button>
                              <button className="page-card-icon-button" onClick={(e) => { e.stopPropagation(); openDuplicateModal(page); }}><img className="page-card-copy-icon" src="./img/copy.svg" alt="" /></button>
                              <button className="page-card-icon-button" onClick={(e) => { e.stopPropagation(); openEditModal(page); }}><img className="page-card-delete-icon" src="./img/setting-2.svg" alt="" /></button>
                            </div>
                          </div>
                        </div>
      
                        <div className="page-qr-code">
                          {page.isActive ? (
                            <>
                              <div className="circle-overlay">
                                <img src="./img/bonusclub_icon.png" alt="Image im Kreis" />
                              </div>
                              <QRCode
                                className="modal-qr-code-2"
                                value={page.customDomain ? `https://bonusclub.link/to/${page.customDomain}` : `bonusclub.link/${page.id}`}
                              />
                            </>
                          ) : (
                            <div className="inactive-qr-code">
                              <div className="inactive-qr-background">
                              </div>
                              <p style={{ fontWeight: '500', fontSize: '14px' }}>PUBLISH TO <br />GET QR CODE</p>
                            </div>
                          )}
                        </div>
                        <h3>{page.name}</h3>
                        <p className="page-card-description">{page.description}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      );
                          }      

export default AddDemoComponent;
