import React, { useState, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref as dbRef, get } from 'firebase/database';
import './TeamChatList.css';
import { UnreadStatusContext } from './UnreadStatusContext';
import DashboardHeader from '../../AdminComponents/DashboardHeader';
import TeamChatWindow from './TeamChatWindow';

const TeamChatList = () => {
    const [chats, setChats] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const unreadStatus = useContext(UnreadStatusContext);

    useEffect(() => {
        const fetchChats = async () => {
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) {
                setIsLoading(false);
                return;
            }

            const database = getDatabase();
            const chatsRef = dbRef(database, 'Chats');
            const snapshot = await get(chatsRef);
            if (snapshot.exists()) {
                const chatData = snapshot.val();
                const userChats = await Promise.all(Object.keys(chatData)
                    .filter(key => chatData[key].managerId === currentUser.uid)
                    .map(async key => {
                        const userSnapshot = await get(dbRef(database, `Users/${chatData[key].userId}`));
                        const userData = userSnapshot.val();
                        return { id: key, ...chatData[key], userName: userData.name, userPhoto: userData.userPhoto };
                    }));

                setChats(userChats);
            }
            setIsLoading(false);
        };

        fetchChats();
    }, []);

    const handleChatClick = (chatId) => {
        setSelectedChatId(chatId);
    };

    if (isLoading) {
        return (
            <div className="team-loading-spinner-3">
                {/* Add loading spinner here */}
            </div>
        );
    }

    return (
        <div className="team-chat-list-container">
            <DashboardHeader />
            <div className="team-chat-sidebar">
                <h2>Your Team Chats</h2>
                {chats.length > 0 ? (
                    <ul className="team-chat-list">
                        {chats.map(chat => (
                            <li 
                                key={chat.id} 
                                className={`team-chat-list-item ${chat.id === selectedChatId ? 'selectivo' : ''}`} 
                                onClick={() => handleChatClick(chat.id)}
                            >
                                <a>
                                    <img src={chat.userPhoto} alt={chat.userName} className="team-chat-user-photo" />
                                    <span className="team-chat-user-name">{chat.userName}</span>
                                    {unreadStatus[chat.id] && <span className="team-chat-unread-count">1</span>}
                                </a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="team-no-chats-message">
                        <img style={{ width: '100px' }} src="../../img/big_chat.webp" alt="" />
                        <h2>Seems like your Inbox is empty.</h2>
                        <p>Your inbox is empty. Check back later for new messages.</p>
                    </div>
                )}
            </div>
            <div className="team-chat-window-container">
                {selectedChatId ? (
                    <TeamChatWindow chatId={selectedChatId} />
                ) : (
                    <div className="team-chat-placeholder">
                        <p>Select a chat to start messaging</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TeamChatList;
