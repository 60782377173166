import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, onValue, set, push, remove } from 'firebase/database';
import './DigitalColors.css';

const DigitalColors = ({ userId }) => {
    const [colors, setColors] = useState([]);
    const [newColor, setNewColor] = useState({ name: '', value: '' });
    const [addingColor, setAddingColor] = useState(false);

    const database = getDatabase();

    useEffect(() => {
        if (!userId) return;

        const colorsRef = dbRef(database, `Brandbooks/${userId}/colors`);
        onValue(colorsRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setColors(Object.entries(data).map(([key, value]) => ({ id: key, ...value })));
            } else {
                setColors([]);
            }
        });
    }, [userId, database]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewColor({ ...newColor, [name]: value });
    };

    const addColor = () => {
        if (!newColor.name || !newColor.value) return;

        const colorsRef = dbRef(database, `Brandbooks/${userId}/colors`);
        const newColorRef = push(colorsRef);
        set(newColorRef, newColor);
        setNewColor({ name: '', value: '' });
        setAddingColor(false);
    };

    const deleteColor = (colorId) => {
        const colorRef = dbRef(database, `Brandbooks/${userId}/colors/${colorId}`);
        remove(colorRef);
    };

    return (
        <div className="digital-colors">
            <h2>Colors</h2>
            <div className="colors-container">
                {colors.map((color) => (
                    <div key={color.id} className="color-item">
                        <div className="color-preview" style={{ backgroundColor: color.value }}></div>
                        <div className="color-info">
                            <p>{color.name}</p>
                            <p>{color.value}</p>
                        </div>
                        <div onClick={() => deleteColor(color.id)} className="colors-delete-icon">
                            <img src='./img/trasher.svg' alt="Delete" className="branding-delete-button"/>
                        </div>
                    </div>
                ))}
            </div>
            {addingColor ? (
                <div className="add-color">
                    <h3>Add Color</h3>
                    <input
                        type="text"
                        name="name"
                        value={newColor.name}
                        onChange={handleInputChange}
                        placeholder="Color Name"
                    />
                    <div className="color-picker-wrapper">
                        <input
                            type="color"
                            name="value"
                            value={newColor.value}
                            onChange={handleInputChange}
                            className="color-picker"
                        />
                    </div>
                    <input
                        type="text"
                        name="value"
                        value={newColor.value}
                        onChange={handleInputChange}
                        placeholder="#FFFFFF"
                        className="color-hex-input"
                    />
                    <button onClick={addColor}>Add Color</button>
                </div>
            ) : (
                <div className="add-color-nema" onClick={() => setAddingColor(true)}>
                    <img className="colors-plus-icon" src="./img/plus.svg" alt="Plus Icon" />
                    <span>Click here to Add Color</span>
                </div>
            )}
        </div>
    );
};

export default DigitalColors;
