import { BrowserRouter as Router, Routes, Route, Navigate, useRoutes } from 'react-router-dom';
import './App.css';
import ScrollToTop from './ScrollToTop';
import SurveyOne from './SurveyOne';
import SurveyTwo from './SurveyTwo';
import React, { useState, useEffect } from 'react';
import SignUp from './SignUp';
import store from './Redux/store';
import { Provider } from 'react-redux';
import { auth } from './firebaseConfig';
import ForgotPassword from './ForgotPassword';
import AdminHome from './AdminComponents/AdminHome.js';
import AdminConfigSettings from './BackendSurvey/AdminConfigSettings';
import NewsletterSettings from './BackendSurvey/NewsletterSettings'; // Nehmen Sie an, dass dies der korrekte Pfad ist // Nehmen Sie an, dass dies der korrekte Pfad ist
import AppBackend from './BackendSurvey/AppBackend';
import NewsletterBackend from './BackendSurvey/NewsletterBackend';
import VoucherStatusPage from './BackendSurvey/VoucherStatusPage';
import TeamDashboard from './TeamDashboard/TeamDashboard';
import { database } from "./firebaseConfig";
import { ref, onValue, off } from 'firebase/database';
import EmailTemplates from './BackendSurvey/EmailTemplates';
import { FontProvider } from './Design/FontContext';
import BonusIdeas from './AdminComponents/BonusIdeas';
import AddPageComponent from './Pages/AddPageComponent';
import AddDemoComponent from './Pages/Demo/AddDemoComponent';
import PageSettings from './Pages/PageSettings'; // Ihre Seiteneinstellungs-Komponente
import PagePublic from './Pages/PagePublic'; // Ihre öffentliche Seitenansichts-Komponente
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Concierge from './Pages/Concierge';
import TemplateSelector from './Pages/TemplateSelector';
import UserSettings from './Pages/UserSettings';
import DemoPageSettings from './Pages/Demo/DemoPageSettings';
import DemoPagePublic from './Pages/Demo/DemoPagePublic';
import Layouts from './Pages/Demo/Layouts';
import QRStatusPage from './BackendSurvey/QRStatusPage';
import ServicesPage from './Agency/ServicesPage';
import ChatList from './Pages/Chats/ChatList';
import DigitalBrandbook from './Pages/Brandbook/DigitalBrandbook';
import TeamChatList from './Pages/Chats/TeamChatList';
import { UnreadStatusProvider } from './Pages/Chats/UnreadStatusContext';
import WelcomeInfos from './Pages/WelcomeInfos';
import SuccessPage from './AdminComponents/SuccessPage';
import MarketingDatabase from './TeamDashboard/MarketingDatabase';


function RoutesWithTransitions({ isAuthenticated, setIsAuthenticated }) {
  const [isInitialized, setIsInitialized] = useState(false);



  const canAccessTeamDashboard = () => {
    if (auth.currentUser) {
        const userEmail = auth.currentUser.email;
        const allowedEmails = ['juliprop@hotmail.com', 'valerie.wickl@cwpgmbh.com', 'julian.democharts@gmail.com', 'hardvorevibeandi@gmail.com'];
        return allowedEmails.includes(userEmail);
    }
    return false;
};



  useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(user => {
          if (user) {
              setIsAuthenticated(true);
          } else {
              setIsAuthenticated(false);
          }
          setIsInitialized(true);
      });

      

      return () => unsubscribe();
  }, [setIsAuthenticated]);
  


  useEffect(() => {
    if (isAuthenticated) {  
        const surveyRef = ref(database, `surveyConfigs/${auth.currentUser.uid}`);
        
        const listener = onValue(surveyRef, snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                if (data.surveyPrimaryColor) {
                    document.documentElement.style.setProperty('--blue', data.surveyPrimaryColor);
                }
            }
        });
        
        // Cleanup-Funktion
        return () => {
            off(surveyRef, 'value', listener);
        };
    }
  }, [isAuthenticated]);

  if (!isInitialized) {
      return <div className="loading"></div>;  
  }

  return (
    <FontProvider>
         <UnreadStatusProvider>
        <div className="route-section">
            <Routes>
                {/* Öffentliche Routen */}
                <Route path="/survey" element={<SurveyOne />} />
                <Route path="/surveytwo" element={<SurveyTwo />} />
                <Route path="/to/:customRoute" element={<PagePublic />} />
                <Route path="/success" element={<SuccessPage />} /> {/* Füge die SuccessPage-Route hinzu */}

                

                {/* Signup Route nur für nicht-authentifizierte Benutzer */}
                {!isAuthenticated ? (
                    <Route path="/signup" element={<SignUp setIsAuthenticated={setIsAuthenticated} />} />
                ) : (
                    <Route path="/signup" element={<Navigate to="/" replace />} />
                )}
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/survey/:userId" element={<AppBackend />} />
                <Route path="/news/:userId" element={<NewsletterBackend />} />
                <Route path="/updateStatus" element={<VoucherStatusPage />} />
                <Route path="/qr" element={<QRStatusPage />} />

                {/* Routen für authentifizierte Benutzer */}
                {isAuthenticated && (
                    <>
                        <Route path="/demo/:customRoute" element={<DemoPagePublic />} />
                        <Route path="/social" element={<AdminHome />} />
                        <Route path="/agency" element={<ServicesPage />} />
                        <Route path="/chat" element={<ChatList />} />
                        <Route path="/teamchat" element={<TeamChatList />} />
                        <Route path="/brand" element={<DigitalBrandbook />} />
                        <Route path="/layouts" element={<Layouts />} />
                        <Route path="/settings/page/:pageId/templates" element={<TemplateSelector />} />
                        <Route path="/settings" element={<AdminConfigSettings />} />
                        <Route path="/concierge" element={<Concierge />} />
                        <Route path="/newsletter" element={<NewsletterSettings />} />
                        <Route path="/emails" element={<EmailTemplates />} />
                        <Route path="/ideas" element={<BonusIdeas />} />
                        <Route path="/welcome" element={<WelcomeInfos />} />
                        <Route path="/user-settings" element={<UserSettings />} />
                        <Route path="/" element={<AddPageComponent />} />
                        <Route path="/settings/page/:pageId" element={<PageSettings />} />
                        <Route path="/settings/demoPage/:pageId" element={<DemoPageSettings />} />
                        {/* Zugriff auf TeamDashboard nur für erlaubte E-Mail-Adressen */}
         {canAccessTeamDashboard() ? (
        <Route path="/teamdashboard" element={<TeamDashboard />} />
    ) : (
        <Route path="/teamdashboard" element={<Navigate to="/" replace />} />
    )}
       {canAccessTeamDashboard() ? (
        <Route path="/demos" element={<AddDemoComponent />} />
    ) : (
        <Route path="/demos" element={<Navigate to="/" replace />} />
    )}
     {canAccessTeamDashboard() ? (
     <Route path="/settings/demoPage/:pageId" element={<DemoPageSettings />} />
                        ) : (
        <Route path="/settings/demoPage/:pageId" element={<Navigate to="/" replace />} />
    )}
           {canAccessTeamDashboard() ? (
        <Route path="/teamdatabase" element={<MarketingDatabase />} />
    ) : (
        <Route path="/teamdatabase" element={<Navigate to="/" replace />} />
    )}

    {canAccessTeamDashboard() ? (
        <Route path="/teamdashboard" element={<TeamDashboard />} />
    ) : (
        <Route path="/teamdashboard" element={<Navigate to="/" replace />} />
    )}
                    </>
                )}

                {/* Catch-All Route */}
                {isAuthenticated ? (
                    <Route path="*" element={<Navigate to="/" replace />} />
                ) : (
                    <Route path="*" element={<Navigate to="/signup" replace />} />
                )}
            </Routes>
        </div>
        </UnreadStatusProvider>
    </FontProvider>
);
                } 

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  

  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <DndProvider backend={HTML5Backend}>
          <div className="App">
            <RoutesWithTransitions isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
          </div>
        </DndProvider>
      </Router>
    </Provider>
  );
}

export default App;
