import React, { useState, useEffect } from 'react';
import SignUp from '../SignUp';
import Dashboard from './Dashboard';
import { getAuth } from 'firebase/auth';
import DashboardHeader from './DashboardHeader';
import './AdminHome.css';
import Dashboard2 from './Dashboard2';
import BottomPurchase from '../Pages/BottomPurchase';



function AdminHome() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const auth = getAuth();
  const [viewMode, setViewMode] = useState('table'); // 'table' or 'grid'

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, []);

 

  return (
    <div className="admin-home">
      {isAuthenticated ? (
        <>
           <DashboardHeader /> 
          <Dashboard />
        </>
      ) : (
        <SignUp setIsAuthenticated={setIsAuthenticated} />
      )}
    </div>
  );
}

export default AdminHome;
