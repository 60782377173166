import React, { useState, useEffect } from 'react';
import './PageWizard.css';
import HotelInfoDisplay from './HotelInfoDisplay';
import WizardFinalDetails from './WizardFinalDetails';
import WizardMagic from './WizardMagic';
import LoadingSpinnero from './LoadingSpinnero';

const PageWizard = () => {
    const [showModal, setShowModal] = useState(false);
    const [step, setStep] = useState(1);
    const [pageName, setPageName] = useState('');
    const [hotelInfo, setHotelInfo] = useState({
        hotelName: '',
        hotelAddress: '',
        summary: '',
        highlights: [],
        imageUrl: ''
    });
    const [selectedHighlights, setSelectedHighlights] = useState({});
    const [loading, setLoading] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
    const [imageFile, setImageFile] = useState(null);
    const [fontStyle, setFontStyle] = useState(0);
    const [fontColor, setFontColor] = useState('dark');

    useEffect(() => {
        console.log('Font color updated in PageWizard:', fontColor);
    }, [fontColor]);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setStep(1);
        setPageName('');
        setHotelInfo({
            hotelName: '',
            hotelAddress: '',
            summary: '',
            highlights: [],
            imageUrl: ''
        });
        setSelectedHighlights({});
        setBackgroundColor('');
        setImageFile(null);
        setFontStyle(0);
        setFontColor('dark');
    };

    const handleFetchHotelInfo = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://us-central1-bonusclub-ab5e2.cloudfunctions.net/hotelFetcher', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ domain: pageName })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Received Data:', data);

            const highlights = data.highlights.map(highlight => ({ ...highlight, widgetType: 'WidgetType1' }));
            setHotelInfo({
                hotelName: data.hotelName,
                hotelAddress: data.translatedAddress,
                summary: data.summary,
                highlights: highlights,
                imageUrl: data.imageUrl
            });

            setStep(2);
        } catch (error) {
            console.error('Error fetching hotel info:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleToggleWidgetType = (index, widgetType) => {
        const newHighlights = hotelInfo.highlights.map((highlight, i) => {
            if (i === index) {
                return { ...highlight, widgetType };
            }
            return highlight;
        });
        setHotelInfo({ ...hotelInfo, highlights: newHighlights });
    };

    const toggleHighlightSelection = (index) => {
        setSelectedHighlights((prevSelected) => ({
            ...prevSelected,
            [index]: !prevSelected[index],
        }));
    };

    const handleNext = () => {
        if (step < 4) {
            setStep(step + 1);
        }
    };

    const fontStyles = [
        { primary: 'Classic', secondary: 'Poppins' },
        { primary: 'Bloque', secondary: 'Inter' },
        { primary: 'Gong', secondary: 'Montserrat' },
        { primary: 'Money', secondary: 'Nunito Sans' },
        { primary: 'Urbanist', secondary: 'Poppins' },
        { primary: 'Posh', secondary: 'Nunito Sans' }
    ];

    const fontColors = {
        light: { primary: '#FFFFFF', secondary: '#FAFAFA' },
        dark: { primary: '#000000', secondary: '#121212' }
    };

    const updateHotelName = (newName) => {
        setHotelInfo((prevInfo) => ({ ...prevInfo, hotelName: newName }));
    };

    return (
        <>
            <button className="page-wizard-button-2" onClick={handleOpenModal}>
                <img src="./img/wand-2.svg" alt="" />
                <p className="ai-info-addon">AI</p>
            </button>
            {showModal && (
                <div className="page-wizard-modal-overlay">
                    <div className="page-wizard-modal-content">
                        <div className="page-wizard-modal-header">
                            <img style={{width: '25px', marginLeft: '5px', filter: 'invert(1)'}} src="./img/wand-2.svg" alt="" />
                            <h2>AI Wizard</h2>
                            <button className="page-wizard-close-button" onClick={handleCloseModal}>×</button>
                        </div>
                        <div className="page-wizard-modal-body">
                            {step === 1 && (
                                <>
                                    {loading ? (
                                        <div>
                                            <LoadingSpinnero />
                                        </div>
                                    ) : (
                                        <>
                                            <p className="enter-page-name-label">Booking.com Link</p>
                                            <input
                                                type="text"
                                                value={pageName}
                                                onChange={(e) => setPageName(e.target.value)}
                                                placeholder="Enter Booking.com Link"
                                                className="page-wizard-name-input"
                                            />
                                            <button className="fetch-hotel-info" onClick={handleFetchHotelInfo} disabled={loading}>
                                                Create Guest Page
                                            </button>
                                        </>
                                    )}
                                </>
                            )}
                            {step === 2 && (
                                <HotelInfoDisplay
                                    initialHotelInfo={hotelInfo}
                                    handleToggleWidgetType={handleToggleWidgetType}
                                    toggleHighlightSelection={toggleHighlightSelection}
                                    selectedHighlights={selectedHighlights}
                                    updateHotelName={updateHotelName} // Pass the update function
                                />
                            )}
                            {step === 3 && (
                                <WizardFinalDetails
                                    backgroundColor={backgroundColor}
                                    setBackgroundColor={setBackgroundColor}
                                    setImageFile={setImageFile}
                                    fontStyle={fontStyle}
                                    setFontStyle={setFontStyle}
                                    fontColor={fontColor}
                                    setFontColor={setFontColor}
                                />
                            )}
                            {step === 4 && (
                                <WizardMagic
                                    pageName={pageName}
                                    hotelInfo={hotelInfo}
                                    selectedHighlights={selectedHighlights}
                                    backgroundColor={backgroundColor}
                                    imageFile={imageFile}
                                    primaryFont={fontStyles[fontStyle].primary}
                                    secondaryFont={fontStyles[fontStyle].secondary}
                                    fontColor={fontColors[fontColor]}
                                />
                            )}
                        </div>
                        {step === 1 && (
                            <div className="">
                              
                            </div>
                        )}
                        {step === 2 && (
                            <button className="wizard-next-button" onClick={() => setStep(3)}>
                                Next
                            </button>
                        )}
                        {step === 3 && (
                            <button className="wizard-next-button" onClick={handleNext}>
                                Next
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default PageWizard;
