import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadBytesResumable, getDownloadURL, ref as storageRef } from "firebase/storage";
import { ref, push, set } from "firebase/database";
import { submitStart, submitSuccess, submitFailure } from './Redux/actions';
import { database, storage } from './firebaseConfig';
import { setCurrentStep } from './Redux/actions'; // Import the necessary Redux action
import { saveFileUrls } from './Redux/actions';
import { v4 as uuidv4 } from 'uuid';  // Falls Sie `uuid` noch nicht installiert haben: npm install uuid




export const handleUpload = (uploadedFiles, storage) => async (dispatch) => {
    console.log(storage);  // Hier loggen Sie das storage-Objekt

    if (!uploadedFiles.length) return;

    const downloadURLs = [];

    const uploads = uploadedFiles.map(file => {
        return new Promise(async (resolve, reject) => {
            // Erzeugt einen einzigartigen Namen für jede Datei
            const uniqueFileName = `${uuidv4()}-${file.name}`;
            const storageReference = storage.ref('image-uploads/' + uniqueFileName);
            
            const uploadTask = storageReference.put(file);

            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done for ' + file.name);
                },
                (error) => {
                    console.error("Error uploading file:", error);
                    reject(error);
                },
                async () => {
                    try {
                        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                        downloadURLs.push(downloadURL);
                        dispatch(saveFileUrls(downloadURL));
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                }
            );
        });
    });

    try {
        await Promise.all(uploads);
    } catch (error) {
        console.error('Error while uploading files:', error);
    }
};
// ...

export const handleSubmissionTwo = async (database, inputs, step, dispatch, setCurrentComponent) => {
    try {
      console.log("Submitting data to Firebase...");
  
      const timestamp = Date.now();
  
      const data = {
        timestamp,
        fixedTag: 123456,
        selectedBonus: inputs.selectedBonus,
        quickQuestions: inputs.quickQuestions,
        uploadedFiles: inputs.uploadedFiles,
        userData: inputs.userData
      };
  
      // Send the data to the Firebase realtime database
      const newSurveyRef = database.ref('/surveys').push();
      await newSurveyRef.set(data);
  
      console.log("Data submitted successfully!");
  
      // Update the current step in Redux
      if (step === 4) {
        dispatch(setCurrentStep(1)); // Reset step to 1
        setCurrentComponent('ThankYou'); // Set the next component to 'ThankYou'
      }
  
      // You can add further logic here to navigate to the desired step
  
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  

export const handleSubmission = (database, inputs, setCurrentStep) => {
    // Aktueller Timestamp
    const timestamp = new Date().toISOString();

    // Daten mit Timestamp aktualisieren
    const updatedInputs = {
        ...inputs,
        timestamp: timestamp
    };

    const surveyReference = ref(database, 'surveys'); // 'surveys' ist der Name Ihrer Sammlung in Firebase. Passen Sie diesen Wert an, wenn er anders ist.
    const newSurveyRef = push(surveyReference);

    set(newSurveyRef, updatedInputs)
        .then(() => {
            setCurrentStep(5);
        })
        .catch(error => {
            console.error("Daten konnten nicht gesendet werden: ", error);
        });
};


export const removeFile = (fileToRemove, uploadedFiles, setUploadedFiles) => {
    setUploadedFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
};

export const handleInputChange = (event, setInputs) => {
    const { name, value } = event.target;
    setInputs(prevInputs => ({ ...prevInputs, [name]: value }));
};

export const handleBack = (setCurrentStep) => {
    setCurrentStep((prevStep) => prevStep - 1);
};

export const useDropzoneConfig = () => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length <= 5) {
                setUploadedFiles([...uploadedFiles, ...acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))]);
            } else {
                console.error("Sie können maximal 5 Dateien hochladen.");
            }
        }
    });

    return {
        uploadedFiles,
        setUploadedFiles,
        getRootProps,
        getInputProps,
        handleBack,
        handleSubmission,
        handleInputChange,
        removeFile,
        handleSubmissionTwo
    };
};
