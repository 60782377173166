import React, { useState, useEffect } from 'react';
import { database } from "../firebaseConfig";
import { ref, get } from 'firebase/database';
import BackendHeaderComponent from "./BackendHeaderComponent";
import NewsPage from './NewsPage';
import { useParams } from 'react-router-dom';
import './AppBackend.css'

function NewsletterBackend() {
    const [surveyConfig, setSurveyConfig] = useState({});
    const { userId } = useParams();  // angenommen, dass userId aus der URL kommt

    useEffect(() => {
        const fetchData = async () => {
            const surveyRef = ref(database, `surveyConfigs/${userId}`);
            const snapshot = await get(surveyRef);
            
            if (snapshot.exists()) {
                const data = snapshot.val();
                setSurveyConfig(data);
            }
        };
        
        fetchData();
    }, [userId]);

    return (
        <div>
            <BackendHeaderComponent headerImage={surveyConfig.headerImage} />
            <NewsPage />
        </div>
    )
}

export default NewsletterBackend;
