import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions';
import './CancelSubscriptionButton.css';

const CancelSubscriptionButton = () => {
  const [loading, setLoading] = useState(false);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);

  useEffect(() => {
    const fetchStripeCustomerId = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const db = getDatabase();
        const userRef = ref(db, `Users/${user.uid}/stripeCustomerId`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const customerId = snapshot.val();
          setStripeCustomerId(customerId);
          console.log('Fetched Stripe customer ID:', customerId);
        } else {
          console.error('Stripe customer ID not found in database');
        }
      }
    };

    fetchStripeCustomerId();
  }, []);

  const handleClick = async () => {
    if (!stripeCustomerId) {
      console.error('Stripe customer ID is not available');
      return;
    }

    setLoading(true);

    const functions = getFunctions();
    const createCustomerPortalLink = httpsCallable(functions, 'createCustomerPortalLink');

    try {
      console.log('Calling createCustomerPortalLink with customerId:', stripeCustomerId);
      const result = await createCustomerPortalLink({ customerId: stripeCustomerId });
      console.log('Received portal URL:', result.data.url);

      // Weiterleiten zur erhaltenen URL
      console.log('Redirecting to:', result.data.url);
      window.location.href = result.data.url;
    } catch (error) {
      console.error('Customer portal session error:', error.message);
    }

    setLoading(false);
  };

  return (
    <button onClick={handleClick} className="cancel-subscription-button">
      {loading ? <div className="button-spinner-2"></div> : 'Manage Subscription'}
    </button>
  );
};

export default CancelSubscriptionButton;
