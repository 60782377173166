import './IconFeature.css'; 
import './WidgetStyle.css'; 
import { useFont } from '../../Design/FontContext';
import React, { useState } from 'react';
import DeleteModal from './DeleteModal';

function appendPx(value) {
    if (typeof value === 'string' && isNaN(value)) {
        return value;
    }
    return `${value}px`;
}

function EmergencyWidget({ data, onRemove, isSelected, isEditMode, inverted }) {
    const defaultImg = '/img/star-of-life.svg'; // Pfad zum Standard-Bild anpassen
    const defaultIcon = '/img/right.svg'; // Pfad zum Standard-Bild anpassen
    const deleteLogo = '/img/delete.svg'; // Pfad zum Standard-Löschbild anpassen
    const widgetStyle = {
        backgroundColor: data.backgroundColor,
        marginBottom: appendPx(data.marginBottom),
        marginTop: appendPx(data.marginTop),
        border: getBorderStyle()

    };

    const fonts = useFont(); 

    function isDark(color) {
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;
        const luma = 0.299 * r + 0.587 * g + 0.114 * b;
        return luma < 128;
    }

    function getBorderStyle() {
        if (isEditMode) {
            // Im Editiermodus
            if (isSelected) {
                // Wenn das Widget ausgewählt ist
                const borderColor = isDark(data.backgroundColor) ? '#FFFFFF' : '#000000';
                return `2px dashed ${borderColor}`;
            } else {
                // Wenn das Widget nicht ausgewählt ist
                return data.border ? `1.5px solid ${data.borderColor}` : '2px dashed transparent';
            }
        } else {
            // Im Live-Modus
            return data.border ? `1.5px solid ${data.borderColor}` : 'none';
        }
    }
    
    const widgetHref = isEditMode ? null : `tel:${data.link}`;
    const [showDeleteModal, setShowDeleteModal] = useState(false);


    const handleDelete = (event) => {
        // Logik zum Löschen des Objekts
        onRemove(event, data.id);
        setShowDeleteModal(false);
    };

    return (
        <a  href={widgetHref} target="_blank" className="icon_feature_7" style={{
            backgroundColor: data.backgroundColor,
            marginBottom: appendPx(data.marginBottom),
            textDecoration: 'none',
            marginTop: appendPx(data.marginTop),
            boxShadow: data.shadow ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : 'none',
            border: data.border ? `1.5px solid ${data.borderColor}` : 'none', // Bedingter Rand mit Farbe
            borderRadius: appendPx(data.borderRadius),
            ...widgetStyle // Fügen Sie den widgetStyle hier ein
        }}>
            <div className="icon-feature-right-9">
                <div className="icon_border_7" style={{borderRadius: '20px'}}>
                    <img 
                        src={data.img1 || defaultImg} 
                        className="icon_feature_img_20"
                        alt="Img" 
                        style={{ width: appendPx(data.imgWidth)}} 
                    />
                </div>
                <div>
    {isEditMode && (
        <button className="delete_button" onClick={() => setShowDeleteModal(true)} style={{marginTop: '-25px'}}
>
            <img className="delete_logo" src={deleteLogo} alt="Löschen" />
        </button>
    )}
    {isEditMode && showDeleteModal && (
        <DeleteModal
            isOpen={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onConfirm={(event) => handleDelete(event, data.id)}
        />
    )}
</div>
            </div>
            <div className="icon-button-text-2">
                <div className="icon-buttontext-inhalt">
                <h5 
                id="button_h5_text"
                style={{
                    color: data.textColor1,
                    fontSize: appendPx(data.text1Size),
                    fontWeight: data.text1Weight,
                    fontFamily: fonts.globalSmallFont, 
                    textDecoration: 'none',
                }}>
                    {data.text1}
                </h5>
                <p style={{
                    color: data.textColor2,
                    fontSize: appendPx(data.text2Size),
                    fontWeight: data.text2Weight,
                    fontFamily: fonts.globalSmallFont, 
                    letterSpacing: '0.5px',
                    lineHeight: '140%',
                }}>
                    {data.text2}
                </p>
                </div>
                <div className="icon_border_3">
                    <img 
                        src={data.img2 || defaultIcon} 
                        className={`icon_feature_img_13 ${inverted ? 'inverted' : ''}`}
                        alt="Img" 
                        style={{ 
                         filter: data.inverted ? 'invert(100%)' : 'none'
                        
                        }} 
                    />
                </div>
            </div>
            <div>
          
            </div>
        </a>
    );
}

export default EmergencyWidget;
