import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, push, get, set, onValue } from "firebase/database";
import { getAuth } from 'firebase/auth';
import './AddPageComponent.css'; // Ensure you create the CSS file accordingly
import { useNavigate } from 'react-router-dom';
import DashboardHeader from '../AdminComponents/DashboardHeader';
import GenerativeOrbAnimation from './GenerativeOrbAnimation';
import PagesDisplay from './PagesDisplay';
import CreatePageModal from './CreatePageModal';
import BuyPremium from './BuyPremium';
import LayoutTemplates from './Demo/LayoutTemplates';
import BottomPurchase from './BottomPurchase';
import PageWizard from './PageWizard';

const AddPageComponent = () => {
    const [showModal, setShowModal] = useState(false);
    const [pageName, setPageName] = useState('');
    const [pageDescription, setPageDescription] = useState('');
    const [customRoute, setCustomRoute] = useState('');
    const [pages, setPages] = useState([]);
    const [isRouteValid, setIsRouteValid] = useState(true);
    const [isPremium, setIsPremium] = useState(null);
    const [loading, setLoading] = useState(true); // New state for loading status
    const [basicInfos, setBasicInfos] = useState(null); // New state for basic infos
    const [isTrialValid, setIsTrialValid] = useState(false); // New state for trial validity
    const [selectedPageId, setSelectedPageId] = useState('');
    const [selectedUserId, setSelectedUserId] = useState('');
    const [showLayoutModal, setShowLayoutModal] = useState(false); // New state for layout modal

    const db = getDatabase();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const navigate = useNavigate();

    const validateCustomRoute = (route) => {
        const isValid = /^[a-z0-9-]+$/.test(route); // Allow only lowercase letters, numbers, and hyphens
        setIsRouteValid(isValid);
        return isValid;
    };

    const checkCustomRouteExists = async (route) => {
        const publicPageRef = dbRef(db, `/publicPages/${route}`);
        const snapshot = await get(publicPageRef);
        return snapshot.exists();
    };

    const handleCreateClubPage = async () => {
        if (!currentUser) {
            console.error('No user is logged in.');
            return;
        }

        if (!customRoute.trim() || !validateCustomRoute(customRoute)) {
            setIsRouteValid(false);
            setTimeout(() => setIsRouteValid(true), 2000);
            return;
        }

        const routeExists = await checkCustomRouteExists(customRoute);
        if (routeExists) {
            alert('This domain is already in use. Please try with a different name.');
        } else {
            setShowModal(true);
        }
    };

    const createNewPage = async () => {
        if (!currentUser) {
            console.error('No user is logged in.');
            return;
        }

        if (!customRoute.trim() || !validateCustomRoute(customRoute)) {
            alert("Please type in a valid custom route for your page.");
            return;
        }

        const routeExists = await checkCustomRouteExists(customRoute);
        if (routeExists) {
            alert('This domain is already in use. Please try with a different name.');
            return;
        }

        const newPage = {
            name: pageName || "New Page",
            description: pageDescription,
            customRoute: customRoute,
            customDomain: customRoute,
            globalSettings: {
                font: "DefaultFont",
                colorScheme: "DefaultColorScheme"
            },
            colors: {
                color1: "#CDD1C4",
                color2: "#5C80BC",
                color3: "#EBF5DF",
                color4: "#30323D",
            },
            widgets: []
        };

        const pagesRef = dbRef(db, `/pages`);
        const newPageRef = push(pagesRef);
        set(newPageRef, newPage)
            .then(() => {
                console.log("New page created successfully");
                const publicPageRef = dbRef(db, `/publicPages/${customRoute}`);
                return set(publicPageRef, { pageId: newPageRef.key, userId: currentUser.uid });
            })
            .then(() => {
                const userPageRef = dbRef(db, `/pages/${newPageRef.key}/userId`);
                return set(userPageRef, currentUser.uid);
            })
            .then(() => {
                console.log("Custom route and user ID saved successfully");
                setPageName('');
                setPageDescription('');
                setCustomRoute('');
                setShowModal(false);
                navigate(`/settings/page/${newPageRef.key}`);
            })
            .catch(error => {
                console.error("Error creating new page:", error);
            });
    };

    useEffect(() => {
        if (currentUser) {
            setLoading(true); // Start loading
            const userRef = dbRef(db, `/Users/${currentUser.uid}`);
            get(userRef).then((snapshot) => {
                const userInfo = snapshot.val();
                const basicInfosStatus = userInfo?.basicInfos ?? false;

                setBasicInfos(basicInfosStatus);
                if (!basicInfosStatus) {
                    navigate('/welcome');
                    setLoading(false);
                    return;
                }

                const isPremiumUser = userInfo?.isPremium ?? false;
                setIsPremium(isPremiumUser);

                const trialStartDate = userInfo?.pagesFreeTrial?.startDate;
                if (trialStartDate) {
                    const trialDate = new Date(trialStartDate);
                    const currentDate = new Date();
                    const diffInDays = Math.floor((currentDate - trialDate) / (1000 * 60 * 60 * 24));
                    setIsTrialValid(diffInDays < 30);
                } else {
                    setIsTrialValid(false);
                }

                const userPagesRef = dbRef(db, `/pages`);
                onValue(userPagesRef, (snapshot) => {
                    const pagesData = snapshot.val() || {};
                    const loadedPages = Object.keys(pagesData)
                        .filter((key) => pagesData[key].userId === currentUser.uid)
                        .map((key) => ({
                            id: key,
                            ...pagesData[key],
                        }));

                    setPages(loadedPages);
                    setLoading(false);
                });
            }).catch(error => {
                console.error("Error fetching user info:", error);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [currentUser, db, navigate]);

    if (loading) {
        return (
            <div className="loading-spinner" id="fake-loading-spinner">
                <h1 style={{ marginTop: '-100px' }}>BonusClub</h1>
                <div className="spinner-circle"></div>
            </div>
        ); // Show spinner while loading is true
    }

    if (!isPremium && !isTrialValid) {
        return <BuyPremium />;
    }

    return (
        <div>
          <BottomPurchase />
            <DashboardHeader />
            <GenerativeOrbAnimation />

            <div className="add-page-container">
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
                        <div className="add_page_top_header">
                            <h2>Special Pages for your Guests</h2>
                            {pages.length === 0 && (
                                <div className="no-pages-message">
                                    <p>Create a Guest Page 👋 <br /> <span>Set your Link!</span></p>
                                </div>
                            )}
                            <p>Build your own Club Page and share it with your Guests. The Page can contain information like Wifi-Passwords, Houserules, Q&A and even welcome videos.</p>
                        </div>
                        <div className="add_page_big_cta">
                            <div>
                            <PageWizard />

                                <p>bonusclub.link/</p>
                                <input
                                    type="text"
                                    value={customRoute}
                                    onChange={(e) => {
                                        const sanitizedValue = e.target.value.replace(/[^a-z0-9-]/g, '').toLowerCase();
                                        setCustomRoute(sanitizedValue);
                                    }}
                                    placeholder="name-of-your-link"
                                    className={!isRouteValid ? "input-invalid" : ""}
                                />
                            </div>
                            <button className="add-page-highlight-button" onClick={handleCreateClubPage}>
                                <p>Create new Guest Page</p>
                                <img className="add-page-button-icon" src="./img/down.svg" alt="" />
                            </button>

                        </div>

                    </div>
                    {pages.length === 0 && (
                        <div className="no-pages-message-2">
                            <img className="val-pic" src="/img/val.webp" alt="" />
                            <a style={{ textDecoration: 'none', color: 'black' }} href="https://bonusclub.io" target="_blank">
                                <p>Need help? 🤗 <br /> <span>Click here to get support</span></p>
                            </a>
                        </div>
                    )}
                </>
                <CreatePageModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    pageName={pageName}
                    setPageName={setPageName}
                    pageDescription={pageDescription}
                    setPageDescription={setPageDescription}
                    createNewPage={createNewPage}
                />
                <PagesDisplay pages={pages} setPages={setPages} />
                <div className="layouts-add">
                            <p>Or start with a template</p>
                        </div>
                        <LayoutTemplates
                            showLayoutModal={showLayoutModal}
                            setShowLayoutModal={setShowLayoutModal}
                            selectedPageId={selectedPageId}
                            setSelectedPageId={setSelectedPageId}
                            selectedUserId={selectedUserId}
                            setSelectedUserId={setSelectedUserId}
                        />
            </div>
        </div>
    );
}

export default AddPageComponent;
