import React from 'react';
import Papa from 'papaparse';
import './CSVExportButton.css'

function CSVExportButton({ surveys }) {
    const handleExport = () => {
        const data = surveys.map(survey => {
            const baseData = {
                uploadedFiles: (survey.uploadedFiles || []).join(", "),
                fixedTag: survey.fixedTag,
                selectedBonus: survey.selectedBonus,
                firstName: survey.userData.firstName,
                lastName: survey.userData.lastName,
                email: survey.userData.email,
                telephone: survey.userData.telephone,
                timestamp: new Date(survey.timestamp).toLocaleString(),
                id: survey.id,
                adminMail: survey.adminMail,
            };

            const quickQuestions = Object.entries(survey.quickQuestions || {}).reduce((accum, [index, questionData]) => {
                accum[`quickQuestion${index}`] = questionData.value;
                return accum;
            }, {});

            return {
                ...baseData,
                ...quickQuestions
            };
        });

        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'surveys_export.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <button className="buttonNav" onClick={handleExport}>
            <img src="./img/downloads.svg" alt="csv" /> 
            </button>
    );
}

export default CSVExportButton;
