import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import '../Steps/SelectBonus.css';
import '../App.css';
import { getDatabase, ref, onValue } from "firebase/database";
import { setSelectedBonus } from '../Redux/actions'; 
import { useFont } from '../Design/FontContext';


const SelectBonusBackend = ({ handleNext }) => {
    const dispatch = useDispatch();
    const [availableBonuses, setAvailableBonuses] = useState([]);
    const selectedBonus = useSelector(state => state.selectedBonus);
    const { userId } = useParams();
    const [selectBonusTitle, setSelectBonusTitle] = useState("");
const [selectBonusDescription, setSelectBonusDescription] = useState("");
const selectedFont = useFont();
const [loadedImages, setLoadedImages] = useState([]);

const handleImageLoad = (bonusId) => {
    setLoadedImages(prev => [...prev, bonusId]);
};


    useEffect(() => {
        // Abrufen der Daten aus Firebase Realtime Database
        const db = getDatabase();
        const bonusRef = ref(db, `surveyConfigs/${userId}`);
        
        onValue(bonusRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                if (data.selectBonus) {
                    setAvailableBonuses(data.selectBonus);
                }
                if (data.selectBonusTitle) {
                    setSelectBonusTitle(data.selectBonusTitle);
                }
                if (data.selectBonusDescription) {
                    setSelectBonusDescription(data.selectBonusDescription);
                }
            }
        }, {
            onlyOnce: true
        });
        
    }, [userId]);
    
    useEffect(() => {
        // Wählen Sie die erste Box standardmäßig aus, wenn der selectedBonus noch nicht gesetzt ist
        if (!selectedBonus && availableBonuses.length > 0) {
            dispatch(setSelectedBonus(availableBonuses[0].bonusId));
        }
    }, [dispatch, selectedBonus, availableBonuses]);
    

    const handleBoxClick = (boxId) => {
        dispatch(setSelectedBonus(boxId));
    };

    return (
        <div className="select-bonus-container">
            <h2 style={{ fontFamily: selectedFont }} className="surveyHeader">{selectBonusTitle}</h2>
            <p className="surveyParagraph">{selectBonusDescription}</p>
            <div className="bonus-list">
                {availableBonuses.map((bonus) => (
                     <div
                     key={bonus.bonusId}
                     className={`bonus-item ${selectedBonus === bonus.bonusId ? "selected" : ""}`}
                     onClick={() => handleBoxClick(bonus.bonusId)}
                 >
                     {!loadedImages.includes(bonus.bonusId) && <div className="spinner"></div>}
                     <img 
                         className="bonus-image" 
                         src={bonus.img} 
                         alt={bonus.label}
                         onLoad={() => handleImageLoad(bonus.bonusId)} 
                         style={{ visibility: loadedImages.includes(bonus.bonusId) ? 'visible' : 'hidden' }}
                     />
                        <div className="bonus-title">{bonus.label}</div>
                        <div className="bonus-description">{bonus.description}</div>
                    </div>
                ))}
            </div>
            <div className="bottom-buttons">
                <button onClick={handleNext} className="buttonClassy">Next</button>
            </div>
        </div>
    );
};

export default SelectBonusBackend;
