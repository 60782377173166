import React, { useState, useEffect } from 'react';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref as dbRef, update, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import './NewsletterSettings.css'

const NewsletterEditor = () => {
    const [newsletter, setNewsletter] = useState({
        newsletterSendgrid: "", 
        newsletterImage: "",
        newsletterTitle: "",
        newsletterText: "",
        newsletterOptBanner: "", // Neues Feld
        newsletterOptTitle: "" // Neues Feld
    });
    const [isUploading, setIsUploading] = useState(false);
    const [features, setFeatures] = useState([]);
    const currentUser = getAuth().currentUser;
    const [isSaved, setIsSaved] = useState(false);

    const loadNewsletterData = async () => {
        if (!currentUser) {
            console.error("Benutzer ist nicht angemeldet.");
            return;
        }
    
        const db = getDatabase();
        const newsletterRef = dbRef(db, `news/${currentUser.uid}`);
    
        try {
            const snapshot = await get(newsletterRef);
            if (snapshot.exists()) {
                const data = snapshot.val();
                setNewsletter({ 
                    newsletterSendgrid: data.newsletterSendgrid, 
                    newsletterImage: data.newsletterImage, 
                    newsletterTitle: data.newsletterTitle, 
                    newsletterText: data.newsletterText,
                    newsletterOptBanner: data.newsletterOptBanner || "", // Neues Feld
                    newsletterOptTitle: data.newsletterOptTitle || "" // Neues Feld
                });
                setFeatures(data.features ? data.features.reverse() : []);
            } else {
                console.log("Keine Newsletter-Daten gefunden.");
            }
        } catch (error) {
            console.error("Fehler beim Laden des Newsletters:", error);
        }
    };

    useEffect(() => {
        loadNewsletterData();
    }, []);

    const uploadNewsletterImageToFirebase = async (file) => {
        if (!file) {
            console.error("File is missing for image upload.");
            return;
        }

        const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
        if (file.size > maxFileSize) {
            console.error("Error: File size exceeds the limit of 1MB.");
            alert("File size exceeds the limit of 1MB.");
            return;
        }

        setIsUploading(true);
        const storage = getStorage();
        const storageReference = storageRef(storage, 'newsletterImages/' + file.name);
        const uploadTask = uploadBytesResumable(storageReference, file);

        try {
            const snapshot = await uploadTask;
            const downloadURL = await getDownloadURL(snapshot.ref);
            console.log('File available at', downloadURL);
            setNewsletter(prevNewsletter => ({ ...prevNewsletter, newsletterImage: downloadURL }));
        } catch (error) {
            console.error("Error uploading image:", error);
        } finally {
            setIsUploading(false);
        }
    };

    const uploadFeatureImageToFirebase = async (file, index) => {
        if (!file) {
            console.error("File is missing for feature image upload.");
            return;
        }

        const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
        if (file.size > maxFileSize) {
            console.error("Error: File size exceeds the limit of 1MB for feature.");
            alert("File size exceeds the limit of 1MB for feature.");
            return;
        }

        setIsUploading(true);
        const storage = getStorage();
        const storageReference = storageRef(storage, 'featureImages/' + file.name);
        const uploadTask = uploadBytesResumable(storageReference, file);

        try {
            const snapshot = await uploadTask;
            const downloadURL = await getDownloadURL(snapshot.ref);
            
            // Aktualisieren Sie den features State sicher
            setFeatures(currentFeatures => 
                currentFeatures.map((feature, featureIndex) =>
                    featureIndex === index ? { ...feature, img: downloadURL } : feature
                )
            );
        } catch (error) {
            console.error("Error uploading feature image:", error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleAddFeature = () => {
        setFeatures([{ img: '', title: '', description: '' }, ...features]);
    };
    
    const handleDeleteFeature = (index) => {
        setFeatures(features.filter((_, i) => i !== index));
    };

    const updateFeatureTitle = (index, title) => {
        const updatedFeatures = [...features];
        updatedFeatures[index].title = title;
        setFeatures(updatedFeatures);
    };
    
    const updateFeatureDescription = (index, description) => {
        const updatedFeatures = [...features];
        updatedFeatures[index].description = description;
        setFeatures(updatedFeatures);
    };


    const saveNewsletterToFirebase = async () => {
        setIsSaved(false); // Zurücksetzen beim Start des Speicherns
        
        if (!currentUser) {
            console.error("Benutzer ist nicht angemeldet.");
            return;
        }
    
        const db = getDatabase();
        const newsletterRef = dbRef(db, `news/${currentUser.uid}`);
        try {
            await update(newsletterRef, { ...newsletter, features });
            console.log("Newsletter erfolgreich gespeichert");
            setIsSaved(true); // Setzen auf true, wenn erfolgreich
            
            // Timer setzen, um die Nachricht nach 3 Sekunden auszublenden
            setTimeout(() => {
                setIsSaved(false);
            }, 3000);
        } catch (error) {
            console.error("Fehler beim Speichern des Newsletters:", error);
        }
    };
    
    

    const triggerFileUpload = () => {
        document.querySelector('.editor-upload').click();

        
    };

    return (
        <div className="editor-container">
            <div className="editor-header">
                <h1>Newsletter Wizard</h1>
                <button className="editor-save-btn" onClick={saveNewsletterToFirebase}>Save Settings</button>
                {isSaved && <p className="save-confirmation">Settings Saved</p>}
            </div>
    
            <div className="editor-fields">
                <div className="image-upload-section">
                    <div className="image_box">
                        {newsletter.newsletterImage && <img src={newsletter.newsletterImage} className="editor-main-image" alt="Vorschaubild" />}
                        {isUploading && <p className="editor-upload-status">Lädt hoch...</p>}
                    </div>
                    <button className="custom-upload-btn" onClick={triggerFileUpload}>Upload Header Image</button>
                    <input type="file" id="image-upload" className="editor-upload" onChange={(e) => uploadNewsletterImageToFirebase(e.target.files[0])} />
                </div>
    
                <label className="editor-label">Page Title:</label>
                <input type="text" className="editor-input" value={newsletter.newsletterTitle} onChange={(e) => setNewsletter({ ...newsletter, newsletterTitle: e.target.value })} placeholder="Newsletter-Titel" />
                
                <label className="editor-label">Page Description:</label>
                <textarea className="editor-textarea" value={newsletter.newsletterText} onChange={(e) => setNewsletter({ ...newsletter, newsletterText: e.target.value })} placeholder="Newsletter-Text" />
                
                {/* Weitere Felder mit Labels */}
             
            </div>
            
            <div className="editor-features">
            <h2>Features</h2>
            <button className="editor-add-feature-btn" onClick={handleAddFeature}>Add Feature</button>
            {features.map((feature, index) => (
                <div key={index} className="editor-feature">
                                            <button className="editor-delete-feature-btn" onClick={() => handleDeleteFeature(index)}>Delete Feature</button>
                    {feature.img && <img src={feature.img} alt="Feature" className="editor-feature-image" />}
                                        <input
                    className="feature-upload-button"
    type="file"
    onChange={(e) => {
        const file = e.target.files[0];
        if (file) {
            // Verwenden Sie uploadFeatureImageToFirebase mit dem richtigen Index
            uploadFeatureImageToFirebase(file, index);
        }
    }}
/>
                        <label className="editor-label">Feature Title:</label>
                        <input type="text" className="editor-input" value={feature.title} onChange={(e) => updateFeatureTitle(index, e.target.value)} placeholder="Feature Title" />
                        <label className="editor-label">Feature Description:</label>
                        <textarea className="editor-textarea" value={feature.description} onChange={(e) => updateFeatureDescription(index, e.target.value)} placeholder="Feature Description" />
                    </div>
                ))}
            </div>
            <label className="editor-label">Opt-In Header:</label>
                <input type="text" className="editor-input" value={newsletter.newsletterOptBanner} onChange={(e) => setNewsletter({ ...newsletter, newsletterOptBanner: e.target.value })} placeholder="Newsletter Opt-In Banner URL" />
    
                <label className="editor-label">Opt-In Text:</label>
                <input type="text" className="editor-input" value={newsletter.newsletterOptTitle} onChange={(e) => setNewsletter({ ...newsletter, newsletterOptTitle: e.target.value })} placeholder="Newsletter Opt-In Titel" />
    
                <label className="editor-label">SendGrid Forms URL:</label>
                <input type="text" className="editor-input" value={newsletter.newsletterSendgrid} onChange={(e) => setNewsletter({ ...newsletter, newsletterSendgrid: e.target.value })} placeholder="Newsletter SendGrid ID" />
        </div>
    );
    
                    }    

export default NewsletterEditor;