import React, { useState, useEffect } from 'react';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref as dbRef, update, remove, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import './UserSettings.css'
import { Link } from 'react-router-dom';
import CancelSubscriptionButton from '../Checkout/CancelSubscriptionButton';

function UserSettings() {
    const [userPhoto, setUserPhoto] = useState('');
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const [isUploading, setIsUploading] = useState(false);
    const [saveConfirmation, setSaveConfirmation] = useState(false);
    const [billingDocuments, setBillingDocuments] = useState([]);
    const [activeSection, setActiveSection] = useState('Personal');


    const handleUploadChange = (event, field) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsUploading(true); // Setzen Sie den Ladezustand
        const storage = getStorage();
        const fileRef = storageRef(storage, `users/${currentUser.uid}/${field}/${file.name}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Optional: Fortschrittsanzeige implementieren
            },
            (error) => {
                console.error("Error uploading file:", error);
                setIsUploading(false); // Zurücksetzen des Ladezustands im Fehlerfall
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    if (field === 'userPhoto') {
                        setUserPhoto(downloadURL);
                        // Speichern des Download-URLs in der Firebase Database
                        const db = getDatabase();
                        update(dbRef(db, `/Users/${currentUser.uid}`), { [field]: downloadURL }).then(() => {
                            setIsUploading(false); // Zurücksetzen des Ladezustands nach erfolgreichem Hochladen
                        });
                    }
                });
            }
        );
    };

    const handleDeleteAccount = () => {
        // Bestätigungsmodal anzeigen (Pseudocode)
        if (window.confirm("Are you sure you want to delete your account? This cannot be undone.")) {
            // Löschen des Benutzerkontos aus der Datenbank
            const db = getDatabase();
            remove(dbRef(db, `/Users/${currentUser.uid}`))
                .then(() => {
                    console.log('Account successfully deleted');
                    // Weiterleitung oder zusätzliche Schritte nach dem Löschen
                })
                .catch((error) => {
                    console.error("Error deleting account:", error);
                });
        }
    };

    useEffect(() => {
        if (currentUser) {
            const db = getDatabase();
            const userRef = dbRef(db, `Users/${currentUser.uid}`);
    
            // Abfrage des Benutzerprofils (Foto, Name, Firmenname)
            get(userRef).then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setUserPhoto(data.userPhoto || '');
                    setName(data.name || '');
                    setCompanyName(data.companyName || '');
                    setBillingDocuments(data.billingDocuments || []);
                }
            }).catch((error) => {
                console.error("Error fetching user data:", error);
            });
        }
    }, [currentUser]);
    

    const handleSubmit = () => {
        if (!currentUser) {
            console.error("No user logged in");
            return;
        }
        
        // Asynchrones Speichern der Benutzerdaten
        const db = getDatabase();
        const userRef = dbRef(db, `/Users/${currentUser.uid}`);
        update(userRef, { name, companyName, userPhoto })
        .then(() => {
            console.log('User settings saved successfully');
            setSaveConfirmation(true);
            setTimeout(() => setSaveConfirmation(false), 3000); // Bestätigungsnachricht nach 3 Sekunden ausblenden
        })
        .catch((error) => {
            console.error("Error saving user settings:", error);
        });
    };
    return (
        <div className="admin-config">
 
            <div className="settings-header-bar">
                <h3>Custom User Settings</h3>
                <div className="prev-link-header-bar">
                    <div className="settings-switch-bar">
                      
                      
                    </div>
                    <div>
                        <button className={saveConfirmation ? 'settings-button-saved' : 'settings-button'} onClick={handleSubmit}>
                            {saveConfirmation ? 'Saved' : 'Save Settings'}
                        </button>
                    </div>
                </div>
            </div>
           
            <div className="scroll-menu">
   
    <div className="back-menu">
        <Link to="/" alt="home">
            <div>
                <img src="./img/right.svg" alt="back" />
            </div>
            <p>Back to home</p>
        </Link>
    </div>

    <div className="scroll-menu-header">
        <h4>User Settings</h4>
        <hr />
    </div>
    <button 
        onClick={() => setActiveSection('Personal')}
        className={activeSection === 'Personal' ? 'menu-active' : 'menu'}
    >
        Personal
    </button>
    <button 
        onClick={() => setActiveSection('Billing')}
        className={activeSection === 'Billing' ? 'menu-active' : 'menu'}
    >
        Billing
    </button>
    <button 
        onClick={() => setActiveSection('Danger Zone')}
        className={activeSection === 'Danger Zone' ? 'menu-active' : 'menu'}
    >
        Danger Zone
    </button>
    <div className="scroll-menu-sub">
        <a href="https://bonusclub.io">
            <img className="right-sub-icon" src="./img/layout.svg" alt="right" />
            <p>Upgrade to Pro</p>
            <img className="right-sub-arrow" src="./img/right.svg" alt="right" />
        </a>
    </div>

    <div className="scroll-menu-bottom">
        <a href="https://bonusclub.io">Contact the Team</a>
    </div>
</div>

            {activeSection === 'Personal' && (
                <div className="componenten-wrapper" style={{minHeight: '100vh'}}>
                    <div className="menu-componenter">
                        <h2 style={{marginLeft: '0px', marginTop: '50px', marginBottom: '-10px'}}>User Profile Settings</h2>
                        <p style={{marginLeft: '0px', marginBottom: '30px'}}>Customize your profile and manage your personal information.</p>
                        <div className="upload_boxen">
                            <div className="upload_1">
                                <label style={{marginBottom: '10px'}}>Profile Picture</label>
                                <label className="custom-upload-box" style={{width: '190px', height: '250px', borderRadius: '100px'}} htmlFor="image-upload">
                                <input 
    id="image-upload" // Stellen Sie sicher, dass diese ID mit dem htmlFor-Attribut des Labels übereinstimmt
    type="file" 
    accept="image/*" 
    onChange={(e) => handleUploadChange(e, 'userPhoto')} 
    style={{ display: 'none'}} 
/>

                                    {isUploading ? (
                                           <div className="spinner-container">
                                           <div className="spinner-circle"></div>
                                                  <p>Uploading...</p>
                                              </div>
                                    ) : (
                                        <div className="upload-image-preview-2">
                                            {userPhoto ? <img src={userPhoto} alt="Profile" /> : <span>Click here to upload your photo</span>}
                                        </div>
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="input_group">
                            <label style={{textTransform: 'uppercase', fontSize: '12px', color: 'gray', fontFamily: 'jost'}} htmlFor="userName">Name</label>
                            <input 
                                type="text" 
                                id="userName" 
                                value={name} 
                                onChange={(e) => setName(e.target.value)} 
                                placeholder="Enter your name" 
                                style={{width: '400px'}}
                            />
                        </div>
                        <div className="input_group">
                        <label style={{textTransform: 'uppercase', fontSize: '12px', color: 'gray', fontFamily: 'jost'}} htmlFor="userName">Company Name</label>
                            <input 
                                type="text" 
                                id="companyName" 
                                value={companyName} 
                                onChange={(e) => setCompanyName(e.target.value)} 
                                placeholder="Enter your company name" 
                                style={{width: '400px'}}
                            />
                        </div>
                    </div>
                </div>
            )}
            {activeSection === 'Billing' && (
                <div className="componenten-wrapper" style={{minHeight: '100vh'}}>
                    <div className="menu-componenter">
                    <h2 style={{marginLeft: '0px', marginTop: '50px', marginBottom: '-10px'}}>Billing Documents</h2>
                        <p style={{marginLeft: '0px', marginBottom: '30px'}}>Customize your profile and manage your personal information.</p>
                       <CancelSubscriptionButton />
                        {billingDocuments.length > 0 ? (
                            billingDocuments.map((doc, index) => (
                                <div key={index}>
                                    {/* Darstellung der Dokumente */}
                                </div>
                            ))
                        ) : (
                            <p>No documents available.</p>
                        )}
                    </div>
                </div>
            )}
            {activeSection === 'Danger Zone' && (
                <div className="componenten-wrapper" style={{minHeight: '100vh'}}>
                    <div className="menu-componenter">
                    <h2 style={{marginLeft: '0px', marginTop: '50px', marginBottom: '-10px'}}>Danger Zone</h2>
                        <p style={{marginLeft: '0px', marginBottom: '30px'}}>This action deletes your account permanently from our databases.</p>
                        <button onClick={handleDeleteAccount}>Delete My Account</button>
                    </div>
                </div>
            )}
        </div>
    );
            }    

export default UserSettings;
