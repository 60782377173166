import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, onValue, push, set, remove } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import './DigitalFonts.css';

const DigitalFonts = ({ userId }) => {
    const [fonts, setFonts] = useState([]);
    const [newFont, setNewFont] = useState({ file: null, name: '' });
    const [preview, setPreview] = useState(null);
    const [fontUrl, setFontUrl] = useState(null);

    const database = getDatabase();
    const storage = getStorage();

    useEffect(() => {
        if (!userId) return;

        const fontsRef = dbRef(database, `Brandbooks/${userId}/fonts`);
        onValue(fontsRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setFonts(Object.entries(data).map(([key, value]) => ({ id: key, ...value })));
            } else {
                setFonts([]);
            }
        });
    }, [userId, database]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setNewFont({ ...newFont, file });
        if (file) {
            const url = URL.createObjectURL(file);
            setFontUrl(url);
        } else {
            setFontUrl(null);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewFont({ ...newFont, [name]: value });
    };

    const addFont = () => {
        if (!newFont.file) return;

        const fileRef = storageRef(storage, `fonts/${userId}/${newFont.file.name}`);
        uploadBytes(fileRef, newFont.file).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                const fontsRef = dbRef(database, `Brandbooks/${userId}/fonts`);
                const newFontRef = push(fontsRef);
                set(newFontRef, { file: downloadURL, name: newFont.name, fontUrl: downloadURL });
                setNewFont({ file: null, name: '' });
                setFontUrl(null);
            });
        });
    };

    const deleteFont = (fontId) => {
        const fontRef = dbRef(database, `Brandbooks/${userId}/fonts/${fontId}`);
        remove(fontRef);
    };

    const downloadFont = (url, filename) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
    };

    return (
        <div className="digital-fonts">
            <h2>Fonts</h2>
            <div className="fonts-container">
                {fonts.map((font) => (
                    <div key={font.id} className="font-item">
                        <style>
                            {`
                                @font-face {
                                    font-family: '${font.name}';
                                    src: url(${font.fontUrl});
                                }
                            `}
                        </style>
                        <p style={{ fontFamily: font.name }}>{font.name}</p>
                        <div onClick={() => deleteFont(font.id)} className="fonts-delete-icon">
                            <img src='./img/trasher.svg' style={{right: '-5px'}} alt="Delete" className="branding-delete-button"/>
                        </div>
                        <div className="font-actions">
                            <button onClick={() => downloadFont(font.file, font.name)}>
                                Download
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="add-font">
                <label htmlFor="font-upload" className="file-input-label">
                    {fontUrl ? (
                        <span className="font-preview" style={{ fontFamily: 'uploaded-font' }}>{newFont.name}</span>
                    ) : (
                        <div style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img className="fonts-plus-icon" style={{right: '0px'}} src="./img/plus.svg" alt="Plus Icon" />
                            <span>Click here to Add Font</span>
                        </div>
                    )}
                    <input
                        type="file"
                        accept=".ttf,.otf,.woff,.woff2"
                        onChange={handleFileChange}
                        id="font-upload"
                    />
                </label>
                {fontUrl && (
                    <>
                        <input
                            type="text"
                            name="name"
                            value={newFont.name}
                            onChange={handleInputChange}
                            placeholder="Font Name"
                        />
                        <button onClick={addFont}>Add Font</button>
                        <style>
                            {`
                                @font-face {
                                    font-family: 'uploaded-font';
                                    src: url(${fontUrl});
                                }
                            `}
                        </style>
                    </>
                )}
            </div>
        </div>
    );
};

export default DigitalFonts;
