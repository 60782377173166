import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, onValue } from 'firebase/database';
import { useParams } from 'react-router-dom';

function PageLoading() {
  const { customRoute } = useParams(); // Angenommen, customRoute wird zur Identifizierung verwendet
  const [logoUrl, setLogoUrl] = useState("/img/default-logo.webp"); // Standardlogo
  const db = getDatabase();

  useEffect(() => {
    const pageRef = ref(db, `/publicPages/${customRoute}`);
    onValue(pageRef, (snapshot) => {
      if (snapshot.exists()) {
        const { userId, pageId } = snapshot.val();
        const logoRef = ref(db, `/pages/${userId}/${pageId}/livePageImg`);
        get(logoRef).then((logoSnapshot) => {
          if (logoSnapshot.exists()) {
            setLogoUrl(logoSnapshot.val());
          }
        });
      }
    });
  }, [customRoute, db]);

  return (
    <div className="loading">
      <img src={logoUrl} alt="Loading Logo" style={{ marginBottom: '240px', height: '70px', maxWidth: '300px', objectFit: 'contain' }} />
      <div className="spinner3" id="spinnerle"></div>
    </div>
  );
}

export default PageLoading;
