import React, { useState, useEffect } from "react";
import SelectBonusBackend from "./SelectBonusBackend";
import UserDatafieldsBackend from "./UserDatafieldsBackend";
import FileUploaderBackend from "./FileUploaderBackend";
import QuickQuestionsBackend from "./QuickQuestionsBackend";
import ThankYouBackend from "./ThankYouBackend";
import '../SurveyOne.css';
import '../App.css';
import { database } from "../firebaseConfig";
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentStep } from '../Redux/actions';
import { ref, get, push, set, onValue, off } from 'firebase/database';
import BackendHeaderComponent from "./BackendHeaderComponent";
import { useParams } from 'react-router-dom';
import './SurveyBackend.css';
import SelectedType from "./SelectedType";



const SurveyBackend = () => {
    const [currentComponent, setCurrentComponent] = useState(null);
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [surveyConfig, setSurveyConfig] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isUserDataValid, setIsUserDataValid] = useState(false);
    const { userId } = useParams();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [fadeIn, setFadeIn] = useState(true);
    const [emailTemplateLinks, setEmailTemplateLinks] = useState([]);
    const { selectBonus } = surveyConfig;
    const [surveyId, setSurveyId] = useState('');


    useEffect(() => {
        const surveyRef = ref(database, `surveyConfigs/${userId}`);
        
        // Dieser Listener wird jedes Mal aufgerufen, wenn sich die Daten ändern
        const listener = onValue(surveyRef, snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setSurveyConfig(data);
    
                if (data.surveyPrimaryColor) {
                    document.documentElement.style.setProperty('--blue', data.surveyPrimaryColor);
                }
                setLoading(false);
                setDataLoaded(true);
            }
        });
    
        // Cleanup-Funktion
        return () => {
            off(surveyRef, 'value', listener);
        };
    }, [userId]);  // Wenn sich userId ändert, wird der Effekt erneut ausgeführt
    
  
    useEffect(() => {
        const bonusRef = ref(database, `surveyConfigs/${userId}/selectBonus`);
        
        const listener = onValue(bonusRef, snapshot => {
            if (snapshot.exists()) {
                const bonusArray = snapshot.val();
                const templateLinks = bonusArray.map(bonus => bonus.emailTemplateLink);
                setEmailTemplateLinks(templateLinks);
            }
        });
    
        return () => {
            off(bonusRef, 'value', listener);
        };
    }, [userId]);
    
    const {
        bonusPosition,
        quickQuestionsPosition,
        uploadPosition,
        userDataPosition,
        thankYouPosition,
        isBonusActive,
        isQuickQuestionsActive,
        isUploaderActive,
        isUserDataActive,
        adminMail
        // ... andere Eigenschaften, die Sie benötigen
    } = surveyConfig;

    const componentsList = [
        { name: 'SelectedType', position: 0, isActive: true }, // neuer Eintrag für SelectedType
        { name: 'SelectBonus', position: bonusPosition, isActive: isBonusActive },
        { name: 'QuickQuestions', position: quickQuestionsPosition, isActive: isQuickQuestionsActive },
        { name: 'FileUploader', position: uploadPosition, isActive: isUploaderActive },
        { name: 'UserDatafields', position: userDataPosition, isActive: isUserDataActive },
    ];

    const activeComponents = componentsList
    .filter(comp => comp.isActive)
    .sort((a, b) => a.position - b.position)
    .map(comp => comp.name)
    .concat('ThankYou');

    useEffect(() => {
        if (currentIndex >= 0 && currentIndex < activeComponents.length) {
            setCurrentComponent(activeComponents[currentIndex]);
        }
    }, [currentIndex, activeComponents]);
    

    const handleBack = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevIndex => prevIndex - 1);
        }
    };
   
    const handleNext = () => {
        if (currentIndex < activeComponents.length - 1) {
            setCurrentIndex(prevIndex => prevIndex + 1);
        }
    };
    const handleSubmissionTwo = async () => {
        try {
    
            const timestamp = Date.now();
    
            const data = {
                timestamp,
                templateKeyAdmin:'d-edc3d6c40b754ea6b9fcc398af3ad56c', 
                fixedTag: 123456,
                adminMail,
                surveyLink: 'https://www.bonusclub.link/survey1',
                selectedBonus: state.selectedBonus || '',
                quickQuestions: state.quickQuestions || {},
                uploadedFiles: state.uploadedFiles || [],
                userData: state.userData || {},
                emailTemplateLinks,
                status: 'valid', 
                bonusName: selectBonus ? selectBonus.map(bonus => bonus.label) : [],
                bonusDesc: selectBonus ? selectBonus.map(bonus => bonus.description) : [],
                bonusImg: selectBonus ? selectBonus.map(bonus => bonus.img) : [],
                isFollowedUp: false, 
                userId: userId,
                uploadedThumbnails: state.thumbnails || [], 
            };
    
            const surveyRef = ref(database, `/surveys/${userId}`);
            const newSurveyRef = push(surveyRef);
            
            await set(newSurveyRef, data);
            setSurveyId(newSurveyRef.key);

            // Reset states or any other clean up tasks if needed
            setCurrentIndex(activeComponents.length - 1);


        } catch (error) {
        }   
    };
    useEffect(() => {
    }, [currentIndex]);
    
    
 return (
        <div className="survey_backend"
        >
                 {currentComponent === 'SelectedType' && <SelectedType  handleNext={handleNext} />}
            
                {currentComponent === 'SelectBonus' && <SelectBonusBackend handleNext={handleNext} availableBonuses={surveyConfig.selectBonusBackend || []} />}
                
                {currentComponent === 'QuickQuestions' && <QuickQuestionsBackend handleBack={handleBack} handleNext={handleNext} questionConfigs={surveyConfig.quickQuestionsBackend || []} />}
                
                {currentComponent === 'FileUploader' && <FileUploaderBackend handleBack={handleBack} handleNext={handleNext} maxFiles={surveyConfig.fileUploaderBackend?.maxFiles} minFiles={surveyConfig.fileUploaderBackend?.minFiles} />}
                
                {currentComponent === 'UserDatafields' &&
                    <>
                        <UserDatafieldsBackend 
                            handleBack={handleBack} 
                            handleNext={handleNext} 
                            onValidationChange={setIsUserDataValid} 
                            fields={surveyConfig.fields ? surveyConfig.fields.filter(field => field.selected) : []}
                        />
                        <div className="bottom-button-solo">
                            <button className={`buttonClassySolo ${!isUserDataValid ? 'buttonClassySolo-disabled' : ''}`} onClick={handleSubmissionTwo} disabled={!isUserDataValid}>Submit</button>
                        </div>
                    </>
                }
                
                {currentComponent === 'ThankYou' && <ThankYouBackend surveyId={surveyId} logoImage={surveyConfig.thankYouSettings?.logoImage} thankYouText={surveyConfig.thankYouSettings?.thankYouText} />}
            </div>
    );
    
            }    
export default SurveyBackend;