import React, { useState } from 'react';
import ImageGallery from './ImageGallery';
import './ServicePage.css';
import DeliveryModal from './DeliveryModal';

function ServiceItem({ service, getDeliveryIcon }) {
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [strategyCall, setStrategyCall] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleDeliveryClick = (option) => {
        setSelectedDelivery(option);
    };

    const handleCheckboxChange = (e) => {
        setStrategyCall(e.target.checked);
    };

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div className="service-item">
            <div className="service-item-left">                  
                <ImageGallery images={service.images} />
            </div>
            <div className="service-item-middle">
                <h5 className="service-item-top-category">{service.category}</h5>
                <h3>{service.serviceTitle}</h3>
                <p>{service.serviceDescription}</p>
                <div className="service-features-2">
                    <div>
                        {service.features.map((feature, index) => (
                            <div className="service-features-inside" key={index}>
                                <img src="./img/check.svg" alt="" />
                                <p>{feature}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="service-item-right">
                <h4>Delivery Options:</h4>
                <div>
                    {service.delivery.map((option, index) => (
                        <div
                            className={`service-delivery-item ${selectedDelivery === option ? 'selected' : ''}`}
                            key={index}
                            onClick={() => handleDeliveryClick(option)}
                        >
                            <img src={getDeliveryIcon(option.deliveryTime)} alt={option.deliveryTime} />
                            <div>
                                <span>{option.deliveryTime}</span>
                                <p>{option.deliveryDays} Days</p>
                            </div>
                            <span>{option.customOffer ? 'Custom Offer' : `${option.deliveryCredits} Credits`}</span>
                        </div>
                    ))}
                </div>
                <hr />
                <div className="service-contact-expert">
                    <input type="checkbox" checked={strategyCall} onChange={handleCheckboxChange} />
                    <img src="./img/val.webp" alt="" />
                    <div>
                        <h2>Add Strategy Call</h2>
                        <p>30 Minutes</p>
                    </div>
                </div>
                <div>
                    <button className="add-service-button-container" onClick={handleButtonClick}>
                        <p>Confirm and add Info</p>
                        <img src="./img/right.svg" alt="" />
                    </button>
                </div>
            </div>

            {showModal && (
                <DeliveryModal 
                    onClose={closeModal}
                    selectedDelivery={selectedDelivery}
                    strategyCall={strategyCall}
                    serviceTitle={service.serviceTitle}
                    deliveryCredits={selectedDelivery ? selectedDelivery.customOffer ? 'Custom Offer' : selectedDelivery.deliveryCredits : 0} // Hier werden die Credits oder "Custom" übergeben
                />
            )}
        </div>
    );
}

export default ServiceItem;
