import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { storage } from '../firebaseConfig';
import { v4 as uuidv4 } from 'uuid';  
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { saveFileUrls, setThumbnails } from '../Redux/actions';
import '../Steps/FileUploader.css';
import Lottie from 'lottie-react';
import animationData from '../img/lottie_animation.json';
import { useParams } from 'react-router-dom';
import { getDatabase, ref as firebaseRef, onValue } from 'firebase/database';
import { useFont } from '../Design/FontContext';



function FileUploaderBackend({ handleNext, handleBack }) {
    const [minFiles, setMinFiles] = useState(1);
    const [maxFiles, setMaxFiles] = useState(10); 
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch();
    const { userId } = useParams();
    const selectedFont = useFont();
    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const filteredFiles = files.filter(file => {
            if (!allowedFileTypes.includes(file.type.toLowerCase())) {
                alert(`Das Dateiformat von ${file.name} wird nicht unterstützt.`);
                return false;
            }
            return true;
        
        });
        const updatedFiles = [...selectedFiles, ...filteredFiles];
        setSelectedFiles(updatedFiles);
        updateProgress(updatedFiles.length);
    };
    
    const [uploadTitle, setUploadTitle] = useState('');
const [uploadDescription, setUploadDescription] = useState('');
const [individualProgress, setIndividualProgress] = useState([]);

    const [filesProgress, setFilesProgress] = useState([]);
 
    
    const handleRemove = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
        updateProgress(newFiles.length);
    };
    
    const updateProgress = (currentFileCount) => {
        const newProgress = (currentFileCount / minFiles) * 100;
        setProgress(Math.min(newProgress, 100)); // sicherstellen, dass der Fortschritt nicht über 100% geht
    };

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        updateProgress(selectedFiles.length);
    }, [selectedFiles.length]);

    const onDrop = useCallback((acceptedFiles) => {
        const files = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
        setSelectedFiles(prev => [...prev, ...files]);
    }, [setSelectedFiles]);

    useEffect(() => {
        const db = getDatabase();
    
        // Ref für die Datei-Uploader-Konfiguration
        const configRef = firebaseRef(db, `surveyConfigs/${userId}/fileUploader`);
    
        onValue(configRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setMinFiles(data.minFiles || 0);
                setMaxFiles(data.maxFiles || 10);
            }
        });
    
        // Ref für den Titel und die Beschreibung
        const titleDescRef = firebaseRef(db, `surveyConfigs/${userId}`);
    
        onValue(titleDescRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                if(data.uploadTitle) setUploadTitle(data.uploadTitle);
                if(data.uploadDescription) setUploadDescription(data.uploadDescription);
            }
        });
    
    }, [userId]);
    
  
    const messages = [
        `Upload at least ${minFiles} pictures!`,
        "Keep it up!",
        "Looking good!",
        "You're on a roll!",
        "Great job!",
        "Almost there!",
        "You're doing awesome!",
        "Keep those pictures coming!",
        "Fantastic!",
        "Just a few more!",
        "Great, you're done!"
    ];
    
    const allowedFileTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
        'image/webp',
        'video/mp4',
        'video/mov',
        'video/MOV',
        'video/avi',
        'video/ogg',
        'video/webm',
      ];

    const getEncouragementMessage = (currentCount) => {
        if (currentCount === 0) return messages[0];
        if (currentCount >= minFiles) return messages[messages.length - 1];
    
        // Ein Index basierend auf der Anzahl der hochgeladenen Dateien auswählen
        const index = Math.ceil(currentCount / minFiles * (messages.length - 2));
        return messages[index];
    };
    
   

        const handleUpload = async () => {
            if (!selectedFiles.length) return;

            if (selectedFiles.length > maxFiles) {
                alert(`Too many pictures, the maximum allowed number is ${maxFiles}`);
                return;  // Frühes Beenden der Funktion, um den Upload zu verhindern
            }
        
            selectRandomFact()
            setUploading(true);
        
            const fileInfos = [];
        
            const uploads = selectedFiles.map((file, index) => {
                return new Promise(async (resolve, reject) => {
                    const uniqueFileName = `${uuidv4()}-${file.name}`;
                    const storageReference = ref(storage, `users/${userId}/image-uploads/${uniqueFileName}`);
                    
                    const uploadTask = uploadBytesResumable(storageReference, file);
                    
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done for ' + file.name);
                            let updatedProgress = [...filesProgress];
                            updatedProgress[index] = progress;
                            setFilesProgress(updatedProgress);
                            let averageProgress = updatedProgress.reduce((acc, curr) => acc + curr, 0) / updatedProgress.length;
                            setProgress(averageProgress);
                        },
                        (error) => {
                            console.error("Error uploading file:", error);
                            reject(error);
                        },
                        async () => {
                            try {
                                const originalUrl = await getDownloadURL(storageReference);
                                
                                // Thumbnail erstellen und hochladen
                                const thumbnail = await createThumbnail(file);
                                const thumbFileName = `thumbnail-${uniqueFileName}`;
                                const thumbRef = ref(storage, `users/${userId}/image-uploads/${thumbFileName}`);
                                await uploadBytesResumable(thumbRef, thumbnail);
                                const thumbnailUrl = await getDownloadURL(thumbRef);
        
                                fileInfos.push({
                                    original: originalUrl,
                                    thumbnail: thumbnailUrl
                                });
        
                                dispatch(saveFileUrls(originalUrl));
                                resolve();
                            } catch (error) {
                                reject(error);
                            }
                        }
                    );
                });
            });
        
            try {
                await Promise.all(uploads);
                dispatch(setThumbnails(fileInfos)); // Hier speichern wir die Thumbnails und Original URLs
                setUploading(false);
                handleNext(); // Nächster Schritt
            } catch (error) {
                console.error('Error while uploading files:', error);
                setUploading(false);
            }
        };
        
        const createThumbnail = async (file) => {
            const fileType = file.type.split('/')[0];
        
            return new Promise((resolve, reject) => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const maxSize = 900;
        
                const resizeDimensions = (width, height) => {
                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    } else {
                        if (height > maxSize) {
                            width *= maxSize / height;
                            height = maxSize;
                        }
                    }
        
                    return { width, height };
                };
        
                if (fileType === 'image') {
                    const img = new Image();  // Verwenden Sie `new Image()` anstelle von `document.createElement('img')`
                    img.src = URL.createObjectURL(file);
                    img.onload = () => {
                        const { width, height } = resizeDimensions(img.width, img.height);
                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(img, 0, 0, width, height);
                        canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.7);
                    };
                    img.onerror = () => reject(new Error('Failed to create thumbnail for image.'));
        
                } else if (fileType === 'video') {
                    const video = document.createElement('video');
                    video.src = URL.createObjectURL(file);
                    
                    video.addEventListener('loadeddata', () => {
                        video.currentTime = 0.5;  // versuchen Sie, ein Bild von 0.5 Sekunden ins Video zu ziehen
                    });
        
                    video.addEventListener('timeupdate', () => {
                        const { width, height } = resizeDimensions(video.videoWidth, video.videoHeight);
                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(video, 0, 0, width, height);
                        canvas.toBlob(blob => {
                            resolve(blob);
                            video.pause(); // Stoppt das Video nach dem Erfassen des Thumbnails
                        }, 'image/jpeg', 0.7);
                    });
        
                    video.onerror = () => reject(new Error('Failed to create thumbnail for video.'));
        
                } else {
                    reject(new Error('Unsupported file type for thumbnail creation.'));
                }
            });
        };
        

    const [currentFact, setCurrentFact] = useState('');


    const randomFacts = [
        "There's a place in Bolivia called the Salar de Uyuni which, after a rain, becomes the world's largest mirror spanning 4,086 square miles.",
        "A day on Jupiter lasts only about 10 hours.",
        "Most of the wasabi served outside Japan is a mixture of horseradish, mustard, and green dye. Real wasabi is quite expensive and tastes more herbal than the knockoffs.",
        "Not all beaches are golden or white. There are green beaches in Hawaii, pink beaches in the Bahamas, purple beaches in California, and black beaches in Iceland, all thanks to unique local minerals and coral.",
        "The total weight of all the ants on Earth is greater than total weight of all the humans on the planet.",
        "There's a planet where it rains glass. It's called HD 189733b!",
        "Some Japanese tourists are said to experience 'Paris Syndrome' - a transient psychological disorder after realizing Paris is not what they expected.",
        "The Eiffel Tower can be 15 cm taller during the summer due to the expansion of the iron on hot days.",
        "There are more ways to shuffle a deck of cards than there are stars in our galaxy.",
        "The world's shortest flight, between two islands in Scotland, lasts only 2 minutes.",
        "If you drilled a tunnel straight through the Earth and jumped in, it would take you exactly 42 minutes and 12 seconds to get to the other side.",
        "The world's smallest volcano, Cuexcomate, is located in Puebla, Mexico. It's just 43 feet tall!",
        "The word 'alphabet' comes from the first two letters of the Greek alphabet: alpha and beta.",
        "Australia has over 10,000 beaches. If you were to visit a new beach every day, it would take you over 27 years to see them all.",
        "Venus rotates in the opposite direction to most planets, including Earth."
    ];

    const selectRandomFact = () => {
        const randomIndex = Math.floor(Math.random() * randomFacts.length);
        setCurrentFact(randomFacts[randomIndex]);
    };

    useEffect(() => {
        if (uploading) {
            selectRandomFact();  // initialer Fact direkt beim Start
            const intervalId = setInterval(() => {
                selectRandomFact();
            }, 7000);  // alle 4 Sekunden

            // Aufräumen, wenn Komponente unmountet oder `uploading` false wird
            return () => clearInterval(intervalId);
        }
    }, [uploading]);

return (
    <div className="uploader-master">
  {!uploading && (
        <>
            <h2 style={{ fontFamily: selectedFont }} className="surveyHeader">{uploadTitle}</h2>
            <p className="surveyParagraph">
            {uploadDescription}
            </p>
        </>
    )}
<div className={!uploading ? "uploader-container" : "uploader-container-loading"}>
        
        {uploading ? <div className="upload-status">
            <div>
         <Lottie animationData={animationData} class="lottie_animation" autoplay loop /> 
         <p className="pulsating-text">Your Files are uploading...</p>
</div>
            <div className="loading-quote">
                <img src="../img/gem.svg" alt="" />
                <h3>Did you know?</h3>
                <p>
                    {currentFact}
                    </p>
                    </div>
           
            </div> : (
            <>
<div className="file-drop-area">
    <img src="../img/upload1.svg" alt="" />
    <label htmlFor="fileInput" className="custom-file-input">
       <p>{getEncouragementMessage(selectedFiles.length)}</p> 

       {selectedFiles.length >= minFiles && (
           <p 
               className="upload-more-text"
               onClick={() => document.getElementById("fileInput").click()}
           >
               PS: You can upload up to {maxFiles} files...
           </p>
       )}

       <div className="progress-bar">
           <div className="progress" style={{width: `${progress}%`}}></div>
       </div>
    </label>
    <input type="file" id="fileInput" multiple onChange={handleFileChange} className="file-input" />
</div>




                <div className="files-list">
                    {selectedFiles.map((file, index) => (
                        <div key={index} className="file-item">
                            <span className="file-name">{file.name}</span>
                            {file.type.startsWith('video') ? (
    <video src={file.preview || URL.createObjectURL(file)} controls className="file-preview" />
) : (
    <img src={file.preview || URL.createObjectURL(file)} alt="preview" className="file-preview" />
)}                            <button onClick={() => handleRemove(index)} className="delete-btn">Delete</button>
                        </div>
                    ))}
                </div>
                <div className="bottom-buttons">
                <button className="buttonClassyBack" onClick={handleBack}>Back</button>
                <button 
    onClick={handleUpload} 
    className={`buttonClassy ${selectedFiles.length < minFiles ? 'buttonClassy-disabled' : ''}`} 
    disabled={selectedFiles.length < minFiles}
>
    Continue
</button>

                        </div>
            </>
        )}
    </div>
    </div>

);
                    }

export default FileUploaderBackend;
