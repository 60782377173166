import React from "react";

const StepTwo = ({ handleInputChange, handleBack, handleNext, setInputs }) => {
  return (
    <div>
      <h2>Step Two</h2>
      <div>
        <label htmlFor="questionOne">Question One:</label>
        <select name="questionOne" id="questionOne" onChange={e => handleInputChange(e, setInputs)}>
          <option value="">Select...</option>
          <option value="option1">Option 1</option>
          <option value="option2">Option 2</option>
        </select>
      </div>
      <div>
        <label htmlFor="questionTwo">Question Two:</label>
        <select name="questionTwo" id="questionTwo" onChange={e => handleInputChange(e, setInputs)}>
          <option value="">Select...</option>
          <option value="option1">Option 1</option>
          <option value="option2">Option 2</option>
        </select>
      </div>
      <div>
        <label htmlFor="questionThree">Question Three:</label>
        <select name="questionThree" id="questionThree" onChange={e => handleInputChange(e, setInputs)}>
          <option value="">Select...</option>
          <option value="option1">Option 1</option>
          <option value="option2">Option 2</option>
        </select>
      </div>
      <button onClick={handleBack}>Back</button>
      <button onClick={handleNext}>Next</button>
    </div>
  );
};

export default StepTwo;
