import React, { useState } from 'react';

function DomainModal({ onDomainSubmit, onClose }) {
    const [domainName, setDomainName] = useState('');

    const handleSubmit = () => {
        onDomainSubmit(domainName);
        setDomainName('');
    };

    return (
        <div className="modal-background">
            <div className="modal-content">
                <h2>Set Custom Domain</h2>
                <label className="modal-content-label">Custom Domain Name</label>
                <input
                    type="text"
                    value={domainName}
                    onChange={(e) => setDomainName(e.target.value)}
                    placeholder="Enter domain name"
                    className="text-input-field"
                />
                <div className="qr-buttons">
                    <button className="qr-modal-open" onClick={handleSubmit}>Save Domain</button>
                    <button className="qr-modal-close" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default DomainModal;
