import React, { useState, useEffect, useRef } from 'react';
import Masonry from 'masonry-layout';
import './Memories.css';
import { useDashboardData } from './UseDashboardData';
import DBSearch from './DBSearch';
import CSVExportButton from './CSVExportButton';
import imagesLoaded from 'imagesloaded';
import { getDatabase, ref, set, get } from 'firebase/database';
import { getAuth } from "firebase/auth";
import '../BackendSurvey/VoucherStatusPage.css'
import { Link, NavLink } from 'react-router-dom';
import ResponsiveMasonry from "react-responsive-masonry";
import QRCodeComponent from './QRCodeComponent'; // Stellen Sie sicher, dass der Pfad korrekt ist



function Memories({ onMasonryLoad, filteredSurveys, showFavoritesOnly, galleryStatus }) {
    const {
        surveys, searchTerm, setSearchTerm, startDate, setStartDate, 
        endDate, setEndDate, stringSafeToLower, 
        showModal, confirmDelete, closeModal
    } = useDashboardData();
    
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const [selectedImage, setSelectedImage] = useState(null);
    const [masonryLoaded, setMasonryLoaded] = useState(false);
    const [favorites, setFavorites] = useState([]);
    const [playingIndex, setPlayingIndex] = useState(null);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
    const [thumbnailRefs, setThumbnailRefs] = useState([]);
    const INITIAL_LOAD_COUNT = 10;
    const [hasManyItems, setHasManyItems] = useState(false);
    const [showGallery, setShowGallery] = useState(true);
    const [hasSettings, setHasSettings] = useState(false);
    const [isPremium, setIsPremium] = useState(null);




    const displayedSurveys = showFavoritesOnly
 ? filteredSurveys.map(survey => ({
     ...survey,
     uploadedThumbnails: (survey.uploadedThumbnails || []).filter(thumbnailObj => favorites.includes(thumbnailObj.thumbnail))
   })).filter(survey => survey.uploadedThumbnails.length > 0)
 : filteredSurveys;

//  const togglePlayPause = (index) => {
//     const video = videoRefs.current[index];
//     let updatedIsPlayingArray = [...isPlayingArray];

//     if (!video) {
//         console.error("Keine Video-Referenz gefunden für Index", index);
//         return;
//     }
    


//     if (video.paused) {
//         video.play()
//             .then(() => {
//                 updatedIsPlayingArray[index] = true;
//                 setIsPlayingArray(updatedIsPlayingArray);
//             })
//             .catch(err => {
//                 console.error("Error playing video:", err);
//             });
//     } else {
//         video.pause();
//         updatedIsPlayingArray[index] = false;
//         setIsPlayingArray(updatedIsPlayingArray);
//     }

//     const onVideoEnded = () => {
//         video.currentTime = 0;
//         updatedIsPlayingArray[index] = false;
//         setIsPlayingArray(updatedIsPlayingArray);
//         video.removeEventListener('ended', onVideoEnded);
//     };

//     video.addEventListener('ended', onVideoEnded);
// };





    useEffect(() => {
      }, [favorites]);

      useEffect(() => {
        if (currentUser) {
            //...
        }
     }, [currentUser]);
    
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedSurvey, setSelectedSurvey] = useState(null);
    const [loadedVideosCount, setLoadedVideosCount] = useState(0);
    const [totalVideos, setTotalVideos] = useState(0);
    const videoRefs = useRef([]);
    const [isPlayingArray, setIsPlayingArray] = useState(Array(displayedSurveys.length).fill(false));

    const getOriginalUrl = (index, survey) => {
      if (index !== null && survey && survey.uploadedThumbnails && survey.uploadedThumbnails[index]) {
        return survey.uploadedThumbnails[index].original;
      }
      return null;
    }
    
    
    const allImages = displayedSurveys.flatMap(survey => {
      return (survey.uploadedThumbnails || []).map(thumbnailObj => thumbnailObj.thumbnail);
  });

  const allItems = displayedSurveys.flatMap(survey => {
    const videoExtensions = ['.mp4','.MP4', '.MOV', '.mov', '.webm', '.ogg']; // Hinzufügen weiterer Videoendungen nach Bedarf
    const thumbnails = (survey.uploadedThumbnails || []).map(thumbnailObj => {
      const urlBeforeParameters = thumbnailObj.original.split('?')[0];
      const isVideo = videoExtensions.some(ext => urlBeforeParameters.endsWith(ext));
      return {
        type: isVideo ? 'video' : 'image',
        content: thumbnailObj.thumbnail,
        original: thumbnailObj.original
      };
    });
    return [...thumbnails];
});

useEffect(() => {
  const fetchIsPremium = async (userId) => {
    try {
      const db = getDatabase();
      const userRef = ref(db, `Users/${userId}/isPremium`);
      const snapshot = await get(userRef);

      const currentHasSettings = await fetchHasSettings(userId);
      setHasSettings(currentHasSettings);

      return snapshot.val();
    } catch (error) {
      console.error("Error fetching premium status:", error);
      return false; // Default to non-premium if there's an error
    }
  };

  if (auth.currentUser && auth.currentUser.uid) {
    (async () => {
      const currentIsPremium = await fetchIsPremium(auth.currentUser.uid);
      if (!currentIsPremium && allItems.length > 0) {
        setShowGallery(false);
        galleryStatus(false);  // Update galleryStatus prop to Dashboard
      } else {
        setShowGallery(true);
        galleryStatus(true);  // Update galleryStatus prop to Dashboard
      }
    })();
  } else {
    console.warn("No authenticated user found.");
  }

}, [allItems, auth.currentUser]);

const fetchHasSettings = async (userId) => {
  try {
    const db = getDatabase();
    const userRef = ref(db, `Users/${userId}/hasSettings`);
    const snapshot = await get(userRef);
    return snapshot.val();
  } catch (error) {
    console.error("Error fetching settings status:", error);
    return false; // Default to no settings if there's an error
  }
};


  
  const nextImage = () => {
    if (selectedIndex !== null && selectedIndex < allItems.length - 1) {
        const newIndex = selectedIndex + 1;
        setSelectedIndex(newIndex);
        
        const newItem = allItems[newIndex];
        const newSurvey = displayedSurveys.find(survey =>
            (survey.uploadedThumbnails || []).some(thumbnailObj => thumbnailObj.thumbnail === newItem.content)
        );
        
        setSelectedSurvey(newSurvey);

        if (newItem.type === 'video') {
        }
    }
};

    
    const prevImage = () => {
        if (selectedIndex !== null && selectedIndex > 0) {
            const newIndex = selectedIndex - 1;
            setSelectedIndex(newIndex);
            
            const newItem = allItems[newIndex];
            const newSurvey = displayedSurveys.find(survey =>
                (survey.uploadedThumbnails || []).some(thumbnailObj => thumbnailObj.thumbnail === newItem.content)
            );
            
            setSelectedSurvey(newSurvey);
        }
    };

    


  const handleCloseModal = () => {
      setSelectedIndex(null);
      setSelectedSurvey(null);
    };

    const audioRef = useRef(null);
    const masonryRef = useRef(null); // Masonry-Instanz Referenz

    
    
//// FAVORITES LOGIK 



const getFavoritesFromDB = async (userId) => {
  const db = getDatabase();
  const favoritesRef = ref(db, `favorites/${userId}`);
  const snapshot = await get(favoritesRef);
  return snapshot.val() || [];
}

useEffect(() => {
    const fetchFavorites = async () => {
        if (currentUser) {
            const userFavorites = await getFavoritesFromDB(currentUser.uid);
            setFavorites(userFavorites);
        }
    };
    fetchFavorites();
}, [currentUser]);


const updateFavoritesInDB = async (userId, updatedFavorites) => {
  const db = getDatabase();
  const favoritesRef = ref(db, `favorites/${userId}`);
  await set(favoritesRef, updatedFavorites);
}


    const toggleFavorite = async (thumbnailUrl) => {
        let updatedFavorites = [];
        if (favorites.includes(thumbnailUrl)) {
            updatedFavorites = favorites.filter(fav => fav !== thumbnailUrl);
        } else {
            updatedFavorites = [...favorites, thumbnailUrl];
        }
        setFavorites(updatedFavorites);

        if (currentUser) {
            await updateFavoritesInDB(currentUser.uid, updatedFavorites);
        }
    };


    useEffect(() => {
  
        const masonryInstance = new Masonry('.grid-container', {
            itemSelector: '.media-item',
            columnWidth: '.media-item',
            percentPosition: true,
            gutter: 16,
            transitionDuration: '0.1s'
        });
        masonryRef.current = masonryInstance;

        const videos = document.querySelectorAll('.media-item video');
        setTotalVideos(videos.length);
        
        const handleVideoLoadedMetadata = () => {
            setLoadedVideosCount(count => count + 1);
        };

        videos.forEach(video => video.addEventListener('loadedmetadata', handleVideoLoadedMetadata));

   
        const handleImagesLoaded = () => {
          masonryRef.current.layout();
          if (!masonryLoaded) {
              setMasonryLoaded(true);
              if (typeof onMasonryLoad === 'function') {
                  onMasonryLoad();
              }
          }
      };
      
      const imgLoad = imagesLoaded('.grid-container'); // Dies überwacht jetzt sowohl Bilder als auch Video-Poster
      imgLoad.on('always', handleImagesLoaded);
      
      return () => {
          imgLoad.off('always', handleImagesLoaded);
          if (masonryRef.current) masonryRef.current.destroy();
      };
      
    }, [filteredSurveys, masonryLoaded, onMasonryLoad, loadedVideosCount, totalVideos]);


useEffect(() => {
    if (masonryRef.current) {
        masonryRef.current.reloadItems();
        masonryRef.current.layout();
    }
}, [favorites, showFavoritesOnly]);

const stopPropagation = (e) => {
    e.stopPropagation();
  };

  function urlToThumbnail(originalUrl, uploadedThumbnails) {
    if (uploadedThumbnails && uploadedThumbnails.length > 0) {
      const thumbnailObj = uploadedThumbnails.find(thumb => thumb.original === originalUrl);
            return thumbnailObj ? thumbnailObj.thumbnail : originalUrl;
    } else {
      return originalUrl;
    }
  }
  
  
  useEffect(() => {
    if (thumbnailRefs.length === 0) return; // Keine Thumbnails zum Beobachten
    const observer = new IntersectionObserver(entries => {
        console.log("Observer callback called");  // Zum Debuggen hinzugefügt

        entries.forEach(entry => {
            if (entry.isIntersecting) {
                console.log("Image is intersecting!");  // Zum Debuggen hinzugefügt

                const imgElement = entry.target;
                const thumbnail = imgElement.getAttribute('data-src');
                if (thumbnail) {
                    imgElement.src = thumbnail;
                }
                imgElement.onload = () => {
                    if (masonryRef.current) {
                        masonryRef.current.layout();
                    }
                };
                observer.unobserve(imgElement);
            }
        });
    }, {
        rootMargin: "500px"
    });

    console.log("Observing images");  // Zum Debuggen hinzugefügt
    thumbnailRefs.forEach(thumbnail => observer.observe(thumbnail));

    return () => {
        observer.disconnect();
    };
}, [thumbnailRefs]);



const addThumbnailRef = el => {
    if (el && !thumbnailRefs.includes(el)) {
      setThumbnailRefs(prevRefs => [...prevRefs, el]);
    }
  };



  return (
    <div>
      {!masonryLoaded && 
        <div className="loading-spinner" id={isPremium === false ? "fake-loading-spinner-premium" : "fake-loading-spinner-2"}>
        <h1>BonusClub</h1>
          <div className="spinner-circle"></div>
        </div>
      }

      <div className={`memories ${masonryLoaded ? 'is-loaded' : 'is-loading'}`}>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <p>Wollen Sie den Eintrag wirklich löschen?</p>
              <button onClick={confirmDelete}>Ja</button>
              <button onClick={closeModal}>Nein</button>
            </div>
          </div>
        )}

        <div className="grid-container">
          {displayedSurveys.length > 0 ? (
             displayedSurveys.map((survey) => {
              if (!survey.userData || !survey.userData.firstName || !survey.userData.lastName) {
                // Hier können Sie auch eine Fehlermeldung oder einen Platzhalter rendern, wenn Sie möchten.
                return null;
              }
          
              return survey.uploadedThumbnails.map((urlObj, urlIndex) => {
                const originalUrl = urlObj.original;
                const thumbnailUrl = urlObj.thumbnail;
                const isImage = thumbnailUrl ? ['.jpg', '.jpeg', '.JPEG',  '.JPG', '.png', '.gif', '.svg', '.webp'].some(ext => thumbnailUrl.includes(ext)) : false;
                const isVideo = thumbnailUrl ? ['.mp4', '.MP4', '.webm', '.avi', '.AVI', '.ogg', '.mov', '.MOV'].some(ext => thumbnailUrl.includes(ext)) : false;

                if (isImage) {
                    return (
                        <div key={`${survey.id}-${urlIndex}`} className="media-item image-item">
         <img 
    ref={addThumbnailRef} 
    {...(urlIndex < INITIAL_LOAD_COUNT ? { src: thumbnailUrl } : { 'data-src': thumbnailUrl })} 
    alt={`Uploaded by ${survey.userData.firstName} ${survey.userData.lastName}`} 
    onClick={() => {
        const index = allImages.findIndex(thumbUrl => thumbUrl === thumbnailUrl);
        setSelectedIndex(index);
        setSelectedSurvey(survey);
    }}
    loading="lazy"
/>
                      <div className="favorite-icon" onClick={(e) => {e.stopPropagation(); toggleFavorite(thumbnailUrl);}}>
                        <img 
                          className={favorites.includes(thumbnailUrl) ? "heart-round" : "heart-white"}
                          src={favorites.includes(thumbnailUrl) ? "/img/heart-round.svg" : "/img/heart-white.svg"} 
                          alt={favorites.includes(thumbnailUrl) ? "Favorite" : "Not a Favorite"} 
                        />
                      </div>
                      <a href={originalUrl} target="_blank" download className="download-btn">
                        <div>
                          <img className="grid_download_button" src="./img/downloads.svg" alt="download"/>
                        </div>
                      </a>
                      <div className="creator">
  {survey.userData && survey.userData.firstName && survey.userData.lastName && (
    <>
      {survey.userData.firstName} {survey.userData.lastName}
    </>
  )}
</div>
                    </div>
                  );
                } else if (isVideo) {
                    return (
                        <div key={`${survey.id}-${urlIndex}`} className="media-item video-item" onClick={() => {
                                const index = allImages.findIndex(thumbUrl => thumbUrl === thumbnailUrl);
                            setSelectedIndex(index);
                            setSelectedSurvey(survey); 
                        }}>
                            <video preload="metadata" poster={thumbnailUrl} ref={(el) => { 
                                    videoRefs.current[urlIndex] = el;
                                    addThumbnailRef(el);  // Hinweis: Hier verwenden wir den gleichen Ref-Hinzufügungsmechanismus wie für Bilder
                                }} 
                                width="100%">
                                <source data-src={originalUrl} type={`video/${thumbnailUrl.split('?')[0].split('.').pop()}`} />
                                Ihr Browser unterstützt das Video-Tag nicht.
                            </video>
                      <div className="favorite-icon" onClick={(e) => {e.stopPropagation(); toggleFavorite(thumbnailUrl);}}>
                        <img 
                          className={favorites.includes(thumbnailUrl) ? "heart-round" : "heart-white"}
                          src={favorites.includes(thumbnailUrl) ? "/img/heart-round.svg" : "/img/heart-white.svg"} 
                          alt={favorites.includes(thumbnailUrl) ? "Favorite" : "Not a Favorite"} 
                        />
                      </div>
                      <div>
                      <button 
    className="play-button" 
    onClick={() => {
        const index = allImages.findIndex(thumbUrl => thumbUrl === thumbnailUrl);
    setSelectedIndex(index);
    setSelectedSurvey(survey); 
}}
>
    {isPlayingArray[urlIndex] ? (
        <img className="pause-button-1" src="./img/pause.svg" alt="Pause" />
    ) : (
        <img className="play-button-1" src="./img/play.svg" alt="Play" />
    )}
</button>

                      </div>
                      <a href={originalUrl} download className="download-btn">
                        <div>
                          <img className="grid_download_button" src="./img/downloads.svg" alt="download"/>
                        </div>
                      </a>
                      <div className="creator">
                        {survey.userData.firstName} {survey.userData.lastName}
                      </div>
                    </div>
                  );
                }
                return null;
              });
            })
          ) : (
            <div className="no-surveys-message">
            <div className="no-surveys-text">
              {hasSettings ? (
                <>      
                  <img src="./img/BonusClub_Logo.webp" alt="logo" />
                    <QRCodeComponent />
                  <h2>Share your Link.</h2>
                  <p style={{ marginBottom: '20px'}}>You have successfully set up your settings. Now share your link with your guests. Submitted reviews and content will display here.</p>
                  <div>
                  <a className="button-start" href="https://bonusclub.io" target="_blank">Need Support?</a>
                  <Link style={{background: 'white', color: 'black', border: '1px solid black', marginLeft: '20px'}} className="button-start" to="/settings"> Go to settings</Link>
                  </div>
              
                </>
              ) : (
                <>
                  <img src="./img/BonusClub_Logo.webp" alt="logo" />
                  <h2>Welcome to BonusClub.</h2>
                  <p>Create bonuses, reward guests, and skyrocket your business.</p>
                  <NavLink className="button-start" to="/settings">Start Creating your Link</NavLink>
                </>
              )}
            </div>
            <img className="background_images_floating" src="./img/Background_Images.webp" alt="background" />
          </div>
          )}
        </div>



        {selectedIndex !== null && selectedSurvey && (
    <div className="fullscreen-modal" onClick={handleCloseModal}>
        <button className="fullscreen-prev" onClick={(e) => {
            prevImage();
            e.stopPropagation();
        }}>
            <img src="./img/right.svg" alt="left button" />
        </button>
        
        {selectedIndex !== null && ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp'].some(ext => allImages[selectedIndex].includes(ext)) ? (
            <div className="media-container">
                <img
                    src={urlToThumbnail(allImages[selectedIndex], selectedSurvey.uploadedThumbnails)}
                    alt="Fullscreen" className="fullscreen-media" onClick={stopPropagation} />
                <div>
                <a href={allItems[selectedIndex].original}  target="_blank" download className="download-btn-modal" onClick={stopPropagation}>
  <img className="downloads-popup" src="./img/downloads.svg" alt="download" />
  Download
</a>

                    <div className="favorite-icon" id="pop-up-icon" onClick={(e) => { e.stopPropagation(); toggleFavorite(allImages[selectedIndex]); }}>
                        <img
                            className={favorites.includes(allImages[selectedIndex]) ? "heart-round" : "heart-white"}
                            src={favorites.includes(allImages[selectedIndex]) ? "/img/heart-round.svg" : "/img/heart-white.svg"}
                            alt={favorites.includes(allImages[selectedIndex]) ? "Favorite" : "Not a Favorite"}
                        />
                    </div>
                    <div className="creator-fullscreen" >
                    {selectedSurvey.userData.firstName && selectedSurvey.userData.lastName && (
                        <div>
                            {selectedSurvey.userData.firstName} {selectedSurvey.userData.lastName}
                        </div>
                    )}
                    {selectedSurvey.userData.email && (
                        <div>
                            <img src="./img/mail_1.svg" alt="" />
                            {selectedSurvey.userData.email}
                        </div>
                    )}
                    {selectedSurvey.userData.instagram && (
                        <div>
                            <img src="./img/instagram.svg" alt="" />
                            {selectedSurvey.userData.instagram}
                        </div>
                    )}
                          {selectedSurvey.userData.tiktok && (
                        <div>
                            <img src="./img/tiktok.svg" alt="" />
                            {selectedSurvey.userData.tiktok}
                        </div>
                    )}
                       {selectedSurvey.userData.facebook && (
                        <div>
                            <img src="./img/facebook.svg" alt="" />
                            {selectedSurvey.userData.facebook}
                        </div>
                    )}
                      {selectedSurvey.userData.linkedin && (
                        <div>
                            <img src="./img/linkedin.svg" alt="" />
                            {selectedSurvey.userData.linkedin}
                        </div>
                    )}
                </div>
                </div>
            </div>
        ) : null}

        {selectedIndex !== null && selectedSurvey && allItems[selectedIndex].type === 'video' ? (
            <div className="media-container">
              
        <video controls preload='none' className="fullscreen-media" onClick={stopPropagation} poster={urlToThumbnail(allImages[selectedIndex], selectedSurvey.uploadedThumbnails)}>
                    <source
                        src={allItems[selectedIndex].original}
                        type={`video/${allItems[selectedIndex].original.split('?')[0].split('.').pop()}`}
                    />
                    Ihr Browser unterstützt das Video-Tag nicht.
                </video>
                <a href={getOriginalUrl(selectedIndex, selectedSurvey)} target="_blank" download className="download-btn-modal" id="video-modal" onClick={stopPropagation}>
  <img className="downloads-popup" src="./img/downloads.svg" alt="download" />
  Download
</a>
                <div className="favorite-icon" id="pop-up-icon-2" onClick={(e) => { e.stopPropagation(); toggleFavorite(allImages[selectedIndex]); }}>
                        <img
                            className={favorites.includes(allImages[selectedIndex]) ? "heart-round" : "heart-white"}
                            src={favorites.includes(allImages[selectedIndex]) ? "/img/heart-round.svg" : "/img/heart-white.svg"}
                            alt={favorites.includes(allImages[selectedIndex]) ? "Favorite" : "Not a Favorite"}
                        />
                    </div>
                <div className="creator-fullscreen" id="video-fullscreen">
                    {selectedSurvey.userData.firstName && selectedSurvey.userData.lastName && (
                        <div>
                            {selectedSurvey.userData.firstName} {selectedSurvey.userData.lastName}
                        </div>
                    )}
                    {selectedSurvey.userData.email && (
                        <div>
                            <img src="./img/mail_1.svg" alt="" />
                            {selectedSurvey.userData.email}
                        </div>
                    )}
                    {selectedSurvey.userData.instagram && (
                        <div>
                            <img src="./img/instagram.svg" alt="" />
                            {selectedSurvey.userData.instagram}
                        </div>
                    )}
                </div>
            </div>
        ) : null}

        <button className="fullscreen-next" onClick={(e) => {
            nextImage();
            e.stopPropagation();
        }}>
            <img src="./img/right.svg" alt="right button" />
        </button>
        <button className="fullscreen-close" onClick={() => { setSelectedIndex(null); setSelectedSurvey(null); }}>
            Close
        </button>
    </div>
)}
</div>
</div>
);
}

export default Memories;