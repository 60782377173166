import React, { useState } from 'react';
import { getDatabase, ref as dbRef, get, set, update } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { createChatAndMessages } from './ChatCreatFunction';
import './DeliveryModal.css';

function DeliveryModal({ onClose, selectedDelivery, strategyCall, serviceTitle }) {
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    const auth = getAuth();
    const user = auth.currentUser;
    const db = getDatabase();
    const storage = getStorage();

    const getDeliveryIcon = (deliveryTime) => {
        switch (deliveryTime) {
            case 'Fast':
                return './img/delivery_1.svg';
            case 'Standard':
                return './img/delivery_2.svg';
            case 'Superfast':
                return './img/delivery_3.svg';
            default:
                return './img/delivery_default.svg';
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setFilePreview(event.target.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleOrderSubmit = async () => {
        if (!user) {
            alert('You must be logged in to place an order');
            return;
        }

        const userId = user.uid;
        const userCreditsRef = dbRef(db, `Credits/${userId}/amount`);

        try {
            const snapshot = await get(userCreditsRef);
            if (snapshot.exists()) {
                const currentCredits = snapshot.val();
                const requiredCredits = selectedDelivery.deliveryCredits;

                if (currentCredits >= requiredCredits) {
                    const newCredits = currentCredits - requiredCredits;
                    await update(dbRef(db, `Credits/${userId}`), { amount: newCredits });

                    setIsUploading(true);

                    let fileUrl = '';
                    if (file) {
                        const fileRef = storageRef(storage, `orders/${file.name}`);
                        await uploadBytes(fileRef, file);
                        fileUrl = await getDownloadURL(fileRef);
                    }

                    const orderId = uuidv4();
                    const orderData = {
                        user: {
                            uid: user.uid,
                            email: user.email,
                            displayName: user.displayName || 'Anonymous'
                        },
                        serviceTitle,
                        selectedDelivery,
                        strategyCall,
                        description,
                        fileUrl
                    };

                    const newOrderRef = dbRef(db, `Orders/${orderId}`);
                    await set(newOrderRef, orderData);

                    // Get the managerId from the user's data
                    const userRef = dbRef(db, `Users/${userId}`);
                    const userSnapshot = await get(userRef);
                    const userData = userSnapshot.val();
                    const managerId = userData.agencyManager;

                    if (managerId) {
                        await createChatAndMessages(userId, managerId, orderData);
                    }

                    setIsUploading(false);
                    onClose();
                    alert('Order placed successfully!');
                } else {
                    alert('Not enough credits to place the order');
                }
            } else {
                alert('User credits not found');
            }
        } catch (error) {
            console.error('Error checking or updating credits:', error);
            alert('An error occurred while placing the order. Please try again.');
        }
    };

    return (
        <div className="delivery-modal-overlay">
            <div className="delivery-modal-container">
                <button className="delivery-modal-close" onClick={onClose}>
                    <img src="./img/plus_1.svg" alt="Close" />
                </button>
                <h2 className="delivery-modal-title">{serviceTitle}</h2>
                <hr className="delivery-modal-hr" />

                <div className="delivery-modal-content">
                    {selectedDelivery ? (
                        <div className="delivery-details">
                            <img src={getDeliveryIcon(selectedDelivery.deliveryTime)} alt={selectedDelivery.deliveryTime} className="delivery-icon" />
                            <div>
                                <p className="delivery-time">{selectedDelivery.deliveryTime}</p>
                                <p className="delivery-days">{selectedDelivery.deliveryDays} Days</p>
                            </div>
                        </div>
                    ) : (
                        <p>No delivery option selected</p>
                    )}

                    {strategyCall && (
                        <div className="strategy-call">
                            <img src="./img/val.webp" alt="Strategy Call" className="strategy-call-icon" />
                            <div>
                                <p className="strategy-call-title">Strategy Call</p>
                                <p className="strategy-call-duration">30 Minutes</p>
                            </div>
                        </div>
                    )}
                </div>

                <label htmlFor="offer-description" className="offer-description-label">Describe your Order</label>
                <div className="offer-description-container">
                    <textarea
                        id="offer-description"
                        className="offer-description-input"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                    <div className="file-upload-wrapper">
                        {filePreview && <img src={filePreview} alt="File preview" className="file-preview-image" />}
                        <input
                            type="file"
                            id="file-upload"
                            className="file-upload-input"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="file-upload" className="file-upload-button">
                            <img src="./img/link.svg" alt="Upload" />
                        </label>
                    </div>
                </div>

                <button
                    className="confirm-order-button"
                    onClick={handleOrderSubmit}
                    disabled={isUploading}
                >
                    {isUploading ? 'Placing Order...' : 'Confirm Order'}
                </button>
            </div>
        </div>
    );
}

export default DeliveryModal;
