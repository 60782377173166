// Action Types
export const SAVE_FILE_URLS = 'SAVE_FILE_URLS';
export const SET_UPLOADED_FILES = "SET_UPLOADED_FILES";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_QUICK_QUESTIONS = "SET_QUICK_QUESTIONS";
export const SET_SELECTED_BONUS = "SET_SELECTED_BONUS";
export const SET_INPUTS = "SET_INPUTS";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SUBMIT_START = 'SUBMIT_START';
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS';
export const SUBMIT_FAILURE = 'SUBMIT_FAILURE';
export const SET_THUMBNAILS = 'SET_THUMBNAILS';
export const SET_HAS_MANY_ITEMS = 'SET_HAS_MANY_ITEMS';

