import React, { useState, useEffect } from 'react';
import './PageWizard.css';

const loadingMessages = [
    "Checking if your Hotel is Kosher...",
    "Calculating the Best Room Views...",
    "Brewing Fresh Coffee for Your Stay...",
    "Ensuring All Pillows are Fluffed...",
    "Warming Up the Pool...",
    "Double-Checking the Minibar...",
    "Preparing the Welcome Drink...",
    "Checking the Wi-Fi Password...",
    "Finding the Best Local Restaurants...",
    "Syncing with the Hotel's AI...",
];

const getRandomMessageIndex = (excludedIndex) => {
    let newIndex;
    do {
        newIndex = Math.floor(Math.random() * loadingMessages.length);
    } while (newIndex === excludedIndex);
    return newIndex;
};

const LoadingSpinnero = () => {
    const [currentMessageIndex, setCurrentMessageIndex] = useState(getRandomMessageIndex(-1));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => getRandomMessageIndex(prevIndex));
        }, 3500);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="loading-spinerro-container">
            <h2 className="loading-spinerro-text">{loadingMessages[currentMessageIndex]}</h2>
            <div className="loading-spinnerro-cont">
                <div className="loading-spinnerro"></div>
                <img src="./img/BonusClub_Logo.webp" alt="" />
            </div>
        </div>
    );
};

export default LoadingSpinnero;
