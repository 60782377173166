import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getDatabase, ref, onValue } from "firebase/database";
import { useParams } from 'react-router-dom';
import { setQuickQuestions } from '../Redux/actions';
import '../Steps/QuickQuestions.css';
import '../App.css';
import { useFont } from '../Design/FontContext';


const QuickQuestionsBackend = ({ handleBack, handleNext }) => {
    const dispatch = useDispatch();
    const quickQuestionsData = useSelector(state => state.quickQuestions) || {};
    const [questionConfigs, setQuestionConfigs] = useState([]);
    const { userId } = useParams();
    const [quickQuestionsTitle, setQuickQuestionsTitle] = useState('');
    const [quickQuestionsDescription, setQuickQuestionsDescription] = useState('');
    const selectedFont = useFont();

    useEffect(() => {
        const db = getDatabase();
        const configRef = ref(db, `surveyConfigs/${userId}`);
    
        onValue(configRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setQuestionConfigs(data.quickQuestions || []);
                setQuickQuestionsTitle(data.quickQuestionsTitle || '');
                setQuickQuestionsDescription(data.quickQuestionsDescription || '');
            }
        }, {
            onlyOnce: true
        });
    }, [userId]);
    

    const handleQuestionChange = (questionIndex, selectedValue, placeholder) => {
        const updatedQuestions = { ...quickQuestionsData };
        updatedQuestions[questionIndex] = { placeholder, value: selectedValue };
        dispatch(setQuickQuestions(updatedQuestions));
    };

    const allQuestionsAnswered = () => {
        return questionConfigs.every((_, index) => {
            return quickQuestionsData[index] && quickQuestionsData[index].value !== "";
        });
    };

    const handleNextClick = () => {
        if (!allQuestionsAnswered()) {
            return; // Frühzeitiges Beenden, wenn nicht alle Fragen beantwortet wurden
        }
    
        handleNext();
    };
    
    return (
        <div className="quick-questions">
        <h2 style={{ fontFamily: selectedFont }} className="surveyHeader">{quickQuestionsTitle}</h2>
        <p className="surveyParagraph">{quickQuestionsDescription}</p>
            
            {questionConfigs.map((question, index) => (
                <div className="question" key={index}>
                    <div className="question-wrapper">
                        <select
                            name={`question-${index}`}
                            id={`question-${index}`}
                            className="select-dropdown"
                            value={(quickQuestionsData[index] && quickQuestionsData[index].value) || ""}
                            onChange={(e) => handleQuestionChange(index, e.target.value, question.placeholder)}
                        >
                            <option value="">{question.placeholder}</option>
                            {question.options.map((option, optionIndex) => (
                                <option key={optionIndex} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <img src="../img/down.svg" alt="Dropdown arrow" />
                    </div>
                </div>
            ))}

            <div className="bottom-buttons">
                <button className="buttonClassyBack" onClick={handleBack}>Back</button>
                <button 
                    className={`buttonClassy ${!allQuestionsAnswered() ? 'buttonClassy-disabled' : ''}`} 
                    onClick={handleNextClick} 
                >
                    Next
                </button>
            </div>
        </div>    
    );
};

export default QuickQuestionsBackend;

