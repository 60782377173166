import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ref, onValue, off, getDatabase, set, update } from 'firebase/database';
import './VoucherStatusPage.css';
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function VoucherStatusPage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [voucherStatus, setVoucherStatus] = useState('loading');
  const [selectedBonus, setSelectedBonus] = useState(null);
  const [bonusName, setBonusName] = useState("");
  const [bonusDesc, setBonusDesc] = useState("");
  const [bonusImage, setBonusImage] = useState("");

  const query = useQuery();
  const surveyIdFromQuery = query.get("surveyId");
  const userId = query.get("userId");
  const surveyId = surveyIdFromQuery;

  useEffect(() => {
    console.log('Checking for surveyId and userId...');
    console.log('surveyIdFromQuery:', surveyIdFromQuery);
console.log('userId from useParams:', userId);
console.log('Final surveyId used:', surveyId);
    if (!surveyId || !userId) {
        setError('Keine surveyId oder userId angegeben');
        setLoading(false);
        return;
    }
    console.log('Both surveyId and userId found:', surveyId, userId);

    const db = getDatabase();
    const voucherRef = ref(db, `surveys/${userId}/${surveyId}/status`);

    const listener = onValue(voucherRef, (snapshot) => {
        if (snapshot.exists()) {
            console.log('Status retrieved from database:', snapshot.val());
            setVoucherStatus(snapshot.val());
            setLoading(false);
        } else {
            setError('Gutschein existiert nicht');
            setLoading(false);
        }
    }, (errorObject) => {
        console.error('Error fetching status:', errorObject);
        setError(errorObject.message);
        setLoading(false);
    });

    return () => {
        off(voucherRef, listener);
    };
}, [surveyId]);

useEffect(() => {
  if (!surveyId) return;

  const db = getDatabase();
  const selectedBonusRef = ref(db, `surveys/${userId}/${surveyId}/selectedBonus`);

  const listener = onValue(selectedBonusRef, (snapshot) => {
      if (snapshot.exists()) {
          console.log('Selected bonus retrieved:', snapshot.val());
          setSelectedBonus(snapshot.val());
      }
  });

  return () => {
      off(selectedBonusRef, listener);
  };
}, [surveyId, userId]);



useEffect(() => {
  if (!selectedBonus) return;

  console.log('Fetching bonus details...');

  const bonusIndex = parseInt(selectedBonus.replace("bonus", "")) - 1;

  const db = getDatabase();
  const bonusNamesRef = ref(db, `surveys/${userId}/${surveyId}/bonusName/${bonusIndex}`);
  const bonusDescRef = ref(db, `surveys/${userId}/${surveyId}/bonusDesc/${bonusIndex}`);
  const bonusImageRef = ref(db, `surveys/${userId}/${surveyId}/bonusImg/${bonusIndex}`);

  onValue(bonusNamesRef, (snapshot) => {
      if (snapshot.exists()) {
          console.log('Bonus name:', snapshot.val());
          setBonusName(snapshot.val());
      }
  });

  onValue(bonusDescRef, (snapshot) => {
      if (snapshot.exists()) {
          console.log('Bonus description:', snapshot.val());
          setBonusDesc(snapshot.val());
      }
  });

  onValue(bonusImageRef, (snapshot) => {
      if (snapshot.exists()) {
          console.log('Bonus image:', snapshot.val());
          setBonusImage(snapshot.val());
      }
  });
}, [selectedBonus, surveyId]);

const handleUpdateStatus = async () => {
    const db = getDatabase();
    const updateRef = ref(db, `surveys/${userId}/${surveyId}/status`);
    try {
        await set(updateRef, 'invalid');
        setVoucherStatus('invalid');
    } catch (updateError) {
      setError(updateError.message);
    }
};
  return (
    <div className="container_container">
        <div className="container">
            {loading ? (
                <>
                    <h1><span>Checking coupon...</span></h1>
                    {/* Sie können hier auch eine kleine Ladeanimation hinzufügen, wenn Sie möchten */}
                </>
            ) : (
                <>
                    {voucherStatus === 'valid' ? (
                        <>
                            <div className="container-element-wrapper">
                                <h1><span>Coupon Status: Valid</span></h1>
                                {bonusImage && <img src={bonusImage} alt={bonusName} />}
                                <h3>{bonusName}</h3>
                                <p>{bonusDesc}</p>
                                <button onClick={handleUpdateStatus}>Deactivate</button>
                            </div>
                        </>
                    ) : (
                        <div className="container-invalid">
                            <h1>Coupon Status: Invalid</h1>
                        </div>
                    )}
                </>
            )}
        </div>
    </div>
);

                    }


export default VoucherStatusPage;