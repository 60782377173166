// PremiumRestrictionMessage.js
import React from 'react';
import './AddPageComponent.css'; // Create this CSS file for styling

const PremiumRestrictionMessage = () => {
  return (
    <div>
      <div className="premium-restriction-message" style={{ textAlign: 'center', position: 'relative', marginLeft: '15px', marginBottom: '-5px' }}>
        <div className="question-right-pro" style={{backgroundColor: 'transparent', border: '1px solid #f2f2f2'}}>
          <img className="crown" src="./img/crow.svg" alt="lock"/>
          <p>AGENCY</p>
        </div>
        <img src="./img/Bonusclub_Ipad.webp" alt="" />
        <h2>Join the Agency</h2>
        <p>Upgrade your account to get access.</p>
        <a className="pro-feature-button" style={{textDecoration: 'none', color: 'white'}} href="https://bonusclub.io">Contact our Team</a>
        <a className="pro-feature-button-2" style={{textDecoration: 'none', color: 'white'}} href="https://bonusclub.io">More Info</a>
      </div>
    </div>
  );
};

export default PremiumRestrictionMessage;
