import { initializeApp } from 'firebase/app';
import { getDatabase, update } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, set, get } from 'firebase/database';

function getCurrentTimestamp() {
    return new Date().toISOString();
}

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { app, database, storage, auth };



export function addSurveyConfig(userId, surveyConfig) {
    const userSurveyConfigRef = ref(database, 'surveyConfigs/' + userId);
    return update(userSurveyConfigRef, surveyConfig);  // Use the update method here
}

export function getSpecificSurveyConfig(userId, callback) {
    const userSurveyConfigRef = ref(database, 'surveyConfigs/' + userId);
    
    userSurveyConfigRef.on('value', (snapshot) => {
        const data = snapshot.val();
        callback(data);
    });
}

export function addUserEntry(userId) {
    const userRef = ref(database, `Users/${userId}`);
    return update(userRef, { hasSettings: false });
}

export function setUserPremiumStatus(userId, isPremium = false) {
    const userRef = ref(database, `Users/${userId}`);
    return update(userRef, { isPremium });
}



export function getSurveyConfigs(callback) {
    const surveyConfigsRef = ref(database, 'surveyConfigs');
    surveyConfigsRef.on('value', (snapshot) => {
        const data = snapshot.val();
        callback(data);
    });
}

// firebaseConfig.js
export const getSurveyConfig = async (userId) => {
    // Holen Sie sich die Daten aus Firebase basierend auf der Benutzer-ID.
    const userRef = ref(database, `users/${userId}`);
    let result = null;

    await userRef.once('value', (snapshot) => {
        if (snapshot.exists()) {
            result = snapshot.val();
        } else {
            console.log("No such document!");
        }
    });

    return result;
};

onAuthStateChanged(auth, user => {
    if (user) {
        const userId = user.uid;
        // Referenz auf den Benutzer in der Datenbank
        const userRef = ref(getDatabase(), `Users/${userId}`);
        get(userRef).then(snapshot => {
            const now = getCurrentTimestamp();
            if (!snapshot.exists()) {
                // Wenn der Benutzer zum ersten Mal registriert wird, füge zusätzliche Daten hinzu
                set(userRef, {
                    email: user.email,
                    name: user.displayName,
                    signupTimestamp: now,
                    hasSettings: false,
                    isPremium: false,
                    sessionCount: 1, // Initialisiere den Session-Counter
                    lastLogin: now,
                    lastAccess: now,
                }).then(() => console.log('Benutzerdaten bei der Registrierung gespeichert.'))
                .catch(error => console.error('Fehler beim Speichern der Benutzerdaten:', error));
            } else {
                // Extrahiere den letzten Zugriffszeitstempel
                const lastAccess = new Date(snapshot.val().lastAccess).getTime();
                const currentTime = new Date(now).getTime();
                const diff = currentTime - lastAccess;
                const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 Minuten in Millisekunden

                // Überprüfe, ob die Session als neu betrachtet werden sollte
                if (diff > SESSION_TIMEOUT) {
                    const currentSessionCount = snapshot.val().sessionCount || 0;
                    update(userRef, {
                        sessionCount: currentSessionCount + 1, // Inkrementiere den Session-Counter
                        lastLogin: now,
                    }).then(() => console.log('Session-Informationen aktualisiert.'))
                    .catch(error => console.error('Fehler beim Aktualisieren der Session-Informationen:', error));
                }

                // Aktualisiere den letzten Zugriffszeitstempel unabhängig davon, ob es eine neue Session ist oder nicht
                update(userRef, { lastAccess: now });
            }
        });
    }
});




