import React, { useState, useRef, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import './DashboardHeader.css';
import QRCode from 'react-qr-code';
import { useFont } from '../Design/FontContext';
import { ref, onValue, off } from 'firebase/database';
import { getDatabase } from 'firebase/database';
import { UnreadStatusContext } from '../Pages/Chats/UnreadStatusContext';
import CheckoutButton from '../Checkout/CheckoutButton';
import BottomPurchase from '../Pages/BottomPurchase';

function DashboardHeader() {
    const auth = getAuth();
    const unreadStatus = useContext(UnreadStatusContext);
    const hasUnreadMessages = Object.values(unreadStatus).some(status => status);
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    const [hasAgency, setHasAgency] = useState(null);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [qrDataUrl, setQrDataUrl] = useState(null);
    const qrRef = useRef(null);
    const profileMenuRef = useRef(null);
    const profilePicRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const modalContentRef = useRef(null);
    const [hasSettings, setHasSettings] = useState(false);
    const [userPhoto, setUserPhoto] = useState('');
    const [isPremium, setIsPremium] = useState(false);
    const [currentOption, setCurrentOption] = useState('bonusClubLink');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const selectedFont = useFont();
    const database = getDatabase();
    const [databaseName, setDatabaseName] = useState('');
    const priceId = 'price_1PMNWtEK7wspITU4nz2nByIH'; // Definiere hier deine Stripe Price ID

    const handleOptionChange = (option) => {
        setCurrentOption(option);
    };

    useEffect(() => {
        if (auth.currentUser) {
            setUserEmail(auth.currentUser.email || 'No Email');
        }
    }, [auth.currentUser]);

    useEffect(() => {
        if (userId) {
            const userNameRef = ref(database, `Users/${userId}/userName`);
            onValue(userNameRef, (snapshot) => {
                if (snapshot.exists()) {
                    setDatabaseName(snapshot.val());
                }
            });
        }
    }, [userId, database]);

    useEffect(() => {
        if (databaseName) {
            setUserName(databaseName);
        } else if (auth.currentUser && auth.currentUser.displayName) {
            setUserName(auth.currentUser.displayName);
        } else {
            setUserName('No Name');
        }
    }, [databaseName, auth.currentUser]);

    const getUrl = () => {
        return currentOption === 'bonusClubLink'
            ? `bonusclub.link/survey/${userId}`
            : `bonusclub.link/news/${userId}`;
    };

    const handleSignOut = () => {
        signOut(auth).then(() => {
            console.log("Erfolgreich abgemeldet!");
        }).catch((error) => {
            console.error("Fehler beim Abmelden:", error);
        });
    };

    const toggleProfileMenu = () => {
        setShowProfileMenu(prev => !prev);
    };

    useEffect(() => {
        const checkClickOutside = (e) => {
            if (showProfileMenu && profileMenuRef.current && !profileMenuRef.current.contains(e.target) && !profilePicRef.current.contains(e.target)) {
                toggleProfileMenu();
            }
        };

        document.addEventListener("mousedown", checkClickOutside);

        return () => {
            document.removeEventListener("mousedown", checkClickOutside);
        };
    }, [showProfileMenu]);

    const handleModalOpen = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if (userId) {
            const userPhotoRef = ref(database, `Users/${userId}/userPhoto`);
            onValue(userPhotoRef, (snapshot) => {
                if (snapshot.exists()) {
                    setUserPhoto(snapshot.val());
                } else {
                    setUserPhoto('');
                }
            });
        }
    }, [userId, database]);

    useEffect(() => {
        const checkClickOutside = (e) => {
            if (showModal && modalContentRef.current && !modalContentRef.current.contains(e.target)) {
                handleModalClose();
            }
        };
        document.addEventListener("mousedown", checkClickOutside);

        return () => {
            document.removeEventListener("mousedown", checkClickOutside);
        };
    }, [showModal]);

    useEffect(() => {
        if (userId) {
            const userSettingsRef = ref(database, `Users/${userId}/hasSettings`);
            const listener = onValue(userSettingsRef, snapshot => {
                if (snapshot.exists()) {
                    setHasSettings(snapshot.val());
                }
            });
            return () => {
                off(userSettingsRef, 'value', listener);
            };
        }
    }, [userId]);

    useEffect(() => {
        if (userId) {
            const userAgencyRef = ref(database, `Users/${userId}/hasAgency`);
            const agencyListener = onValue(userAgencyRef, snapshot => {
                if (snapshot.exists()) {
                    setHasAgency(snapshot.val());
                } else {
                    setHasAgency(false);
                }
            });
            return () => {
                off(userAgencyRef, 'value', agencyListener);
            };
        }
    }, [userId]);

    const getButtonClass = (option) => {
        return currentOption === option ? 'button-active' : 'button-inactive';
    };

    useEffect(() => {
        if (userId) {
            const userPremiumRef = ref(database, `Users/${userId}/isPremium`);
            onValue(userPremiumRef, (snapshot) => {
                if (snapshot.exists()) {
                    setIsPremium(snapshot.val());
                } else {
                    setIsPremium(false);
                }
            });
        }
    }, [userId, database]);

    useEffect(() => {
        if (auth.currentUser) {
            setUserName(auth.currentUser.displayName || 'No Name');
            setUserEmail(auth.currentUser.email || 'No Email');
        }
    }, [auth.currentUser]);

    return (
        <div className="dashboard-header">
            <div className="logo-and-menu">
                <NavLink to="/" style={{ textDecoration: 'none'}}>
                    <div  style={{ fontFamily: selectedFont }} className="header_logo_1"> <img src="./img/BonusClub_Logo.webp" alt="" /></div>
                </NavLink>
            </div>
            <div className="dashboard_header_links">
                <NavLink 
                    to={`/`} 
                    className="menu-item" 
                    className={({ isActive }) => 
                    isActive ? "menu-item selector" : "menu-item"
                }
                >
                    <div>
                        <img className="invisible_icon" src="./img/eye.png" alt="" />
                        <p>Guest Pages</p> 
                    </div>
                </NavLink> 
                <NavLink 
                    to={`/social`} 
                    className="menu-item" 
                    className={({ isActive }) => 
                    isActive ? "menu-item selector" : "menu-item"
                }>
                    <div>
                        <img className="invisible_icon" src="./img/launch.svg" alt="" />
                        <p>Review Boost</p> 
                    </div>
                </NavLink> 
                <NavLink 
                    to={`/agency`} 
                    className={({ isActive }) => 
                        isActive ? "menu-item selector" : "menu-item"
                    }
                >
                    <div style={{ position: 'relative' }}>
                        <img className="invisible_icon" src="./img/brain.svg" alt="" />
                        <p className={hasUnreadMessages ? "unread-notification" : "no-unread-messages"}>1</p>
                        <p>Agency</p> 
                    </div>
                </NavLink>
        </div>

            <div className="profile-section">
                
                <div className="profile-section-wrapper" onClick={toggleProfileMenu}>
                <img 
                  src="./img/menu_1.svg" 
                  alt="Profilbild" 
                  className={`profile-pic-2 ${showProfileMenu ? 'profile-pic-rotate' : ''}`}
                  ref={profilePicRef} 
                />
                 <img 
                      src={userPhoto || "./img/user-three.svg"} // Verwenden des Benutzerfotos oder des Avatar-Bildes
                      alt="Profilbild" 
                      className="profile-pic"
                      ref={profilePicRef} 
                    />
               {showProfileMenu && (
    <div className="profile-menu" ref={profileMenuRef}>
        <div className="profile-menu-info">
           <div className="profile-menu-item-2" style={{color: 'black'}}>{userName}</div>
                    <div className="profile-menu-item-3" style={{color: 'black'}}>{userEmail}</div>
                    </div>
                    {hasAgency && (
         <div className="question-right-pro-6" style={{right: '135px', top: '8px'}}>
         <img className="crown" src="./img/diamond.svg" alt="Pro"/>
         <p>CLUB</p>
     </div>
        )}

        {/* Überprüfung, ob der Benutzer Pro ist und KEINE Agency */}
        {isPremium && !hasAgency && (
            <div className="question-right-pro-6" style={{right: '135px', top: '8px'}}>
                <img className="crown" src="./img/crow.svg" alt="Pro"/>
                <p>PRO</p>
            </div>
        )}
            
                                        <div className="dashboard-menu-slice">
<img src="./img/edit.svg" alt= "link settings" />
<NavLink to="/user-settings">Edit Profile</NavLink>

</div>

                                        <div className="dashboard-menu-slice" style={{marginTop: '-5px'}}>
<img src="./img/qr.svg" alt= "link settings" />
<NavLink to="/" onClick={handleModalOpen}>QR Codes</NavLink>

</div>


{/* <div className="dashboard-menu-slice">
<img src="./img/template.svg" alt= "link settings" />
<NavLink to="/emails">E-Mail Templates</NavLink>
</div> */}

<div className="dashboard-menu-slice" style={{marginTop: '-5px'}}>
<img src="./img/mailbox.svg" alt= "link settings" />
<a href="https://bonusclub.io" target="_blank">Contact Us</a>
</div>



<NavLink className="sign_out" to="/login" onClick={handleSignOut}>Sign Out</NavLink>
                    </div>
                )}
            </div>
            </div>
            {showModal && (
    <div className="modal">
        <div className="modal-content" ref={modalContentRef}>
            <div className="modal-switch">
                <button
                    onClick={() => setCurrentOption('bonusClubLink')}
                    className={getButtonClass('bonusClubLink')}
                >
                    Bonus Club
                </button>
                <button
                    onClick={() => setCurrentOption('newsletterWizard')}
                    className={getButtonClass('newsletterWizard')}
                >
                    Newsletter Wizard
                </button>
            </div>

            {currentOption === 'bonusClubLink' && (
                <>
                    <h2>Your QR Code</h2>
                    <QRCode className="modal-qr-code" value={getUrl()} ref={qrRef} />
                    <div className="modal-link-container">
                        <input type="text" value={getUrl()} readOnly />
                        <button onClick={() => navigator.clipboard.writeText(getUrl())}>
                            <img className="copy-link-img" src="./img/link.svg" alt="copy"/>
                        </button>
                    </div>
                       <div className="qr-buttons">
            <NavLink 
    to={`/survey/${userId}`}  // Sie können die URL hier ändern.
    className="menu-item" 
    activeClassName="selected"
    target="_blank"  // Dies sorgt dafür, dass der Link in einem neuen Fenster geöffnet wird.
    rel="noopener noreferrer"  // Das ist aus Sicherheitsgründen zu empfehlen.
    className="qr-modal-open"
>
    Open Club Link
</NavLink>
                <button className="qr-modal-close" onClick={handleModalClose}>Close</button>
            </div>
                </>
            )}

            {currentOption === 'newsletterWizard' && (
                <>
                    <h2>Your QR Code</h2>
                    {hasAgency ? (
                        <>
                            <QRCode className="modal-qr-code" value={getUrl()} ref={qrRef} />
                            <div className="modal-link-container">
                                <input type="text" value={getUrl()} readOnly />
                                <button onClick={() => navigator.clipboard.writeText(getUrl())}>
                                    <img className="copy-link-img" src="./img/link.svg" alt="copy"/>
                                </button>
                            </div>
                            <div className="qr-buttons">
            <NavLink 
    to={`/news/${userId}`}  // Sie können die URL hier ändern.
    className="menu-item" 
    activeClassName="selected"
    target="_blank"  // Dies sorgt dafür, dass der Link in einem neuen Fenster geöffnet wird.
    rel="noopener noreferrer"  // Das ist aus Sicherheitsgründen zu empfehlen.
    className="qr-modal-open"
>
    Open Newsletter Link
</NavLink>
                <button className="qr-modal-close" onClick={handleModalClose}>Close</button>
            </div>
                        </>
                    ) : (
                        <div className="modal-qr-block">
                        <div className="modal-qr-code-3">
                        <div className="question-container-2">
             <div className="question-unlock-pro-container">
                 <div className="question-right-pro-6">
                 <img className="crown" src="./img/diamond.svg" alt="lock"/>
                 <p>AGENCY</p>
                 </div>
            <div className="question-lock-div">
            <img className="padlock" src="./img/lock.svg" alt="lock"/>
            </div>
            <h2>Unlock <span>Agency</span> to access</h2>
            </div>
        </div>
                        </div>
                        <div className="modal-link-container">
                                <input type="text" value="" readOnly />
                             
                            </div>
                        </div>
                      
                        
                    )}
                    
                </>
            )}

         
        </div>
    </div>
)}


        </div>
    );
              }    
export default DashboardHeader;
