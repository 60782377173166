import React, { useState, useEffect } from 'react';
import './BulkDelete.css';

function BulkDelete({ marketingEntries, onBulkDelete }) {
    const [showModal, setShowModal] = useState(false);
    const [importNames, setImportNames] = useState([]);
    const [selectedImportName, setSelectedImportName] = useState("");

    useEffect(() => {
        const uniqueImportNames = [...new Set(marketingEntries.map(entry => entry.importName))];
        setImportNames(uniqueImportNames);
    }, [marketingEntries]);

    const handleBulkDelete = () => {
        onBulkDelete(selectedImportName);
        setShowModal(false);
    };

    return (
        <>
            <button className="bulk-delete-button" onClick={() => setShowModal(true)}>Bulk Delete
            <img style={{width: '18px', filter: 'invert(1)', marginRight: '-15px', marginLeft: '10px'}} src="./img/delete.svg" alt="" />
            </button>
            {showModal && (
                <div className="bulk-delete-modal">
                    <div className="bulk-delete-modal-content">
                        <h2>Bulk Delete</h2>
                        <select
                            value={selectedImportName}
                            onChange={(e) => setSelectedImportName(e.target.value)}
                        >
                            <option value="">Select Import Name</option>
                            {importNames.map(name => (
                                <option key={name} value={name}>{name}</option>
                            ))}
                        </select>
                        <div className="bulk-delete-modal-buttons">
                            <button onClick={handleBulkDelete}>Delete</button>
                            <button style={{backgroundColor: 'gray'}} onClick={() => setShowModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default BulkDelete;
