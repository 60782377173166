import React, { useRef, useEffect } from 'react';
import './DBSearch.css';

function DBSearch({ searchTerm, setSearchTerm, startDate, setStartDate, endDate, setEndDate }) {

    const startRef = useRef(null);
    const endRef = useRef(null);
    const realInputRef = useRef(null);

    useEffect(() => {
        realInputRef.current = document.createElement('input');
        realInputRef.current.type = 'date';
        realInputRef.current.style.display = 'none';
        document.body.appendChild(realInputRef.current);

        return () => {
            if (realInputRef.current) {
                document.body.removeChild(realInputRef.current);
            }
        };
    }, []);

    const handleDateClick = (setDate) => {
        if (realInputRef.current) {
            realInputRef.current.click();
            realInputRef.current.addEventListener('change', () => {
                setDate(realInputRef.current.value);
            });
        }
    };

    return (
        <div className="db-search-container">
            <div className="fake-search-bar">
                <img src="./img/search.svg" alt="search" />
                <input 
                    type="text" 
                    placeholder="Search..." 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="db-search"
                />
            </div>
            
            <div className="db-date-container" onClick={() => handleDateClick(setStartDate)}>
                <img src="./img/calendar1.svg" alt="custom-date-icon" className="custom-date-icon" />
                <input 
    type="date" 
    value={startDate || ''}
    onChange={(e) => setStartDate(e.target.value)}
    placeholder="Startdatum"
    className="db-date"
    onClick={(e) => e.target.focus()}
/>
            </div>

            <div className="db-date-container" onClick={() => handleDateClick(setEndDate)}>
                <img src="./img/calendar1.svg" alt="custom-date-icon" className="custom-date-icon" />
                <input 
                    type="date" 
                    value={endDate || ''}
                    onChange={(e) => setStartDate(e.target.value)}
                    placeholder="Enddatum"
                    className="db-date"
                    onClick={(e) => e.target.focus()}
                />
            </div>
        </div>
    );
}

export default DBSearch;
