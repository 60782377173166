import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, onValue, update } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import './AddManager.css';

function AddManager() {
    const [users, setUsers] = useState({});
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedManager, setSelectedManager] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const db = getDatabase();
    const auth = getAuth();
    const currentUser = auth.currentUser;

    useEffect(() => {
        if (!currentUser) return; // Exit if no user is logged in.

        const usersRef = dbRef(db, 'Users');
        const unsubscribe = onValue(usersRef, (snapshot) => {
            const usersData = snapshot.val();
            if (usersData) {
                setUsers(usersData);
            } else {
                setUsers({});
            }
        });

        return () => unsubscribe();
    }, [db, currentUser]);

    const handleUserChange = (e) => {
        setSelectedUser(e.target.value);
    };

    const handleManagerChange = (e) => {
        setSelectedManager(e.target.value);
    };

    const handleAssignManager = () => {
        if (!selectedUser || !selectedManager) {
            setStatusMessage('Please select both a user and a manager.');
            return;
        }

        const userRef = dbRef(db, `Users/${selectedUser}`);
        update(userRef, { agencyManager: selectedManager })
            .then(() => {
                setStatusMessage('Agency manager assigned successfully.');
            })
            .catch((error) => {
                console.error('Error assigning agency manager:', error);
                setStatusMessage('Failed to assign agency manager.');
            });
    };

    return (
        <div className="add-manager-container">
            <h2>Assign Agency Manager</h2>
            <div className="select-container">
                <label htmlFor="select-user">Select User:</label>
                <select id="select-user" value={selectedUser} onChange={handleUserChange}>
                    <option value="">Select a user</option>
                    {Object.entries(users).map(([userId, userData]) => (
                        <option key={userId} value={userId}>
                            {userData.email}
                        </option>
                    ))}
                </select>
            </div>
            <div className="select-container">
                <label htmlFor="select-manager">Select Manager:</label>
                <select id="select-manager" value={selectedManager} onChange={handleManagerChange}>
                    <option value="">Select a manager</option>
                    {Object.entries(users).map(([userId, userData]) => (
                        <option key={userId} value={userId}>
                            {userData.email}
                        </option>
                    ))}
                </select>
            </div>
            <button onClick={handleAssignManager}>Assign Manager</button>
            {statusMessage && <p>{statusMessage}</p>}
        </div>
    );
}

export default AddManager;
