import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ref, onValue, off, getDatabase } from 'firebase/database';
import GenerativeOrbAnimation from '../Pages/GenerativeOrbAnimation';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function QRStatusPage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState('');

  const query = useQuery();
  const surveyIdFromQuery = query.get("surveyId");
  const userId = query.get("userId");
  const surveyId = surveyIdFromQuery;

  useEffect(() => {
    if (!surveyId || !userId) {
        setError('Keine surveyId oder userId angegeben');
        setLoading(false);
        return;
    }

    const db = getDatabase();
    const qrCodeRef = ref(db, `surveys/${userId}/${surveyId}/qrCodeDataUrl`);

    const listener = onValue(qrCodeRef, (snapshot) => {
        if (snapshot.exists()) {
            setQrCodeDataUrl(snapshot.val());
            setLoading(false);
        } else {
            setError('QR-Code existiert nicht');
            setLoading(false);
        }
    }, (errorObject) => {
        setError(errorObject.message);
        setLoading(false);
    });

    return () => {
        off(qrCodeRef, listener);
    };
  }, [surveyId, userId]);

  return (
      <div>
                                              <GenerativeOrbAnimation />

    <div className="container_container">
        <div className="container" style={{minHeight: '300px', maxWidth: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {loading ? (
                <h1><span>Loading...</span></h1>
            ) : error ? (
                <div className="error-message">{error}</div>
            ) : (
                <>
                <div  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', zIndex: '1000'}}>
                    <div className="qr-message">
                        <p style={{fontSize: '24px'}}>Here is your Bonus.</p>
                    </div>
                    <div className="qr-code-container">
                        {qrCodeDataUrl && <img src={qrCodeDataUrl} alt="QR Code" />}
                    </div>
                    </div>
                </>
            )}
        </div>
    </div>     
     </div>

  );
}

export default QRStatusPage;
