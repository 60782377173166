import React from 'react';

const ColorVault = ({ colors, onColorChange }) => {
    // Überprüfung, ob colors definiert ist und ein Objekt ist
    const isColorsDefined = colors && typeof colors === 'object';

    return (
        <div className="color-picker-flex-container" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '00px', marginLeft: '10px', marginRight: '10px' }}>
         {isColorsDefined && Object.entries(colors).map(([colorKey, colorValue], index) => (
                <div key={colorKey} className="input-group">
                    <label style={{fontWeight: '500'}} htmlFor={colorKey}>{`Color ${index + 1}:`}</label>
                    <div className="colorPicker">
                        <input 
                            id={colorKey}
                            type="color" 
                            name={colorKey}
                            value={colorValue}
                            onChange={(e) => onColorChange(colorKey, e.target.value)}
                            className="color-input"
                        />
                        <div 
                            className="custom-color-display-2" 
                            style={{ backgroundColor: colorValue, border: 'none', width: '70px', height: '70px' }}
                        ></div>
                        <img className="color-picker-icon" src="/img/text.svg" alt="" style={{right: '29%', top: '29%'}}/>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ColorVault;
