import React, { useState } from "react";
import './SurveyOne.css';
import { app, database, storage } from './firebaseConfig';
import { ref as dbRef, set, push } from "firebase/database";
import { uploadBytesResumable, getDownloadURL, ref as storageRef } from "firebase/storage";
import { useDropzone } from 'react-dropzone';



const SurveyOne = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedBox, setSelectedBox] = useState(null);
    const [inputs, setInputs] = useState({
        questionOne: "",
        questionTwo: "",
        questionThree: "",
        textInputs: ["", "", "", ""],
        termsOne: false,
        termsTwo: false,
        downloadURLs: [],
        uploadedImages: []  // Hinzufügen dieser Zeile
    });
    const [uploadedFiles, setUploadedFiles] = useState([]);
  
    const handleNext = () => {
      if (currentStep === 3) {  // Step, in dem die Datei ausgewählt wird
        handleUpload();
      } else {
        setCurrentStep(prevStep => prevStep + 1);
      }
    };

    const [isLoading, setIsLoading] = useState(false);

    const removeFile = (fileToRemove) => {
        setUploadedFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
    }
  
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if(acceptedFiles.length <= 5) {  // Hier können Sie die maximale Anzahl der Dateien bestimmen
                setUploadedFiles([...uploadedFiles, ...acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))]);
            } else {
                console.error("Sie können maximal 5 Dateien hochladen.");
            }
        }
    });

    const handleBack = () => setCurrentStep(prevStep => prevStep - 1);
  
 
    const handleUpload = async () => {
        if (uploadedFiles.length) {
            setIsLoading(true);  // Start der Ladeanimation
            const downloadURLs = [];
    
            const uploads = uploadedFiles.map(file => {
                return new Promise(async (resolve, reject) => {
                    const storageReference = storageRef(storage, 'image-uploads/' + file.name);
                    const uploadTask = uploadBytesResumable(storageReference, file);
    
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done for ' + file.name);
                        },
                        (error) => {
                            console.error("Error uploading file:", error);
                            reject(error);
                        },
                        async () => {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            console.log('File available at', downloadURL);
                            downloadURLs.push(downloadURL);
                            resolve();
                        }
                    );
                });
            });
    
            Promise.all(uploads)
                .then(() => {
                    setInputs(prev => ({ ...prev, downloadURLs }));
                    setCurrentStep(prevStep => prevStep + 1);
                    setIsLoading(false);  // Beenden der Ladeanimation
                })
                .catch(() => {
                    setIsLoading(false);  // Beenden der Ladeanimation im Fehlerfall
                });
        }
    };

    const handleSubmission = () => {
      const surveyReference = dbRef(database, 'surveys');
      const newSurveyRef = push(surveyReference);
  
      set(newSurveyRef, inputs)
        .then(() => {
          setCurrentStep(5);
        })
        .catch(error => {
          console.error("Daten konnten nicht gesendet werden: ", error);
        });
    };
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    };
  
  return (
    <div>
      {currentStep === 1 && (
        <div>
          <div
            className={selectedBox === "bonus_one" ? "selectedBox" : "box"}
            onClick={() => setSelectedBox("bonus_one")}
          >
            Bonus One
          </div>
          <div
            className={selectedBox === "bonus_two" ? "selectedBox" : "box"}
            onClick={() => setSelectedBox("bonus_two")}
          >
            Bonus Two
          </div>
          <button onClick={handleNext}>Next</button>
        </div>
      )}
      
      {currentStep === 2 && (
        <div>
          <select name="questionOne" onChange={handleInputChange}>
            <option value="">Select...</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
          </select>
          <select name="questionTwo" onChange={handleInputChange}>
            <option value="">Select...</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
          </select>
          <select name="questionThree" onChange={handleInputChange}>
            <option value="">Select...</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
          </select>
          <button onClick={handleBack}>Back</button>
          <button onClick={handleNext}>Next</button>
        </div>
      )}
      
      {currentStep === 3 && (
  <div>
  <div {...getRootProps()} style={{ border: '1px solid gray', padding: '20px', textAlign: 'center' }}>
      <input type="file" multiple onChange={(e) => {
          const filesArray = Array.from(e.target.files);
          setUploadedFiles(prevState => [...prevState, ...filesArray]);
      }} />
      <p>Ziehen Sie Ihre Dateien hierher oder klicken Sie zum Durchsuchen</p>
  </div>

  <aside>
      <h4>Dateien</h4>
      <ul>
          {uploadedFiles.map((file, index) => (
              <li key={file.name}>
                  <div>
                      <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} style={{ height: '100px' }} />
                  </div>
                  {file.name} - {file.size} bytes
                  <button onClick={() => removeFile(file)}>Entfernen</button>
              </li>
          ))}
      </ul>
  </aside>

  <button onClick={handleBack}>Back</button>
  <button onClick={handleNext} disabled={uploadedFiles.length === 0 && currentStep === 3}>Next</button>
  <div>
      {isLoading && (
          <div className="loading-container">
              <div className="spinner"></div>
              Bitte warten, Ihre Dateien werden hochgeladen...
          </div>
      )}
  </div>
</div>
 
      )}

      {currentStep === 4 && (
        <div>
          {Array(4).fill().map((_, idx) => (
            <input 
              key={idx}
              type="text" 
              onChange={(e) => {
                const newTextInputs = [...inputs.textInputs];
                newTextInputs[idx] = e.target.value;
                setInputs({ ...inputs, textInputs: newTextInputs });
              }}
              value={inputs.textInputs[idx]}
            />
          ))}
          <div>
            <input type="checkbox" name="termsOne" onChange={handleInputChange} />
            Accept terms of use 1
          </div>
          <div>
            <input type="checkbox" name="termsTwo" onChange={handleInputChange} />
            Accept terms of use 2
          </div>
          <button onClick={handleBack}>Back</button>
          <button onClick={handleSubmission}>Submit</button>
        </div>
        
      )}

        {currentStep === 5 && (
            <div>
                <h2>Vielen Dank!</h2>
                <p>Wir haben Ihre Antworten erhalten.</p>
            </div>
        )}
    </div>
  );
};

export default SurveyOne;


