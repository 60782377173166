import React from 'react';
import './MarketingDatabaseHeader.css';

const MarketingDatabaseHeader = ({ entryCount }) => {
    return (
        <div className="marketing-header">
            <img style={{width: '50px', marginBottom: '10px'}} src="./img/BonusClub_Logo.webp" alt="" />
            <h1>Sales Database</h1>
            <p>{entryCount} Hotels</p>
        </div>
    );
};

export default MarketingDatabaseHeader;
