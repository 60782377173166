import './FancyBullet.css'; 
import './WidgetStyle.css'; 
import { useFont } from '../../Design/FontContext';
import React, { useState } from 'react';
import DeleteModal from './DeleteModal';

function appendPx(value) {
    if (typeof value === 'string' && isNaN(value)) {
        return value;
    }
    return `${value}px`;
}

function FancyBullet({ data, onRemove, isSelected, isEditMode, inverted }) {
    const defaultImg = '/img/icons/handdrawn/drink.svg';
    const deleteLogo = '/img/delete.svg';

    const fonts = useFont();

    function isDark(color) {
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;
        const luma = 0.299 * r + 0.587 * g + 0.114 * b;
        return luma < 128;
    }

    function getBorderStyle() {
        if (isEditMode) {
            if (isSelected) {
                const borderColor = isDark(data.backgroundColor) ? '#FFFFFF' : '#000000';
                return `2px dashed ${borderColor}`;
            } else {
                return data.border ? `1.5px solid ${data.borderColor}` : '2px dashed transparent';
            }
        } else {
            return data.border ? `1.5px solid ${data.borderColor}` : 'none';
        }
    }

    const widgetStyle = {
        backgroundColor: data.backgroundColor,
        marginBottom: appendPx(data.marginBottom),
        marginTop: appendPx(data.marginTop),
        height: appendPx(data.height),
        border: getBorderStyle(),
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (event) => {
        onRemove(event, data.id);
        setShowDeleteModal(false);
    };

    return (
        <div className={`fancy_bullet ${data.headerStyle}`} style={widgetStyle}>
            <div className="icon_border" style={{ borderColor: data.borderColor }}>
                <img 
                    src={data.img || defaultImg} 
                    className={`fancy_bullet_img ${inverted ? 'inverted' : ''}`}
                    alt="Img" 
                    style={{
                        width: appendPx(data.logoWidth),
                        filter: data.inverted ? 'invert(100%)' : 'none',
                    }} 
                />
            </div>
            <div>
                {isEditMode && (
                    <button className="delete_button" onClick={() => setShowDeleteModal(true)}>
                        <img className="delete_logo" src={deleteLogo} alt="Löschen" />
                    </button>
                )}
                {isEditMode && showDeleteModal && (
                    <DeleteModal
                        isOpen={showDeleteModal}
                        onClose={() => setShowDeleteModal(false)}
                        onConfirm={(event) => handleDelete(event, data.id)}
                    />
                )}
            </div>
            <h2 style={{
                color: data.textColor1,
                fontSize: appendPx(data.text1Size),
                fontWeight: data.text1Weight,
                fontFamily: fonts.globalSmallFont,
            }}>
                {data.text1}
                <hr className="fancy-underline"/>
            </h2>
            <p style={{
                color: data.textColor2,
                fontSize: appendPx(data.text2Size),
                fontWeight: data.text2Weight,
                marginBottom: data.marginBottom,
                fontFamily: fonts.globalSmallFont,
            }}>
                {data.text2}
            </p>
        </div>
    );
}

export default FancyBullet;
