import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import './PackageSelectionModal.css';

const stripePromise = loadStripe('pk_live_51JtPI5EK7wspITU4metLteANz6fXOGY4vIvFtRdJYYg3oYt0W9M0mipHroPArKiCQPaAeU7sZvgH17wU0FMYN69200bLf83Oq7');

const PackageSelectionModal = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [priceId, setPriceId] = useState('price_1PiqKLEK7wspITU44LOLwzb2'); // Default to 2 years
  const [totalPrice, setTotalPrice] = useState(499); // Default price

  const handleCheckout = async () => {
    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      console.log(`Current user ID: ${user.uid}`);
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
      
      try {
        const { data } = await createCheckoutSession({ userId: user.uid, priceId });
        console.log('Checkout session created:', data);
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId: data.sessionId });

        if (error) {
          console.error("Stripe checkout error", error);
        }
      } catch (error) {
        console.error("Checkout session error", error);
      }
    } else {
      console.error("User is not logged in");
    }

    setLoading(false);
    onClose(); // Close the modal after initiating checkout
  };

  const handlePriceSelection = (id, price) => {
    setPriceId(id);
    setTotalPrice(price);
  };

  const handleOverlayClick = (e) => {
    if (e.target.className.includes('package-selection-modal-overlay')) {
      onClose();
    }
  };

  const features = [
    "Up to 10 guest pages",
    "QR codes & custom links",
    "Pro blocks and templates",
    "No hidden fees"
  ];

  if (priceId === 'price_1PiqKLEK7wspITU44LOLwzb2') {
    features.push(
      <span key="special">
        Our team will design your page
        <span className="special-badge">BONUS</span>
      </span>
    );
  }

  if (!isOpen) return null;

  return (
    <div className="package-selection-modal-overlay" onClick={handleOverlayClick}>
      <div className="package-selection-modal-content">
        <h2>Select Your Package</h2>
        <div className="package-selection-modal-price-options">
          <div
            className={`package-option ${priceId === 'price_1PiqJoEK7wspITU4d21nGDWF' ? 'selected' : ''}`}
            onClick={() => handlePriceSelection('price_1PiqJoEK7wspITU4d21nGDWF', 39)}
          >
            <input
              type="radio"
              name="price"
              checked={priceId === 'price_1PiqJoEK7wspITU4d21nGDWF'}
              onChange={() => handlePriceSelection('price_1PiqJoEK7wspITU4d21nGDWF', 39)}
            />
            <div>
              <h4>Monthly</h4>
              <span>$39/month</span>
            </div>
            <img src="./img/check.svg" alt="Check" />
          </div>
          <div
            className={`package-option ${priceId === 'price_1PiqKLEK7wspITU44LOLwzb2' ? 'selected' : ''}`}
            onClick={() => handlePriceSelection('price_1PiqKLEK7wspITU44LOLwzb2', 599)}
          >
            <input
              type="radio"
              name="price"
              checked={priceId === 'price_1PiqKLEK7wspITU44LOLwzb2'}
              onChange={() => handlePriceSelection('price_1PiqKLEK7wspITU44LOLwzb2', 599)}
            />
            <div className="special-price-option">
              <div className="two-years-option">
                <h4>2 Years </h4>
                <span className="save-banner">Save 36%</span>
              </div>
              <span>$25/month</span>
            </div>
            <img src="./img/check.svg" alt="Check" />
          </div>
        </div>
        <div className="package-features">
          {features.map((feature, index) => (
            <div key={index}><img src="./img/check_1.svg" alt="Check" /> {feature}</div>
          ))}
        </div>
        <hr className="package-price-hr" />
        <div className="package-total">
          <span>Total</span>
          <span>${totalPrice.toFixed(2)}</span>
        </div>
        <button onClick={handleCheckout} className="package-selection-modal-checkout-button">
          {loading ? <div className="package-selection-modal-button-spinner"></div> : 'Go To Checkout'}
        </button>
        <div className="package-secure-payment">
          <img src="./img/stripe_3.webp" alt="Check" />
        </div>
      </div>
    </div>
  );
};

export default PackageSelectionModal;
