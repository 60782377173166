import './IconFeature.css'; 
import './WidgetStyle.css'; 
import { useFont } from '../../Design/FontContext';
import React, { useState } from 'react';
import DeleteModal from './DeleteModal';


function appendPx(value) {
    if (typeof value === 'string' && isNaN(value)) {
        return value;
    }
    return `${value}px`;
}

function ImprintBar({ data, onRemove, isSelected, isEditMode }) {
    const defaultImg = '/img/yoga.png'; // Pfad zum Standard-Bild anpassen
    const defaultIcon = '/img/right.svg'; // Pfad zum Standard-Bild anpassen
    const deleteLogo = '/img/delete.svg'; // Pfad zum Standard-Löschbild anpassen
    const widgetStyle = {
        backgroundColor: data.backgroundColor,
        marginBottom: appendPx(data.marginBottom),
        marginTop: appendPx(data.marginTop),
        border: getBorderStyle(),
        width: '100%', 

    };

    const fonts = useFont(); 
   
    function isDark(color) {
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;
        const luma = 0.299 * r + 0.587 * g + 0.114 * b;
        return luma < 128;
    }

    function getBorderStyle() {
        if (isEditMode) {
            // Im Editiermodus
            if (isSelected) {
                // Wenn das Widget ausgewählt ist
                const borderColor = isDark(data.backgroundColor) ? '#FFFFFF' : '#000000';
                return `2px dashed ${borderColor}`;
            } else {
                // Wenn das Widget nicht ausgewählt ist
                return data.border ? `1.5px solid ${data.borderColor}` : '2px dashed transparent';
            }
        } else {
            // Im Live-Modus
            return data.border ? `1.5px solid ${data.borderColor}` : 'none';
        }
    }
    
    const widgetHref1 = isEditMode ? null : data.imprintLink;
    const widgetHref2 = isEditMode ? null : data.termsLink;

    const [showDeleteModal, setShowDeleteModal] = useState(false);


    const handleDelete = (event) => {
        // Logik zum Löschen des Objekts
        onRemove(event, data.id);
        setShowDeleteModal(false);
    };


    return (
        <div   className="icon_feature_8" style={{
            textDecoration: 'none',
            backgroundColor: data.backgroundColor,
            marginBottom: appendPx(data.marginBottom),
            marginTop: appendPx(data.marginTop),
            boxShadow: data.shadow ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : 'none',
            border: data.border ? `1.5px solid ${data.borderColor}` : 'none', // Bedingter Rand mit Farbe
            borderRadius: appendPx(data.borderRadius),
            ...widgetStyle // Fügen Sie den widgetStyle hier ein
        }}>
              <div>
    {isEditMode && (
        <button className="delete_button" onClick={() => setShowDeleteModal(true)} 

>
            <img className="delete_logo" src={deleteLogo} alt="Löschen"  />
        </button>
    )}
    {isEditMode && showDeleteModal && (
        <DeleteModal
            isOpen={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onConfirm={(event) => handleDelete(event, data.id)}
        />
    )}
</div>
<div className="icon-button-imprint-wrapper">
            <div className="icon-button-text-beginning">
                <h5 
                id="button_h5_text"
                style={{
                    color: data.textColor1,
                    fontSize: appendPx(data.text1Size),
                    fontWeight: data.text1Weight,
                    fontFamily: fonts.globalSmallFont, 
                    marginLeft: '10px',
                }}>
                    {data.text1}
                </h5>
            
            </div>
            <div className="imprint-bottom-components">

            <div className="icon-button-text-4">
                <a href={widgetHref1} target="_blank" style={{textDecoration: 'none'}}>
                <h5 
                id="button_h5_text"
                style={{
                    color: data.textColor2,
                    fontSize: appendPx(data.text2Size),
                    fontWeight: data.text2Weight,
                    fontFamily: fonts.globalSmallFont, 
                }}>
                    {data.text2}
                </h5>
                </a>
            </div>
            <div className="icon-button-text-4">
            <a href={widgetHref1} target="_blank" style={{textDecoration: 'none', color: data.textColor2}}>
                <h5 
                id="button_h5_text"
                style={{
                    color: data.textColor2,
                    fontSize: appendPx(data.text2Size),
                    fontWeight: data.text2Weight,
                    fontFamily: fonts.globalSmallFont, 
                    marginLeft: '15px',
                    marginRight: '10px',
                }}>
                    {data.text3}
                </h5>
                </a>
                </div>

            </div>        
                </div>

            <div>
          
            </div>
        </div>
    );
}

export default ImprintBar;
