import React, { useState, useEffect } from 'react';
import './PageWizard.css';

const HotelInfoDisplay = ({ initialHotelInfo, handleToggleWidgetType, toggleHighlightSelection, selectedHighlights, updateHotelName }) => {
    const [hotelInfo, setHotelInfo] = useState(initialHotelInfo || {
        hotelName: '',
        hotelAddress: '',
        summary: '',
        highlights: [],
        imageUrl: ''
    });

    useEffect(() => {
        if (initialHotelInfo) {
            setHotelInfo(initialHotelInfo);
        }
    }, [initialHotelInfo]);

    const handleWidgetToggle = (index, widgetType) => {
        const currentWidgetType = hotelInfo.highlights[index].widgetType;
        const newWidgetType = currentWidgetType === widgetType ? '' : widgetType;

        handleToggleWidgetType(index, newWidgetType);

        if (newWidgetType) {
            if (!selectedHighlights[index]) {
                toggleHighlightSelection(index);
            }
        } else {
            if (selectedHighlights[index]) {
                toggleHighlightSelection(index);
            }
        }
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setHotelInfo({ ...hotelInfo, hotelName: newName });
        updateHotelName(newName); // Update the hotel name in the parent component
    };

    if (!hotelInfo) {
        return <div>Loading...</div>;
    }

    return (
        <div className="hotel-info-container">
            {hotelInfo.imageUrl && (
                <div className="image-containerro">
                    <img src={hotelInfo.imageUrl} alt="Hotel" className="hotel-image-url" />
                </div>
            )}
            <input
                type="text"
                value={hotelInfo.hotelName}
                onChange={handleNameChange}
                className="hotel-name-input"
            />
            <p>{hotelInfo.hotelAddress}</p>
            <h4>Summary</h4>
            <p>{hotelInfo.summary}</p>
            <h4 style={{ marginBottom: '20px', marginTop: '20px' }}>Highlights</h4>
            <div className="highlights-container">
                {hotelInfo.highlights.map((highlight, index) => (
                    <div
                        key={index}
                        className={`highlight-box ${selectedHighlights[index] ? 'selected' : ''}`}
                    >
                        <div className="highlight-content">
                            <strong>{highlight.title}</strong>
                            <h5>{highlight.description}</h5>
                            <div className="highlight-widget-toggle">
                            <button
                                    className={`hightlight-widget-button ${highlight.widgetType === 'widget3' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleWidgetToggle(index, 'widget3');
                                    }}
                                >
                                    <img src="./img/minibar.webp" alt="" />
                                </button>
                                <button
                                    className={`hightlight-widget-button ${highlight.widgetType === 'widget2' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleWidgetToggle(index, 'widget2');
                                    }}
                                >
                                    <img src="./img/preview-2.webp" alt="" />
                                </button>
                              
                                <button
                                    className={`hightlight-widget-button ${highlight.widgetType === 'widget9' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleWidgetToggle(index, 'widget9');
                                    }}
                                >
                                    <img src="./img/sushivisit.webp" alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HotelInfoDisplay;
