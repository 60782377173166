import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './ImageGallery.css';

function ImageGallery({ images }) {
    const swiperRef = useRef(null);

    const handlePrevClick = () => {
        if (swiperRef.current && swiperRef.current.slidePrev) {
            swiperRef.current.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (swiperRef.current && swiperRef.current.slideNext) {
            swiperRef.current.slideNext();
        }
    };

    return (
        <div className="image-gallery-container">
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                loop={true}
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                }}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img src={image.url} alt={`Slide ${index}`} className="slide-image" />
                    </SwiperSlide>
                ))}
            </Swiper>
            <button onClick={handlePrevClick} className="swiper-button-prev-2">
                <img src="./img/right.svg" alt="Previous" />
            </button>
            <button onClick={handleNextClick} className="swiper-button-next-2">
                <img src="./img/right.svg" alt="Next" />
            </button>
        </div>
    );
}

export default ImageGallery;

