import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './PageNav.css';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import DomainModal from './DomainModal';
import { getDatabase, ref as dbRef, get, onValue, set } from 'firebase/database';
import GlobalSettingsModal from './GlobalSettingsModal';

function PageNav({ projectName }) {
    const location = useLocation();
    const navigate = useNavigate();
    const currentPageId = location.pathname.split('/').pop();
    const [previousPageId, setPreviousPageId] = useState(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const menuRef = useRef();
    const [isActive, setIsActive] = useState(false);
    const db = getDatabase();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const [pages, setPages] = useState([]);
    const [isDomainModalOpen, setIsDomainModalOpen] = useState(false);
    const [customDomainUrl, setCustomDomainUrl] = useState("");
    const livePageButtonClass = isActive ? "live-preview-button" : "live-preview-button inactive";
    const [isGlobalSettingsModalOpen, setIsGlobalSettingsModalOpen] = useState(false);

    const handleDomainModalOpen = () => {
        setIsDomainModalOpen(true);
    };

    const handleDomainModalClose = () => {
        setIsDomainModalOpen(false);
    };

    const handleGlobalSettingsModalOpen = () => {
        setIsGlobalSettingsModalOpen(true);
    };

    const handleGlobalSettingsModalClose = () => {
        setIsGlobalSettingsModalOpen(false);
    };

    const handleDomainSubmit = (domainName) => {
        if (!validateCustomDomain(domainName)) {
            return;
        }

        const saveDomainName = (domainName) => {
            if (currentUser && pages.length > 0) {
                const currentPage = pages.find(p => p.id === currentPageId);
                if (currentPage) {
                    const currentPageRef = dbRef(db, `/pages/${currentPageId}`);
                    const publicPageRef = dbRef(db, `/publicPages/${domainName}`);

                    // Speichere den Domainnamen in /publicPages
                    set(publicPageRef, { pageId: currentPageId, userId: currentUser.uid })
                        .then(() => {
                            // Aktualisiere den Domainnamen in /pages
                            return set(currentPageRef, { ...currentPage, hasDomain: true, customDomain: domainName });
                        })
                        .then(() => {
                            console.log("Domainname erfolgreich gespeichert");
                            setIsDomainModalOpen(false);
                        })
                        .catch(error => {
                            console.error("Fehler beim Speichern des Domainnamens:", error);
                        });
                }
            }
        };

        const publicPageRef = dbRef(db, `/publicPages/${domainName}`);
        get(publicPageRef).then((snapshot) => {
            if (snapshot.exists()) {
                alert("Name existiert bereits. Bitte anderen Namen eingeben.");
            } else {
                saveDomainName(domainName);
            }
        }).catch((error) => {
            console.error("Fehler beim Überprüfen des Domainnamens:", error);
        });
    };

    const toggleActiveStatus = async () => {
        const newIsActive = !isActive;

        // Hole die aktuelle Seite aus dem State
        const currentPage = pages.find(p => p.id === currentPageId);

        if (newIsActive && currentPage) { // Überprüfen beim Umschalten auf "Active"
            if (!currentPage.hasDomain && !currentPage.customDomain) { // Prüfe, ob keine Domain vorhanden ist
                handleDomainModalOpen(); // Öffne das Modal, um eine Domain hinzuzufügen
                return; // Verhindere das Aktivieren der Seite, bis der Domainname eingegeben wurde
            }
        }

        // Wenn die Seite bereits eine Domain hat oder inaktiv geschaltet wird, aktualisiere direkt den Status
        setIsActive(newIsActive);
        updatePageStatus(newIsActive); // Aktualisiere den Status in der Datenbank
    };

    const updatePageStatus = (newStatus) => {
        if (currentUser && pages.length > 0) {
            const currentPage = pages.find(p => p.id === currentPageId);
            if (currentPage) {
                const pageRef = dbRef(db, `/pages/${currentPageId}`);
                set(pageRef, { ...currentPage, isActive: newStatus })
                    .then(() => console.log("Status erfolgreich aktualisiert"))
                    .catch(error => console.error("Fehler beim Aktualisieren des Status:", error));
            }
        }
    };

    const checkDomainBeforeGoingLive = async (pageId) => {
        const page = pages.find(p => p.id === pageId);
        if (!page.hasDomain) {
            // Öffne das Modal für die Eingabe des Domainnamens
            setIsDomainModalOpen(true);
            return;
        }
        // Setze die Seite live, wenn das `hasDomain`-Feld gesetzt ist
        toggleActiveStatus();
    };

    const validateCustomDomain = (domainName) => {
        const isValid = /^[a-zA-Z0-9-_]+$/.test(domainName);
        if (!isValid) {
            alert("Ungültiger Domainname. Nur Buchstaben, Zahlen, Bindestriche und Unterstriche sind erlaubt.");
        }
        return isValid;
    };

    const handleMouseEnter = () => {
        setIsMenuVisible(true);
    };

    const livePageUrl = `/page/${currentPageId}`;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    useEffect(() => {
        setPreviousPageId(currentPageId);
    }, [currentPageId]);

    // Eine Funktion, die die Sortierung der Seiten übernimmt
    const getSortedPages = () => {
        // Wenn pages nicht definiert ist oder leer ist, gib ein leeres Array zurück
        if (!pages || pages.length === 0) {
            return [];
        }

        return pages.sort((a, b) => {
            if (a.id === currentPageId) return -1;
            if (b.id === currentPageId) return 1;
            return 0;
        });
    };

    const handlePageClick = (pageId) => {
        navigate(`/settings/page/${pageId}`);
    };

    useEffect(() => {
        if (!pages || pages.length === 0) {
            return; // Beenden, wenn keine Seiten vorhanden sind
        }
        const currentPage = pages.find(p => p.id === currentPageId);
        if (currentPage) {
            setIsActive(currentPage.isActive || false);
        }
    }, [pages, currentPageId]);

    useEffect(() => {
        const currentPage = pages.find(p => p.id === currentPageId);
        if (currentPage && currentPage.isActive && currentPage.customDomain) {
            setCustomDomainUrl(`/to/${currentPage.customDomain}`);
        } else {
            setCustomDomainUrl("");
        }
    }, [pages, currentPageId, isActive]);

    // Fetch pages for the current user
    useEffect(() => {
        if (currentUser) {
            const pagesRef = dbRef(db, `/pages`);
            onValue(pagesRef, (snapshot) => {
                const allPages = snapshot.val() || {};
                const userPages = Object.keys(allPages).map(key => ({
                    id: key,
                    ...allPages[key],
                })).filter(page => page.userId === currentUser.uid);

                setPages(userPages);
            });
        }
    }, [currentUser, db]);

    return (
        <div className="page-builder-header">
            {isDomainModalOpen && (
                <DomainModal
                    onDomainSubmit={handleDomainSubmit}
                    onClose={handleDomainModalClose}
                />
            )}
            <div className="page-nav-menu-left">
                <NavLink to="/pages" className="page-nav-menu-left">
                    <img src="/img/BonusClub_Logo.webp" alt="Cooler text" />
                </NavLink>
                <hr className="hrrrrr" />
                <div className="page-nav-text-item">
                    <div onClick={handleMouseEnter}>
                        <p>{projectName}</p>
                        <img src="/img/down.svg" alt="" />
                    </div>
                    <div className="page-nav-text-item" id="menu-item-1">
                        <button style={{ backgroundColor: 'white', border: '1px solid lightgrey' }} onClick={handleGlobalSettingsModalOpen}>Edit Page Settings</button>
                        {/* <button 
                            className="template-button"
                            onClick={() => navigate(`/settings/page/${currentPageId}/templates`)} // Beispiel für die Navigation
                        >
                            Templates
                        </button> */}

                        {isGlobalSettingsModalOpen && (
                            <GlobalSettingsModal
                                show={isGlobalSettingsModalOpen}
                                onClose={handleGlobalSettingsModalClose}
                                pageId={currentPageId}
                            />
                        )}
                    </div>
                    <div ref={menuRef}
                        className="hover-menu"
                        style={{ display: isMenuVisible ? 'block' : 'none' }}
                        onMouseLeave={() => setIsMenuVisible(false)}>
                        <div className="hover-menu-comp">
                            <div className="hover-menu-comp-top">
                                <p>Currently Open</p>
                                <Link target="_blank" to="/pages" className="create-new-page-link" style={{ textDecoration: 'none' }}><h4>Create New Page</h4></Link>
                            </div>
                            {getSortedPages().map((page) => (
                                <div key={page.id}
                                    className={`hover-menu-comp-mid ${page.id === currentPageId ? 'current-page' : ''}`}
                                    onClick={() => handlePageClick(page.id)}>
                                    <div className="hover-menu-comp-mid-left">
                                        <img src="/img/landing-page.svg" alt="" />
                                    </div>
                                    <div className="hover-menu-comp-mid-middle">
                                        <h5>{page.name}</h5>
                                        <p>{Array.isArray(page.widgets) ? page.widgets.length : 0} <span>Widgets</span></p>
                                    </div>
                                    <div className="hover-menu-comp-mid-right">
                                        {page.id === currentPageId && <img src="/img/tick.svg" alt="" />}
                                    </div>
                                </div>
                            ))}
                            <div className="hover-menu-comp-bottom">
                                <Link target="_blank" to="/pages" className="create-new-page-link" style={{ textDecoration: 'none', color: 'black' }}><a>BACK TO PAGES</a></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="menu-right">
                <span className="status-text">{isActive ? "Public" : "Draft"}</span>
                <div className="switch-container">
                    <label className="switch">
                        <input type="checkbox" checked={isActive} onChange={toggleActiveStatus} />
                        <span className="slider round"></span>
                    </label>
                </div>
                <a href={isActive ? customDomainUrl : "#"}
                    target={isActive ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                    className={livePageButtonClass}
                    onClick={(e) => !isActive && e.preventDefault()}>
                    <div className="live_page_button">
                        <p>Live Page</p>
                        <img src="/img/link_1.svg" alt="" />
                    </div>
                </a>
            </div>
        </div>
    );
}

export default PageNav;
