import React, { useState } from 'react';
import PackageSelectionModal from './PackageSelectionModal';
import './CheckoutButton.css';

const CheckoutButton = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <button onClick={openModal} className="checkout-button">
        Go Premium <img src="./img/diamond_1.svg" alt="Premium Icon" className="premium-icon-bottom" />
      </button>
      <PackageSelectionModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default CheckoutButton;
