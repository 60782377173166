import React from 'react';
import './MarketingDatabase.css';

const SortButtons = ({ sortOrder, setSortOrder }) => {
    return (
        <div className="sort-buttons" style={{ display: 'flex' }}>
            <button
                style={{ marginLeft: '10px' }}
                className={sortOrder === 'notSentFirst' ? 'active-sort-button' : ''}
                onClick={() => setSortOrder('notSentFirst')}
            >
                Fresh
            </button>
            <button
                style={{ marginLeft: '10px' }}
                className={sortOrder === 'sentFirst' ? 'active-sort-button' : ''}
                onClick={() => setSortOrder('sentFirst')}
            >
                Sent
            </button>
        </div>
    );
}

export default SortButtons;
