import React from 'react';

function StyleSelector({ options, selectedStyle, onStyleChange }) {
  return (
    <div className="style-selector">
      {options.map((option) => (
        <div
          key={option.id}
          className={`style-option ${selectedStyle === option.id ? 'active' : ''}`}
          onClick={() => onStyleChange(option.id)}
        >
          <img src={option.iconPath} alt={option.id} />
          {/* Optional können Sie hier den Text einfügen, falls gewünscht */}
          {/* <div style={{ textAlign: 'center', marginLeft: '5px' }}>{option.text}</div> */}
        </div>
      ))}
    </div>
  );
}

export default StyleSelector;
