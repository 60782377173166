import React from 'react';
import './CountryDropdown.css'

const CountryDropdown = ({ value, onChange }) => {
    const countries = [
        "United States", "Canada", "United Kingdom", "Germany", "France", 
        "Australia", "Japan", "China", "India", "Brazil", "South Africa", "Other"
    ];

    return (
        <div className="input-group-country">
            <label htmlFor="country">Country</label>
            <select id="country" value={value} onChange={(e) => onChange(e.target.value)}>
                <option value="">Select your country</option>
                {countries.map((country, index) => (
                    <option key={index} value={country}>{country}</option>
                ))}
            </select>
        </div>
    );
};

export default CountryDropdown;
