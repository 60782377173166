import React, { useEffect, useRef } from 'react';
import * as PIXI from 'pixi.js';
import { KawaseBlurFilter } from '@pixi/filter-kawase-blur';
import hslToHex from 'hsl-to-hex';
import { createNoise2D } from 'simplex-noise';
import './GenerativeOrbAnimation.css'

const GenerativeBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const random = (min, max) => Math.random() * (max - min) + min;
    const app = new PIXI.Application({
      view: canvasRef.current,
      resizeTo: window,
      backgroundColor: 0xFFFFFF, // Weißer Hintergrund
    });

    class ColorPalette {
      constructor() {
        this.setColors();
      }
    
      setColors() {
        // Einstellen auf einen Hue-Bereich, der orangene und lila Töne repräsentiert
        // Orangene Töne
        const orangeHue = Math.floor(random(15, 45)); // Orangene Töne
        // Lila Töne
        // const purpleHue = Math.floor(random(260, 290)); // Lila Töne
    
        this.saturation = 95;
        this.lightness = 50;
    
        // Konvertierung der HSL-Werte in Hex-Werte für orange und lila Farben
        const orangeColor = hslToHex(orangeHue, this.saturation, this.lightness);
        // const purpleColor = hslToHex(purpleHue, this.saturation, this.lightness);
    
        // Spezifischer Blauton direkt als Hex
        this.specialBlue = "#6897B7"; 
    
        // Speichern der Farbwahlen, inklusive des Blautons
        this.colorChoices = [
          orangeColor,
          // purpleColor,
          this.specialBlue // Direkt hinzugefügt
        ];
      }
    
    
      getColor(type) {
        let hslValue;
        switch (type) {
          case 'dark':
            hslValue = hslToHex(this.hue, this.saturation, this.lightness.dark);
            break;
          case 'light':
            hslValue = hslToHex(this.hue, this.saturation, this.lightness.light);
            break;
          case 'base':
            hslValue = hslToHex(this.hue, this.saturation, this.lightness.base);
            break;
          case 'specialBlue':
            return PIXI.utils.string2hex(this.specialBlue); // Direkte Rückgabe des spezifischen Blautons
          default:
            hslValue = hslToHex(this.hue, this.saturation, this.lightness.base); // Fallback zu 'base'
        }
        return PIXI.utils.string2hex(hslValue);
      }
    
      randomColor() {
        // Zufällige Auswahl einer Farbe aus den verfügbaren Optionen
        const index = Math.floor(random(0, this.colorChoices.length));
        const color = this.colorChoices[index];
        // Umwandlung in PixiJS-kompatible Hexadezimalzahl
        return PIXI.utils.string2hex(color.startsWith('#') ? color : color);
      }
    }
    
    const noise2D = createNoise2D();

    class Orb {
      constructor(x, y, radius) {
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.graphics = new PIXI.Graphics();
        app.stage.addChild(this.graphics);
        this.color = colorPalette.randomColor(); // Für einen speziellen Orb
        this.noiseStep = 0.005;
        this.time = 0; // Zeitvariable hinzufügen
        this.noiseSpeed = 0.01; // Geschwindigkeit der Zeitänderung
      }
    
      update() {
        // Die Zeitvariable beeinflusst den Noise, um eine dynamische Bewegung zu erzeugen
        const noiseX = noise2D(this.x * this.noiseStep, this.time);
        const noiseY = noise2D(this.y * this.noiseStep, this.time);
        this.x += noiseX * 2; // Bewegungsgeschwindigkeit anpassen
        this.y += noiseY * 2; // Bewegungsgeschwindigkeit anpassen
        this.time += this.noiseSpeed; // Zeit inkrementieren
      }
    
      draw() {
        this.graphics.clear();
        this.graphics.beginFill(this.color, 0.75);
        this.graphics.drawCircle(this.x, this.y, this.radius);
        this.graphics.endFill();
      }
    }
    

    const colorPalette = new ColorPalette();
    const orbs = [];
    app.stage.filters = [new KawaseBlurFilter(20, 10, true)];

    const numberOfOrbs = 10;

   // Erstellen eines speziellen Orbs mit dem Blauton
   let x = Math.random() * window.innerWidth;
   let y = Math.random() * window.innerHeight;
   let radius = Math.random() * (250 - 30) + 30;
   let specialBlueOrb = new Orb(x, y, radius, colorPalette.specialBlue); // Übergabe des Blautons
   orbs.push(specialBlueOrb);
 
   // Erstellen der restlichen Orbs mit zufälligen orangen Tönen
   for (let i = 1; i < numberOfOrbs; i++) { // Startet bei 1, da bereits ein Orb erstellt wurde
     x = Math.random() * window.innerWidth;
     y = Math.random() * window.innerHeight;
     radius = Math.random() * (250 - 30) + 30;
     orbs.push(new Orb(x, y, radius, colorPalette.randomColor())); // Verwendung der randomColor-Methode
   }
 
   app.ticker.add(() => {
     orbs.forEach(orb => {
       orb.update();
       orb.draw();
     });
   });
 
   // Bereinigung, wenn die Komponente demontiert wird
   return () => {
     app.destroy(true, { children: true, texture: true, baseTexture: true });
   };
 }, []);
  return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%' }}></canvas>;
};

export default GenerativeBackground;
