import React, { useState, useEffect } from 'react';
import { widgetTemplates } from './widgetTemplates';
import './AddWidgets.css';

const categoryIcons = {
  'All': '/img/night.svg',
  'Fancy': '/img/red-carpet.svg',
  'Basic': '/img/blocks.svg',
  'Button': '/img/push.svg',
  'Video': '/img/camera.svg',
  'Image': '/img/cam.svg',
  'Bonus': '/img/launch.svg',
};

function DraggableButton({ widgetType, onAddWidget }) {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    onAddWidget(widgetTemplates[widgetType]);
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 2000);
  };

  return (
    <button
      onClick={handleClick}
      className={`widget-button widget-${widgetType} ${isAnimating ? 'clicked' : ''}`}
      style={{ background: 'none', padding: '0', borderRadius: '10px', margin: '0' }}
    >
      <div className="image-container">
        {widgetTemplates[widgetType].imagePreview && (
          <img
            className="widget-preview"
            src={widgetTemplates[widgetType].imagePreview}
            alt={`Vorschau von ${widgetType}`}
          />
        )}
        <div className="widget-overlay">
          <img src="/img/plus_1.svg" alt="" />
          <span className="widget-overlay-text">Click to add me</span>
        </div>
      </div>
      <p style={{ marginTop: '5px', marginBottom: '30px', fontSize: '12px', textTransform: 'uppercase', color: 'gray', letterSpacing: '2px' }}>
        {widgetTemplates[widgetType].name}
      </p>
    </button>
  );
}

function AddWidgets({ onAddWidget }) {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categories, setCategories] = useState(['All']);

  useEffect(() => {
    const extractedCategories = Object.values(widgetTemplates).reduce((acc, { cat1, cat2 }) => {
      if (cat1 && !acc.includes(cat1)) acc.push(cat1);
      if (cat2 && !acc.includes(cat2)) acc.push(cat2);
      return acc;
    }, ['All']);
    setCategories(extractedCategories);
  }, []);

  const filteredWidgets = Object.keys(widgetTemplates).filter(widgetType => {
    const { cat1, cat2 } = widgetTemplates[widgetType];
    return selectedCategory === 'All' || cat1 === selectedCategory || cat2 === selectedCategory;
  });

  return (
    <div className="add-widgets-layout">
      <div className="widget-categories">
        {categories.map(category => (
          <button 
            key={category} 
            onClick={() => setSelectedCategory(category)} 
            className={`widget-cat-button category-button ${selectedCategory === category ? 'active' : ''}`}
          >
            <img 
              className={`widget-cat-icons ${selectedCategory === category ? 'selected-category-icon' : ''}`} 
              src={categoryIcons[category] || categoryIcons['All']} 
              alt={`${category} icon`} 
              style={{ verticalAlign: 'middle' }}
            />
            <p className={`widget-cat-text ${selectedCategory === category ? 'selected-category-text' : ''}`}>{category}</p> 
          </button>
        ))}
      </div>
      <div className="widget_container">
        <h2 className="h2_container">
          {selectedCategory} Widgets
          <img 
            src={categoryIcons[selectedCategory] || categoryIcons['All']} 
            alt={`${selectedCategory} icon`} 
            style={{ verticalAlign: 'middle', marginRight: '8px', width: '24px', height: '24px' }} 
          />
        </h2>
        {filteredWidgets.length > 0 ? (
          filteredWidgets.map(widgetType => <DraggableButton key={widgetType} widgetType={widgetType} onAddWidget={onAddWidget} />)
        ) : (
          <p>No widgets available for this category.</p>
        )}
      </div>
    </div>
  );
}

export default AddWidgets;
