// PremiumRestrictionMessage.js
import React from 'react';
import './AddPageComponent.css'; // Create this CSS file for styling

const PremiumRestrictionMessage2 = () => {
  return (
    <div>
      <div className="premium-restriction-message" style={{ textAlign: 'center', position: 'relative', marginLeft: '15px', marginBottom: '-5px' }}>
        <div className="question-right-pro" style={{backgroundColor: 'transparent', border: '1px solid #f2f2f2'}}>
          <img className="crown" src="./img/crow.svg" alt="lock"/>
          <p>Bonus Links</p>
        </div>
        <img style={{width: '400px'}} src="./img/Bonusclub_Link.webp" alt="" />
        <h2 style={{marginTop: '25px'}}>Your Bonus Link</h2>
        <div className="bonusclub-bullet-points-container" style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
          <div className="bonusclub-column" style={{marginRight: '50px', textAlign: 'left'}}>
            <div className="bonusclub-bullet-point">
              <img src="./img/check_3.svg" alt="check" className="bonusclub-checkmark"/>
              <span>More 5 Star Reviews</span>
            </div>
            <div className="bonusclub-bullet-point">
              <img src="./img/check_3.svg" alt="check" className="bonusclub-checkmark"/>
              <span>Social Media Shoutouts</span>
            </div>
            <div className="bonusclub-bullet-point">
              <img src="./img/check_3.svg" alt="check" className="bonusclub-checkmark"/>
              <span>Guest's Snapshots</span>
            </div>
          </div>
          <div className="bonusclub-column" style={{textAlign: 'left'}}>
            <div className="bonusclub-bullet-point">
              <img src="./img/check_3.svg" alt="check" className="bonusclub-checkmark"/>
              <span>Newsletter Consent</span>
            </div>
            <div className="bonusclub-bullet-point">
              <img src="./img/check_3.svg" alt="check" className="bonusclub-checkmark"/>
              <span>Detailed Guest Data</span>
            </div>
            <div className="bonusclub-bullet-point">
              <img src="./img/check_3.svg" alt="check" className="bonusclub-checkmark"/>
              <span>More Bookings</span>
            </div>
          </div>
        </div>
        <a className="pro-feature-button" style={{textDecoration: 'none', color: 'white'}} href="https://bonusclub.io">Contact Team to Unlock</a>
      </div>
    </div>
  );
};

export default PremiumRestrictionMessage2;
