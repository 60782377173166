function ImageWidget({ data }) {
    return (
      <div style={{ backgroundColor: data.backgroundColor }}>
        <img src={data.imageRef} alt="Widget" />
        {/* Weitere Stil- und Inhaltselemente... */}
      </div>
    );
  }

  export default ImageWidget;
