import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, set, onValue } from 'firebase/database';
import './WhatsappTextModal.css';

function WhatsappTextModal({ onClose }) {
    const [textTemplate, setTextTemplate] = useState('');
    const db = getDatabase();

    useEffect(() => {
        const textRef = dbRef(db, 'WhatsApp/whatsappText');
        onValue(textRef, (snapshot) => {
            if (snapshot.exists()) {
                setTextTemplate(snapshot.val());
            }
        });
    }, [db]);

    const handleSave = () => {
        const textRef = dbRef(db, 'WhatsApp/whatsappText');
        set(textRef, textTemplate);
        onClose();
    };

    return (
        <div className="whatsapp-text-modal">
            <div className="whatsapp-text-modal-content">
                <h2>Set WhatsApp Text</h2>
                <textarea
                    value={textTemplate}
                    onChange={(e) => setTextTemplate(e.target.value)}
                    rows="5"
                    cols="50"
                />
                <div className="whatsapp-text-modal-buttons">
                    <button onClick={handleSave}>Save</button>
                    <button style={{backgroundColor: 'gray'}} onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default WhatsappTextModal;
