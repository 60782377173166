import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { useParams, useNavigate } from 'react-router-dom'; // Importieren von useNavigate
import '../Steps/ThankYou.css';
import { useFont } from '../Design/FontContext';

const ThankYouBackend = ({ surveyId }) => { // Korrekte Destrukturierung der Props
    const [thankYouSettings, setThankYouSettings] = useState({});
    const { userId } = useParams(); // User-ID aus URL-Parametern
    const selectedFont = useFont();
    const [imageLoaded, setImageLoaded] = useState(false);
    const navigate = useNavigate(); // Verwendung von useNavigate

    useEffect(() => {
        const db = getDatabase();
        const thankYouRef = ref(db, `surveyConfigs/${userId}/thankYouSettings`);

        onValue(thankYouRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setThankYouSettings(data);
            }
        }, {
            onlyOnce: true
        });
    }, [userId]);

    const goToBonusPage = () => {
        if (surveyId && userId) {
            navigate(`/qr?surveyId=${surveyId}&userId=${userId}`); // Verwendung der Survey-ID und User-ID in der URL
        } else {
            console.error('Missing surveyId or userId.');
        }
    };

    return (
        <div className="thank_you">
            {imageLoaded && <p></p>}
            <img className="sent_bird" src={thankYouSettings.thankYouImage} alt="Thank You" onLoad={() => setImageLoaded(true)} />
            <h2 style={{ fontFamily: selectedFont }} className="surveyHeader">Thank you!</h2>
            <p className="thank_you_text">{thankYouSettings.thankYouText || "Your bonus will be sent to your mail."}</p>
            <button style={{ maxWidth: '400px', marginTop: '30px', marginBottom: '100px' }} className="buttonClassy" onClick={goToBonusPage}>Go to my Bonus</button>
        </div>
    );
};

export default ThankYouBackend;
