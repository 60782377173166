import React, { useEffect } from 'react';
import './GoogleTranslate.css'; // Importieren der CSS-Datei

const GoogleTranslate = ({ data }) => {
  useEffect(() => {
    const addGoogleTranslateScript = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit&hl=en'; // 'hl=en' setzt die Standardsprache auf Englisch
      script.async = true;
      document.body.appendChild(script);
    };

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({
        pageLanguage: 'en',
        includedLanguages: 'en,fr,es,it,pt,de,', // Gewünschte Sprachen eingeben
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false,
      }, 'google_translate_element');
      
      setTimeout(() => {
        const spanElement = document.querySelector('.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span');
        if (spanElement) {
          if (data.translate1) {
            spanElement.style.color = 'white';
          } else if (data.translate2) {
            spanElement.style.color = 'black';
          }
        }
      }, 1000); // Füge eine Verzögerung hinzu, um sicherzustellen, dass das Element vorhanden ist
    };

    addGoogleTranslateScript();
  }, [data]);

  const handleIconClick = () => {
    const interval = setInterval(() => {
      const googleTranslateLink = document.querySelector('.goog-te-gadget-simple a');
      if (googleTranslateLink) {
        googleTranslateLink.click();
        clearInterval(interval);
      }
    }, 100);
  };

    const iconStyle = {
    filter: data.translate1 ? 'invert(1)' : 'none',
  };

  return (
    <div 
      className={`google_translate_wrapper ${data.translate1 ? 'translate1' : ''} ${data.translate2 ? 'translate2' : ''}`}>
      <img src="../../img/g-translate.svg" alt="plus icon" onClick={handleIconClick} style={{ cursor: 'pointer' }} />
      <div id="google_translate_container">
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }} id="google_translate_element">
          <img src="../../img/down.svg" alt="plus icon" onClick={handleIconClick}             style={{ cursor: 'pointer', ...iconStyle }}  />
        </div>   
      </div>
    </div>
  );
};

export default GoogleTranslate;
