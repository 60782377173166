import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, onValue, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import ServiceItem from './ServiceItem';
import './ServicePage.css';
import DashboardHeader from '../AdminComponents/DashboardHeader';
import GenerativeBackground from '../Pages/GenerativeOrbAnimation';
import AgencyMenu from './AgencyMenu';
import NoAgency from './NoAgency';
import PremiumRestrictionMessage from '../Pages/PremiumRestrictionMessage';

function ServicePage() {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true); // New state for loading status
    const [hasAgency, setHasAgency] = useState(null); // New state for agency status

    useEffect(() => {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (currentUser) {
            const db = getDatabase();
            const userRef = dbRef(db, `Users/${currentUser.uid}`);

            get(userRef).then((snapshot) => {
                const userData = snapshot.val();
                setHasAgency(userData?.hasAgency ?? false);

                if (userData?.hasAgency) {
                    const servicesRef = dbRef(db, 'Services');

                    onValue(servicesRef, (snapshot) => {
                        const data = snapshot.val();
                        const serviceList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
                        setServices(serviceList);
                        setLoading(false); // End loading after services are fetched
                    });
                } else {
                    setLoading(false); // End loading if user does not have agency
                }
            }).catch(error => {
                console.error('Error fetching user data:', error);
                setLoading(false);
            });
        } else {
            setLoading(false); // End loading if no user is logged in
        }
    }, []);

    const getDeliveryIcon = (deliveryTime) => {
        switch (deliveryTime) {
            case 'Fast':
                return './img/delivery_1.svg';
            case 'Standard':
                return './img/delivery_2.svg';
            case 'Superfast':
                return './img/delivery_3.svg';
            default:
                return './img/delivery_default.svg';
        }
    };

    const categorizedServices = services.reduce((acc, service) => {
        const category = service.category || 'Other';
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push(service);
        return acc;
    }, {});

    const otherCategories = Object.keys(categorizedServices)
        .filter(category => category !== 'Print Materials')
        .sort();

    if (loading) {
        return (
            <div className="loading-spinner" id="fake-loading-spinner">
                <h1>BonusClub</h1>
                <div className="spinner-circle"></div>
            </div>
        );
    }

    if (!hasAgency) {
        return (
            <div className="access">
                <GenerativeBackground />
                <DashboardHeader />
                <PremiumRestrictionMessage />
            </div>
        );
    }

    return (
        <div className="service-page-container">
            <DashboardHeader />
            <AgencyMenu />
            {categorizedServices['Print Materials'] && (
                <div key="Print Materials">
                    <h2 className="category-service-page">Print Materials</h2>
                    {categorizedServices['Print Materials'].map(service => (
                        <ServiceItem key={service.id} service={service} getDeliveryIcon={getDeliveryIcon} />
                    ))}
                </div>
            )}
            {otherCategories.map(category => (
                <div key={category}>
                    <h2 className="category-service-page">{category}</h2>
                    {categorizedServices[category].map(service => (
                        <ServiceItem key={service.id} service={service} getDeliveryIcon={getDeliveryIcon} />
                    ))}
                </div>
            ))}
        </div>
    );
}

export default ServicePage;
