import React from 'react';

const EmptyPage = () => {
  // Pfad zum Bild, das Sie anzeigen möchten
  const imageUrl = '/img/default-logo.webp';
  
  // Inline-Styles für die Komponente
  const styles = {
    container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '50px',
    height: '80vh',
    },
    image: {
      maxWidth: '200px',
      height: 'auto',
      margin: '10px 0px 30px 0px',
    },
    heading: {
      margin: '20px 10px 0px 10px',
      fontSize: '48px',
      fontFamily: 'Jost',
      maxWidth: '400px',
    },
    paragraph: {
      margin: '10px 10px 10px 10px',
      fontSize: '18px',
      maxWidth: '400px',
    },
  };

  return (
    <div style={styles.container}>
      <img src={imageUrl} alt="Beschreibender Text" style={styles.image} />
      <h1 style={styles.heading}>This Page does not exist.</h1>
      <p style={styles.paragraph}>We're sorry, but the page you were trying to reach seems to be offline and could not be found.</p>
    </div>
  );
};

export default EmptyPage;
