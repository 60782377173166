import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import './Dashboard.css';
import { remove } from 'firebase/database';
import DBSearch from './DBSearch';
import CSVExportButton from './CSVExportButton';
import Memories from './Memories';
import { useFont } from '../Design/FontContext';
import { NavLink } from 'react-router-dom';
import GenerativeOrbAnimation from '../Pages/GenerativeOrbAnimation';
import PremiumRestrictionMessage from '../Pages/PremiumRestrictionMessage';
import PremiumRestrictionMessage2 from '../Pages/PremiumRestrictionMessage2';

function Dashboard() {
    const [surveys, setSurveys] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedSurveyId, setSelectedSurveyId] = useState(null);
    const [viewMode, setViewMode] = useState('grid'); // 'table' or 'grid'
    const [isLoading, setIsLoading] = useState(true);
    const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
    const buttonClass = showFavoritesOnly ? "icon-button-fav" : "icon-button-all";    
    const [memoriesLoaded, setMemoriesLoaded] = useState(false);
    const [componentLoaded, setComponentLoaded] = useState(false);
    const selectedFont = useFont();
    const [hasManyItems, setHasManyItems] = useState(false);
    const auth = getAuth();
    const userId = auth.currentUser?.uid;
    const [showGallery, setShowGallery] = useState(true);
    const [showAllFiles, setShowAllFiles] = useState({}); // Neuer Zustand
    const [isPremium, setIsPremium] = useState(null);
    const [deleteActionStatus, setDeleteActionStatus] = useState("default");
    const [isAgency, setIsAgency] = useState(null);






    
    const stringSafeToLower = (value) => {
        return typeof value === 'string' ? value.toLowerCase() : '';
    };
    useEffect(() => {

        
        const db = getDatabase();
        const surveyRef = ref(db, `surveys/${userId}`);
        const auth = getAuth();
        const currentUserId = auth.currentUser?.uid;
    
        onValue(surveyRef, (snapshot) => {
            const surveysData = snapshot.val();
            const surveysList = [];
        
            for (let id in surveysData) {
                if (surveysData[id].userId === currentUserId) {  // Prüfung auf userId
                    surveysList.push({ id, ...surveysData[id] });
                }
            }
        
            setSurveys(surveysList);
            setMemoriesLoaded(true);
        });
        
    
        return () => {
            off(surveyRef);
        };
    }, []);
    


useEffect(() => {
    if (memoriesLoaded && componentLoaded) {
        setIsLoading(false);
    }
}, [memoriesLoaded, componentLoaded]);

const urlToThumbnail = (originalUrl, uploadedThumbnails) => {
    if (uploadedThumbnails && uploadedThumbnails.length > 0) {
      const thumbnailObj = uploadedThumbnails.find(thumb => thumb.original === originalUrl);
      return thumbnailObj ? thumbnailObj.thumbnail : originalUrl;
    } else {
      return originalUrl;
    }
};
    

const handleHasManyItemsChange = (value) => {
    setHasManyItems(value);
};


useEffect(() => {
    if (!userId) return; // Verlässt die Funktion frühzeitig, wenn keine userId vorhanden ist

    const db = getDatabase();
    const isAgencyRef = ref(db, `/Users/${userId}/hasAgency`);

    const unsubscribe = onValue(isAgencyRef, (snapshot) => {
        const isAgencyValue = snapshot.val();
        setIsAgency(!!isAgencyValue); // Setzt isAgency auf true, wenn isAgencyValue wahr ist, sonst false
    });

    return () => unsubscribe(); // Bereinigt den Listener, wenn die Komponente unmountet
}, [userId]); // Abhängigkeiten: führt den Effekt aus, wenn sich userId ändert


    const handleMasonryLoad = () => {
        setComponentLoaded(true);
    };
    
    
    const deleteSurvey = (id) => {
        const db = getDatabase();
        const surveyRef = ref(db, `surveys/${userId}/${id}`);
        
        remove(surveyRef)
            .then(() => {
                console.log("Eintrag erfolgreich gelöscht!");
            })
            .catch((error) => {
                console.error("Fehler beim Löschen des Eintrags:", error);
            });
    };

    const openModal = (id) => {
        if (isPremium) {
            setSelectedSurveyId(id);
            setDeleteActionStatus("confirm");
        } else {
            setDeleteActionStatus("nonPremium");
        }
    };
    

  const closeModal = () => {
    setSelectedSurveyId(null);
    setShowModal(null);
};


    const isDateBetween = (timestamp, start, end) =>  {
        if (!start && !end) return true;

        const date = new Date(timestamp);
        if (start && !end) return date >= new Date(start);
        if (!start && end) return date <= new Date(end);
        return date >= new Date(start) && date <= new Date(end);
    };

    

    const filteredSurveys = surveys
    .filter(survey => isDateBetween(survey.timestamp, startDate, endDate))
    .filter(survey => {
        const searchTermLower = searchTerm.toLowerCase();

        // Felder, die durchsucht werden sollen
        const fieldsToSearch = [
            survey.userData?.firstName,
            survey.userData?.lastName,
            survey.userData?.email,
            ...Object.values(survey.quickQuestions || {}).map(q => q.value)
        ];

        return fieldsToSearch.some(value =>
            stringSafeToLower(value).includes(searchTermLower)
        );
    });

    const sortedSurveys = filteredSurveys.sort((a, b) => b.timestamp - a.timestamp);



    return (
        <div>
            
            {  !memoriesLoaded ? (
        <div className="loading-spinner">
            <h1>BonusClub</h1>
            <div className="spinner-circle"></div>
        </div>
    ) : isAgency === null ? (
        <div>Loading...</div> // Zeigt einen Ladezustand an, während isAgency abgefragt wird
    ) : !isAgency ? (
            <div className="access">
                                  <GenerativeOrbAnimation/>
                                  <div className="premium-restriction-div" >
                             <PremiumRestrictionMessage2 />
     </div>
        </div>
    ) : (
                <>
                    <div className="db_bar">
                        <DBSearch
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                        />
                        <div className="db_bar_left"> 
                            <button className={buttonClass}   onClick={() => {
        setShowFavoritesOnly(prev => !prev);
        setViewMode('grid'); 
    }}>
                                {showFavoritesOnly ? 
                                    <img className="heart-icons-1" src="./img/heart-dev.svg" alt="Favoriten" /> :
                                    <img className="heart-icons-2" src="./img/love.svg" alt="Alle anzeigen" />
                                }
                            </button>
                            <CSVExportButton surveys={filteredSurveys} />
                            <button 
                            className="database-switch-buttons" 
                            onClick={() => setViewMode(prev => prev === 'table' ? 'grid' : 'table')}
                            >
                                {viewMode === 'table' ? 
                                    <img className="database-switch-1" src="./img/menu.svg" alt="Switch to Memories" /> :
                                    <img className="database-switch-2" src="./img/grid.svg" alt="Switch to Database" />
                                }
                            </button>
                            <NavLink style={{textDecoration: 'none'}} className="db_settings_button" to="/settings"><p>Settings</p></NavLink>
                        </div>
                    </div>
                 
                    {viewMode === 'grid' ? (
                        <Memories 
                            onMasonryLoad={handleMasonryLoad} 
                            filteredSurveys={filteredSurveys} 
                            showFavoritesOnly={showFavoritesOnly} 
                            setShowFavoritesOnly={setShowFavoritesOnly}
                            galleryStatus={(status) => setShowGallery(status)}
                            />
                        
                    ) : (
<div className={`table-container ${!isLoading ? 'fade-in' : ''}`}>
                        
<div>

</div>
<table>
    <thead>
        <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Telephone</th>
            <th>Instagram</th> {/* New field */}
            <th>TikTok</th> {/* New field */}
            <th>LinkedIn</th> {/* New field */}
            <th>Social Media</th> {/* New field */}
            <th>Uploaded Files Count</th> {/* New field */}
            <th>Bonus Status</th> {/* New field */}
            <th>Bonus</th>
            <th>Questions</th>
            <th>Files</th>
            <th>Consent Given</th>
            <th>Delete</th> {/* Moved to the end */}
        </tr>
    </thead>
    <tbody>
        {filteredSurveys.map((survey) => (
            <tr key={survey.id}>
                <td>{survey.userData?.firstName}</td>
                <td>{survey.userData?.lastName}</td>
                <td>{survey.userData?.email}</td>
                <td>{survey.userData?.telephone}</td>
                <td>{survey.userData?.instagram}</td> {/* New field */}
                <td>{survey.userData?.tiktok}</td> {/* New field */}
                <td>{survey.userData?.linkedin}</td> {/* New field */}
                <td>{survey.userData?.socialMedia}</td> {/* New field */}
                <td>{(survey.uploadedFiles || []).length}</td> {/* New field */}
                <td>{survey.status}</td> {/* New field */}
                <td>{survey.selectedBonus}</td>
                <td>{Object.entries(survey.quickQuestions || {}).map(([index, questionData]) => (
    <div key={index}>
        {questionData.placeholder}: {questionData.value}
    </div>
))}
</td>

             
                <td> 

             
<div className="image-box">
{(survey.uploadedFiles || []).slice(0, survey.uploadedFiles.length > 3 && !showAllFiles[survey.id] ? 3 : undefined).map((url, urlIndex) => {
        const thumbnailUrl = urlToThumbnail(url, survey.uploadedThumbnails);
        const isImage = ['.jpg', '.jpeg', '.webp', '.png', '.gif', '.svg'].some(ext => url.includes(ext));
        const isVideo = ['.mp4', '.MP4', '.mov', '.MOV'].some(ext => url.includes(ext));

        if (isImage) {
            return (
                <a key={urlIndex} href={url} target="_blank" rel="noopener noreferrer">
                    <img src={thumbnailUrl} loading="lazy" alt={`Uploaded ${urlIndex}`} />
                </a>
            );
        } else if (isVideo) {
            return (
                <a key={urlIndex} href={url} target="_blank" rel="noopener noreferrer">
                    <video poster={thumbnailUrl} preload="none" className="dashboard-video" width="150" controls>
                        <source src={url} type="video/mp4" alt={`Uploaded ${urlIndex}`} />
                        Your browser does not support the video tag.
                    </video>
                </a>
            );
        }
        return null;
    })}

    {(survey.uploadedFiles.length > 3 && !showAllFiles[survey.id]) && (
        <span 
            className="show-more-icon" 
            onClick={() => setShowAllFiles(prev => ({ ...prev, [survey.id]: true }))}
        >
            <img className="show-more-plus" src="./img/plus.svg" alt="plus" /> 
        </span>
    )}
</div>
                </td>
                <td>{new Date(survey.timestamp).toLocaleString()}</td>
                <td>
    <div className="button_container">
        {deleteActionStatus === "default" && (
            <button className="delete-button" onClick={() => openModal(survey.id)}>Delete</button>
        )}
        {deleteActionStatus === "confirm" && (
            <>
                <button className="delete-button confirm-delete" onClick={() => { deleteSurvey(survey.id); setDeleteActionStatus("default"); }}>Confirm</button>
                <button className="cancel-button" onClick={() => setDeleteActionStatus("default")}>Cancel</button>
            </>
        )}
        {deleteActionStatus === "nonPremium" && (
            <span className="non-premium-warning">This is a premium function</span>
        )}
    </div>
</td>

            </tr>
        ))}
    </tbody>
</table>
                        </div>
                    )}
                </>
            )}
            
        </div>                
    );
    
}
    

export default Dashboard;
