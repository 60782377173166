import React, { useState, useEffect } from 'react';
import jsonData from '../json/bonus_ideas.json';
import './BonusIdeas.css';

function BonusIdeas() {
  const [data, setData] = useState(jsonData);
  const [selectedFilters, setSelectedFilters] = useState({
    Type: [],
    Cost: [],
    Cat1: [],
    Cat2: [],
  });
  const [availableFilters, setAvailableFilters] = useState({
    Type: [],
    Cost: [],
    Cat1: [],
    Cat2: [],
  });

  const [displayedFilters, setDisplayedFilters] = useState({
    Type: [],
    Cost: [],
    Cat1: [],
    Cat2: [],
  });

  
  const updateSelectedFilters = (category, filter) => {
    setSelectedFilters(prevFilters => {
      if (prevFilters[category].includes(filter)) {
        const updatedFilters = {
          ...prevFilters,
          [category]: prevFilters[category].filter(item => item !== filter),
        };
        return updatedFilters;
      } else {
        const updatedFilters = {
          ...prevFilters,
          [category]: [...prevFilters[category], filter],
        };
        return updatedFilters;
      }
    });
  };

  const baseFiltered = data.filter(item => {
    if (selectedFilters.Type.length > 0 && !selectedFilters.Type.includes(item.Type)) {
        return false;
    }
    if (selectedFilters.Cost.length > 0 && !selectedFilters.Cost.includes(item.Cost)) {
        return false;
    }
    return true;
  });

  const filteredData = baseFiltered.filter(item => {
    if (selectedFilters.Cat1.length === 0 && selectedFilters.Cat2.length === 0) {
        return true;
    }
    const matchesCat1 = selectedFilters.Cat1.some(cat => item.Cat1.includes(cat));
    const matchesCat2 = selectedFilters.Cat2.some(cat => item.Cat2.includes(cat));
    return matchesCat1 || matchesCat2;
  });

  useEffect(() => {
    // Verfügbare Filter basierend auf vollständigen Daten festlegen
    const allTypeFilters = [...new Set(data.map(item => item.Type))];
    const allCostFilters = [...new Set(data.map(item => item.Cost))];
    const allCat1Filters = [...new Set(data.flatMap(item => item.Cat1))];
    const allCat2Filters = [...new Set(data.flatMap(item => item.Cat2))];
    setAvailableFilters({
      Type: allTypeFilters,
      Cost: allCostFilters,
      Cat1: allCat1Filters,
      Cat2: allCat2Filters,
    });
  }, [data]);
  
  useEffect(() => {
    const typeCostFiltered = data.filter(item => {
      if (selectedFilters.Type.length > 0 && !selectedFilters.Type.includes(item.Type)) {
          return false;
      }
      if (selectedFilters.Cost.length > 0 && !selectedFilters.Cost.includes(item.Cost)) {
          return false;
      }
      return true;
    });
  
    const currentTypeFilters = [...new Set(typeCostFiltered.map(item => item.Type))];
    const currentCostFilters = [...new Set(typeCostFiltered.map(item => item.Cost))];
    const currentCat1Filters = [...new Set(typeCostFiltered.flatMap(item => item.Cat1))];
    const currentCat2Filters = [...new Set(typeCostFiltered.flatMap(item => item.Cat2))];
  
    setDisplayedFilters({
      Type: currentTypeFilters,
      Cost: currentCostFilters,
      Cat1: currentCat1Filters,
      Cat2: currentCat2Filters,
    });
  }, [selectedFilters, availableFilters, data]);
  
  
  const combinedCatFilters = [
    ...new Set([...displayedFilters.Cat1, ...availableFilters.Cat2])
  ];

  return (
    <div className="bonus-ideas-container">
        <div className="filters" >
      {/* Hier kommen die anklickbaren Buttons zum Filtern */}
      <div className="filter-section" id="top-filter">
    {availableFilters.Type.map(filter => (
        <button 
            key={filter}
            className={`filter-button ${selectedFilters.Type.includes(filter) ? 'active' : ''}`}
            onClick={() => updateSelectedFilters('Type', filter)}
        >
            {filter}
        </button>
    ))}
</div>

<div className="filter-section">
    {availableFilters.Cost.map(filter => (
        <button 
            key={filter}
            className={`filter-button ${selectedFilters.Cost.includes(filter) ? 'active' : ''}`}
            onClick={() => updateSelectedFilters('Cost', filter)}
        >
            {filter}
        </button>
    ))}
</div> 

<div className="filter-section" id="top-filter-2">
    {combinedCatFilters.map(filter => (
        <button 
            key={filter}
            className={`filter-button ${selectedFilters.Cat1.includes(filter) || selectedFilters.Cat2.includes(filter) ? 'active' : ''}`}
            onClick={() => {
              if (displayedFilters.Cat1.includes(filter)) {
                updateSelectedFilters('Cat1', filter);
              } else {
                updateSelectedFilters('Cat2', filter);
              }
            }}
        >
            {filter}
        </button>
    ))}
</div>
</div>





      
      {/* Hier kommt die Liste der angezeigten Bonus-Ideen */}
      <ul className="ideas-list">
    {filteredData.map((item, index) => (
          <li key={index} className="idea-item">
                          <h2>{item.Title}</h2>
                       <div className="idea-item-cat">
            <p><strong></strong> {item.Type}</p>
            <p><strong></strong> {item.Cost}</p>
            <p><strong></strong> {Array.isArray(item.Cat1) ? item.Cat1.join(', ') : item.Cat1}</p>
            <p><strong></strong> {Array.isArray(item.Cat2) ? item.Cat2.join(', ') : item.Cat2}</p>
            </div> 
         
            <p>{item.Description}</p>
        </li>
    ))}
</ul>

    </div>
  );
}

export default BonusIdeas;
