import React from 'react';
import TopWidgetEdit from './WidgetEditTypes/TopWidgetEdit';
import FancyBulletEdit from './WidgetEditTypes/FancyBulletEdit';
import IconFeatureEdit from './WidgetEditTypes/IconFeatureEdit';

import './WidgetEdit.css'
import WifiCardEdit from './WidgetEditTypes/WifiCardEdit';
import FaqWidgetEdit from './WidgetEditTypes/FaqWidgetEdit';
import CheckInCardEdit from './WidgetEditTypes/CheckInCardEdit';
import DoubleTextEdit from './WidgetEditTypes/DoubleTextEdit';
import EliteButtonEdit from './WidgetEditTypes/EliteButtonEdit';
import PicTextEdit from './WidgetEditTypes/PicTextEdit';
import Bonus1 from './WidgetTypes/Bonus1';
import Bonus1Edit from './WidgetEditTypes/Bonus1Edit';
import BigButtonEdit from './WidgetEditTypes/BigButtonEdit';
import MegaButtonEdit from './WidgetEditTypes/MegaButtonEdit';
import AddyCardEdit from './WidgetEditTypes/AddyCardEdit';
import EndWidget from './WidgetTypes/EndWidget';
import EndWidgetEdit from './WidgetEditTypes/EndWidgetEdit';
import VideoTextEdit from './WidgetEditTypes/VideoTextEdit';
import HeaderWidgetEdit from './WidgetEditTypes/HeaderWidgetEdit';
import ImprintBarEdit from './WidgetEditTypes/ImprintBarEdit';
import WeatherButtonEdit from './WidgetEditTypes/WeatherButtonEdit';
import EmergencyWidgetEdit from './WidgetEditTypes/EmergencyWidgetEdit';

function WidgetEdit({ selectedWidget, onUpdateWidget, colors }) {
  const widgetEditorClass = selectedWidget ? "widget-editor active-widget" : "widget-editor";

  
  const renderEditOptions = (widget) => {
    switch (widget.type) {
      case 'Widget1':
        return <TopWidgetEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget2':
        return <FancyBulletEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget3':
        return <IconFeatureEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget4':
        return <WifiCardEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget5':
        return <FaqWidgetEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget6':
        return <CheckInCardEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget7':
        return <DoubleTextEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget8':
        return <EliteButtonEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget9':
        return <PicTextEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget10':
        return <Bonus1Edit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget11':
        return <BigButtonEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget12':
        return <MegaButtonEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget13':
        return <AddyCardEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget14':
        return <EndWidgetEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget15':
        return <VideoTextEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      case 'Widget16':
        return <HeaderWidgetEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
         case 'Widget16':
        return <HeaderWidgetEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
        case 'Widget17':
          return <ImprintBarEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
          case 'Widget18':
            return <WeatherButtonEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
            case 'Widget19':
              return <EmergencyWidgetEdit key={widget.id} widget={widget} onUpdate={onUpdateWidget} colors={colors} />;
      default:
        return <div>Widget-Typ nicht unterstützt</div>;
    }
  };

  return (
    <div className={widgetEditorClass}>
      {selectedWidget && renderEditOptions(selectedWidget)}
    </div>
  );
}

export default WidgetEdit;
