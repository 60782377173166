import React, { createContext, useContext, useState, useEffect } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useLocation } from 'react-router-dom';

const FontContext = createContext();

export const useFont = () => {
    return useContext(FontContext);
};

export const FontProvider = ({ children }) => {
    const [allFonts, setAllFonts] = useState({
        selectedFont: 'Arial', // Standardwert
        globalSmallFont: 'Arial', // Standardwert
        globalBigFont: 'Arial', // Standardwert
    });

    const location = useLocation();
    const db = getDatabase();
    const auth = getAuth();

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const isCustomUrl = pathSegments[1] === 'to';
        const isSettingsPage = pathSegments[1] === 'settings' && pathSegments[2] === 'page';
        const isDemoUrl = pathSegments[1] === 'demo';
        const isSettingsDemoPage = pathSegments[1] === 'settings' && pathSegments[2] === 'demoPage';
        const customUrl = isCustomUrl || isDemoUrl ? pathSegments[2] : null;
        const pageId = isSettingsPage || isSettingsDemoPage ? pathSegments[pathSegments.length - 1] : null;

        if ((isCustomUrl || isDemoUrl) && customUrl) {
            const refPath = isDemoUrl ? `/demoPublicPages/${customUrl}` : `/publicPages/${customUrl}`;
            get(ref(db, refPath)).then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    if (data.pageId && data.userId) {
                        loadFonts(db, data.pageId, data.userId, isDemoUrl);
                    } else {
                        loadFonts(db, pageId, auth.currentUser.uid, false);
                    }
                }
            });
        } else if ((isSettingsPage || isSettingsDemoPage) && pageId) {
            const userId = auth.currentUser ? auth.currentUser.uid : null;
            if (userId) {
                loadFonts(db, pageId, userId, isSettingsDemoPage);
            }
        }
    }, [location.pathname]);

    const loadFonts = (db, pageId, userId, isDemo) => {
        let basePath = isDemo ? `/demoPages/${userId}/${pageId}` : `/pages/${pageId}`;
        const fontRef = ref(db, `${basePath}/selectedFont`);
        const globalSmallFontRef = ref(db, `${basePath}/globalSmallFont`);
        const globalBigFontRef = ref(db, `${basePath}/globalBigFont`);

        const updateFont = (snapshot, fontKey) => {
            if (snapshot.exists()) {
                setAllFonts(prevFonts => ({
                    ...prevFonts,
                    [fontKey]: snapshot.val(),
                }));
            }
        };

        get(fontRef).then(snapshot => updateFont(snapshot, 'selectedFont'));
        get(globalSmallFontRef).then(snapshot => updateFont(snapshot, 'globalSmallFont'));
        get(globalBigFontRef).then(snapshot => updateFont(snapshot, 'globalBigFont'));
    };

    return (
        <FontContext.Provider value={allFonts}>
            {children}
        </FontContext.Provider>
    );
};
