import React, { createContext, useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref as dbRef, onValue } from 'firebase/database';

export const UnreadStatusContext = createContext();

export const UnreadStatusProvider = ({ children }) => {
    const [unreadStatus, setUnreadStatus] = useState({});
    const auth = getAuth();

    useEffect(() => {
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        const userId = currentUser.uid;
        const database = getDatabase();
        const chatsRef = dbRef(database, 'Chats');

        const handleValueChange = (snapshot) => {
            const chats = snapshot.val();
            const updatedUnreadStatus = {};

            for (const chatId in chats) {
                const chat = chats[chatId];
                const messages = chat.messages || {};

                for (const messageId in messages) {
                    const message = messages[messageId];
                    if (message.userId !== userId && !message.readStatus) {
                        updatedUnreadStatus[chatId] = true;
                        break;
                    }
                }

                if (!updatedUnreadStatus[chatId]) {
                    updatedUnreadStatus[chatId] = false;
                }
            }

            setUnreadStatus(updatedUnreadStatus);
        };

        const unsubscribe = onValue(chatsRef, handleValueChange);

        return () => unsubscribe();
    }, [auth]);

    return (
        <UnreadStatusContext.Provider value={unreadStatus}>
            {children}
        </UnreadStatusContext.Provider>
    );
};
