import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import './SuccessPage.css'; // Importiere die CSS-Datei

const SuccessPage = () => {
  const [status, setStatus] = useState('loading');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCheckoutSession = async () => {
      if (!sessionId) {
        setStatus('error');
        return;
      }

      try {
        const functions = getFunctions();
        const verifyCheckoutSession = httpsCallable(functions, 'verifyCheckoutSession');
        const { data } = await verifyCheckoutSession({ sessionId });

        if (data.success) {
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.error('Error verifying checkout session:', error);
        setStatus('error');
      }
    };

    fetchCheckoutSession();
  }, [sessionId]);

  const handleHomeClick = () => {
    navigate('/');
  };

  return (
    <div className="success-page">
      {status === 'loading' && <p>Loading...</p>}
      {status === 'success' && (
        <div>
            <img src="./img/diamond_1.svg" alt="" />
          <h2>Your payment was successful!<br/></h2>
          <p>Your premium status has been updated.</p>
          <button onClick={handleHomeClick}>Go to Home</button>
        </div>
      )}
      {status === 'error' && <p>There was an error processing your payment. Please contact support.</p>}
    </div>
  );
};

export default SuccessPage;
