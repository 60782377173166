import React from 'react';
import DashboardHeader from '../AdminComponents/DashboardHeader';
import CheckoutButton from '../Checkout/CheckoutButton';
import './BuyPremium.css';
import GenerativeBackground from './GenerativeOrbAnimation';

const BuyPremium = () => {
    return (
        <div className="buy-premium-container">
           <DashboardHeader />
            <GenerativeBackground />
            <div className="buy-premium-text-wrapper">
            <h2>Your Trial has ended</h2>
            <p>Your trial period has ended. Please upgrade to continue using BonusClub.</p>
            <CheckoutButton />
            </div>
        </div>
    );
};

export default BuyPremium;
