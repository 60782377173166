import React from 'react';
import './ImageSizeSlider.css'; // Stellen Sie sicher, dass Sie die CSS-Datei entsprechend erstellen

const OpacitySlider = ({ label, opacity, setOpacity }) => {
  // Umrechnung der Slider-Werte (0 bis 100) in Opazitätswerte (0 bis 1)
  const handleSliderChange = (e) => {
    const sliderValue = e.target.value; // Wert von 0 bis 100
    const opacityValue = sliderValue / 100; // Umrechnung in Wert von 0 bis 1
    setOpacity(opacityValue);
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ''); // Erlaube Dezimalzahlen
    // Stelle sicher, dass der Wert nicht größer als 100 ist
    const numericValue = Math.min(Number(value), 100);
    const opacityValue = numericValue / 100; // Umrechnung in Wert von 0 bis 1
    setOpacity(opacityValue);
  };

  return (
    <div className="image-size-slider-container">
      <label className="slided-label">{label}</label>
      <input
        type="range"
        min="1"
        max="100" // Setze das Maximum auf 100
        value={opacity * 100} // Berechne den aktuellen Slider-Wert basierend auf der Opazität
        className="slided"
        id="opacity-slider"
        onChange={handleSliderChange}
      />
      <input
        type="text"
        className="slided-value-input"
        value={Math.round(opacity * 100)} // Zeige den Wert im Textfeld als ganzzahligen Wert von 0 bis 100
        onChange={handleInputChange}
      />
    </div>
  );
};

export default OpacitySlider;
