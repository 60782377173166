import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, onValue, set } from 'firebase/database'; // Import 'set' hier
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AddWidgets from './AddWidgets';
import PagePreview from './PagePreview';
import WidgetEdit from './WidgetEdit';
import './PageSettings.css'; // Importieren Sie Ihre CSS-Datei
import PageNav from './PageNav';

function PageSettings() {
    const { pageId } = useParams();
    const db = getDatabase();
    const auth = getAuth();
    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState({ widgets: [] });
    const [activeWidgetId, setActiveWidgetId] = useState(null);
    const [allPages, setAllPages] = useState([]); // Neuer State für alle Seiten
    const [showPopup, setShowPopup] = useState(false);
    const [colors, setColors] = useState([]); // Neuer State für Farbdaten

    function generateUniqueId() {
        return Math.random().toString(36).substr(2, 9);
    }

    useEffect(() => {
        const colorsRef = ref(db, `/pages/${pageId}/colors`);
        onValue(colorsRef, (snapshot) => {
            const colorsData = snapshot.val() || [];
            setColors(colorsData); // Speichert die abgerufenen Farbdaten im State
        });
    }, [db, pageId]); // Abhängigkeiten für useEffect

    useEffect(() => {
        // Stellen Sie sicher, dass ein Widget ausgewählt ist, wenn Widgets vorhanden sind
        if (pageData.widgets.length > 0 && activeWidgetId === null) {
            setActiveWidgetId(pageData.widgets[0].id);
        }
    }, [pageData.widgets, activeWidgetId]); // Fügen Sie activeWidgetId hinzu, um sicherzustellen, dass dies nur läuft, wenn kein Widget aktiv ist

    const updateWidgetsInDatabase = (widgetsArray) => {
        // Aktualisiere den bestehenden Pfad
        const widgetsRef = ref(db, `/pages/${pageId}/widgets`);
        set(widgetsRef, widgetsArray)
            .then(() => console.log("Widgets erfolgreich als Array in der Datenbank aktualisiert."))
            .catch((error) => console.error("Fehler beim Aktualisieren der Widgets als Array in der Datenbank:", error));
    };

    // Hilfsfunktion, um die Widgets in der Firebase-Datenbank zu aktualisieren
    const handleUpdateWidget = (updatedWidget) => {
        const updatedWidgets = pageData.widgets.map(widget =>
            widget.id === updatedWidget.id ? updatedWidget : widget
        );
        setPageData({ ...pageData, widgets: updatedWidgets });
        updateWidgetsInDatabase(updatedWidgets);
    };

    // Funktion zum Neuordnen der Widgets
    const onReorderWidgets = (startIndex, endIndex) => {
        if (!pageData.widgets || !Array.isArray(pageData.widgets)) {
            // Wenn pageData.widgets nicht existiert oder kein Array ist, setzen Sie es auf ein leeres Array
            setPageData({ ...pageData, widgets: [] });
            console.log("pageData.widgets existiert nicht oder ist kein Array. Wird auf leeres Array gesetzt.");
            return;
        }

        // Widgets neu anordnen
        const updatedWidgets = [...pageData.widgets];
        const [movedWidget] = updatedWidgets.splice(startIndex, 1);
        updatedWidgets.splice(endIndex, 0, movedWidget);

        // Aktualisiere den lokalen Zustand
        const updatedPageData = { ...pageData, widgets: updatedWidgets };
        setPageData(updatedPageData);

        // Aktualisiere die Datenbank
        const pageRef = ref(db, `/pages/${pageId}`);
        set(pageRef, updatedPageData)
            .then(() => {
                console.log("Datenbank erfolgreich aktualisiert.");
            })
            .catch((error) => {
                console.error("Fehler beim Aktualisieren der Datenbank:", error);
            });

        console.log(`Widgets neu angeordnet. Start-Index: ${startIndex}, End-Index: ${endIndex}`);
    };

    const toggleWidgetActivation = (widgetId) => {
        setActiveWidgetId(prevActiveWidgetId => {
            // Wenn die aktuelle ID gleich der neuen ist, setze zuerst auf null
            if (prevActiveWidgetId === widgetId) {
                setTimeout(() => {
                    setActiveWidgetId(widgetId);
                }, 0);
                return null;
            }
            // Ansonsten setze die neue ID
            return widgetId;
        });
    };

    const selectedWidget = pageData.widgets.find(widget => widget.id === activeWidgetId);

    const handleAddWidget = (newWidget) => {
        const updatedWidgets = [...(pageData.widgets || []), { ...newWidget, id: generateUniqueId() }];
        const updatedPageData = { ...pageData, widgets: updatedWidgets };
        setPageData(updatedPageData);

        // Update in der Firebase-Datenbank
        const pageRef = ref(db, `/pages/${pageId}`);
        set(pageRef, updatedPageData);

        // Popup anzeigen
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 3000); // Popup nach 3 Sekunden ausblenden
    };

    const handleRemoveWidget = (widgetId) => {
        // Aktualisiere das pageData-Objekt, indem das ausgewählte Widget entfernt wird
        const updatedWidgets = pageData.widgets.filter(widget => widget.id !== widgetId);
        const updatedPageData = { ...pageData, widgets: updatedWidgets };
        setPageData(updatedPageData);

        const pageRef = ref(db, `/pages/${pageId}`);
        set(pageRef, updatedPageData);
    };

    // Hilfsfunktion zum Generieren einer einzigartigen ID für jedes Widget
    function generateUniqueId() {
        return Math.random().toString(36).substr(2, 9);
    }

    useEffect(() => {
        // Abonnement für Authentifizierungsstatus
        const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
            if (user) {
                // Referenz für alle Seiten des Benutzers
                const pagesRef = ref(db, `/pages/${user.uid}`);

                // Abonnement für Seitenwertänderungen
                const unsubscribeValue = onValue(pagesRef, (snapshot) => {
                    const pagesData = snapshot.val() || {};
                    const loadedPages = Object.keys(pagesData).map(key => ({
                        id: key,
                        ...pagesData[key]
                    }));

                    setAllPages(loadedPages); // Setzen des neuen States mit allen Seiten

                    // Referenz für die spezifische Seite des Benutzers
                    const pageRef = ref(db, `/pages/${pageId}`);

                    // Abonnement für Seitenwertänderungen der spezifischen Seite
                    const unsubscribePageValue = onValue(pageRef, (snapshot) => {
                        const pageData = snapshot.val();
                        if (pageData) {
                            // Prüfen, ob die Widgets als Objekt vorliegen und in ein Array konvertieren
                            let widgetsArray = [];
                            if (pageData.widgets && typeof pageData.widgets === 'object') {
                                widgetsArray = Object.keys(pageData.widgets).map(key => ({
                                    id: key,
                                    ...pageData.widgets[key]
                                }));
                            }

                            // Setzen des Seitenzustands mit Widgets als Array
                            setPageData({ ...pageData, widgets: widgetsArray });
                        } else {
                            console.log('Keine Daten gefunden für: ', pageId);
                        }
                        setLoading(false); // Laden abgeschlossen
                    });

                    // Bereinigungsfunktion für onValue-Abonnement der spezifischen Seite
                    return () => {
                        unsubscribePageValue();
                        unsubscribeValue();
                    };
                });

                // Bereinigungsfunktion für onValue-Abonnement aller Seiten
                return () => unsubscribeValue();
            } else {
                // Benutzer ist nicht angemeldet
                setPageData(null);
                setLoading(false);
            }
        });

        // Bereinigungsfunktion für onAuthStateChanged-Abonnement
        return () => unsubscribeAuth();
    }, [auth, db, pageId]);

    if (loading) {
        return <div className="loading-spinner">
            <h1>BonusClub</h1>
            <div className="spinner-circle"></div>
        </div>
    }

    if (!pageData) {
        return <div>Seite nicht gefunden.</div>; // Nachricht, wenn keine Daten vorhanden sind
    }

    // Zugriff auf die Daten
    const pageName = pageData.name || 'Unnamed Page';
    const pageDescription = pageData.description || 'No Description';
    const pageCustomRoute = pageData.customRoute || 'No Custom Route';

    return (
        <div>
            <PageNav pages={allPages} projectName={pageData.name} />
            <div className="page-settings-container">
                <div className="add-widgets">
                    <AddWidgets onAddWidget={handleAddWidget} />
                </div>
                <div className="page-preview">
                    {showPopup && (
                        <div className="page-preview-popup">New Widget Added
                            <img src="/img/down_2.svg" alt="" />
                        </div>
                    )}
                    <PagePreview
                        pageData={pageData}
                        onRemoveWidget={handleRemoveWidget}
                        onAddWidget={handleAddWidget}
                        onReorderWidgets={onReorderWidgets}
                        onToggleActivation={toggleWidgetActivation}
                        selectedWidget={selectedWidget}
                    />
                </div>
                <div className="widget-edit">
                    <WidgetEdit selectedWidget={selectedWidget} onUpdateWidget={handleUpdateWidget} colors={colors} />
                </div>
            </div>
        </div>
    );
}

export default PageSettings;
