import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getDatabase, ref, onValue } from "firebase/database";
import { useParams } from 'react-router-dom';
import { setUserData } from '../Redux/actions';
import '../Steps/UserDatafields.css';
import { Link } from "react-router-dom";
import { useFont } from '../Design/FontContext';


const UserDatafieldsBackend = ({ handleBack, onValidationChange }) => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.userData);
    const [fields, setFields] = useState([]);
    const { userId } = useParams();
    const [userDataFieldsTitle, setUserDataFieldsTitle] = useState("");
const [userDataFieldsDescription, setUserDataFieldsDescription] = useState("");
const selectedFont = useFont();
const [termsOfUseLink, setTermsOfUseLink] = useState('');
const [imprintLink, setImprintLink] = useState('');


    const checkboxes = [
        {
            name: 'agbAndTerms',
            label: 'Terms of use',
            required: true,
            type: 'checkbox',
            selected: true,
        }, 
        {
            name: 'privacyTerms',  // Ändern Sie dies, um es eindeutig zu machen
            label: 'Privacy Terms',
            required: true,
            type: 'checkbox',
            selected: true,
        }
    ];
    
    const handleFieldChange = (fieldName, value) => {
        const updatedData = { ...userData, [fieldName]: value };
        dispatch(setUserData(updatedData));
    };

    useEffect(() => {
        const db = getDatabase();
        const fieldsRef = ref(db, `surveyConfigs/${userId}/fields`);
        const configRef = ref(db, `surveyConfigs/${userId}`);
    
        onValue(fieldsRef, (snapshot) => {
            const data = snapshot.val();
            const selectedFields = Object.values(data || {}).filter(field => field.selected);
            setFields(selectedFields);
        }, {
            onlyOnce: true
        });
    
        onValue(configRef, (snapshot) => {
            const configData = snapshot.val();
            if (configData) {
                setUserDataFieldsTitle(configData.userDataFieldsTitle || "");
                setUserDataFieldsDescription(configData.userDataFieldsDescription || "");
                setTermsOfUseLink(configData.termsOfUseLink || "https://bonusclub.io/terms-and-conditions"); // Standard-Platzhalter, wenn nicht vorhanden
                setImprintLink(configData.imprintLink || "www.bonusclub.io"); // Standard-Platzhalter, wenn nicht vorhanden
            }
        });
    }, [userId]);
    

    const areRequiredFieldsFilled = () => {
        for (let field of fields) {
            if (field.required && (!userData[field.name] || userData[field.name].trim() === '')) {
                return false;
            }
        }
        for (let checkbox of checkboxes) {
            if (checkbox.required && !userData[checkbox.name]) {
                return false;
            }
        }
        return true;
    };
    

    useEffect(() => {
        let isValid = areRequiredFieldsFilled();

        fields.forEach(field => {
            if (field.name === "email") {
                const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                if (!emailPattern.test(userData[field.name])) {
                    isValid = false;
                }
            }

            if (field.name === "telephone" && userData[field.name]) {
                if (!/^[\d\s-+]+$/.test(userData[field.name])) {
                    isValid = false;
                }
            }
        });

        if (onValidationChange) {
            onValidationChange(isValid);
            
        }
        

    }, [userData, fields, checkboxes, onValidationChange]);

    const SVG_URLS = {
        firstName: "../img/user.svg",
        lastName: "../img/user.svg",
        email: "../img/email.svg",
        telephone: "../img/phone.svg",
        instagram: "../img/instagram.svg",
        linkedIn: "../img/linkedin.svg",
        facebook: "../img/facebook.svg",
        tiktok: "../img/tiktok.svg",
        checkbox: "../img/checkbox.svg", // Fügen Sie eine SVG für Checkboxen hinzu, falls gewünscht.
        default: "../img/user.svg"     // Ein Standard-Icon, falls benötigt.
    };

    return (
        <div className="user-data-container">
        <h2 style={{ fontFamily: selectedFont }} className="surveyHeader">{userDataFieldsTitle}</h2>
            <p className="surveyParagraph">
            <p className="surveyParagraph">{userDataFieldsDescription}</p>
            </p>

            {[...fields, ...checkboxes.filter(checkbox => checkbox.selected)].map((field) =>(
    <div key={field.name} className={field.type !== "checkbox" ? "field-container" : 
    (field.name === 'agbAndTerms' ? "agb-checkbox-container" : "privacy-checkbox-container")}>
                {field.type !== "checkbox" ? (
            <div className="input-wrapper">
                <input
                    type={field.type || "text"}
                    name={field.name}
                    value={userData[field.name] || ""}
                    onChange={(e) => handleFieldChange(field.name, e.target.value)}
                    placeholder={field.label}
                    className="text-input"
                />
                <img src={SVG_URLS[field.name] || SVG_URLS.default} alt={`${field.name} icon`} className="field-icon" />
            </div>
            
        ) : (
            <div className="checkbox-wrapper">
                <input
                    type="checkbox"
                    name={field.name}
                    id={field.name} 
                    checked={userData[field.name] || false}
                    onChange={(e) => handleFieldChange(field.name, e.target.checked)}
                    className="checkbox-input"
                />
                <label htmlFor={field.name} className="checkbox-label">
                    <div className="agb_div">
                        {/* Entsprechend dem Namen des Kontrollkästchens einen Link generieren */}
                        {field.name === 'agbAndTerms' ? (
                          <div className="agb-text-1">
                    <p>I agree to the <a href={termsOfUseLink} target="_blank" rel="noopener noreferrer" className="agb-name">Terms of Use</a> and the <a href={imprintLink} target="_blank" rel="noopener noreferrer" className="agb-name">Privacy Terms</a></p>
                          </div> 
                        ) : field.name === 'privacyTerms' ? (
                            <div className="agb-text">
                            <p>I allow this Bonus Club to use my content for online promotion <br/> and agree to be added to the newsletter.</p>
                        </div>                             ) : (
                            <p className="agb-name">{field.label}</p>
                        )}
                    </div>
                
                </label>
                
            </div>
        )}
        
    </div>
))}  

        </div>
    );
}    

export default UserDatafieldsBackend;
