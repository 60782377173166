import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import CountryDropdown from './CountryDropdown';
import './WelcomeInfos.css';
import GenerativeBackground from './GenerativeOrbAnimation';
import StepIndicator from './StepIndicator';
import { useNavigate } from 'react-router-dom';

const typeOptions = [
    { label: 'Hotel Manager', value: 'Hotel Manager' },
    { label: 'Hotel Owner', value: 'Hotel Owner' },
    { label: 'Airbnb Host', value: 'Airbnb Host' },
    { label: 'Marketing Manager', value: 'Marketing Manager' },
    { label: 'Other', value: 'other' }
];

const WelcomeInfos = () => {
    const [step, setStep] = useState(1);
    const [country, setCountry] = useState('');
    const [type, setType] = useState('');
    const [hotelName, setHotelName] = useState(''); // New state for Hotel Name
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [newsletterAccepted, setNewsletterAccepted] = useState(false);

    const db = getDatabase();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            const userDetailsRef = ref(db, `Users/${currentUser.uid}/basicUserInfos`);
            get(userDetailsRef).then(snapshot => {
                if (snapshot.exists()) {
                    navigate('/home'); // Redirect to home if user details exist
                }
            }).catch(error => {
                console.error('Error fetching user details:', error);
            });
        }
    }, [currentUser, navigate, db]);

    const handleNext = () => {
        if (step === 1 && country && type) {
            setStep(2);
        } else if (step === 2 && hotelName && name && newsletterAccepted) {
            saveUserDetails();
        }
    };

    const saveUserDetails = () => {
        if (currentUser) {
            const userId = currentUser.uid;
            const userDetailsRef = ref(db, `Users/${userId}/basicUserInfos`);
            set(userDetailsRef, {
                country,
                type,
                hotelName, // Save Hotel Name
                name,
                phoneNumber,
                newsletterAccepted,
                newsletterAcceptedDate: new Date().toISOString(),
            }).then(() => {
                const basicInfosRef = ref(db, `Users/${userId}/basicInfos`);
                return set(basicInfosRef, true);
            }).then(() => {
                const pagesFreeTrialRef = ref(db, `Users/${userId}/pagesFreeTrial`);
                return set(pagesFreeTrialRef, {
                    startDate: new Date().toISOString(),
                    hasPagesTrial: true
                });
            }).then(() => {
                console.log('User details saved successfully');
                navigate('/');  // Redirect to the homepage after saving
            }).catch(error => {
                console.error('Error saving user details:', error);
            });
        }
    };

    const getTitleAndSubtitle = () => {
        if (step === 1) {
            return {
                title: "Start your free trial",
                subtitle: "Please complete the following information to get started."
            };
        } else if (step === 2) {
            return {
                title: "Almost done!",
                subtitle: "Just a few more details to set up your account."
            };
        }
        return {};
    };

    const { title, subtitle } = getTitleAndSubtitle();

    return (
        <div className="welcome-container">
            <GenerativeBackground />
            <img src="./img/BonusClub.png" alt="" />
            <div className="welcome-form">
                <StepIndicator currentStep={step} />
                <h1>{title}</h1>
                <p>{subtitle}</p>
                {step === 1 ? (
                    <>
                        <CountryDropdown value={country} onChange={setCountry} />
                        <label style={{ fontFamily: 'Jost', fontSize: '16px', marginBottom: '10px', color: '#333', textAlign: 'left' }}>Type</label>
                        <div className="type-buttons">
                            {typeOptions.map(option => (
                                <button
                                    key={option.value}
                                    className={`select-genre-list ${type === option.value ? 'selected' : ''}`}
                                    onClick={() => setType(option.value)}
                                >
                                    {option.label}
                                </button>
                            ))}
                        </div>
                        <button onClick={handleNext} disabled={!country || !type} className="welcome-button">Next</button>
                    </>
                ) : (
                    <>
                        <input
                            type="text"
                            value={hotelName}
                            onChange={(e) => setHotelName(e.target.value)}
                            placeholder="Hotel Name"
                            className="welcome-input"
                        />
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Your Full Name"
                            className="welcome-input"
                        />
                        <input
                            type="tel"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Phone Number (optional)"
                            className="welcome-input"
                        />
                        <label className="welcome-checkbox">
                            <div>
                                <input
                                    type="checkbox"
                                    checked={newsletterAccepted}
                                    onChange={() => setNewsletterAccepted(!newsletterAccepted)}
                                />
                                <div className="custom-checkbox"></div>
                            </div>
                            <p>I’d love to receive our hotel marketing news and special offers! (No spam and you can unsubscribe any time)</p>
                        </label>
                        <button onClick={handleNext} disabled={!hotelName || !name || !newsletterAccepted} className="welcome-button">Start 30 Day Trial</button>
                    </>
                )}
            </div>
        </div>
    );
};

export default WelcomeInfos;
