import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { UnreadStatusContext } from '../Pages/Chats/UnreadStatusContext';
import './AgencyMenu.css';

function AgencyMenu() {
    const unreadStatus = useContext(UnreadStatusContext);

    const hasUnreadMessages = Object.values(unreadStatus).some(status => status);

    return (
        <div className="agency-menu">
            <NavLink to="/agency" className="agency-menu-item" activeClassName="active">
                <div className="agency-menu-content">
                    <div>
                        <h3>BROWSE ALL CREATIVES</h3>
                        <p className="agency-menu-inner-bottom-font">Order your next Design</p>
                    </div>
                    <div className="agency-menu-icon">
                    <img src="./img/delivery_2.svg" alt="Search" />
                    </div>                </div>
            </NavLink>
            <NavLink to="/chat" className="agency-menu-item" activeClassName="active">
                <div className="agency-menu-content">
                    <div className="agency-menu-inner">
                        <div>
                            <img src="./img/val.webp" alt="" />
                        </div>
                        <div>
                            {hasUnreadMessages && <h5>1</h5>}
                            <h4>{hasUnreadMessages ? "NEW MESSAGE" : "CHAT"}</h4>
                            <h3>BRAND MANAGER</h3>
                            <p className="agency-menu-inner-bottom-font">Valerie Wickl</p>
                        </div>
                    </div>
                    <div className="agency-menu-icon">
                    <img src="./img/sendo.svg" alt="Search" />
                    </div>
                </div>
            </NavLink>
            <NavLink to="/brand" className="agency-menu-item" activeClassName="active">
                <div className="agency-menu-content">
                    <div>
                        <h3>DIGITAL BRANDBOOK</h3>
                        <p className="agency-menu-inner-bottom-font">Order your next Design</p>
                    </div>
                    <div className="agency-menu-icon">
                    <img src="./img/cloud.svg" alt="Search" />
                    </div>                </div>
            </NavLink>
        </div>
    );
}

export default AgencyMenu;
