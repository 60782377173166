import React, { useState, useEffect, useRef } from 'react'; // Fügen Sie useEffect hinzu
import { addSurveyConfig } from '../firebaseConfig';  // Pfad zu Ihrer Firebase-Konfigurationsdatei aktualisieren
import './AdminConfigSettings.css';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDatabase, ref as dbRef, onValue, get } from "firebase/database";
import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import { getAuth } from 'firebase/auth';
import { update } from 'firebase/database';
import { getSpecificSurveyConfig } from '../firebaseConfig';
import DashboardHeader from '../AdminComponents/DashboardHeader';
import BonusIdeas from '../AdminComponents/BonusIdeas';
import { Link } from 'react-router-dom';


function AdminConfigSettings() {
    const [selectBonus, setSelectBonus] = useState([{ bonusId: 'bonus1', label: '', img: '', description: '', type: '', emailTemplateLink: '' }]);
    const [quickQuestions, setQuickQuestions] = useState([{ placeholder: '', options: [{ value: '', label: '' }] }]);
    const [fileUploader, setFileUploader] = useState({
        minFiles: 1,
        maxFiles: 50
    });    
    const [selectBonusTitle, setSelectBonusTitle] = useState('Choose Your Bonus');
    const [selectBonusDescription, setSelectBonusDescription] = useState('Select a special gift from us in appreciation of your valuable photos.');
    const [surveyPrimaryColor, setSurveyPrimaryColor] = useState("#4080B0"); 
    const [quickQuestionsTitle, setQuickQuestionsTitle] = useState('Share Your Thoughts');
    const [quickQuestionsDescription, setQuickQuestionsDescription] = useState('Please take a moment to answer some brief questions. We would love to know more about your experience.');
    const [uploadTitle, setUploadTitle] = useState('Share Your Snaps');
    const [uploadDescription, setUploadDescription] = useState('Upload your pictures or videos here. Let us showcase those memories on our social media!');
    const [userDataFieldsTitle, setUserDataFieldsTitle] = useState('Almost There');
    const [userDataFieldsDescription, setUserDataFieldsDescription] = useState('Just a little info to keep in touch. We promise to keep it safe and sound.');
    const [isSurveyActive, setIsSurveyActive] = useState(true);
    const [isBonusActive, setIsBonusActive] = useState(true);
    const [isQuickQuestionsActive, setIsQuickQuestionsActive] = useState(false);
    const [isUploaderActive, setIsUploaderActive] = useState(true);
    const [isUserDataActive, setIsUserDataActive] = useState(true);
    const [bonusPosition, setBonusPosition] = useState(1)
    const [quickQuestionsPosition, setQuickQuestionsPosition] = useState(2);
    const [uploadPosition, setUploadPosition] = useState(3);
    const [userdataPosition, setUserdataPosition] = useState(4);
    const [thankYouPosition, setThankYouPosition] = useState(5);
    const [adminMail, setAdminMail] = useState('');
    const [isFollowUpActive, setIsFollowUpActive] = useState(true);
    const [followUpIntervall, setFollowUpIntervall] = useState('1 Day');
    const [bonusImages, setBonusImages] = useState([]); // Fügen Sie diese Zeile am Anfang Ihrer Komponentenfunktion hinzu
    const [imagePreview, setImagePreview] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const [isUploadingBanner, setIsUploadingBanner] = useState(false);
    const [uploadingBonusIndexes, setUploadingBonusIndexes] = useState([]);
    const [selectedFont, setSelectedFont] = useState('');
    const [userLink, setUserLink] = useState('');
    const [headerImage, setHeaderImage] = useState('');
    const [objectType, setObjectType] = useState('type1');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalContentRef = useRef(null);
    const [isPremium, setIsPremium] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const [termsOfUseLink, setTermsOfUseLink] = useState('');
    const [imprintLink, setImprintLink] = useState('');


    const initialFields = [
        { name: "firstName", label: "First Name", required: true, type: "text", selected: true },
        { name: "lastName", label: "Last Name", required: true, type: "text", selected: true },
        { name: "email", label: "Email", required: true, type: "email", selected: true },
        { name: "telephone", label: "Phone", required: true, type: "text", selected: false },
        { name: "instagram", label: "Instagram", required: false, type: "text", selected: false },
        { name: "tiktok", label: "TikTok", required: false, type: "text", selected: false },
        { name: "facebook", label: "Facebook", required: false, type: "text", selected: false },
        { name: "linkedIn", label: "Linked-In", required: false, type: "text", selected: false },
        { name: "socialMedia", label: "Social Media", required: false, type: "text", selected: false }
    ];
    
    const [thankYouSettings, setThankYouSettings] = useState({
        thankYouImage: "",
        thankYouText: "We appreciate you sharing your moments with us. Look forward to your special gift in your inbox!"
    });
    const [fields, setFields] = useState(initialFields);
const [message, setMessage] = useState('');

const [saveConfirmation, setSaveConfirmation] = useState(false);
const auth = getAuth();
const currentUser = auth.currentUser;
const [specificConfig, setSpecificConfig] = useState(null);

const handleOutsideClick = (event) => {
    // Check if the click is outside the modal content
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
        setIsModalOpen(false);
    }
};

useEffect(() => {
    if (isModalOpen) {
        document.addEventListener('mousedown', handleOutsideClick);
    } else {
        document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
    }
}, [isModalOpen]);

const dummyData = {
    type1: {
        typeTitle: "Share Your Media",
        typeStep1: "Snap & Record",
        typeDescription1: "Capture your moments with us. We'd love to see your experience!",
        typeStep2: "Upload Here",
        typeDescription2: "Got your content ready? Great! Drop it here. We would love to feature it on our channels.",
        typeStep3: "Receive a Bonus",
        typeDescription3: "Thanks for letting us be part of your memory! There's a bonus for you at the next step."
    },
    type2: {
        typeTitle: "Tag us on Social Media",
        typeStep1: "Post on Instagram, TikTok, etc.",
        typeDescription1: "Showcase your experience. Post a photo or story and don’t forget to tag us!",
        typeStep2: "Share Proof with Us",
        typeDescription2: "Done posting? Just upload a screenshot here as proof and ensure we're tagged.",
        typeStep3: "Get Your Bonus",
        typeDescription3: "We appreciate the love! As a thank you, there’s a bonus waiting for you."
    }
    ,
    type3: {
        typeTitle: "Review us Online",
        typeStep1: "Leave a Google Review",
        typeDescription1: "Your insights matter. Share your thoughts and help us serve better.",
        typeStep2: "Provide Proof",
        typeDescription2: "Done reviewing? Just share a screenshot of your review here.",
        typeStep3: "Claim Your Bonus",
        typeDescription3: "Your feedback is valued! Enjoy a special thank-you bonus from us."
    }
    
}

useEffect(() => {
    const fetchDescription = async () => {
        const descriptionsFromDB = await isTypeDescriptionsPresent(currentUser.uid);
        if (descriptionsFromDB && descriptionsFromDB[objectType]) {
            setCurrentDescription(descriptionsFromDB[objectType]);
        } else {
            setCurrentDescription(dummyData[objectType]);
        }
    };

    fetchDescription();
}, [objectType, currentUser.uid]);


const [currentDescription, setCurrentDescription] = useState(dummyData[objectType]);

useEffect(() => {
    const fetchIsPremium = async () => {
        const db = getDatabase();
        const userId = currentUser.uid;
        const premiumRef = dbRef(db, `/Users/${userId}/isPremium`);
        const snapshot = await get(premiumRef);
        setIsPremium(snapshot.val() || false);
    };

    if (currentUser) {
        fetchIsPremium();
    }
}, [currentUser]);


    useEffect(() => {
        const db = getDatabase();
        const fetchSpecificConfig = async () => {
            if (currentUser) {
                const userId = currentUser.uid;
                console.log("Aktuelle UserID:", userId);
                const config = await getSpecificSurveyConfig(userId);
                setSpecificConfig(config);
            }
        }
        
        
        if (!currentUser) return; // Frühzeitiger Ausstieg aus dem useEffect, wenn kein Benutzer vorhanden ist.
const userId = currentUser.uid;
        const configRef = dbRef(db, `surveyConfigs/${userId}`);
        const unsubscribe = onValue(configRef, (snapshot) => {
            const data = snapshot.val();
            if (!data) {
                console.error("No data received from Firebase.");
                return;
            }

            if (data && data.templateId) {
                setTemplateId(data.templateId);
            }

            let description = null;
         


            if (data) {
                setHeaderImage(data.headerImage);
                setSelectBonus(data.selectBonus || []);
                setQuickQuestions(data.quickQuestions || []);
                setFileUploader(data.fileUploader);
                setFields(data.fields || initialFields);
                setThankYouSettings({
                    thankYouImage: data.thankYouSettings.thankYouImage,
                    thankYouText: (data.thankYouSettings && data.thankYouSettings.thankYouText) ? data.thankYouSettings.thankYouText : "We appreciate you sharing your moments with us. Look forward to your special gift in your inbox!"
                });
                setUserDataFieldsTitle(data.userDataFieldsTitle);
                setUserDataFieldsDescription(data.userDataFieldsDescription);
                setIsSurveyActive(data.isSurveyActive || false);
                setIsBonusActive(data.isBonusActive);
                setIsQuickQuestionsActive(data.isQuickQuestionsActive);
                setIsUploaderActive(data.isUploaderActive);
                setIsUserDataActive(data.isUserDataActive);
                setBonusPosition(data.bonusPosition || 1);
                setQuickQuestionsPosition(data.quickQuestionsPosition || 2);
                setUploadPosition(data.uploadPosition || 3);
                setUserdataPosition(data.userdataPosition || 4);
                setThankYouPosition(data.thankYouPosition || 5);
                setTermsOfUseLink(data.termsOfUseLink || '');
                setImprintLink(data.imprintLink || '');
                setSelectBonusTitle(data.selectBonusTitle);
                setSelectBonusDescription(data.selectBonusDescription);
                setSurveyPrimaryColor(data.surveyPrimaryColor || "#6897B7");
                setSelectedFont(data.selectedFont || "Voyage");
                setQuickQuestionsTitle(data.quickQuestionsTitle);
                setQuickQuestionsDescription(data.quickQuestionsDescription);
                setUploadTitle(data.uploadTitle);
                setUploadDescription(data.uploadDescription);
                setAdminMail(data.adminMail || currentUser.email );
                setIsFollowUpActive(data.isFollowUpActive || false);
                setFollowUpIntervall(data.followUpIntervall || '1 Day');
                setUserLink(`/survey/${userId}`);
                setObjectType(data.objectType);


                if (!data.typeDescriptions) {
                    switch (data.objectType) {
                        case 'type1':
                            setCurrentDescription(dummyData.type1);
                            break;
                        case 'type2':
                            setCurrentDescription(dummyData.type2);
                            break;
                        case 'type3':
                            setCurrentDescription(dummyData.type3);
                            break;
                        default:
                            setCurrentDescription({
                                typeTitle: '',
                                typeStep1: '',
                                typeDescription1: '',
                                typeStep2: '',
                                typeDescription2: '',
                                typeStep3: '',
                                typeDescription3: ''
                            });
                            break;
                    }
                } else {
                    // Use the typeDescriptions from the database
                    setCurrentDescription(data.typeDescriptions[data.objectType]);
                }
            }
        });
    
        return () => unsubscribe();
    }, []);
      function uploadImageToFirebase(file, context, bonusIndex = null) {
        if (!file || !context) {
            console.error("File or context missing for image upload.");
            return;
        }
        const maxFileSize = 0.5 * 1024 * 1024; // 0.5MB in bytes
        if (file.size > maxFileSize) {
            console.error("Error: File size exceeds the limit of 0.5MB.");
            alert("File size exceeds the limit of 0.5MB.");
            return;
        }
    
        let updateUploadingState = () => {};
        if (context === 'headerImages') {
            setIsUploadingLogo(true);
            updateUploadingState = () => setIsUploadingLogo(false);
        } else if (context === 'thankYouImages') {
            setIsUploadingBanner(true);
            updateUploadingState = () => setIsUploadingBanner(false);
        } else if (context === 'bonusImages' && bonusIndex !== null) {
            setUploadingBonusIndexes(prevIndexes => [...prevIndexes, bonusIndex]);
            updateUploadingState = () => setUploadingBonusIndexes(prevIndexes => prevIndexes.filter(index => index !== bonusIndex));
        }
        
        const storage = getStorage();
        const storageReference = storageRef(storage, context + '/' + file.name);
        const uploadTask = uploadBytesResumable(storageReference, file);
    
        uploadTask.on('state_changed',
            (snapshot) => {
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                console.error("Error uploading image:", error);
                updateUploadingState();
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    if (context === "headerImages") {
                        setHeaderImage(downloadURL);
                    } else if (context === "bonusImages" && bonusIndex !== null) {
                        const updatedBonuses = [...selectBonus];
                        updatedBonuses[bonusIndex].img = downloadURL;
                        setSelectBonus(updatedBonuses);
                    } else if (context === "thankYouImages") {
                        setThankYouSettings(prevSettings => ({ ...prevSettings, thankYouImage: downloadURL }));
                    }
                    updateUploadingState();
                }).catch(() => {
                    updateUploadingState();
                });
            }
        );
    }
    
  const addUserDataEntry = async (userId) => {
    const db = getDatabase();
    const userRef = dbRef(db, `Users/${userId}`);
    await update(userRef, { hasSettings: true });
    
}

const handleSaveDescription = async () => {
    const db = getDatabase();
    const descRef = dbRef(db, `surveyConfigs/${currentUser.uid}/typeDescriptions/${objectType}`);

    try {
        await update(descRef, currentDescription);
        alert("Description saved successfully!");
    } catch (error) {
        console.error("Error saving description:", error);
        alert("Failed to save description. Please try again.");
    }
}

    const handleAddBonus = () => {
        const newBonus = {
            bonusId: 'bonus' + (selectBonus.length + 1), 
            label: '', 
            img: '', 
            description: '', 
            type: '', 
            emailTemplateLink: ''
        };
        setSelectBonus(prevBonuses => [...prevBonuses, newBonus]);
    }
    const handleDeleteQuestion = (indexToRemove) => {
        const updatedQuestions = quickQuestions.filter((_, index) => index !== indexToRemove);
        setQuickQuestions(updatedQuestions);
    };
    const handleDeleteOption = (questionIndex, optionIndexToRemove) => {
        const updatedQuestions = [...quickQuestions];
        updatedQuestions[questionIndex].options = updatedQuestions[questionIndex].options.filter((_, optIndex) => optIndex !== optionIndexToRemove);
        setQuickQuestions(updatedQuestions);
    };

    const increaseValue = (type) => {
        setFileUploader(prev => {
            const currentValue = parseInt(prev[type], 10);
            // Überprüfen, ob currentValue NaN ist, und falls ja, es auf 0 setzen
            const newValue = (!isNaN(currentValue) ? currentValue : 0) + 1;
    
            return {
                ...prev,
                [type]: newValue
            };
        });
    };
    
    const decreaseValue = (type) => {
        setFileUploader(prev => {
            const currentValue = parseInt(prev[type], 10);
            // Überprüfen, ob currentValue NaN oder kleiner als 1 ist, 
            // und falls ja, es auf 0 setzen, sonst den Wert verringern
            const newValue = (!isNaN(currentValue) && currentValue > 0) ? currentValue - 1 : 0;
    
            return {
                ...prev,
                [type]: newValue
            };
        });
    };
    

    const isTypeDescriptionsPresent = async (userId) => {
        const db = getDatabase();
        const configRef = dbRef(db, `surveyConfigs/${userId}/typeDescriptions`);
        const snapshot = await get(configRef);
        const data = snapshot.val();
        return data;
    };
    

    
    const handleSubmit = async () => {

        const db = getDatabase();
        const configRef = dbRef(db, `surveyConfigs/${currentUser.uid}/typeDescriptions`);
        const snapshot = await get(configRef);
        const existingDescriptions = snapshot.val() || {};
    
        if (!existingDescriptions[objectType]) {
            existingDescriptions[objectType] = dummyData[objectType];
        }

    
        const surveyConfig = {
            userId: currentUser.uid,
            headerImage,
            selectBonus,
            selectBonusTitle,
            selectBonusDescription,
            bonusPosition,
            quickQuestions,
            quickQuestionsTitle,
            quickQuestionsDescription,
            quickQuestionsPosition,
            fileUploader,
            uploadTitle,
            uploadDescription,
            uploadPosition,
            fields,
            userDataFieldsTitle,
            userDataFieldsDescription,
            userdataPosition,
            thankYouSettings,
            thankYouPosition,
            isSurveyActive,
            isBonusActive,
            isQuickQuestionsActive,
            isUploaderActive,
            isUserDataActive,
            surveyPrimaryColor,
            selectedFont,
            adminMail: currentUser.email,
            isFollowUpActive,
            followUpIntervall,
            termsOfUseLink,
            imprintLink,
            objectType,
            typeDescriptions: {
                ...existingDescriptions,
                [objectType]: currentDescription
            },
            templateId: templateId || "d-4b58d531b9ed4c388f40dd66f3b00401",
        };
     

        addSurveyConfig(currentUser.uid, surveyConfig)
        .then(() => {
            return addUserDataEntry(currentUser.uid);
        })
        .then(() => {
            setSaveConfirmation(true);
            setTimeout(() => setSaveConfirmation(false), 3000);
        })
        .catch(error => {
            console.error("Fehler beim Speichern:", error);
        });
};
useEffect(() => {
    setCurrentDescription(dummyData[objectType]);
}, [objectType]);


useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  
    return () => {
      document.body.style.overflow = 'auto';
    }
  }, [isModalOpen]);
  

    const handleDeleteBonus = (indexToRemove) => {
        const updatedBonuses = selectBonus.filter((_, index) => index !== indexToRemove);
        setSelectBonus(updatedBonuses);
    }

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            const offset = 120; // Der gewünschte Offset in Pixeln
            const sectionTop = sectionElement.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = sectionTop - offset;
    
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };
    

 const [menuSections, setMenuSections] = useState([
    'Style',
    'Bonus',
    'Questions',
    'Image Uploader',
    'Userdata',
    'More',
]);

const [activeSection, setActiveSection] = useState(''); // Falls Sie es nicht bereits definiert haben.

useEffect(() => {
    const handleScroll = () => {
        const scrollPosition = window.scrollY + window.innerHeight / 2 + 700;
        let active = '';

        for (let i = 0; i < menuSections.length; i++) {
            const section = menuSections[i];
            const sectionElement = document.getElementById(section);

            if (!sectionElement) continue;

            const sectionEnd = sectionElement.offsetTop + sectionElement.offsetHeight;

            if (sectionElement.offsetTop <= scrollPosition && sectionEnd > scrollPosition) {
                active = section;
                console.log("Active Section:", active);
                break;
            }
        }

        setActiveSection(active);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

    
    {isUploading && <div className="spinner"></div>}
    

    
    return (
        <div className="admin-config">
            <div className="settings-header-bar">
                <h3>Create your Link</h3>
                <div className="prev-link-header-bar">
                    <div className="settings-switch-bar">
                    <h2>Bonus Club Link</h2>
                 
                  <span> <h2>Newsletter Wizard</h2></span> 
                  <div className="question-right-pro-4">
            <img className="crown" src="./img/saphire.svg" alt="lock"/>
            <p>Agency</p>
        </div>
                    </div>
                <div>
                <a className={userLink ? "preview-link active" : "preview-link"} href={userLink} target="_blank">Preview Link</a>
                <button 
    className={saveConfirmation ? 'settings-button-saved' : 'settings-button'} 
    onClick={handleSubmit}
>
    {saveConfirmation ? 'Saved' : 'Save Settings'}
</button>
</div>
 </div>
            </div>
             <div className="scroll-menu">
             {activeSection === "Questions" ? (
        <div className="question-right-pro-3">
            <img className="crown" src="./img/crow.svg" alt="lock"/>
            <p>PRO</p>
        </div>
    ) : null}
        <div className="back-menu">
            <Link to="/social" alt="home">
            <div>
            <img src="./img/right.svg" alt="back" />
            </div>
            <p>Back to home</p>
            </Link>
        </div>
    
        <div className="scroll-menu-header">
            <h4>Link Settings</h4>
            <hr />
        </div>
        {menuSections.map((sectionId, index) => (
    <button 
        key={sectionId} 
        onClick={() => scrollToSection(sectionId)}
        className={activeSection === sectionId ? 'menu-active' : 'menu'}
    >
       <p>{index + 1}</p> {sectionId}
    </button>
))}
<div className="scroll-menu-sub">
    <a href="/emails">
        <img className="right-sub-icon" src="./img/layout.svg" alt="right" />
    <p>Email Templates</p>
    <img className="right-sub-arrow" src="./img/right.svg" alt="right" /></a>

</div>
<div className="scroll-menu-sub-2" onClick={() => setIsModalOpen(true)}>
    <a>
        <img className="right-sub-icon" src="./img/gift.svg" alt="right" />
    <p>Bonus Generator</p>
    <img className="right-sub-arrow" src="./img/right.svg" alt="right" /></a>

</div>
<div className="scroll-menu-bottom">
    <a href="https://bonusclub.io">Contact the Team</a>
</div>
        </div>
        <div className="componenten-wrapper">
        <div className="menu-componenter">
            <h2>Create your link</h2>
            <p>Customize your BonusClub link to reflect your style, choose your favorite colors, and design every detail to create a unique experience.</p>
            <div className="object-type-container">
        <div className="object-type-boxes">
            <div 
                className={`object-type-box ${objectType === 'type1' ? 'object-type-box-active' : ''}`}
                onClick={() => setObjectType('type1')}
            >
                <img src="./img/Snapshot.webp" alt="selfie" />
            <h4>Guests send <br/> Videos & Pictures</h4>
            <p>Guests or customers send you their content for you to share.</p>
            </div>
            <div 
                className={`object-type-box ${objectType === 'type2' ? 'object-type-box-active' : ''}`}
                onClick={() => setObjectType('type2')}
            >
                                <img src="./img/Social_Media.webp" alt="selfie" />
            <h4>Guests post on their Social Media</h4>  
            <p>Guests or customers share content about you on social media.</p>
            </div>
            <div 
                className={`object-type-box ${objectType === 'type3' ? 'object-type-box-active' : ''}`}
                onClick={() => setObjectType('type3')}
            >
             <img src="./img/Review.webp" alt="selfie" />
            <h4>Guests leave a <br/> Google Review</h4>  
            <p>Visitors write reviews about your service.</p>
            </div>
        </div>
    </div>
            <div className="config-section" id="Style">
                <div className="config-section-head">
                    <div className="section-head-titles">
                    <p>1</p>  
                    <h3>Choose Your Style</h3>
                    </div>
                <div className="type-selection-container">
            
</div>


                {/* <div className="activate-survey">
<input 
            type="checkbox"
            checked={isSurveyActive}
            onChange={() => setIsSurveyActive(prevState => !prevState)}
        />
            <p>Link is Active</p>

       
    </div>     */}
                </div>
                <div className="upload_boxen">
            <div className="upload_1">
                <label>Company Logo</label>
                <label className="custom-upload-box" htmlFor="image-upload">
                    <input 
                        id="image-upload"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                                uploadImageToFirebase(file, 'headerImages');
                            }
                        }}
                        style={{ display: 'none' }}
                    />

                    {isUploadingLogo ? (
                        <div className="spinner-container">
                         <div className="spinner-circle"></div>
                            <p>Hochladen...</p> {/* Hier können Sie Ihren Spinner einfügen */}
                        </div>
                    ) : headerImage ? (
                        <img className="uploaded-image-preview" src={headerImage} alt="Vorhandenes Bild" />
                    ) : (
                        <div className="upload-image-preview">
                            <img src="./img/upload1.svg" />
                            <span>Click here to upload your logo</span>
                        </div>
                    )}
                </label>
            </div>

            <div className="upload_1">
                <label>Image Banner</label>
                <label className="custom-upload-box" htmlFor="thankyou-image-upload">
                    <input 
                        id="thankyou-image-upload"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                                uploadImageToFirebase(file, 'thankYouImages');
                            }
                        }}
                        style={{ display: 'none' }}
                    />

                    {isUploadingBanner ? (
                        <div className="spinner-container">
                     <div className="spinner-circle"></div>
                            <p>Uploading...</p>
                        </div>
                    ) : thankYouSettings.thankYouImage ? (
                        <img className="uploaded-image-preview" src={thankYouSettings.thankYouImage} alt="Vorhandenes Bild" />
                    ) : (
                        <div className="upload-image-preview">
                            <img src="./img/upload1.svg" />
                            <span>Click here to upload your banner</span>
                        </div>
                    )}
                </label>
            </div>
        </div>
<div className="colorPicker">
    <p>Main Color</p>
    <div 
        className="custom-color-display" 
        style={{backgroundColor: surveyPrimaryColor}}
    ></div>
    <input 
        type="color"
        value={surveyPrimaryColor}
        onChange={e => setSurveyPrimaryColor(e.target.value)}
    />
    <input 
        type="text"
        value={surveyPrimaryColor}
        onChange={e => setSurveyPrimaryColor(e.target.value)}
        maxLength={7}
        placeholder="#FFFFFF"
        style={{marginLeft: '10px'}}  // Ein wenig Abstand zwischen den Eingabefeldern
        className="hex-feld"
    />
</div>
 <div >
     <div className="font-select-wrapper">
     <p>Select Font</p>
     </div>
     <div className="fontSelector">

            <div 
                id="voyage"
                className={`font-option ${selectedFont === "Voyage" ? 'selected' : ''}`}
                onClick={() => {
                    setSelectedFont("Voyage");
                }}
            >
                Club
            </div>
            <div 
                 id="urbanist"
                className={`font-option ${selectedFont === "Urbanist" ? 'selected' : ''}`}
                onClick={() => {
                    setSelectedFont("Urbanist");
                }}
            >
                Urban
            </div>
            <div 
                id="classic"
                className={`font-option ${selectedFont === "Classic" ? 'selected' : ''}`}
                onClick={() => {
                    setSelectedFont("Classic");
                }}
            >
                Classic
            </div>
            <div 
               id="palms"
                className={`font-option ${selectedFont === "Palms" ? 'selected' : ''}`}
                onClick={() => {
                    setSelectedFont("Palms");
                }}
            >
                Palms
            </div>
            <div
                 id="boho"
                className={`font-option ${selectedFont === "Boho" ? 'selected' : ''}`}
                onClick={() => {
                    setSelectedFont("Boho");
                }}
            >
                Bebo
            </div>
            <div
                id="Fatty"
                className={`font-option ${selectedFont === "Dela" ? 'selected' : ''}`}
                onClick={() => {
                    setSelectedFont("Dela");
                }}
            >
                Fatty
            </div>
            <div
                id="Maserati"
                className={`font-option ${selectedFont === "Gruppo" ? 'selected' : ''}`}
                onClick={() => {
                    setSelectedFont("Gruppo");
                }}
            >
                Maserati
            </div>
            <div
                id="Moon"
                className={`font-option ${selectedFont === "Moonic" ? 'selected' : ''}`}
                onClick={() => {
                    setSelectedFont("Moonic");
                }}
            >
                Moon
            </div>
            <div
                id="Headline"
                className={`font-option ${selectedFont === "Poppins" ? 'selected' : ''}`}
                onClick={() => {
                    setSelectedFont("Poppins");
                }}
            >
                Headline
            </div>
            <div
                id="Valerie"
                className={`font-option ${selectedFont === "Valerie" ? 'selected' : ''}`}
                onClick={() => {
                    setSelectedFont("Valerie");
                }}
            >
                Valerie
            </div>
        </div>
        </div>


<div className="admin-email-component">
<p>Email Notifications to</p>
<input
    type="text"
    value={adminMail}
    onChange={(e) => setAdminMail(e.target.value)}
    placeholder={"Where should we send email notifications?"}
    className="admin-mail"
/>
</div> 

   
            </div>
            <div className="config-section" id="Bonus">
                <div className="config-section-head">
                <div className="section-head-titles">
                    <p>2</p>  
                    <h3>Add Your Bonus</h3>
                <label>
        {/* <input 
            className="is-active-box"
            type="checkbox"
            checked={isBonusActive}
            onChange={e => setIsBonusActive(e.target.checked)}
        /> */}
    </label>
  
    </div>
    {isModalOpen && (
  <div className="modal-overlay">
    <div className="modal-contento" ref={modalContentRef}>
      <h2>Bonus Generator</h2>
      <p>There are countless methods to provide outstanding bonuses without straining your budget. Explore over 1000 innovative suggestions.</p>      <BonusIdeas />
    </div>
    <button className="close-inspo-modal" onClick={() => setIsModalOpen(false)}>Close</button>
  </div>
)}
<div>
  <div className="inspiration">
  <button className="inspo-button" onClick={() => setIsModalOpen(true)}>Need Inspiration?</button>
</div>
                <button className="add_bonus_button" onClick={handleAddBonus}>Add Bonus</button></div></div>
    <div className="bonus-grid"> 
    {selectBonus.map((bonus, index) => (
        <div key={index} className="bonus-section">
            <div className="bonus-top-section">
            <h4>Bonus {index + 1}</h4>
            <button  onClick={() => handleDeleteBonus(index)}>
                <img className="trash-icon" src="./img/delete.svg" alt="trash"/>
            </button>
                </div>
            {/* Angepasster Bildupload */}
            <label className="custom-upload-box-2" htmlFor={`bonus-image-upload-${index}`}>
            <input 
                id={`bonus-image-upload-${index}`}
                type="file" 
                accept="image/*"
                onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                        uploadImageToFirebase(file, 'bonusImages', index);
                    }
                }}
                style={{ display: 'none' }}
            />
            
            {uploadingBonusIndexes.includes(index) ? (
                <span className="uploaded-image-preview-3">
                <div className="spinner-container">
                <div className="spinner-circle"></div>
                    <p>Uploading...</p>
                </div>
            </span>
            ) : bonus.img ? (
                <img className="uploaded-image-preview-2" src={bonus.img} alt={`Bild für Bonus ${index + 1}`} />
            ) : (
                <span className="uploaded-image-preview-3">
                    <div className="upload-image-preview">
                        <img src="./img/upload1.svg" />
                        <span>Click here to upload your bonus</span>
                    </div>
                </span>
            )}
        </label>

            <input 
            className='bonus-inputs-fett'
                type="text" 
                value={bonus.label}
                onChange={(e) => {
                    const updatedBonuses = [...selectBonus];
                    updatedBonuses[index].label = e.target.value;
                    setSelectBonus(updatedBonuses);
                }}
                placeholder="Bonus Title"
            />      

            <textarea 
            className="bonus-textarea"
                value={bonus.description}
                onChange={(e) => {
                    const updatedBonuses = [...selectBonus];
                    updatedBonuses[index].description = e.target.value;
                    setSelectBonus(updatedBonuses);
                }}
                placeholder="Bonus Description"
            />

            {/* <input 
                        className='bonus-inputs'
                type="text" 
                value={bonus.emailTemplateLink}
                onChange={(e) => {
                    const updatedBonuses = [...selectBonus];
                    updatedBonuses[index].emailTemplateLink = e.target.value;
                    setSelectBonus(updatedBonuses);
                }}
                placeholder="Template Key (Optional)"
            /> */}
        </div>
    ))}
    </div>
</div>


    
<div className="config-section" id="Questions">
<div className="config-section-head">
        <div className="section-head-titles">
                    <p>3</p>  
                    <h3>Ask Questions</h3>
                 
            
            {isPremium && (
                <label>
                      <div className="question-right-pro-2">
                 <img className="crown" src="./img/crow.svg" alt="lock"/>
                 <p>PRO</p>
                 </div>
                    <input 
                        className="is-active-box"
                        type="checkbox"
                        checked={isQuickQuestionsActive}
                        onChange={e => setIsQuickQuestionsActive(e.target.checked)}
                    />
                </label>
                
            )}
        </div>
        
        {isPremium && (
            <button className="add_bonus_button-2" onClick={() => setQuickQuestions([...quickQuestions, { placeholder: '', options: [{ value: '', label: '' }] }])}>
                Add question
            </button>
        )}
    </div>
    {isPremium ? 
        quickQuestions.map((question, index) => (
            <div key={index} className="question-container">
                <div className="bonus-top-section">
                    <h4 id="blue">Question {index + 1}</h4>
                    <button onClick={() => handleDeleteQuestion(index)}>
                        <img className="trash-icon" src="./img/delete.svg" alt="trash"/>
                    </button>
                </div>
                <input 
                    className="questions-inputs-fett"
                    type="text" 
                    value={question.placeholder}
                    onChange={(e) => {
                        const updatedQuestions = [...quickQuestions];
                        updatedQuestions[index].placeholder = e.target.value;
                        setQuickQuestions(updatedQuestions);
                    }}
                    placeholder="Placeholder"
                />
                <div className="input-value-fields">
                    {question.options.map((option, optIndex) => (
                        <div key={optIndex} className="inputs">
                            <input 
                                className= "questions-inputs"
                                type="text" 
                                value={option.label}
                                onChange={(e) => {
                                    const updatedQuestions = [...quickQuestions];
                                    updatedQuestions[index].options[optIndex].label = e.target.value;
                                    setQuickQuestions(updatedQuestions);
                                }}
                                placeholder="Answer Label (Visible)"
                            />
                            <input 
                                className= "questions-inputs-value"
                                type="text" 
                                value={option.value}
                                onChange={(e) => {
                                    const updatedQuestions = [...quickQuestions];
                                    updatedQuestions[index].options[optIndex].value = e.target.value;
                                    setQuickQuestions(updatedQuestions);
                                }}
                                placeholder="Answer Value (Internal)"
                            />
                        </div>
                    ))}
                    <button 
                        className="add-button"
                        onClick={() => {
                            const updatedQuestions = [...quickQuestions];
                            updatedQuestions[index].options.push({ value: '', label: '' });
                            setQuickQuestions(updatedQuestions);
                        }}
                    >
                        <img className="add-button-img" src="./img/plus.svg" alt="" />
                    </button>
                </div>
            </div>
        ))
    : 
        <div className="question-container">
             <div className="question-unlock-pro-container">
                 <div className="question-right-pro">
                 <img className="crown" src="./img/crow.svg" alt="lock"/>
                 <p>PRO</p>
                 </div>
            <div className="question-lock-div">
            <img className="padlock" src="./img/lock.svg" alt="lock"/>
            </div>
            <h2>Unlock <span>PRO</span> to access</h2>
            <p>Check out our Club version and get more features for BonusClub.</p>
            <div className="question-button-box">
                <a id="about-pro-button" href="https://bonusclub.io" target="_blank">More about the Club</a>
            </div>
            </div>
        </div>
    }
</div>


<div className="config-section" id="Image Uploader">
    <div className="config-section-head">
        <div className="section-head-titles">
                    <p>4</p>  
                    <h3>Image/Video Uploader</h3>
    {/* <label>
        <input 
           className="is-active-box"
            type="checkbox"
            checked={isUploaderActive}
            onChange={e => setIsUploaderActive(e.target.checked)}
        />
    </label> */}
        </div>
    </div>
<div className="config-upload-inputs">
    <div className="number-input-wrapper">
    <p>Min Uploads</p>
        <img src="./img/plus.svg" alt="Increase" onClick={() => increaseValue("minFiles")} />
        <input 
            className="upload-input"
            type="number" 
            min="1"
            value={fileUploader.minFiles || 1}
            onChange={(e) => {
                if (e.target.value >= 0) {
                    setFileUploader({...fileUploader, minFiles: e.target.value});
                }
            }}
            placeholder="Min. Uploads"
        />
        <img src="./img/minus.svg" alt="Decrease" onClick={() => decreaseValue("minFiles")} />
    </div>
    <div className="number-input-wrapper">
        <p>Max Uploads</p>
        <img src="./img/plus.svg" alt="Increase" onClick={() => increaseValue("maxFiles")} />
        <input 
                    className="upload-input"
            type="number" 
            min="1"
            value={fileUploader.maxFiles}
            defaultValue="10"
            onChange={(e) => {
                if (e.target.value >= 0) {
                    setFileUploader({...fileUploader, maxFiles: e.target.value});
                }
            }}
            placeholder="Max. Uploads"
        />
        <img src="./img/minus.svg" alt="Decrease" onClick={() => decreaseValue("maxFiles")} />
    </div>
</div>
</div>
 <div className="config-section" id="Userdata">
 <div className="config-section-head">
     <div className="section-head-titles">
                    <p>5</p>  
                    <h3>Customize Your Data Fields</h3>
 {/* <label>
        <input 
            className="is-active-box"
            type="checkbox"
            checked={isUserDataActive}
            onChange={e => setIsUserDataActive(e.target.checked)}
        />
    </label> */}
</div>
</div>
            {fields.map((field, index) => (
                           <div className="user-data-checks">
    <div key={index} className="user-data-field">
        <input 
            id="userdata-box"
            className="is-active-box"
            type="checkbox" 
            checked={field.selected} 
            onChange={() => {
                const updatedFields = [...fields];
                updatedFields[index].selected = !updatedFields[index].selected;
                setFields(updatedFields);
            }}
        />
                <label>{field.label}</label>
          </div>
    </div>
    
))}
        </div>         


       

        <div className="config-section"  id="More">
   

   
   

        <div className="config-section-head">
        <div className="section-head-titles">
                    <p>6</p>  
                    <h3>More</h3>
                    </div>
</div>
<div className="edit-type-desc-wrapper">
    <label htmlFor="termsOfUse">Terms of Use Link:</label>
    <input
            className= "questions-inputs-fett-desc"
        type="url"
        id="termsOfUse"
        value={termsOfUseLink}
        onChange={(e) => setTermsOfUseLink(e.target.value)}
        placeholder="Enter Terms of Use link"
    />
</div>
<div style={{marginBottom: '20px'}} className="edit-type-desc-wrapper">
    <label htmlFor="imprint">Imprint Link:</label>
    <input
        className= "questions-inputs-fett-desc"
        type="url"
        id="imprint"
        value={imprintLink}
        onChange={(e) => setImprintLink(e.target.value)}
        placeholder="Enter Imprint link"
    />
</div>

<div className="edit-type-desc">
    <div className="edit-type-desc-wrapper">
        <h3>Page Descriptions</h3>
            <p>Explanation: Step 1</p>
        {/* Title Input */}
        <input
            id="title"
            value={currentDescription.typeTitle || dummyData[objectType].typeTitle || ''}
            onChange={(e) =>
                setCurrentDescription((prev) => ({ ...prev, typeTitle: e.target.value }))
            }
            placeholder="Type Title"
        />

        {/* Neues Objekt 1 */}
        <input
            value={currentDescription.typeStep1 || dummyData[objectType].typeStep1 || ''}
            onChange={(e) =>
                setCurrentDescription((prev) => ({ ...prev, typeStep1: e.target.value }))
            }
            placeholder={`Type Step 1`}
        />
        <input
            value={currentDescription.typeDescription1 || dummyData[objectType].typeDescription1 || ''}
            onChange={(e) =>
                setCurrentDescription((prev) => ({ ...prev, typeDescription1: e.target.value }))
            }
            placeholder={`Type Description 1`}
        />
            <p>Explanation: Step 2</p>

        {/* Neues Objekt 2 */}
        <input
            value={currentDescription.typeStep2 || dummyData[objectType].typeStep2 || ''}
            onChange={(e) =>
                setCurrentDescription((prev) => ({ ...prev, typeStep2: e.target.value }))
            }
            placeholder={`Type Step 2`}
        />
        <input
            value={currentDescription.typeDescription2 || dummyData[objectType].typeDescription2 || ''}
            onChange={(e) =>
                setCurrentDescription((prev) => ({ ...prev, typeDescription2: e.target.value }))
            }
            placeholder={`Type Description 2`}
        />
            <p>Explanation: Step 3</p>

        {/* Neues Objekt 3 */}
        <input
            value={currentDescription.typeStep3 || dummyData[objectType].typeStep3 || ''}
            onChange={(e) =>
                setCurrentDescription((prev) => ({ ...prev, typeStep3: e.target.value }))
            }
            placeholder={`Type Step 3`}
        />
        <input
            value={currentDescription.typeDescription3 || dummyData[objectType].typeDescription3 || ''}
            onChange={(e) =>
                setCurrentDescription((prev) => ({ ...prev, typeDescription3: e.target.value }))
            }
            placeholder={`Type Description 3`}
        />

    </div>

</div>
<div className="advanced-settings">
    <p>Bonus</p>
  

    <input 
        className= "questions-inputs-fett-desc"
        type="text" 
        value={selectBonusTitle}
        onChange={(e) => setSelectBonusTitle(e.target.value)}
        placeholder="Bonus Header Titel"
    />
    <textarea 
        value={selectBonusDescription}
        onChange={(e) => setSelectBonusDescription(e.target.value)}
        placeholder="Bonus Header Beschreibung"
    />
    <p>Questions</p>
    <input 
            className= "questions-inputs-fett-desc"
        type="text" 
        value={quickQuestionsTitle}
        onChange={(e) => setQuickQuestionsTitle(e.target.value)}
        placeholder="Quickquestions Header Titel"
    />
    <textarea 
        value={quickQuestionsDescription}
        onChange={(e) => setQuickQuestionsDescription(e.target.value)}
        placeholder="Quickquestions Header Beschreibung"
    />
        <p>File Upload</p>
    <input 
            className= "questions-inputs-fett-desc"
        type="text" 
        value={uploadTitle}
        onChange={(e) => setUploadTitle(e.target.value)}
        placeholder="Upload Header Titel"
    />
    <textarea 
        value={uploadDescription}
        onChange={(e) => setUploadDescription(e.target.value)}
        placeholder="Upload Header Beschreibung"
    />
        <p>Personal Info</p>
     <input 
        className= "questions-inputs-fett-desc"
        type="text" 
        value={userDataFieldsTitle}
        onChange={(e) => setUserDataFieldsTitle(e.target.value)}
        placeholder="UserDataFields Titel"
    />
    <textarea 
        value={userDataFieldsDescription}
        onChange={(e) => setUserDataFieldsDescription(e.target.value)}
        placeholder="UserDataFields Beschreibung"
    />
        <p>Thank You Page</p>
      <textarea 
                value={thankYouSettings.thankYouText}
                onChange={(e) => setThankYouSettings(prev => ({ ...prev, thankYouText: e.target.value }))}
                placeholder="Dankeschön-Text hier eingeben"
            />
</div>
<div>
    </div>
    {/* <label>
        Ist Follow-Up aktiv:
        <input 
            type="checkbox" 
            checked={isFollowUpActive} 
            onChange={e => setIsFollowUpActive(e.target.checked)} 
        />
    </label>
    <label>
        Follow-Up Intervall:
        <select value={followUpIntervall} onChange={e => setFollowUpIntervall(e.target.value)}>
            <option value="1">1 Day</option>
            <option value="7">1 Week</option>
            <option value="30">1 Month</option>
        </select>
    </label> */}
</div>
</div>
</div>
    </div>
    
);
                    }

export default AdminConfigSettings;
