import {
  SET_USER_DATA,
  SET_QUICK_QUESTIONS,
  SET_SELECTED_BONUS,
  SET_INPUTS,
  SET_CURRENT_STEP,
  SUBMIT_START, // Fügen Sie diesen Import hinzu
  SUBMIT_SUCCESS, // Fügen Sie diesen Import hinzu
  SUBMIT_FAILURE, // Fügen Sie diesen Import hinzuM
  SAVE_FILE_URLS,
  SET_THUMBNAILS, 
  SET_HAS_MANY_ITEMS,// Fügen Sie diesen Import hinzu

} from './actionTypes';



const initialState = {
  uploadedFiles: [],
  userData: {},
  quickQuestions: {},
  selectedBonus: null,
  inputs: {},
  currentStep: 1,
  surveyData: {},
  isLoading: false,
  error: null,
  thumbnails: [], 
  hasManyItems: false


};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_START:
            return {
                ...state,
                isLoading: true
            };
            
        case SUBMIT_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case SUBMIT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
            case SET_HAS_MANY_ITEMS:
              return {
                  ...state,
                  hasManyItems: action.payload
              };
            case SAVE_FILE_URLS:
              return {
                  ...state,
                  uploadedFiles: [...state.uploadedFiles, action.payload]
              };
              case SET_THUMBNAILS:
                return {
                  ...state,
                  thumbnails: action.payload // Hier aktualisieren wir den Zustand mit den neuen Thumbnails
                };
          

    case SET_USER_DATA:
      return { ...state, userData: action.payload };
    case SET_QUICK_QUESTIONS:
      return { ...state, quickQuestions: action.payload };
    case SET_SELECTED_BONUS:
      return { ...state, selectedBonus: action.payload };
    case SET_INPUTS:
      return { ...state, inputs: action.payload };
    case SET_CURRENT_STEP:
      return { ...state, currentStep: action.payload };
    default:
      
      return state;
  }
};

export default reducer;
