import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './WizardMagic.css';
import { widgetTemplates } from './widgetTemplates';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getDatabase, ref as dbRef, set, push, get } from "firebase/database";
import { getAuth } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinnero from './LoadingSpinnero';

// Funktion zur Bestimmung, ob eine Farbe dunkel ist
const isDarkColor = (color) => {
    const rgb = parseInt(color.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >>  8) & 0xff;
    const b = (rgb >>  0) & 0xff;

    // Verwende die Luminanz-Formel
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminance < 128;
};

// Funktion zur Überprüfung der Einzigartigkeit der Route
const checkUniqueRoute = async (route, db) => {
    const routeRef = dbRef(db, `publicPages/${route}`);
    const snapshot = await get(routeRef);
    return snapshot.exists();
};

const WizardMagic = ({ pageName, hotelInfo, selectedHighlights, backgroundColor, imageFile, primaryFont, secondaryFont, fontColor }) => {
    const [statusMessage, setStatusMessage] = useState('Performing magic action...');
    const [invert, setInvert] = useState(isDarkColor(backgroundColor));
    const navigate = useNavigate();
    const isPageCreatedRef = useRef(false);

    useEffect(() => {
        const createPage = async () => {
            if (isPageCreatedRef.current) return;
            isPageCreatedRef.current = true;

            try {
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) {
                    throw new Error('User not authenticated');
                }

                const storage = getStorage();
                const db = getDatabase();

                let imgLogoUrl = '';

                // Überprüfen, ob ein Logo vorhanden ist, bevor versucht wird, es hochzuladen
                if (imageFile) {
                    const imageFileRef = ref(storage, `images/${uuidv4()}`);
                    await uploadBytes(imageFileRef, imageFile);
                    imgLogoUrl = await getDownloadURL(imageFileRef);
                }
                // Prepare widget data
                const widget1 = {
                    ...widgetTemplates.widget1,
                    imgBanner: hotelInfo.imageUrl,
                    imgLogo: imgLogoUrl || '', // Überprüfen, ob imgLogoUrl vorhanden ist, ansonsten leerer String
                    text1: `Welcome to ${hotelInfo.hotelName}`,
                    text2: hotelInfo.summary,
                    id: uuidv4(),
                    backgroundColor,
                    textColor1: fontColor.primary,
                    textColor2: fontColor.secondary,
                    textColor3: fontColor.secondary,
                    inverted: invert
                };

                const widget4 = {
                    ...widgetTemplates.widget4,
                    id: uuidv4(),
                    backgroundColor,
                    textColor1: fontColor.primary,
                    textColor2: fontColor.secondary,
                    textColor3: fontColor.secondary,
                    shadow: true,
                    border: false,
                    inverted: invert
                };

                const widget6 = {
                    ...widgetTemplates.widget6,
                    id: uuidv4(),
                    backgroundColor,
                    textColor1: fontColor.primary,
                    textColor2: fontColor.secondary,
                    textColor3: fontColor.secondary,
                    shadow: true,
                    border: false,
                    inverted: invert
                };

                const widget10 = {
                    ...widgetTemplates.widget10,
                    id: uuidv4(),
                    backgroundColor,
                    textColor1: widgetTemplates.widget10.textColor1,
                    textColor2: fontColor.secondary,
                    textColor3: fontColor.secondary,
                    inverted: invert,
                    shadow: true,
                    border: false
                };

                // Neues Widget 7 hinzufügen
                const widget7 = {
                    ...widgetTemplates.widget7,
                    id: uuidv4(),
                    text1: 'Things to do',
                    text2: 'Discover a variety of activities and amenities designed to make your stay enjoyable and memorable at our hotel.',
                    backgroundColor,
                    textColor1: fontColor.primary,
                    textColor2: fontColor.secondary,
                    textColor3: fontColor.secondary,
                    inverted: invert,
                    shadow: true,
                    border: false
                };

                // Prepare address widget (widget13)
                const addressParts = hotelInfo.hotelAddress.split(',');
                const widget13 = {
                    ...widgetTemplates.widget13,
                    text1: widgetTemplates.widget13.text1,
                    text2: addressParts[2]?.trim() || '',
                    text3: addressParts[1]?.trim() || '',
                    text4: addressParts[0]?.trim() || '',
                    id: uuidv4(),
                    backgroundColor,
                    textColor1: fontColor.primary,
                    textColor2: fontColor.secondary,
                    textColor3: fontColor.secondary,
                    inverted: invert
                };

                // Prepare FAQ widgets (widget5)
                const faqWidgets = [
                    {
                        ...widgetTemplates.widget5,
                        text1: 'What time is check-in and check-out?',
                        text2: `Check-in is at 3 PM and check-out is at 11 AM at ${hotelInfo.hotelName}.`,
                        id: uuidv4(),
                        backgroundColor,
                        textColor1: fontColor.primary,
                        textColor2: fontColor.secondary,
                        textColor3: fontColor.secondary,
                        inverted: invert
                    },
                    {
                        ...widgetTemplates.widget5,
                        text1: 'Is breakfast included in the room rate?',
                        text2: `Yes, breakfast is included in the room rate for all guests at ${hotelInfo.hotelName}.`,
                        id: uuidv4(),
                        backgroundColor,
                        textColor1: fontColor.primary,
                        textColor2: fontColor.secondary,
                        textColor3: fontColor.secondary,
                        inverted: invert
                    },
                    {
                        ...widgetTemplates.widget5,
                        text1: 'Do you offer free Wi-Fi?',
                        text2: `Yes, ${hotelInfo.hotelName} offers complimentary Wi-Fi throughout the property.`,
                        id: uuidv4(),
                        backgroundColor,
                        textColor1: fontColor.primary,
                        textColor2: fontColor.secondary,
                        textColor3: fontColor.secondary,
                        inverted: invert
                    },
                    {
                        ...widgetTemplates.widget5,
                        text1: 'Is parking available?',
                        text2: `${hotelInfo.hotelName} offers free parking for all guests. Please contact the front desk for more details.`,
                        id: uuidv4(),
                        backgroundColor,
                        textColor1: fontColor.primary,
                        textColor2: fontColor.secondary,
                        textColor3: fontColor.secondary,
                        inverted: invert
                    }
                ];

                // Prepare video tour widget (widget15)
                const widget15 = {
                    ...widgetTemplates.widget15,
                    text1: `How to Check-In at ${hotelInfo.hotelName}`,
                    id: uuidv4(),
                    backgroundColor,
                    textColor1: fontColor.primary,
                    textColor2: fontColor.secondary,
                    textColor3: fontColor.secondary,
                    inverted: invert
                };

                // Prepare contact widget (widget14)
                const widget14 = {
                    ...widgetTemplates.widget14,
                    id: uuidv4(),
                    backgroundColor,
                    text2: `office@${hotelInfo.hotelName.replace(/[^a-zA-Z0-9]+/g, '').toLowerCase()}.com`,
                    textColor1: fontColor.primary,
                    textColor2: fontColor.secondary,
                    textColor3: fontColor.secondary,
                    inverted: invert
                };

                // Prepare additional widgets (widget18 and widget19)
                const widget18 = {
                    ...widgetTemplates.widget18,
                    id: uuidv4(),
                    backgroundColor,
                    textColor1: fontColor.primary,
                    textColor2: fontColor.secondary,
                    textColor3: fontColor.secondary,
                    inverted: invert,
                    shadow: true,
                    border: false
                };

                const widget19 = {
                    ...widgetTemplates.widget19,
                    id: uuidv4(),
                    backgroundColor,
                    textColor1: fontColor.primary,
                    textColor2: fontColor.secondary,
                    textColor3: fontColor.secondary,
                    inverted: invert,
                    shadow: true,
                    border: false
                };

                // Generate custom route
                let customRoute = hotelInfo.hotelName.toLowerCase().replace(/[^a-z0-9]+/g, '-');
                let customDomain = hotelInfo.hotelName.toLowerCase().replace(/[^a-z0-9]+/g, '-');

                // Überprüfen, ob customRoute und customDomain bereits existieren und ggf. anpassen
                let isUnique = await checkUniqueRoute(customRoute, db);
                if (isUnique) {
                    customRoute += '-1';
                    customDomain += '-1';
                }

                // Create a new page entry with a unique key
                const newPageRef = push(dbRef(db, 'pages'));
                const newPageId = newPageRef.key;

                // Set initial widgets
                const widgets = {
                    0: widget1,
                    1: widget13, // Move widget13 right after widget1
                    2: widget4,
                    3: widget6,
                    4: widget10,
                    5: widget18,
                    6: widget7, // Neues Widget 7 direkt nach widget10
                };

                // Add selected highlights as widgets
                let widgetIndex = 7; // Startindex nach dem neuen Widget 7
                for (let index in selectedHighlights) {
                    if (selectedHighlights[index]) {
                        const highlight = hotelInfo.highlights[index];
                        const selectedWidgetType = highlight.widgetType;
                        const selectedWidgetTemplate = widgetTemplates[selectedWidgetType];
                        if (selectedWidgetTemplate) {
                            const newWidget = {
                                ...selectedWidgetTemplate,
                                text1: highlight.title,
                                text2: highlight.description,
                                id: uuidv4(),
                                position: widgetIndex,
                                backgroundColor,
                                textColor1: fontColor.primary,
                                textColor2: fontColor.secondary,
                                textColor3: fontColor.secondary,
                                inverted: invert
                            };
                            widgets[widgetIndex] = newWidget;
                            widgetIndex++;
                        }
                    }
                }

                // Add FAQ widgets (widget5)
                faqWidgets.forEach((faqWidget, idx) => {
                    widgets[widgetIndex + idx] = {
                        ...faqWidget,
                        position: widgetIndex + idx,
                    };
                });

                widgetIndex += faqWidgets.length;

                // Add widget15 (video tour)
                widgets[widgetIndex] = {
                    ...widget15,
                    position: widgetIndex,
                };
                widgetIndex++;

          
                widgetIndex++;
                widgets[widgetIndex] = {
                    ...widget19,
                    position: widgetIndex,
                };
                widgetIndex++;

                // Add widget14 (contact)
                widgets[widgetIndex] = {
                    ...widget14,
                    position: widgetIndex,
                };

                // Set page data
                await set(newPageRef, {
                    customRoute,
                    customDomain,
                    globalBigFont: primaryFont,
                    globalSmallFont: secondaryFont,
                    userId: user.uid,
                    name: hotelInfo.hotelName,
                    description: `This is the first guest page of ${hotelInfo.hotelName}`,
                    backgroundColor,
                    fontColors: fontColor,
                    widgets: widgets
                });

                // Save to publicPages
                await set(dbRef(db, `publicPages/${customRoute}`), {
                    pageId: newPageId,
                    userId: user.uid
                });

                setStatusMessage('Page created successfully');

                // Redirect to the new page
                navigate(`/settings/page/${newPageId}`);
            } catch (error) {
                console.error('Error creating page:', error);
                setStatusMessage('Error creating page');
            }
        };

        createPage();
    }, [pageName, hotelInfo, selectedHighlights, backgroundColor, imageFile, primaryFont, secondaryFont, fontColor, navigate, invert]);

    useEffect(() => {
        setInvert(isDarkColor(backgroundColor));
    }, [backgroundColor]);

    return (
        <div>
           <LoadingSpinnero />
        </div>
    );
};

export default WizardMagic;
