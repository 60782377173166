import React from 'react';
import './ImageSizeSlider.css'; // Stellen Sie sicher, dass Sie die CSS-Datei entsprechend erstellen

const ImageSizeSlider = ({ label, width, setWidth, min = 0, max = 500 }) => {
  const handleSliderChange = (e) => {
    setWidth(e.target.value);
  };

  const handleInputChange = (e) => {
    // Erlaubt nur numerische Eingaben und begrenzt den Wertebereich
    let value = e.target.value.replace(/[^0-9]/g, '');
    value = Math.max(min, Math.min(max, value)); // Stellt sicher, dass der Wert innerhalb von min und max liegt
    setWidth(value);
  };

  return (
    <div className="image-size-slider-container">
      <label className="slided-label">{label}</label>
      <input
        type="range"
        min={min}
        max={max}
        value={width}
        className="slided"
        id="image-size-slider"
        onChange={handleSliderChange}
      />
      <input
        type="text"
        className="slided-value-input"
        value={width}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default ImageSizeSlider;
