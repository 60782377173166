import React from "react";

const StepOne = ({ selectedBox, setSelectedBox, handleNext }) => {
  return (
    <div>
      <div
        className={selectedBox === "bonus_one" ? "selectedBox" : "box"}
        onClick={() => setSelectedBox("bonus_one")}
      >
        Bonus One
      </div>
      <div
        className={selectedBox === "bonus_two" ? "selectedBox" : "box"}
        onClick={() => setSelectedBox("bonus_two")}
      >
        Bonus Two
      </div>
      <button onClick={handleNext}>Next</button>
    </div>
  );
};

export default StepOne;