import './EndWidget.css';
import './WidgetStyle.css';
import { useFont } from '../../Design/FontContext';
import React, { useState } from 'react';
import DeleteModal from './DeleteModal';

function appendPx(value) {
    if (typeof value === 'string' && isNaN(value)) {
        return value;
    }
    return `${value}px`;
}

function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    }
    return text.slice(0, maxLength) + '...';
}

function EndWidget({ data, onRemove, isSelected, isEditMode, inverted }) {
    const defaultBannerSrc = '/img/mail1.svg';
    const defaultLogoSrc = '/img/phone1.svg';
    const deleteLogo = '/img/delete.svg';

    const widgetStyle = {
        backgroundColor: data.backgroundColor,
        marginBottom: appendPx(data.marginBottom),
        marginTop: appendPx(data.marginTop),
        paddingBottom: appendPx(data.paddingBottom),
        paddingTop: appendPx(data.paddingTop),
        borderRadius: appendPx(data.borderRadius),
        border: getBorderStyle(),
        boxShadow: data.shadow ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px' : 'none',
    };

    function isDark(color) {
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        const luma = 0.299 * r + 0.587 * g + 0.114 * b;
        return luma < 128;
    }

    function getBorderStyle() {
        if (isEditMode) {
            if (isSelected) {
                const borderColor = isDark(data.backgroundColor) ? '#FFFFFF' : '#000000';
                return `2px dashed ${borderColor}`;
            } else {
                return data.border ? `1.5px solid ${data.borderColor}` : '2px dashed transparent';
            }
        } else {
            return data.border ? `1.5px solid ${data.borderColor}` : 'none';
        }
    }

    const fonts = useFont();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (event) => {
        onRemove(event, data.id);
        setShowDeleteModal(false);
    };

    return (
        <div className="end_widget" style={widgetStyle}>
            <div>
                {isEditMode && (
                    <button className="delete_button" onClick={() => setShowDeleteModal(true)}>
                        <img className="delete_logo" src={deleteLogo} alt="Löschen" />
                    </button>
                )}
                {isEditMode && showDeleteModal && (
                    <DeleteModal
                        isOpen={showDeleteModal}
                        onClose={() => setShowDeleteModal(false)}
                        onConfirm={(event) => handleDelete(event, data.id)}
                    />
                )}
            </div>
            <div className="end_widget_top">
                <h2 style={{
                    color: data.textColor1,
                    fontSize: appendPx(data.text1Size),
                    fontWeight: data.text1Weight,
                    fontFamily: fonts.globalBigFont,
                }}>
                    {data.text1}
                </h2>
            </div>
            <div className="end_widget_contact">
                <p style={{
                    color: data.textColor2,
                    fontSize: appendPx(data.text2Size),
                    fontWeight: data.text2Weight,
                    fontFamily: fonts.globalSmallFont,
                }}>
                    <a href={`mailto:${data.text2}`} style={{ color: 'inherit' }}>
                        {truncateText(data.text2, 25)}
                    </a>
                </p>
                <img
                    src={data.imgBanner || defaultBannerSrc}
                    className={`end_widget_logo_img ${inverted ? 'inverted' : ''}`}
                    alt="Logo"
                    style={{ filter: data.inverted ? 'invert(100%)' : 'none' }}
                />
            </div>
            <div className="end_widget_contact_2">
                <p style={{
                    color: data.textColor2,
                    fontSize: appendPx(data.text2Size),
                    fontWeight: data.text2Weight,
                    fontFamily: fonts.globalSmallFont,
                }}>
                    <a href={`tel:${data.text3}`} style={{ color: 'inherit' }}>
                        {truncateText(data.text3, 25)}
                    </a>
                </p>
                <img
                    src={data.imgLogo || defaultLogoSrc}
                    className={`end_widget_logo_img ${inverted ? 'inverted' : ''}`}
                    alt="Logo"
                    style={{ filter: data.inverted ? 'invert(100%)' : 'none' }}
                />
            </div>
        </div>
    );
}

export default EndWidget;
