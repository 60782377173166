import React, { useState, useEffect } from "react";
import {
  handleUpload,
  handleInputChange,
  removeFile,
  useDropzoneConfig,
  handleBack,
  getRootProps,
  getInputProps,
  handleSubmission
} from "./Functions"; // Stelle sicher, dass der Importpfad korrekt ist
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import './SurveyOne.css';
import { ref, push, set } from "firebase/database";
import { app, database, storage  } from './firebaseConfig';
import { ref as storageRef, getDownloadURL } from "firebase/storage";



const SurveyTwo = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedBox, setSelectedBox] = useState(null);
    const [inputs, setInputs] = useState({
        tag: 12345,
        questionOne: "",
        selectedBox: "",
        questionTwo: "",
        questionThree: "",
        firstName: "",
        lastName: "",
        telephone: "",
        email: "",
        termsOne: false,
        termsTwo: false,
        downloadURLs: [],
        uploadedImages: [],
        timestamp: ""
      });
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      setInputs(prev => ({ ...prev, selectedBox }));
    }, [selectedBox]);
    const {
        uploadedFiles: dropzoneUploadedFiles,
        setUploadedFiles: setDropzoneUploadedFiles,
        getRootProps,
        getInputProps
      } = useDropzoneConfig();

  
      const handleNext = () => {
        if (currentStep === 3 && dropzoneUploadedFiles.length) {
            setIsLoading(true); 
            handleUpload(
                dropzoneUploadedFiles,
                storage,
                setInputs,
                setCurrentStep,
                setIsLoading
            );
        } else {
            setCurrentStep(prevStep => prevStep + 1);
        }
    };
  

  return (
    <div>
    {currentStep === 1 && (
      <StepOne
        selectedBox={selectedBox}
        setSelectedBox={setSelectedBox}
        handleNext={handleNext}
      />
    )}
    {currentStep === 2 && (
   <StepTwo
   handleInputChange={handleInputChange}
   handleBack={handleBack}
   handleNext={handleNext}
   setInputs={setInputs} // Stelle sicher, dass setInputs übergeben wird
 />   )}

    {currentStep === 3 && (
    <StepThree
    getRootProps={getRootProps}
    getInputProps={getInputProps}
    uploadedFiles={dropzoneUploadedFiles}
    removeFile={file => removeFile(file, dropzoneUploadedFiles, setDropzoneUploadedFiles)}
    handleBack={handleBack}
    handleNext={handleNext}
    isLoading={isLoading}
    currentStep={currentStep}
  />    )}

    {currentStep === 4 && (
    <StepFour
    inputs={inputs}
    handleInputChange={(e) => handleInputChange(e, setInputs)}
    handleBack={() => handleBack(setCurrentStep)}
    handleSubmission={() => handleSubmission(database, inputs, setCurrentStep)}
    setInputs={setInputs} // Diese Zeile ist wichtig
/>  )}

    {currentStep === 5 && (
        <StepFive />
        )}
  </div>
  );
};

export default SurveyTwo;
