import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, onValue } from 'firebase/database';

function WhatsappSendButton({ entry, onWhatsappSent }) {
    const [textTemplate, setTextTemplate] = useState('');
    const db = getDatabase();

    useEffect(() => {
        const textRef = dbRef(db, 'WhatsApp/whatsappText');
        onValue(textRef, (snapshot) => {
            if (snapshot.exists()) {
                setTextTemplate(snapshot.val());
            }
        });
    }, [db]);

    const handleSend = () => {
        let message = textTemplate;
        Object.keys(entry).forEach((key) => {
            const placeholder = `{{${key}}}`;
            if (message.includes(placeholder)) {
                message = message.replace(placeholder, entry[key]);
            }
        });

        // Properly encode the message to handle special characters and emojis
        const encodedMessage = encodeURIComponent(message);

        // Remove any non-digit characters from the phone number except the '+' sign
        const phoneNumber = entry.Number.replace(/[^+\d]/g, '');

        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank');

        // Call the onWhatsappSent callback
        onWhatsappSent();
    };

    return (
        <button
            style={{
                width: 'fit-content',
                display: 'flex',
                color: 'white',
                height: '40px',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: entry.whatsappSent ? 'gray' : 'green',
                cursor: entry.whatsappSent ? 'not-allowed' : 'pointer'
            }}
            onClick={handleSend}
        >
            {entry.whatsappSent ? 'Sent' : 'Send'}
            <img style={{width: '20px', height: '20px', marginLeft: '5px', display: entry.whatsappSent ? 'none' : 'block'}} src="./img/whatsapp.svg" alt="" />
        </button>
    );
}

export default WhatsappSendButton;
