import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, onValue, push, remove, set } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import Masonry from 'react-masonry-css';
import './DigitalMoods.css';

const DigitalAssets = ({ userId }) => {
    const [assets, setAssets] = useState([]);
    const [newAsset, setNewAsset] = useState({ file: null, description: '' });
    const [preview, setPreview] = useState(null);
    const [addingAsset, setAddingAsset] = useState(false);

    const database = getDatabase();
    const storage = getStorage();

    useEffect(() => {
        if (!userId) return;

        const assetsRef = dbRef(database, `Brandbooks/${userId}/assets`);
        onValue(assetsRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setAssets(Object.entries(data).map(([key, value]) => ({ id: key, ...value })).reverse());
            } else {
                setAssets([]);
            }
        });
    }, [userId, database]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setNewAsset({ ...newAsset, file });

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreview(null);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAsset({ ...newAsset, [name]: value });
    };

    const addAsset = () => {
        if (!newAsset.file) return;

        const fileRef = storageRef(storage, `assets/${userId}/${newAsset.file.name}`);
        uploadBytes(fileRef, newAsset.file).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((downloadURL) => {
                const assetsRef = dbRef(database, `Brandbooks/${userId}/assets`);
                const newAssetRef = push(assetsRef);
                set(newAssetRef, { file: downloadURL, description: newAsset.description });
                setNewAsset({ file: null, description: '' });
                setPreview(null);
                setAddingAsset(false);
            });
        });
    };

    const deleteAsset = (assetId) => {
        const assetRef = dbRef(database, `Brandbooks/${userId}/assets/${assetId}`);
        remove(assetRef);
    };

    const downloadAsset = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = url.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const breakpointColumnsObj = {
        default: 2,
        1300: 1
    };

    return (
        <div className="digital-moods">
            <h2>Assets</h2>
            {addingAsset ? (
                <div className="add-mood">
                    <h3>Add Asset</h3>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="file-input"
                        id="asset-file-upload"
                    />
                    <label htmlFor="asset-file-upload" className="file-input-label">
                        Choose File
                    </label>
                    {preview && <img src={preview} alt="Preview" className="mood-preview" />}
                    <input
                        type="text"
                        name="description"
                        value={newAsset.description}
                        onChange={handleInputChange}
                        placeholder="Description"
                    />
                    <button onClick={addAsset}>Add Asset</button>
                </div>
            ) : (
                <div className="add-color-nema" style={{ display: 'flex' }} onClick={() => setAddingAsset(true)}>
                    <img className="moods-plus-icon" src="./img/plus.svg" alt="Plus Icon" />
                    <span>Click here to Add Asset</span>
                </div>
            )}
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="moods-container"
                columnClassName="moods-column"
            >
                {assets.map((asset) => (
                    <div key={asset.id} className="mood-item">
                        <img src={asset.file} alt={asset.description} className="mood-image" />
                        <p>{asset.description}</p>
                        <div onClick={() => deleteAsset(asset.id)} className="moods-delete-icon">
                            <img src='./img/trasher.svg' alt="Delete" style={{ top: '20px', right: '15px', filter: 'invert(1)' }} className="branding-delete-button" />
                        </div>
                        <div onClick={() => downloadAsset(asset.file)} className="moods-download-icon">
                            <img src='./img/downloads.svg' alt="Download" className="branding-download-button" />
                        </div>
                    </div>
                ))}
            </Masonry>
        </div>
    );
};

export default DigitalAssets;
