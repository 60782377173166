import React from 'react';
import WhatsappSendButton from './WhatsappSendButton';

const MarketingTable = ({
    displayedEntries,
    handleDeleteEntry,
    toggleInterest,
    toggleField,
    handleWhatsappSent,
    setEditEntry,
    sortOrder // Receiving sortOrder as a prop
}) => {
    return (
        <table className="marketing-table">
            <thead>
                <tr>
                    <th className="button-cell">Delete</th>
                    <th className="checkbox-cell">Interest</th>
                    <th className="checkbox-cell">Meeting</th>
                    <th className="checkbox-cell">Sale</th>
                    <th className="button-cell">WhatsApp</th>
                    <th className="limited-width">Sent</th>
                    <th>Img</th>
                    <th>Name</th>
                    <th>Number</th>
                    <th>Website</th>
                    <th>State</th>
                    <th>Address</th>
                    <th>Reviews</th>
                    <th>Ratings</th>
                    <th>Country</th>
                    <th>Type</th>
                    <th>Import Name</th>
                    <th>Timestamp</th>
                    <th>Contacted Date</th>
                </tr>
            </thead>
            <tbody>
                {displayedEntries.map(entry => (
                    <tr key={entry.id}>
                        <td className="button-cell">
                            <button className="delete-button-background" onClick={() => handleDeleteEntry(entry.id)}><img style={{width: '15px', height: '15px', filter: 'invert(1)'}} src="./img/delete.svg" alt="" /></button>
                        </td>
                        <td className="checkbox-cell">
                            <input
                                type="checkbox"
                                checked={entry.interest}
                                onChange={() => toggleInterest(entry.id, entry.interest)}
                            />
                        </td>
                        <td className="checkbox-cell">
                            <input
                                type="checkbox"
                                checked={entry.meeting || false}
                                onChange={() => toggleField(entry.id, 'meeting', entry.meeting)}
                            />
                        </td>
                        <td className="checkbox-cell">
                            <input
                                type="checkbox"
                                checked={entry.sale || false}
                                onChange={() => toggleField(entry.id, 'sale', entry.sale)}
                            />
                        </td>
                        <td className="button-cell">
                            <WhatsappSendButton entry={entry} onWhatsappSent={() => handleWhatsappSent(entry.id)} />
                        </td>
                        <td className="limited-width">
                            {entry.whatsappSent ? (
                                <span className="green-circle"></span>
                            ) : (
                                ''
                            )}
                        </td>
                        <td>
                            {entry.Img && (
                                <img src={entry.Img} alt="Preview" />
                            )}
                        </td>
                        <td className="scrollable">
                            <span
                                className="name-editable"
                                onClick={() => setEditEntry(entry)}
                            >
                                {entry.Name}
                            </span>
                        </td>
                        <td className="scrollable">{entry.Number}</td>
                        <td className="scrollable">
                            <a href={entry.Website} target="_blank" rel="noopener noreferrer">
                                {entry.Website}
                            </a>
                        </td>
                        <td className="scrollable">{entry.State}</td>
                        <td className="scrollable">{entry.Address}</td>
                        <td className="scrollable">{entry.Reviews}</td>
                        <td className="scrollable">{entry.Ratings}</td>
                        <td className="scrollable">{entry.Country}</td>
                        <td className="scrollable">{entry.Type}</td>
                        <td className="scrollable">{entry.importName}</td>
                        <td className="scrollable">{entry.timestamp}</td>
                        <td className="scrollable">{entry.contactedDate}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default MarketingTable;
