import React, { useState, useEffect } from 'react';
import './EmailTemplates.css';
import { getDatabase, ref, get, update } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import DashboardHeader from '../AdminComponents/DashboardHeader';

export const fetchEmailTemplatesFromFirebase = async (userId) => {
    const db = getDatabase();
    const emailTemplatesRef = ref(db, `surveyConfigs/${userId}`);
    const snapshot = await get(emailTemplatesRef);
    if (snapshot.exists()) {
        return snapshot.val();
    } else {
        console.error("No email templates data available");
        return null;
    }
};

export const updateEmailTemplatesInFirebase = (userId, emailTemplatesConfig) => {
    const db = getDatabase();
    const emailTemplatesRef = ref(db, `surveyConfigs/${userId}`);
    return update(emailTemplatesRef, emailTemplatesConfig);
};

const OptionBox = ({ label, value, selectedOption, setSelectedOption }) => (
    <div 
        className={`option-box ${selectedOption === value ? 'selected' : ''}`}
        onClick={() => setSelectedOption(value)}
    >
        <div className="option-cta">
            <h3>Custom Design</h3>
            <p>Let our team craft your most exciting email templates.</p>
            <a href="https://bonusclub.io" target="_blank" >CONTACT US</a>
        </div>
        <span className={`option-box-text ${selectedOption === value ? 'selected' : ''}`}>{label}</span>
    </div>
);

const EmailTemplates = () => {
    const [selectedOption, setSelectedOption] = useState('d-4b58d531b9ed4c388f40dd66f3b00401');
    const [customTemplateId, setCustomTemplateId] = useState('');
    const [userId, setUserId] = useState(null);
    const [emailTemplatesData, setEmailTemplatesData] = useState(null); // Neuer Zustand für die Email-Templates-Daten
    const [saveStatus, setSaveStatus] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, user => {
            if (user) {
                setUserId(user.uid);
                // Daten aus der Datenbank holen und in den Zustand speichern
                fetchEmailTemplatesFromFirebase(user.uid)
                    .then(data => {
                        setEmailTemplatesData(data);
    
                        // Überprüfen, ob es sich um eine benutzerdefinierte ID handelt oder nicht
                        if ([
                            'd-1079272230ab4598b8ea311e1e0edd5b', 
                            'd-4b58d531b9ed4c388f40dd66f3b00401', 
                            'd-813bda9a3f5c4cb7a08c745f34e46614'
                        ].includes(data?.templateId)) {
                            setSelectedOption(data?.templateId);
                        } else if(data?.templateId) {
                            setSelectedOption('option4');
                            setCustomTemplateId(data?.templateId);
                        } else {
                            setSelectedOption('d-4b58d531b9ed4c388f40dd66f3b00401');
                        }                        
                    })
                    .catch(err => console.error("Fehler beim Holen der Daten:", err));
            } else {
                console.error("Kein Benutzer ist eingeloggt");
            }
        });
    }, []);
    

  const saveSelectionToFirebase = () => {
    if (!userId) {
        console.error("Keine Benutzer-ID gefunden");
        setSaveStatus('error');
        return;
    }

    let emailTemplatesConfig = {};
    if (selectedOption !== 'option4') {
        emailTemplatesConfig = { templateId: selectedOption };
    } else {
        emailTemplatesConfig = { templateId: customTemplateId };
    }

    updateEmailTemplatesInFirebase(userId, emailTemplatesConfig)
        .then(() => {
            console.log("Data Update Complete");
            setSaveStatus('success');
        })
        .catch(err => {
            console.error("Fehler beim Aktualisieren der Daten:", err);
            setSaveStatus('error');
        });
};


    return (
        <div className="email-templates-container">
                       <DashboardHeader /> 
                       
            <div className="email-templates-header">
            <h2>Email-Templates</h2>
            <button className="save-button" onClick={saveSelectionToFirebase}>Save Settings</button>
            {saveStatus === 'success' && <p className="success-message">New Template Saved!</p>}
        {saveStatus === 'error' && <p className="error-message">Error saving the template.</p>}
            </div>
            <div className="option_box_wrapper">
            <OptionBox 
                label="Template 1" 
                value="d-1079272230ab4598b8ea311e1e0edd5b" 
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
            />
            <OptionBox 
                label="Template 2" 
                value="d-4b58d531b9ed4c388f40dd66f3b00401"
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
            />
            <OptionBox 
                label="Template 3" 
                value="d-813bda9a3f5c4cb7a08c745f34e46614" 
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
            />
            <div className='custom-box-wrapper'>
            <OptionBox 
                label="Custom Design" 
                value="option4"
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
            />
            {selectedOption === 'option4' && (
                <div className="custom-input-section">
                    <input 
                        type="text" 
                        placeholder="Type in your Custom Template ID" 
                        value={customTemplateId}
                        onChange={(e) => setCustomTemplateId(e.target.value)}
                    />
                </div>
                
            )}
            </div>
            </div>
               
        </div>
        
    );
            }    

export default EmailTemplates;
