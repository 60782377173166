import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, onValue } from "firebase/database";
import '../Steps/FileUploader.css';
import './SelectedType.css'; 
import { useFont } from '../Design/FontContext';

import Type1Image1 from '../img/Snapshot.webp';
import Type1Image2 from '../img/Upload.webp';
import Type1Image3 from '../img/Bonus.webp';

import Type2Image1 from '../img/Social_Media.webp';
import Type2Image2 from '../img/Upload.webp';
import Type2Image3 from '../img/Bonus.webp';

import Type3Image1 from '../img/Review.webp';
import Type3Image2 from '../img/Upload.webp';
import Type3Image3 from '../img/Bonus.webp';
import PageLoading2 from "../Pages/PageLoading2";

const SelectedType = ({ handleNext }) => {
    const dispatch = useDispatch();
    const [availableTypes, setAvailableTypes] = useState([]);
    const { userId } = useParams();
    const [selectTypeTitle, setSelectTypeTitle] = useState("");
    const [selectTypeDescription, setSelectTypeDescription] = useState("");
    const [objectType, setObjectType] = useState("");
    const selectedFont = useFont();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {  
        setIsLoading(true);
        const db = getDatabase();
        const typeDescriptionsRef = ref(db, `surveyConfigs/${userId}/typeDescriptions`);
        const objectTypeRef = ref(db, `surveyConfigs/${userId}`);


    onValue(objectTypeRef, (snapshot) => {
        const data = snapshot.val();
        console.log("Object Type Data:", data);  // <-- Fügen Sie das hinzu
        if (data && data.objectType) {
            setObjectType(data.objectType);
        }
    }, {
        onlyOnce: true
    });

        onValue(typeDescriptionsRef, (snapshot) => {
            const typeData = snapshot.val();
            if (typeData) {
                // Extract all the types into an array
                const typesArray = Object.entries(typeData).map(([key, value]) => ({ typeId: key, ...value }));
                setAvailableTypes(typesArray);
            }
            setIsLoading(false);
        }, {
            onlyOnce: true
        });
    }, [userId]);
    
    const getImageBasedOnObjectTypeAndStep = (step) => {
        if(objectType === 'type1') {
            switch(step) {
                case 1: return Type1Image1;
                case 2: return Type1Image2;
                case 3: return Type1Image3;
                default: return null;
            }
        }
        if(objectType === 'type2') {
            switch(step) {
                case 1: return Type2Image1;
                case 2: return Type2Image2;
                case 3: return Type2Image3;
                default: return null;
            }
        }
        if(objectType === 'type3') {
            switch(step) {
                case 1: return Type3Image1;
                case 2: return Type3Image2;
                case 3: return Type3Image3;
                default: return null;
            }
        }
        return null;
    }
    return (
        <div className="select-type-container">
            {isLoading ? (
                <div style={{marginTop: '-70px'}}>
               <PageLoading2 />
                </div>
            ) : (
                <div className="type-list">
                    {availableTypes.filter(type => type.typeId === objectType).map((type) => (
                        <div key={type.typeId} className="type-item">
                            <div className="type-title">
                                <h2 style={{ fontFamily: selectedFont }} className="surveyHeader">{type.typeTitle}</h2>
                            </div>
                            <div className="type-wrapper">
                                <div className="type-description">
                                    <img className="type-preview-pic" src={getImageBasedOnObjectTypeAndStep(1)} alt="Type Image Step 1" />
                                    <h5 className="stepper-count">Step 1</h5>
                                    <h4>{type.typeStep1}</h4>
                                    <p>{type.typeDescription1}</p>
                                    <img className="down-right-button" src="../img/down_2.svg" alt="down" />
                                </div>
                                <div className="type-description">
                                    <img className="type-preview-pic-2" src={getImageBasedOnObjectTypeAndStep(2)} alt="Type Image Step 2" />
                                    <h5 className="stepper-count">Step 2</h5>
                                    <h4>{type.typeStep2}</h4>
                                    <p>{type.typeDescription2}</p>
                                    <img className="down-right-button" src="../img/down_2.svg" alt="down" />
                                </div>
                                <div className="type-description">
                                    <img className="type-preview-pic-3"  src={getImageBasedOnObjectTypeAndStep(3)} alt="Type Image Step 3" />
                                    <h5 className="stepper-count">Step 3</h5>
                                    <h4>{type.typeStep3}</h4>
                                    <p>{type.typeDescription3}</p>
                                </div>
                            </div>
                            <div className="bottom-buttons">
                                <button onClick={handleNext} className="buttonClassy">Next</button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
    
                    }    

export default SelectedType;
