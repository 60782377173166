
import React from "react";

const StepThree = ({
  getRootProps,
  getInputProps,
  uploadedFiles,
  removeFile,
  handleBack,
  handleNext,
  isLoading,
  currentStep,
}) => {
  return (
    <div>
      <div {...getRootProps()} style={{ border: '1px solid gray', padding: '20px', textAlign: 'center' }}>
        <input type="file" multiple {...getInputProps()} />
        <p>Ziehen Sie Ihre Dateien hierher oder klicken Sie zum Durchsuchen</p>
      </div>
  
      <aside>
        <h4>Dateien</h4>
        <ul>
          {uploadedFiles.map((file, index) => (
            <li key={file.name}>
              <div>
                <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} style={{ height: '100px' }} />
              </div>
              {file.name} - {file.size} bytes
              <button onClick={() => removeFile(file)}>Entfernen</button>
            </li>
          ))}
        </ul>
      </aside>
  
      <button onClick={handleBack}>Back</button>
      <button onClick={handleNext} disabled={uploadedFiles.length === 0 && currentStep === 3}>Next</button>
  
      <div>
        {isLoading && (
          <div className="loading-container">
            <div className="spinner"></div>
            Bitte warten, Ihre Dateien werden hochgeladen...
          </div>
        )}
      </div>
    </div>
  );
};

export default StepThree;
