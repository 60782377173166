import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import DigitalLogos from './DigitalLogos';
import DigitalFonts from './DigitalFonts';
import DigitalColors from './DigitalColors';
import DigitalMoods from './DigitalMoods';
import DigitalAssets from './DigitalAssets';
import './DigitalBrandbook.css';
import PageLoading2 from '../PageLoading2';
import DashboardHeader from '../../AdminComponents/DashboardHeader';

const DigitalBrandbook = () => {
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const auth = getAuth();

    useEffect(() => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            setUserId(currentUser.uid);
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 1000); // 1 Sekunde Ladezeit
    }, [auth]);

    return (
        <div className="digital-brandbook">
            <DashboardHeader />
            {isLoading ? (
                <div className="loading-screen">
                    <PageLoading2 />
                </div>
            ) : userId ? (
                <>
                <h1>Digital Brandbook</h1>
                <div className="digital-brandbook-wrapper-1" style={{display: 'flex', alignItems: 'start', justifyContent: 'top'}}>
                <DigitalLogos userId={userId} />
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <DigitalFonts userId={userId} />
                <DigitalColors userId={userId} />
                </div>
                </div>
                    <DigitalAssets userId={userId} />
                    <DigitalMoods userId={userId} />

                </>
            ) : (
                <p>Please log in to view your brandbook.</p>
            )}
        </div>
    );
};

export default DigitalBrandbook;
