import React, { useState, useEffect } from 'react';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  sendEmailVerification,
  signOut
} from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import './SignUp.css';
import { addUserEntry } from "./firebaseConfig"; // Stellen Sie sicher, dass Sie den richtigen Pfad zu Ihrer Konfigurationsdatei angeben
import GenerativeOrbAnimation from './Pages/GenerativeOrbAnimation.js';

function SignUp({ isAuthenticated, setIsAuthenticated }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState("");
  const [showSignIn, setShowSignIn] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showVerificationScreen, setShowVerificationScreen] = useState(false);
  const auth = getAuth();
  const [isValid, setIsValid] = useState(false); 
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [passwordConfirmationTouched, setPasswordConfirmationTouched] = useState(false);
  const navigate = useNavigate();
  const debouncedEmail = useDebounce(email, 600);
  const debouncedPassword = useDebounce(password, 600);
  const debouncedPasswordConfirmation = useDebounce(passwordConfirmation, 600);

  const handleEmailBlur = () => {
    setEmailTouched(true);
  };

  const handlePasswordBlur = () => {
    setPasswordTouched(true);
  };

  const handlePasswordConfirmationBlur = () => {
    setPasswordConfirmationTouched(true);
  };

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(() => {
      const handler = setTimeout(() => setDebouncedValue(value), delay);
      return () => clearTimeout(handler);
    }, [value, delay]);
  
    return debouncedValue;
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!isTimerActive) {
        if (user) {
          if (user.emailVerified) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } else {
          setIsAuthenticated(false);
        }
      }
    });
    
    return () => unsubscribe();
  }, [isTimerActive, auth, setIsAuthenticated]);

  useEffect(() => {
    const validateInputs = () => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      const passwordRegex = /^.{8,}$/;

      if (emailTouched && debouncedEmail && !emailRegex.test(debouncedEmail)) {
        setMessage("Please type in a valid e-mail address.");
        return false;
      }

      if (passwordTouched && debouncedPassword && !passwordRegex.test(debouncedPassword)) {
        setMessage("The password should be at least 8 characters long.");
        return false;
      }

      if (passwordConfirmationTouched && debouncedPassword && debouncedPasswordConfirmation && debouncedPassword !== debouncedPasswordConfirmation) {
        setMessage("Passwords do not match.");
        return false;
      }

      setMessage(""); 
      return true;
    };

    setIsValid(validateInputs());
  }, [debouncedEmail, debouncedPassword, debouncedPasswordConfirmation, emailTouched, passwordTouched, passwordConfirmationTouched]);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out");
      })
      .catch(error => {
        console.error("Error signing out: ", error);
      });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      await addUserEntry(userCredential.user.uid);
      
      await sendEmailVerification(userCredential.user);
      setMessage("");

      setIsTimerActive(true);
      setShowVerificationScreen(true);
      setTimeout(() => {
        setIsTimerActive(false);
        auth.signOut();
      }, 10000); // 10 Sekunden
    } catch (error) {
      setMessage(getFriendlyErrorMessage(error));
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (userCredential.user.emailVerified) {
        setMessage("Erfolgreich angemeldet!");
        setIsAuthenticated(true);
        navigate('/dashboard');
      } else {
        setMessage("Bitte bestätige deine E-Mail-Adresse, bevor du dich anmeldest.");
        setShowVerificationScreen(true);
      }
    } catch (error) {
      setMessage(getFriendlyErrorMessage(error));
    }
  };

  const handleGoogleSignup = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      setMessage("Successfully signed up with Google!");
    } catch (error) {
      setMessage(getFriendlyErrorMessage(error));
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset link sent to your email!");
    } catch (error) {
      setMessage(getFriendlyErrorMessage(error));
    }
  };

  function getFriendlyErrorMessage(error) {
    const firebaseErrorMapping = {
      'auth/invalid-email': 'This e-mail does not seem valid',
      'auth/user-disabled': 'Your account has been deactivated.',
      'auth/user-not-found': 'No account found. Did you mean Sign Up?',
      'auth/wrong-password': 'Sorry, wrong password.',
    };

    return firebaseErrorMapping[error.code] || 'Ein unbekannter Fehler ist aufgetreten.';
  }

  return (
    <div className="login">
      <GenerativeOrbAnimation />
      <div>
        <a className="about-button" href="https://bonusclub.io" target="_blank" rel="noopener noreferrer">About BonusClub</a>
        <img className="header-about-icon" src="./img/BonusClub_Logo.webp" alt="" />
      </div>
      <div className="login-container">
        {auth.currentUser ? (
          <div className="verify_mail_container">
            <h1>Make sure to verify your email!</h1>
            <p>We're happy to get you on board {auth.currentUser.email}</p>
            <Link onClick={handleSignOut} to="/"><button className="login-btn">Log In</button></Link>
          </div>
        ) : showVerificationScreen ? (
          <div className="verification-screen">
            Please verify your E-Mail
          </div>
        ) : showSignIn ? (
          <div className="signup-screen">
            <div className="toggle-buttons">
              <div>
                <button 
                  className={`toggle_button_1 ${showSignIn ? 'active' : ''}`}
                  onClick={() => setShowSignIn(true)}
                >
                  Log In
                </button>
                <button 
                  className={`toggle_button_1 ${!showSignIn ? 'active' : ''}`}
                  onClick={() => setShowSignIn(false)}
                >
                  Sign Up
                </button>
              </div>
            </div>
            <h2 className="login-header">BonusClub</h2>
            <p>Log in to your account</p>
            <form onSubmit={handleSignIn}>
              <div>
                <label className="login-label">Email:</label>
                <div className={`login-input-wrapper ${email ? "has-content" : "login-input-wrapper-passive"}`}>
                  <input className="login-input" type="email" placeholder='Your Email' value={email} onChange={(e) => setEmail(e.target.value)} onBlur={handleEmailBlur} required />
                  <img className="mail-icon" src="./img/mail.svg" alt="" />
                </div>
              </div>
              <div>
                <label className="login-label">Password:</label>
                <div className={`login-input-wrapper ${password ? "has-content" : "login-input-wrapper-passive"}`}>
                  <input className="login-input" type="password" placeholder='Your Password' value={password} onChange={(e) => setPassword(e.target.value)} onBlur={handlePasswordBlur} required />
                  <img className="lock-icon" src="./img/padlock.svg" alt="" />
                </div>
                <Link className="login-btn-4" to="/forgot-password">Forgot?</Link>
              </div>
              <button className={`login-btn-1 ${isValid ? "active-btn" : ""}`} type="submit" disabled={!isValid}>Log in</button>
            </form>
            <button className="login-btn-2" onClick={handleGoogleSignup}>
              <img className="google_logo" src="./img/google.webp" alt="" style={{marginRight: '10px'}}/>
              <p>Continue with Google</p>
            </button>
          </div>
        ) : showForgotPassword ? (
          <div className="signup-screen">
            <h2 className="login-header">Forgot Password</h2>
            <form onSubmit={handlePasswordReset}>
              <div>
                <label className="login-label">Email:</label>
                <input className="login-input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </div>
              <button className="login-btn" type="submit">Reset Password</button>
            </form>
            <button className="login-btn" onClick={() => setShowForgotPassword(false)}>Back to Sign In</button>
          </div>
        ) : (
          <div className="signup-screen">
            <div className="toggle-buttons">
              <div>
                <button 
                  className={`toggle_button_1 ${showSignIn ? 'active' : ''}`}
                  onClick={() => setShowSignIn(true)}
                >
                  Log In
                </button>
                <button 
                  className={`toggle_button_1 ${!showSignIn ? 'active' : ''}`}
                  onClick={() => setShowSignIn(false)}
                >
                  Sign Up
                </button>
              </div>
            </div>
            <h2 className="login-header">BonusClub</h2>
            <p>Create your Account</p>
            <form onSubmit={handleSignUp}>
              <div>
                <label className="login-label">Email:</label>
                <div className={`login-input-wrapper ${email ? "has-content" : "login-input-wrapper-passive"}`}>
                  <input className="login-input" type="email" placeholder='Set your E-Mail' value={email} onChange={(e) => setEmail(e.target.value)} onBlur={handleEmailBlur} required />
                  <img className="mail-icon" src="./img/mail.svg" alt="" />
                </div>
              </div>
              <div>
                <label className="login-label">Password:</label>
                <div className={`login-input-wrapper ${password ? "has-content" : "login-input-wrapper-passive"}`}>
                  <input className="login-input" type="password" placeholder='Create a password' value={password} onChange={(e) => setPassword(e.target.value)} onBlur={handlePasswordBlur} required />
                  <img className="lock-icon" src="./img/padlock.svg" alt="" />
                </div>
                <div className={`login-input-wrapper ${passwordConfirmation ? "has-content" : "login-input-wrapper-passive"}`}>
                  <input className="login-input" type="password" placeholder='Confirm password' value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} onBlur={handlePasswordConfirmationBlur} required />
                  <img className="lock-icon" src="./img/padlock.svg" alt="" />
                </div>
              </div>
              <button className={`login-btn-1 ${isValid ? "active-btn" : ""}`} type="submit" disabled={!isValid}>Create Account</button>
            </form>
            <button className="login-btn-2" onClick={handleGoogleSignup}>
              <img className="google_logo" src="./img/google.webp" alt="" style={{marginRight: '10px'}}/>
              <p>Continue with Google</p>
            </button>
          </div>
        )}
        <p className={`login-message ${message.includes("Erfolg") ? "success" : "error"}`}>{message}</p>
      </div>
      <div className="references">
        <a href="https://bonusclub.io" target="_blank" rel="noopener noreferrer"></a>
        <hr />
        <p>London</p>
        <p>Paris</p>
        <p>New York</p>
        <p>Tokio</p>
        <p>Vienna</p>
        <p>Sydney</p>
        <p>Los Angeles</p>
        <p>Shanghai</p>
        <p>Mexico City</p>
        <p>Singapur</p>       
        <hr />
      </div>
    </div>
  );
}

export default SignUp;
